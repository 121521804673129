import { Z_INDEX_MODAL_OVERLAY } from '@/theme/z-index';
import { styled } from 'goober';
import type { ReactNode } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface FixedPanelWithShadowLayerProps {
  leftOffset?: string;
  onOutsideClick: () => void;
  children: ReactNode;
  className?: string;
}

export const FixedPanelWithShadowLayer = ({
  leftOffset,
  onOutsideClick,
  children,
  className,
}: FixedPanelWithShadowLayerProps) => {
  return (
    <>
      <ShadowLayer leftOffset={leftOffset} />
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <FixedWrapper leftOffset={leftOffset} className={className}>
          {children}
        </FixedWrapper>
      </OutsideClickHandler>
    </>
  );
};

export const ShadowLayer = styled('div')<{ leftOffset?: string }>`
  background-color: rgba(2, 6, 44, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  ${({ leftOffset }) => leftOffset && `left: ${leftOffset}`};
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX_MODAL_OVERLAY};
`;

export const FixedWrapper = styled('div')<{
  leftOffset?: string;
}>`
  position: fixed;
  top: 0;
  ${({ leftOffset }) => leftOffset && `left: ${leftOffset}`};
  height: 100vh;
  min-width: 50%;
  z-index: ${Z_INDEX_MODAL_OVERLAY};
  background-color: ${({ theme }) =>
    theme.palettes.component.explore.page.background};
`;
