import { useEffect } from 'react';
import { type RecoilState, useRecoilState, useResetRecoilState } from 'recoil';

import { filterOutEmptyFilterValues } from '@/helpers/filters-helpers';

import { activeFilterTypeState } from './filter.state';

export const useHandleFilters = <Filters extends object>({
  appliedFiltersState,
  selectedFiltersState,
  updateLocationSearch,
  resetLocationSearch,
}: {
  appliedFiltersState: RecoilState<Filters>;
  selectedFiltersState: RecoilState<Filters>;
  updateLocationSearch: (filters: Filters) => void;
  resetLocationSearch: () => void;
}) => {
  const [activeFilterType, setActiveFilterType] = useRecoilState(
    activeFilterTypeState,
  );

  const resetActiveFilterType = useResetRecoilState(activeFilterTypeState);

  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFiltersState);

  const [appliedFilters, setAppliedFilters] =
    useRecoilState(appliedFiltersState);

  const resetAppliedFilters = useResetRecoilState(appliedFiltersState);
  const resetSelectedFilters = useResetRecoilState(selectedFiltersState);

  useEffect(() => {
    return () => {
      resetAppliedFilters();
      resetSelectedFilters();
      resetActiveFilterType();
    };
  }, [resetAppliedFilters, resetSelectedFilters, resetActiveFilterType]);

  useEffect(() => {
    setSelectedFilters({ ...appliedFilters });
  }, [appliedFilters, setSelectedFilters]);

  const clearSearchInput = () => {
    resetAppliedFilters();
  };

  const hasAnyActiveFilters =
    Object.values(selectedFilters).filter(filterOutEmptyFilterValues).length >
    0;

  const handleApplyFilters = () => {
    if (activeFilterType) {
      setActiveFilterType(null);
    }
    updateLocationSearch(selectedFilters);
    setAppliedFilters(selectedFilters);
  };

  const switchToSearchFilter = () => {
    if (hasAnyActiveFilters) {
      handleApplyFilters();
    }

    setActiveFilterType('search');
  };

  const handleSearchInputChange = (searchPhrase: string) => {
    setAppliedFilters(currentAppliedFilters => {
      const freshFilters = {
        ...currentAppliedFilters,
        search: searchPhrase,
      };
      updateLocationSearch(freshFilters);
      return freshFilters;
    });
  };

  const handleResetAppliedFilters = () => {
    resetLocationSearch();
    resetAppliedFilters();
  };

  const clearAppliedFilters = () => {
    handleResetAppliedFilters();
    setActiveFilterType(null);
  };

  const clearSelectedFilters = () => {
    resetSelectedFilters();
    clearAppliedFilters();
  };

  return {
    clearSearchInput,
    clearSelectedFilters,
    clearAppliedFilters,
    handleSearchInputChange,
    switchToSearchFilter,
    handleApplyFilters,
    hasAnyActiveFilters,
  };
};
