import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { EditStamp } from '@/features/companies/overview/edit-stamp';
import { SubHeader } from '@/ui/typography/widgets';
import { ReadMoreWidgetButton } from '@/ui/widget/read-more-widget-button';
import {
  TRANSITION_WIDGET_GAP,
  TransitionedWidget,
  WidgetOverflowItemsContainer,
} from '@/ui/widget/transitioned-widget';
import type { WidgetProps } from '@/ui/widget/widget';
import {
  WIDGET_CONTAINER_MAX_HEIGHT,
  WIDGET_CONTAINER_PADDING_VERTICAL,
} from '@/ui/widget/widget';

import { useOverflowTags } from './use-overflow-tags';

const TAGS_GAP = 12;

interface TagsOverflowContainerProps extends WidgetProps {
  children: ReactElement[];
  onEditClick: () => void;
  widgetHeight: number;
  subHeader?: string;
}

export const TagsOverflowContainer = ({
  children,
  onEditClick,
  subHeader,
  widgetHeight,
  disabled,
  showEditStamp,
  editStampProps,
  ...widgetProps
}: TagsOverflowContainerProps) => {
  const { t } = useTranslation('default');
  const [showMore, setShowMore] = useState(false);
  const [containerHeight, setContainerHeight] = useState(widgetHeight);

  const {
    visibleElements,
    overflowElements,
    containerRef,
    buttonRef,
    measuringRender,
    headerRef,
    difference,
    childrenContainerRef,
  } = useOverflowTags({
    children,
    containerHeight:
      widgetHeight -
      WIDGET_CONTAINER_PADDING_VERTICAL * 2 -
      TRANSITION_WIDGET_GAP * 2,
    additionalSpacePerElement: TAGS_GAP,
  });

  useEffect(() => {
    setContainerHeight(showMore ? widgetHeight + difference : widgetHeight);
  }, [showMore, difference, widgetHeight]);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const maxHeight = showMore ? WIDGET_CONTAINER_MAX_HEIGHT : containerHeight;

  return (
    <TransitionedWidget
      height={containerHeight}
      ref={containerRef}
      onEditClick={onEditClick}
      showMore={showMore}
      maxHeight={maxHeight}
      $measuringRender={measuringRender}
      disabled={disabled}
      justify={
        measuringRender || overflowElements.length > 0
          ? 'space-between'
          : 'flex-start'
      }
      {...widgetProps}
    >
      {subHeader && <SubHeader ref={headerRef}>{subHeader}</SubHeader>}
      <WidgetOverflowItemsContainer
        wrap="wrap"
        gap={`${TAGS_GAP}px`}
        ref={childrenContainerRef}
        $overflow={containerHeight >= maxHeight}
        $measuringRender={measuringRender}
      >
        {visibleElements}
        {showMore && overflowElements}
      </WidgetOverflowItemsContainer>
      {(measuringRender || overflowElements.length > 0) && (
        <ReadMoreWidgetButton
          ref={buttonRef}
          onClick={handleReadMore}
          showMore={showMore}
          label={showMore ? t`seeLess` : t`showMore`}
          padding="0"
        />
      )}
      <FloatRightContainer>
        {showEditStamp && (
          <EditStamp
            crunchbasePermalink={editStampProps?.crunchbasePermalink ?? null}
          />
        )}
      </FloatRightContainer>
    </TransitionedWidget>
  );
};

const FloatRightContainer = styled('div')`
  position: absolute;
  bottom: 36px;
  right: 0;
`;
