import { getCategoryIcon } from '@/features/modals/manage-tags-modal/helpers/get-category-icon';
import { isDefaultOptionArray } from '@/helpers/other';
import type { IOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';
import { GeneralTag } from '@/ui/tag/general-tag';
import { SubHeaderBold } from '@/ui/typography/widgets';

interface CompanyProductSelectorComponentProps {
  className?: string;
  defaultValue: IOption[];
  title: string;
  isEditMode: boolean;
  setOptions: AsyncSelectProps['setOptions'];
  onChange: (newValue: string[]) => void;
}

export const CompanyProductSelectorComponent = ({
  className,
  defaultValue,
  title,
  isEditMode,
  setOptions,
  onChange,
}: CompanyProductSelectorComponentProps) => {
  const handleSelectionChanged = (options: unknown) => {
    if (!isDefaultOptionArray(options)) return;
    const deployment_model_ids = options.map(option => option.value);
    onChange(deployment_model_ids);
  };

  if ((!defaultValue || defaultValue.length === 0) && !isEditMode) return null;

  return (
    <Flexbox
      name="company-product-item-selector"
      direction="column"
      gap="12px"
      className={className}
    >
      <SubHeaderBold>{title}</SubHeaderBold>
      {isEditMode ? (
        <AsyncSelect
          defaultValue={defaultValue}
          isMulti
          isSearchable
          setOptions={setOptions}
          onChange={handleSelectionChanged}
          {...sharedProps}
        />
      ) : (
        <Flexbox
          name="company-product-languages-widget-items"
          wrap="wrap"
          gap="10px"
        >
          {defaultValue.map(selectedOption => (
            <GeneralTag key={selectedOption.value} variant="blue" size="l">
              {selectedOption.label}
              <Icon
                icon={getCategoryIcon('Technology')}
                height="18px"
                width="18px"
              />
            </GeneralTag>
          ))}
        </Flexbox>
      )}
    </Flexbox>
  );
};
