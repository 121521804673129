import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import type { ProjectOnList, ProjectSortOptions } from '@/api/v4/projects.api';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { FixedBottomContainer } from '@/components/fixed-bottom-container/fixed-bottom-container';
import { getElementOuterHeight } from '@/helpers/get-element-outer-height';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { InfiniteScrollTable } from '@/ui/table/infinite-table/infinite-scroll-table';
import { NoResults } from '@/ui/table/no-results';
import type { ISort } from '@/ui/table/table.types';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { WithHtmlSavvyTitle } from '../../with-html-savvy-title';
import { ProjectsFilters } from '@/features/projects/filters/projects-filters';
import { useProjectsFiltersSearchLocationSearch } from '@/features/projects/filters/use-projects-filters-location-search';
import type { IProjectFilters } from '@/features/projects/project.state';
import {
  appliedProjectsFiltersState,
  selectedProjectsFiltersState,
  projectsSortState,
} from '@/features/projects/project.state';
import { useProjectsTableConfiguration } from '@/features/projects/projects-list/use-projects-table-configuration';
import { useProjects } from '@/features/projects/projects-list/use-projects.query';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { ProjectsKPI } from './projects-kpi/projects-kpi';

export const ProjectsPage = () => {
  const { t } = useTranslation('projects');
  const navigateTo = useNavigateTo();
  const { hasRequiredPermission } = useUserPermissions();
  const hasEditPermission = hasRequiredPermission(PERMISSION.ADD_PROJECT);
  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);
  const kpisContainerRef = useRef<HTMLDivElement>(null);
  const [kpisContainerHeight, setKPIsContainerHeight] = useState(0);
  const appliedFilters = useRecoilValue(appliedProjectsFiltersState);
  const { data, hasNextPage, isLoading, fetchNextPage } = useProjects();
  const projects = data ? data.pages.flat() : [];

  const { header, rows, skeletonConfig } =
    useProjectsTableConfiguration(projects);

  const { updateLocationSearch, resetLocationSearch } =
    useProjectsFiltersSearchLocationSearch();

  const { clearAppliedFilters, hasAnyActiveFilters } =
    useHandleFilters<IProjectFilters>({
      appliedFiltersState: appliedProjectsFiltersState,
      selectedFiltersState: selectedProjectsFiltersState,
      updateLocationSearch,
      resetLocationSearch,
    });

  useEffect(() => {
    setKPIsContainerHeight(getElementOuterHeight(kpisContainerRef.current));
  }, []);

  const handleSort = ({ sort, direction }: ISort<ProjectSortOptions>) =>
    updateLocationSearch(appliedFilters, { direction, sort });

  const onCreateProject = () => {
    navigateTo.createProject();
  };

  return (
    <WithHtmlSavvyTitle title="Projects">
      <ProjectsContentContainer
        name="projects-page-container"
        direction="column"
        padding="0 30px"
        fullHeight
      >
        <ProjectsFilters
          setFiltersContainerHeight={height =>
            setFiltersContainerHeight(height)
          }
          filtersDisabled={
            (!data || projects.length === 0) && !hasAnyActiveFilters
          }
        />
        {!isLoading && rows.length === 0 ? (
          <Flexbox
            name="projects-no-results-wrapper"
            justify="center"
            alignItems="center"
            fullHeight
          >
            {hasAnyActiveFilters ? (
              <NoResults
                header={t`projects.noResults.header`}
                text={t`projects.noResults.text`}
                buttonText={t`projects.noResults.button`}
                buttonIcon="ArrowGoBack"
                onButtonClick={clearAppliedFilters}
              />
            ) : (
              <NoResults
                onButtonClick={onCreateProject}
                buttonText={t`projects.createProject`}
                header={t`projects.empty.title`}
                text={hasEditPermission ? t`projects.empty.description` : ''}
                buttonIcon="Plus"
                showButton={hasEditPermission}
              />
            )}
          </Flexbox>
        ) : (
          <InfiniteScrollTable<ProjectOnList, ProjectSortOptions>
            id="projects"
            isLoading={isLoading}
            headers={header}
            rows={rows}
            data={projects}
            hasMore={Boolean(hasNextPage)}
            onLoadMore={fetchNextPage}
            onSort={handleSort}
            sortState={projectsSortState}
            height={`calc(100vh - ${filtersContainerHeight}px - ${TOPBAR_HEIGHT} - ${
              kpisContainerHeight + 68
            }px)`}
            skeletonConfig={skeletonConfig}
          />
        )}
        <FixedBottomContainer>
          <ProjectsKPI />
        </FixedBottomContainer>
      </ProjectsContentContainer>
    </WithHtmlSavvyTitle>
  );
};

const ProjectsContentContainer = styled(Flexbox)`
  position: relative;
  overflow: hidden;
`;
