import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createTeam } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { useTeams } from './use-teams.query';

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const { refetch: refreshTeams } = useTeams();
  const navigateTo = useNavigateTo();

  const createTeamMutation = useMutation(createTeam, {
    onSuccess: async response => {
      await refreshTeams();
      await queryClient.invalidateQueries([QueryKey.LastActivities]);
      await queryClient.invalidateQueries([QueryKey.LastActivitiesCount]);
      navigateTo.team({ teamId: response.id });
    },
  });

  return {
    ...createTeamMutation,
    isError: createTeamMutation.isError,
  };
};
