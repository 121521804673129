import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'goober';

import { useListingsSectionSearchParams } from '@/features/projects/project-listings/use-listings-section-search-params';
import { paths } from '@/routes/helpers/paths';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { TableSecondaryCell } from '@/ui/table/infinite-table/table-cells';

import { ListStatusTag } from '../../list-status-tag/list-status-tag';
import { RouteLink } from '@/ui/link/route-link';

interface RelatedProjectRowProps {
  projectId: string;
  projectName: string;
  listingsCount: number;
  stage: string;
}

export const RelatedProjectRow = ({
  projectId,
  projectName,
  listingsCount,
  stage,
}: RelatedProjectRowProps) => {
  const { t } = useTranslation('teams');
  const listingsSectionSearchParams = useListingsSectionSearchParams();
  return (
    <Row>
      <Flexbox
        name="related-project-row-container"
        justify="space-between"
        alignItems="center"
        fullWidth
        gap="20px"
      >
        <Stack gap="0px">
          <ProjectLink
            to={{
              pathname: paths.project({
                projectId,
              }),
            }}
          >
            {projectName}
          </ProjectLink>
          <TableSecondaryCell>
            <Link
              to={{
                pathname: paths.project({
                  projectId,
                }),
                search: listingsSectionSearchParams,
              }}
            >
              {listingsCount} {t`companies`}
            </Link>
          </TableSecondaryCell>
        </Stack>
        {stage === '' ? '-' : <ListStatusTag text={stage} />}
      </Flexbox>
    </Row>
  );
};
const Row = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};
  padding: 16px 5px;
  width: 100%;
`;

const ProjectLink = styled(RouteLink)`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  ${({ theme }) => theme.mixins.ellipsis}
  text-decoration: none;
  max-width: 500px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
