import React, { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { useListingsSectionSearchParams } from '@/features/projects/project-listings/use-listings-section-search-params';
import { isEllipsis } from '@/helpers/other';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { WidgetBox } from '@/ui/widget-box/widget-box';

import { RecentItemLogo } from './recent-item-logo';
import { Inline } from '@/ui/line/line';
import { PinItemButton } from './pinned-items/pin-item-button';
import { useTogglePinnedItem } from './pinned-items/use-toggle-pinned-item.mutation';
import { useOptimisticRecentItemsUpdate } from './use-optimistic-recent-items-update';
import { RouteLink } from '@/ui/link/route-link';
import { paths } from '@/routes/helpers/paths';

interface RecentItemProps {
  id: string;
  type: 'project' | 'company' | 'team';
  name: string;
  logoUrl?: string;
  teamMembersCount?: number;
  listingCount?: number;
  isPinned?: boolean;
}

export const RecentItem = ({
  id,
  type,
  name,
  logoUrl,
  teamMembersCount,
  listingCount,
  isPinned,
}: RecentItemProps) => {
  const { t } = useTranslation('homepage');
  const refActivityName = useRef<HTMLDivElement>(null);
  const listingsSectionSearchParams = useListingsSectionSearchParams();
  const canBePinned = type !== 'team';

  const { handleOptimisticChange, rollbackToPreviousData } =
    useOptimisticRecentItemsUpdate();
  const { toggleItem } = useTogglePinnedItem({
    onMutate: handleOptimisticChange,
    onRollback: rollbackToPreviousData,
  });

  const getRedirectPath = (type: RecentItemProps['type']) => {
    const navigateMap = {
      company: paths.company({ companyId: id }),
      project: paths.project({ projectId: id }),
      team: paths.team({ teamId: id }),
    };

    return navigateMap[type];
  };

  return (
    <AnimationWrapper>
      <Tooltip
        offset={[0, -20]}
        content={name}
        disabled={!isEllipsis(refActivityName.current)}
      >
        <ActivityItemLink to={getRedirectPath(type)}>
          <ActivityItemContainer data-testid={id}>
            <Flexbox
              name={id + '_activity'}
              gap="16px"
              justify="space-between"
              alignItems="center"
              fullWidth
            >
              <Inline alignItems="center">
                <RecentItemLogo
                  type={type}
                  size="md"
                  name={name}
                  logoUrl={logoUrl}
                />
                <DetailSpacer>
                  <ActivityText ref={refActivityName}>{name}</ActivityText>
                  {type === 'team' && (
                    <ActivityExtraInfo>
                      {t('activities.countTeamMember', {
                        count: teamMembersCount,
                      })}
                    </ActivityExtraInfo>
                  )}
                  {type === 'project' && (
                    <ActivityExtraInfo>
                      <ActivityExtraRedirect
                        to={{
                          pathname: paths.project({ projectId: id }),
                          search: listingsSectionSearchParams,
                        }}
                      >
                        {t('activities.countProjectCompany', {
                          count: listingCount,
                        })}
                      </ActivityExtraRedirect>
                    </ActivityExtraInfo>
                  )}
                </DetailSpacer>
              </Inline>

              {canBePinned && (
                <PinItemButton
                  itemId={id}
                  itemType={type}
                  isPinned={!!isPinned}
                  onToggle={toggleItem}
                />
              )}
            </Flexbox>
          </ActivityItemContainer>
        </ActivityItemLink>
      </Tooltip>
    </AnimationWrapper>
  );
};

const AnimationWrapper = styled('div')`
  ${({ theme }) => theme.animations.fadeAnimation()};
`;

const ActivityItemContainer = styled(WidgetBox, forwardRef)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  height: 70px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c9};
  }
`;

const ActivityItemLink = styled(RouteLink)`
  text-decoration: none;
`;

const DetailSpacer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 160px;
  justify-content: center;
`;

const ActivityText = styled('div', React.forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  ${({ theme }) => theme.mixins.ellipsis}
  font-size: 15px;
  max-width: 270px;
  padding: 2px 0px;
`;

const ActivityExtraInfo = styled('div')`
  ${({ theme }) => theme.typography.widget.smallText};
  ${({ theme }) => theme.mixins.ellipsis};
  padding: 2px 0px;
`;

const ActivityExtraRedirect = styled(RouteLink)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
