import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  projectsFilterOptionsState,
  selectedProjectsFiltersState,
} from '@/features/projects/project.state';
import { filterSearchTags } from '@/helpers/filter-search-tags';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';
import { useTags } from '@/features/tags/use-tags.query';

export const TagsFilter = () => {
  const { t } = useTranslation('projects');
  const { tags } = useTags();

  const setProjectsFilters = useSetRecoilState(projectsFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  const setOptions = useCallback(
    async (search: string) => {
      const { doesTagMatchSearchPhrase } = filterSearchTags({
        searchPhrase: search,
      });

      const tagList =
        search.length === 0 ? tags : tags.filter(doesTagMatchSearchPhrase);

      const tagOptions = tagList.map(tag => ({
        value: tag.id,
        label: tag.name,
      }));

      setProjectsFilters(currentFilters => ({
        ...currentFilters,
        tags: tagOptions,
      }));
      return tagOptions;
    },
    [setProjectsFilters, tags],
  );

  return (
    <AsyncSelect
      id="filter-tags"
      label={t`projects.tags`}
      defaultValue={selectedFilters.tags}
      isMulti
      isSearchable
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;
        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          tags: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
