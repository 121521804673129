import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  appliedCompaniesFiltersState,
  companiesFilterOptionsState,
  defaultAppliedCompaniesFilters,
  isCompanyFilterKeyContainingPredefinedOptions,
} from '@/features/companies/companies.state';
import { isObject } from '@/helpers/is-object';
import { isPresent } from '@/helpers/is-present';
import {
  isCapitalRaisedOptionArray,
  isDateRangeOption,
  isDefaultOptionArray,
  isNumberOptionArray,
} from '@/helpers/other';
import type { DateRangeOption } from '@/types';

import {
  extractCompanySearchFilters,
  isValidDateRangeFilter,
} from './company-filters-helpers';

export const useApplyCompanyFiltersFromUrl = ({
  isPrefetching,
  onFiltersApply,
}: {
  isPrefetching: boolean;
  onFiltersApply?: () => void;
}) => {
  const location = useLocation();

  const filtersOptions = useRecoilValue(companiesFilterOptionsState);

  const setAppliedFilters = useSetRecoilState(appliedCompaniesFiltersState);

  useEffect(() => {
    if (!location.search || isPrefetching) return;

    const filtersFromUrl = extractCompanySearchFilters(location.search);

    const filtersToApply = Object.entries(filtersFromUrl).reduce(
      (acc, [key, value]) => {
        if (!(key in defaultAppliedCompaniesFilters)) return acc;

        const options = isCompanyFilterKeyContainingPredefinedOptions(key)
          ? filtersOptions[key]
          : null;

        // handled keys: search
        if (!options) {
          return { ...acc, [key]: value };
        }

        if (Array.isArray(value) && isCapitalRaisedOptionArray(options)) {
          const capitalRaisedOptions = value.map(range => {
            const [min, max] = range
              .split('-')
              .map(part => (part === 'null' ? null : Number(part)));
            return { min, max };
          });

          const optionArray = options.filter(item =>
            capitalRaisedOptions.some(
              range =>
                range.min === item.value.min && range.max === item.value.max,
            ),
          );

          return {
            ...acc,
            [key]: optionArray,
          };
        }

        // handled keys: verified
        if (typeof value === 'boolean' && value === true) {
          return {
            ...acc,
            [key]: value,
          };
        }

        // handled keys: tags, fundingStage, status, employeesRange
        if (Array.isArray(value) && isDefaultOptionArray(options)) {
          const optionArray = options.filter(item =>
            value.includes(item.value),
          );
          return {
            ...acc,
            [key]: optionArray,
          };
        }

        // handled keys: createdBy, ranking
        if (Array.isArray(value) && isNumberOptionArray(options)) {
          const numberValues: number[] = value
            .map(stringValue => {
              const parsedValue = parseFloat(stringValue);
              return isNaN(parsedValue) ? null : parsedValue;
            })
            .filter(isPresent);

          const numbersArray = options.filter(item =>
            numberValues.includes(item.value),
          );

          return {
            ...acc,
            [key]: numbersArray,
          };
        }

        // handled keys: yearFounded
        if (
          isObject(value) &&
          isDateRangeOption(value) &&
          isValidDateRangeFilter(value)
        ) {
          const startEndFilter: DateRangeOption = {
            start: null,
            end: null,
          };

          if (value.start) {
            startEndFilter.start = {
              value: value.start,
              label: value.start,
            };
          }
          if (value.end) {
            startEndFilter.end = {
              value: value.end,
              label: value.end,
            };
          }

          return {
            ...acc,
            [key]: {
              ...startEndFilter,
            },
          };
        }

        return acc;
      },
      defaultAppliedCompaniesFilters,
    );

    setAppliedFilters(filtersToApply);
    onFiltersApply?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefetching]);
};
