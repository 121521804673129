import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { Z_INDEX_HIDDEN_INPUT } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header } from '@/ui/typography/widgets';

import { companyIdState, CompanyRatingType } from '../company.state';

import { CompanyRatingCard } from './company-rating-card';
import { RankingStarIcon } from './ranking-star-icon';
import { useCompanyRankings } from './use-company-rating.query';
import { useAddCompanyRanking } from './use-company-rating.mutation';
import { useCompanyRankingLoadingStates } from './use-company-rating-loading-states';

export const CompanyRatingWidget = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const { data: ratings } = useCompanyRankings();

  const companyId = useRecoilValue(companyIdState);
  const { isLoading } = useCompanyRankingLoadingStates(companyId);

  const { addRating, removeRating } = useAddCompanyRanking(companyId);
  const overallAverage = ratings?.overallRating || null;

  const technologyUserRating = ratings?.technology.userRating || null;
  const technologyRating = ratings?.technology.averageRating || null;

  const teamRating = ratings?.team.averageRating || null;
  const teamUserRating = ratings?.team.userRating || null;

  const financialRating = ratings?.financial.averageRating || null;
  const financiaUserRating = ratings?.financial.userRating || null;

  const checkIfRankValueExists = useCallback(
    (rating: number, type: CompanyRatingType) => {
      const ratingWithDecimal = Number(rating.toFixed(1));
      switch (type) {
        case CompanyRatingType.Technology:
          return ratingWithDecimal === technologyUserRating;
        case CompanyRatingType.Team:
          return ratingWithDecimal === teamUserRating;
        case CompanyRatingType.Financial:
          return ratingWithDecimal === financiaUserRating;
      }
    },
    [financiaUserRating, teamUserRating, technologyUserRating],
  );

  const handleRatingChange = (rating: number, type: CompanyRatingType) => {
    if (!companyId) return;

    const shouldDelete = checkIfRankValueExists(rating, type);

    if (shouldDelete) {
      return removeRating.mutate({
        organizationId: companyId,
        type,
      });
    } else {
      return addRating.mutate({
        organizationId: companyId,
        rating: rating,
        type,
      });
    }
  };

  const shouldHideAverages =
    !technologyRating && !teamRating && !financialRating;

  return (
    <RankingContainer name="company-ranking-widget" className={className}>
      <Stack gap="24px" fullWidth>
        <Flexbox name="company-ranking-header" gap="32px" alignItems="center">
          <Header>{t`ranking.header`}</Header>
          <Flexbox
            name="company-ranking-rating-overview"
            gap="10px"
            alignItems="center"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Flexbox
                  name="company-ranking-rating-overview-overall"
                  gap="4px"
                >
                  <RatingText data-testid="general-impression">
                    {overallAverage
                      ? overallAverage.toFixed(1)
                      : t`ranking.emptyRankingMessage`}
                  </RatingText>
                  <RankingStarIcon filled />
                </Flexbox>
                {overallAverage && (
                  <GeneralImpression>{t`ranking.generalImpression`}</GeneralImpression>
                )}
              </>
            )}
          </Flexbox>
        </Flexbox>
        <Flexbox name="rating-types" gap="48px" flex="1" justify="space-evenly">
          <CompanyRatingCard
            type={CompanyRatingType.Technology}
            rating={technologyRating}
            initialValue={technologyUserRating}
            onChange={rate =>
              handleRatingChange(rate, CompanyRatingType.Technology)
            }
            loading={isLoading}
            hideRankingAverage={shouldHideAverages}
          />
          <CompanyRatingCard
            type={CompanyRatingType.Team}
            rating={teamRating}
            initialValue={teamUserRating}
            onChange={rate => handleRatingChange(rate, CompanyRatingType.Team)}
            loading={isLoading}
            hideRankingAverage={shouldHideAverages}
          />
          <CompanyRatingCard
            type={CompanyRatingType.Financial}
            rating={financialRating}
            initialValue={financiaUserRating}
            onChange={rate =>
              handleRatingChange(rate, CompanyRatingType.Financial)
            }
            loading={isLoading}
            hideRankingAverage={shouldHideAverages}
          />
        </Flexbox>
      </Stack>
    </RankingContainer>
  );
};

const RankingContainer = styled(Flexbox)<{
  loading?: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: 100%;
  height: 100%;
  padding: 36px 40px;
  border-radius: 20px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  z-index: ${({ loading }) => (loading ? Z_INDEX_HIDDEN_INPUT : 'inherit')};
`;

const RatingText = styled('p', forwardRef)`
  ${({ theme }) => theme.typography.companyRanking.generalImpressionRating};
`;

const GeneralImpression = styled('p', forwardRef)`
  ${({ theme }) => theme.typography.companyRanking.generalImpressionText};
`;
