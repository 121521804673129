import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';

import { ContactCardContainer } from './card/contact-card-container';

export const EmptyContacts = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation('contacts');
  const onClickHandler = useCallback(() => {
    if (disabled) return;
    onClick();
  }, [disabled, onClick]);
  return (
    <ContactCardContainer
      name="empty-contacts-container"
      alignItems="center"
      justify="center"
      gap="24px"
    >
      <Icon icon="Contact" />
      <Stack>
        <AddContact>{t`addContact`}</AddContact>
        <AddButton
          startIcon="ArrowLong"
          variant="text"
          onClick={onClickHandler}
        >
          {t`clickToAdd`}
        </AddButton>
      </Stack>
    </ContactCardContainer>
  );
};

const AddContact = styled('p', forwardRef)`
  ${({ theme }) => theme.typography.contacts.addContact};
`;

const AddButton = styled(Button, forwardRef)`
  &:hover {
    color: ${({ theme }) => theme.colors.blue.primaryA};
    ${({ theme }) =>
      `
    ${colorIcon(theme.colors.blue.primaryA)}
  `};
  }
`;
