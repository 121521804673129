import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { useFundingStages } from '@/features/funding-stages/use-funding-stages';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

import { mapCompanyFundingStageFilter } from './company-filters-helpers';

export const FundingStageFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const { data: fundingStages = [] } = useFundingStages();

  const [companiesFilters, setCompaniesFilters] = useRecoilState(
    companiesFilterOptionsState,
  );
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const setOptions = async (search: string) => {
    if (search && companiesFilters.fundingStages) {
      return companiesFilters.fundingStages.filter(item =>
        item.label.toLowerCase().includes(search.toLowerCase()),
      );
    }

    const fundingStageOptions = fundingStages.map(mapCompanyFundingStageFilter);
    setCompaniesFilters(currentFilters => ({
      ...currentFilters,
      fundingStage: fundingStageOptions,
    }));
    return fundingStageOptions;
  };

  return (
    <AsyncSelect
      id="filter-funding-stage"
      label={t`companies.filters.fundingStage`}
      defaultValue={selectedFilters.fundingStages}
      isMulti
      isSearchable
      disableFetchMore
      closeMenuOnSelect={false}
      className={className}
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;
        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          fundingStages: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
