import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphSmall, SubHeader } from '@/ui/typography/widgets';

export const AuthHeader = ({
  children,
  textCenter = true,
}: {
  children: React.ReactNode;
  textCenter?: boolean;
}) => (
  <Wrapper
    fullWidth
    name="header-container"
    direction="column"
    textCenter={textCenter}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled(Flexbox)<{ textCenter: boolean }>`
  text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
`;

const AuthTitle = ({ children }: { children: string }) => (
  <Title>{children}</Title>
);

const Title = styled(SubHeader)`
  margin-bottom: 6px;
`;

const AuthParagraph = ({
  children,
  color,
}: {
  children: string;
  color?: string;
}) => <ParagraphSmall color={color}>{children}</ParagraphSmall>;

AuthHeader.Title = AuthTitle;
AuthHeader.Paragraph = AuthParagraph;
