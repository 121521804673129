import { useTranslation } from 'react-i18next';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Icon } from '@/ui/icons/icon';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { AuthHeader } from '@/auth/ui/auth-header';
import { AuthLayout } from '@/auth/ui/auth-layout';

export const SignUpInvitationExpiredPage = () => {
  const { t } = useTranslation('auth');
  const navigateTo = useNavigateTo();

  return (
    <WithHtmlSavvyTitle title={t`signUpPageTitle`}>
      <AuthLayout padding="0px 80px 80px 80px">
        <AuthLayout.Content gap="48px">
          <Icon icon="BrowserTab" />
          <AuthHeader>
            <AuthHeader.Title>{t`invitationExpired.title`}</AuthHeader.Title>
            <AuthHeader.Paragraph>
              {t`invitationExpired.subtitle`}
            </AuthHeader.Paragraph>
            <AuthHeader.Paragraph>
              {t`invitationExpired.subtitle2`}
            </AuthHeader.Paragraph>
          </AuthHeader>
        </AuthLayout.Content>
        <AuthLayout.SubmitButton onClick={() => navigateTo.signIn()}>
          {t`invitationExpired.submitButton`}
        </AuthLayout.SubmitButton>
      </AuthLayout>
    </WithHtmlSavvyTitle>
  );
};
