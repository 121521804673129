import { forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import i18n from 'i18next';
import { isEqual } from 'lodash-es';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ActivateFiltersButton } from '@/components/filters/activate-filters-button';
import { activeFilterTypeState } from '@/components/filters/filter.state';
import { FilterSearchInput } from '@/components/filters/filter-search-input';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { getElementOuterHeight } from '@/helpers/get-element-outer-height';
import type { Entries } from '@/helpers/other';
import {
  isCapitalRaisedOption,
  isCapitalRaisedOptionArray,
  isDateRangeOption,
  isDefaultOption,
  isDefaultOptionArray,
  isNumberOption,
  isNumberOptionArray,
} from '@/helpers/other';
import type {
  CapitalRaisedOption,
  DateRangeOption,
  INumberOption,
  IOption,
  MinMaxRange,
} from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox, FlexContainer } from '@/ui/flexbox/flexbox';

import type { CompanyFilters } from '../companies.state';
import {
  appliedCompaniesFiltersState,
  selectedCompaniesFiltersState,
  selectedCompaniesState,
} from '../companies.state';
import { Filters } from '../filters';

import { useApplyCompanyFiltersFromUrl } from './use-apply-company-filters-from-url';
import { useCompaniesFiltersSearchLocationSearch } from './use-companies-filters-location-search';
import { useFetchCompanyFiltersOptions } from './use-fetch-company-filters-options.query';
import { AppliedFiltersTags } from '@/components/filters/applied-filters-tags';

export const CompaniesFilters = ({
  setFiltersContainerHeight,
  filtersDisabled,
}: {
  setFiltersContainerHeight: (height: number) => void;
  filtersDisabled: boolean;
}) => {
  const { t } = useTranslation('companies');

  const [activeFilterType, setActiveFilterType] = useRecoilState(
    activeFilterTypeState,
  );

  const filtersContainerRef = useRef<HTMLDivElement>(null);

  const [appliedFilters, setAppliedFilters] = useRecoilState(
    appliedCompaniesFiltersState,
  );

  const { isFetchingCompanyFilters } = useFetchCompanyFiltersOptions();

  const activateSearchAfterApplyingFiltersFromUrl = () => {
    if (!appliedFilters.search) return;

    setActiveFilterType('search');
  };

  useApplyCompanyFiltersFromUrl({
    isPrefetching: isFetchingCompanyFilters,
    onFiltersApply: activateSearchAfterApplyingFiltersFromUrl,
  });

  const { updateLocationSearch, resetLocationSearch } =
    useCompaniesFiltersSearchLocationSearch();

  const {
    handleSearchInputChange,
    hasAnyActiveFilters,
    clearAppliedFilters,
    clearSearchInput,
    clearSelectedFilters,
    switchToSearchFilter,
    handleApplyFilters,
  } = useHandleFilters<CompanyFilters>({
    appliedFiltersState: appliedCompaniesFiltersState,
    selectedFiltersState: selectedCompaniesFiltersState,
    updateLocationSearch,
    resetLocationSearch,
  });

  const resetSelectedCompanies = useResetRecoilState(selectedCompaniesState);

  useEffect(() => {
    return () => {
      resetSelectedCompanies();
    };
  }, [resetSelectedCompanies]);

  useEffect(() => {
    setFiltersContainerHeight(
      getElementOuterHeight(filtersContainerRef.current),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterType, appliedFilters]);

  const handleFilterRemove = (
    key: keyof CompanyFilters,
    value?: string | number | MinMaxRange,
  ) => {
    const appliedFilter = appliedFilters[key];
    let newFilterValue:
      | IOption[]
      | INumberOption[]
      | null
      | DateRangeOption
      | CapitalRaisedOption[] = null;

    if (value && appliedFilter && isDefaultOptionArray(appliedFilter)) {
      const filtered = appliedFilter.filter(v => v.value !== value);
      newFilterValue = filtered.length > 0 ? filtered : null;
    }
    if (value && appliedFilter && isNumberOptionArray(appliedFilter)) {
      const filtered = appliedFilter.filter(v => v.value !== value);
      newFilterValue = filtered.length > 0 ? filtered : null;
    }

    if (value && appliedFilter && isCapitalRaisedOptionArray(appliedFilter)) {
      const filtered = appliedFilter.filter(v => !isEqual(v.value, value));
      newFilterValue = filtered.length > 0 ? filtered : null;
    }

    if (key === 'yearFounded') {
      newFilterValue = { start: null, end: null };
    }

    setAppliedFilters(prev => {
      const newAppliedFilters = { ...prev, [key]: newFilterValue };
      updateLocationSearch(newAppliedFilters);
      return newAppliedFilters;
    });
  };

  return (
    <FilterSearchContainer
      ref={filtersContainerRef}
      direction="column"
      gap="15px"
    >
      <Flexbox
        name="filter-buttons-container"
        justify="flex-end"
        gap="10px"
        alignItems="center"
      >
        <ActivateFiltersButton
          variant="text"
          isActive={activeFilterType === 'advanced'}
          disabled={filtersDisabled}
          startIcon="Filter"
          onMouseDown={() =>
            setActiveFilterType(current =>
              current === 'advanced' ? null : 'advanced',
            )
          }
        >
          {t`companies.filters.filter`}
        </ActivateFiltersButton>
        <FilterSearchInput
          onChange={handleSearchInputChange}
          placeholder={t`companies.filters.searchByName`}
          onRemove={clearSearchInput}
          disabled={filtersDisabled}
          isInputActivated={
            activeFilterType === 'search' || !!appliedFilters.search
          }
          onInputActivate={switchToSearchFilter}
          onInputDeactivate={() => setActiveFilterType(null)}
          defaultValue={appliedFilters.search ?? ''}
        />
      </Flexbox>

      <Flexbox name="filter-buttons" justify="flex-end">
        {activeFilterType === 'advanced' && hasAnyActiveFilters && (
          <Button variant="underlined" onClick={clearSelectedFilters}>
            {t`companies.filters.clearAll`}
          </Button>
        )}
      </Flexbox>
      {activeFilterType === 'advanced' ? (
        <Filters onApply={handleApplyFilters} />
      ) : (
        <AppliedFiltersTags
          getTagLabel={getTagLabel}
          tags={Object.entries(appliedFilters) as Entries<CompanyFilters>}
          handleRemove={handleFilterRemove}
          handleClear={clearAppliedFilters}
        />
      )}
    </FilterSearchContainer>
  );
};

const getTagLabel = (value: unknown): string => {
  if (typeof value !== 'object') return `${value}`;

  if (
    isDefaultOption(value) ||
    isNumberOption(value) ||
    isCapitalRaisedOption(value)
  )
    return value.label;

  if (isDateRangeOption(value)) {
    const { start, end } = value;
    if (!start && !end) return '';
    if (start && !end)
      return i18n.t('companies:companies.filters.start', {
        start: start.label,
      });
    if (!start && end)
      return i18n.t('companies:companies.filters.end', {
        end: end.label,
      });
    return `${start?.label} - ${end?.label}`;
  }
  throw new Error('Invalid value type');
};

const FilterSearchContainer = styled(FlexContainer, forwardRef)`
  padding-right: 20px;
  padding-left: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
`;
