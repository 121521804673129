import type { APIRequestQueryParams } from './base-fetch';
import { get, type APIResponse } from './base-fetch';

type CompanyNewsAPIResponse =
  APIResponse<'/api/v4/organization/{organizationId}/news'>;

export type OrganizationNews = CompanyNewsAPIResponse['news'][0];

type OrganizationNewsQueryParams =
  APIRequestQueryParams<'/api/v4/organization/{organizationId}/news'>;

export const fetchOrganizationNews = async (
  organizationId: string,
  params: OrganizationNewsQueryParams,
): Promise<CompanyNewsAPIResponse> =>
  get<CompanyNewsAPIResponse>(`/v4/organization/${organizationId}/news`, {
    params,
  });
