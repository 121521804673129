import { FilterFeatureInput } from '@/components/filter-feature-select/filter-feature-select';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';

export const RecentItemsTypeSelect = ({
  menu,
  selectedOptionLabel,
  className,
}: {
  menu: DropdownOption[];
  selectedOptionLabel: string;
  className?: string;
}) => (
  <div className={className}>
    <WithDropdown
      items={menu}
      options={{
        placement: 'bottom-end',
      }}
    >
      <FilterFeatureInput label={selectedOptionLabel} />
    </WithDropdown>
  </div>
);
