import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export type CompaniesInProjectCustomDateRange = { from: string; to: string };

export const definedDateRangeTypes = [
  'thisMonth',
  'thisQuarter',
  'thisYear',
  'allProjects',
] as const;

type DefinedDateRangeType = (typeof definedDateRangeTypes)[number];

export type CompaniesInProjectDateRangeType =
  | DefinedDateRangeType
  | CompaniesInProjectCustomDateRange;

export const companiesInProjectSelectedDateRangeTypeState =
  atom<CompaniesInProjectDateRangeType>({
    key: 'companiesInProjectSelectedDateRangeTypeState',
    default: 'allProjects',
    effects: [persistAtom],
  });

export type ChartType = 'funnel' | 'snapshot';

export const companiesInProjectActiveTabState = atom<ChartType>({
  key: 'companiesInProjectActiveTabState',
  default: 'snapshot',
});
