import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { ParagraphSmall } from '@/ui/typography/widgets';

export const FilterFeatureInput = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: () => void;
}) => {
  return (
    <FilterFeatureInputContainer
      name="filter-feature-input-container"
      alignItems="center"
      gap="6px"
      onClick={onClick}
    >
      <Label>{label}</Label>
      <Button icon="NaviChevron" />
    </FilterFeatureInputContainer>
  );
};

const FilterFeatureInputContainer = styled(Flexbox)`
  cursor: pointer;
`;

const Button = styled(Icon)`
  transform: rotate3d(0, 0, 1, 90deg);
  height: 18px;
  width: 18px;
  padding: 4px;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palettes.component.button.text.hover};
  }
`;

const Label = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.gray.c12};
`;
