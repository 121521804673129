import { useQuery } from '@tanstack/react-query';

import { fetchLastActivitiesCount } from '@/api/v4/last-activities.api';
import { QueryKey } from '@/config/query-client';

export const useLastActivitiesCounts = () => {
  return useQuery([QueryKey.LastActivitiesCount], fetchLastActivitiesCount, {
    staleTime: 1000 * 60 * 5,
  });
};
