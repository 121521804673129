import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { formatCalendarDate } from '@/helpers/format-date';
import { formatNotApplicable } from '@/helpers/format-empty';
import { getAbbreviateNumber } from '@/helpers/format-number';
import { colors } from '@/theme/colors';
import { Stack } from '@/ui/line/line';
import { Header, SmallText } from '@/ui/typography/widgets';
import type { CompanyFundings } from '@/api/v4/organization-fundings.api';

interface CompanyFundingViewModeProps {
  data: CompanyFundings;
}

export const CompanyFundingViewMode = ({
  data,
}: CompanyFundingViewModeProps) => {
  const { t } = useTranslation('companies');

  return (
    <Stack margin="36px 0px 4px 0px" gap="44px" minHeight="fit-content">
      <Stack>
        <DataHighlights color={colors.blue.primaryB}>
          {formatNotApplicable(data.fundingStage?.value?.name)}
        </DataHighlights>
        <SmallText
          color={colors.gray.c12}
        >{t`funding.lastFundingRound`}</SmallText>
        <SmallText color={colors.gray.c9}>
          {data.lastFundingDate &&
            formatCalendarDate(data.lastFundingDate.value)}
        </SmallText>
      </Stack>
      <Stack>
        <DataHighlights color={colors.blue.primaryB}>
          {data.capitalRaised.value
            ? `$${getAbbreviateNumber(data.capitalRaised.value)}`
            : formatNotApplicable(data.capitalRaised.value)}
        </DataHighlights>
        <SmallText>{t`funding.totalCapitalRaised`}</SmallText>
      </Stack>
    </Stack>
  );
};

const DataHighlights = styled(Header)`
  ${({ theme }) => theme.mixins.ellipsis};
`;
