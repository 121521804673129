import type {
  ForwardRefExoticComponent,
  ReactElement,
  Ref,
  RefAttributes,
} from 'react';
import { forwardRef, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { OtherProjects } from '@/components/other-projects/other-projects';
import { selectedCompaniesState } from '@/features/companies/companies.state';
import { companyIdState } from '@/features/companies/overview/company.state';
import { modalState } from '@/state/modal.state';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { StatusTag } from '@/ui/tag/status-tag';
import { ReadMoreWidgetButton } from '@/ui/widget/read-more-widget-button';
import {
  TRANSITION_WIDGET_GAP,
  TransitionedWidget,
  WidgetOverflowItemsContainer,
} from '@/ui/widget/transitioned-widget';
import type { WidgetProps } from '@/ui/widget/widget';
import {
  WIDGET_CONTAINER_MAX_HEIGHT,
  WIDGET_CONTAINER_PADDING_VERTICAL,
} from '@/ui/widget/widget';

import { useOverflowProjectRows } from './use-overflow-project-rows';

interface RelatedProjectOverflowContainerProps extends WidgetProps {
  children: ReactElement[];
  defaultHeight: number;
  Header: ForwardRefExoticComponent<RefAttributes<HTMLHeadingElement>>;
  className?: string;
  withStatusTag?: boolean;
  otherProjectsCount?: number;
  disableAddCompany?: boolean;
}

export const RelatedProjectOverflowContainer = ({
  children,
  Header,
  defaultHeight,
  disabled,
  className,
  withStatusTag = true,
  otherProjectsCount,
  disableAddCompany = false,
}: RelatedProjectOverflowContainerProps) => {
  const { t } = useTranslation('default');
  const [showMore, setShowMore] = useState(false);

  const setModalState = useSetRecoilState(modalState);
  const setSelectedCompanies = useSetRecoilState(selectedCompaniesState);
  const companyId = useRecoilValue(companyIdState);

  const {
    visibleElements,
    overflowElements,
    containerRef,
    buttonRef,
    measuringRender,
    headerRef,
    difference,
    childrenContainerRef,
  } = useOverflowProjectRows({
    children,
    containerHeight:
      defaultHeight -
      WIDGET_CONTAINER_PADDING_VERTICAL * 2 -
      TRANSITION_WIDGET_GAP * 2,
  });

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const containerHeight = useMemo(() => {
    return showMore ? defaultHeight + difference : defaultHeight;
  }, [defaultHeight, difference, showMore]);

  const maxHeight = useMemo(() => {
    return showMore ? WIDGET_CONTAINER_MAX_HEIGHT : containerHeight;
  }, [containerHeight, showMore]);

  const WidgetHeader = forwardRef((_props, ref: Ref<HTMLHeadingElement>) => (
    <Header ref={ref} />
  ));

  WidgetHeader.displayName = 'WidgetHeader';

  const isReadMoreWidgetButtonVisible =
    measuringRender || overflowElements.length > 0;
  const isOtherProjectsCounterVisible =
    otherProjectsCount &&
    ((isReadMoreWidgetButtonVisible && showMore) ||
      overflowElements.length === 0);

  const handleAddCompanyToProject = useCallback(() => {
    if (!companyId) return;
    setSelectedCompanies([{ organizationId: companyId }]);
    setModalState({ state: 'addCompanyToProjects' });
  }, [companyId, setModalState, setSelectedCompanies]);

  return (
    <TransitionedWidget
      height={maxHeight}
      ref={containerRef}
      showMore={showMore}
      maxHeight={maxHeight}
      $measuringRender={measuringRender}
      showEditButton={false}
      disabled={disabled}
      className={className}
      id="related-projects-widget"
      dataTestId="related-projects-widget"
    >
      <Flexbox
        name="related-projects-widget-header"
        gap="10px"
        alignItems="center"
        justify="space-between"
      >
        <Flexbox
          name="related-projects-widget-header-left"
          gap="10px"
          alignItems="center"
        >
          <WidgetHeader ref={headerRef} />
          {withStatusTag && (
            <StatusTag variant="blue" weight="semibold">
              {visibleElements.length + overflowElements.length}
            </StatusTag>
          )}
        </Flexbox>
        {!disableAddCompany && (
          <IconButton
            icon="Plus"
            variant="secondary"
            onClick={handleAddCompanyToProject}
          />
        )}
      </Flexbox>
      <WidgetOverflowItemsContainer
        ref={childrenContainerRef}
        $overflow={containerHeight >= maxHeight}
        $measuringRender={measuringRender}
        direction="column"
        flex="1"
      >
        {visibleElements}
        {showMore && overflowElements}
      </WidgetOverflowItemsContainer>
      <Flexbox
        name="related-container-footer"
        justify={isReadMoreWidgetButtonVisible ? 'space-between' : 'right'}
      >
        {isReadMoreWidgetButtonVisible && (
          <ReadMoreWidgetButton
            ref={buttonRef}
            onClick={handleReadMore}
            showMore={showMore}
            label={showMore ? t`seeLess` : t`showMore`}
          />
        )}
        {isOtherProjectsCounterVisible ? (
          <OtherProjects count={otherProjectsCount} />
        ) : null}
      </Flexbox>
    </TransitionedWidget>
  );
};
