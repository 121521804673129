import type { TeamsSortParams } from '@/api/v4/team.api';
import type { HeaderCellConfig } from '../../ui/table/table.types';

export const teamsHeaders: HeaderCellConfig<TeamsSortParams>[] = [
  {
    columnKey: 'teams',
    width: '35%',
    sortKey: 'name',
    padding: '30px 20px 20px 60px',
    defaultSortDirection: 'asc',
  },
  {
    columnKey: 'members',
    width: '15%',
    sortKey: 'activeMembersCount',
    align: 'right',
  },
  {
    columnKey: 'teamsProjects',
    width: '20%',
    sortKey: 'projectsCount',
    align: 'right',
    padding: '30px 60px 20px 20px',
  },
];
