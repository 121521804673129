import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { useEditCompany } from '@/features/companies/hooks/use-edit-company.mutation';
import {
  COLUMN_1_WIDTH,
  COLUMN_WIDTH,
  GRID_GAP,
  WIDGET_BOTTOM_CONTENT_PADDING,
  WIDGET_ROW_1_HEIGHT,
} from '@/features/companies/overview/utils/constants';
import { Header } from '@/ui/typography/widgets';

import { useCanEditCompany } from '../hooks/use-can-edit-company';
import { useCrunchbaseEnrichmentCheck } from '../hooks/use-crunchbase-enrichment-check';

import { useCompany } from './use-company.query';
import { WidgetLoaderContainer } from './widget-loader-container';

export const AboutWidget = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const { mutateAsync, isLoading: isUpdating } = useEditCompany();
  const { isEnrichedFromCrunchbase } = useCrunchbaseEnrichmentCheck('about');
  const canEditCompany = useCanEditCompany();
  const { data: company, isLoading } = useCompany();

  if (isLoading || isUpdating) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_1_HEIGHT} className={className}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!company) {
    return null;
  }

  const handleEdit = (aboutValue: string | null) => {
    return mutateAsync({
      detailedDescription: aboutValue ?? '',
    });

    return mutateAsync({});
  };

  return (
    <TextWidget
      dataTestId="about-widget"
      header={<HeaderContainer>{t`company.about`}</HeaderContainer>}
      paragraphVariant="medium"
      value={company.detailedDescription.value ?? null}
      height={WIDGET_ROW_1_HEIGHT}
      disableBaseWidth
      maxWidth={COLUMN_1_WIDTH + GRID_GAP + COLUMN_WIDTH}
      disabled={!canEditCompany}
      placeholder={t`company.emptyState.aboutPlaceholder`}
      className={className}
      onSave={handleEdit}
      bottomContentProps={{
        padding: WIDGET_BOTTOM_CONTENT_PADDING,
      }}
      showEditStamp={Boolean(
        isEnrichedFromCrunchbase && company.detailedDescription,
      )}
      editStampProps={{
        crunchbasePermalink: company.crunchbasePermalink,
        lastEdited: company.detailedDescription.lastEditedAt ?? undefined,
      }}
    />
  );
};

const HeaderContainer = styled(Header)`
  padding-bottom: 24px;
`;
