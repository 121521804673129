import { isEllipsis } from '@/helpers/other';
import { useEffect, useRef, useState } from 'react';

export const useEllipsisLabel = (index: number) => {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  return {
    isVisible: isReady && isEllipsis(refItemLabel.current[index]),
    setRefItemLabel: (element: HTMLDivElement | null) =>
      (refItemLabel.current[index] = element),
  };
};
