import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';

const generateRandomRowWidth = () =>
  Math.floor(Math.random() * (100 - 50 + 1)) + 50;

export const ExploreLoadingResultsSkeleton = ({ rows }: { rows: number }) => (
  <Stack gap="20px">
    <Inline justifyContent="space-between">
      <Skeleton width="125px" height="32px" />
      <Skeleton width="97px" height="32px" />
    </Inline>
    <TableSkeleton>
      <CellFilter>
        <Skeleton width="71px" height="12px" />
      </CellFilter>

      {Array.from({ length: rows }).map((_, cellIndex) => (
        <SkeletonItem key={cellIndex} name="explore-table-skeleton">
          <Grid>
            <Inline justifyContent="flex-start">
              <Skeleton circle width="20px" height="20px" />
              <Skeleton width="168px" height="12px" />
            </Inline>

            <div>
              <Skeleton height="12px" width="100%" />
              <Skeleton height="12px" width={`${generateRandomRowWidth()}%`} />
            </div>
            <Inline justifyContent="flex-end">
              <Skeleton circle width="32px" height="32px" />
              <Skeleton circle width="32px" height="32px" />
            </Inline>
          </Grid>
        </SkeletonItem>
      ))}
    </TableSkeleton>
  </Stack>
);

const TableSkeleton = styled('div')`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const CellFilter = styled('div')`
  padding: 35px 80px;
`;

const SkeletonItem = styled(Flexbox)`
  padding: 16px 102px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c3};
`;

const Grid = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
`;
