import { ExternalLinksWidget } from '@/components/external-links-widget/external-links-widget';
import { useRecoilValue } from 'recoil';
import { projectIdState } from '../../project.state';
import { useAddProjectExternalLink } from './use-add-project-external-link.mutation';
import { useProjectExternalLinks } from './use-project-external-links.query';
import { useRemoveProjectExternalLink } from './use-remove-project-external-link.mutation';

export const ProjectExternalLinksWidget = ({
  className,
  height,
  listMaxHeight,
}: {
  className?: string;
  height?: number;
  listMaxHeight?: number;
}) => {
  const projectId = useRecoilValue(projectIdState);
  const { data: externalLinks, isLoading } = useProjectExternalLinks();
  const { mutateAsync: addExternalLink } = useAddProjectExternalLink();
  const { mutateAsync: removeExternalLink } = useRemoveProjectExternalLink();

  const handleAddLink = async (link: string) => {
    await addExternalLink({
      projectId: projectId ?? '',
      data: { urlValue: link },
    });
  };

  const onRemoveLink = async (id: string) =>
    await removeExternalLink({ projectId: projectId ?? '', linkId: id });

  return (
    <ExternalLinksWidget
      addLinkHandler={handleAddLink}
      removeLinkHandler={onRemoveLink}
      isLoading={isLoading}
      linksData={externalLinks ?? []}
      emptyStateConfig={{
        direction: 'row',
        labelAlign: 'left',
      }}
      className={className}
      height={height ?? 260}
      listMaxHeight={listMaxHeight ?? 109}
      smallHeader
    />
  );
};
