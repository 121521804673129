import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { TagsWidget } from '@/components/widgets/tags-widget/tags-widget';
import { modalState } from '@/state/modal.state';
import { SubHeader } from '@/ui/typography/widgets';
import { Widget } from '@/ui/widget/widget';

import { AppUserEmptyState } from '../../../app-users/empty-state';

import { useTeamTags } from './use-team-tags.query';

export const TeamTagsWidget = ({
  teamId,
  defaultHeight,
  disabled,
}: {
  teamId: string;
  defaultHeight: number;
  disabled: boolean;
}) => {
  const { t } = useTranslation('teams');
  const setModal = useSetRecoilState(modalState);

  const { data } = useTeamTags();

  const onEditClick = useCallback(() => {
    setModal({
      state: 'manageTags',
      type: 'team',
      entityId: teamId,
    });
  }, [setModal, teamId]);

  if (!data?.tags.length) {
    return (
      <Widget
        header={<SubHeader>{t`areasOfInterest`}</SubHeader>}
        height={defaultHeight}
        disabled={disabled}
      >
        <AppUserEmptyState
          onClick={onEditClick}
          buttonText={t`addTags`}
          text={t`emptyTeamTags`}
          variant="secondary"
          disabled={disabled}
        />
      </Widget>
    );
  }

  return (
    <TagsWidget
      disabled={disabled}
      onEditClick={onEditClick}
      tags={data.tags}
      subHeader={t`areasOfInterest`}
      widgetHeight={defaultHeight}
      crunchbasePermalink={null}
    />
  );
};
