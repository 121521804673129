import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import SvgTags from '@/assets/widgets/empty-state-tags.svg';
import { TagsWidget } from '@/components/widgets/tags-widget/tags-widget';
import { modalState } from '@/state/modal.state';
import { Header } from '@/ui/typography/widgets';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';

import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import { useCrunchbaseEnrichmentCheck } from '../../hooks/use-crunchbase-enrichment-check';
import { useCompany } from '../use-company.query';

import { useCompanyTags } from './use-company-tags.query';

const COMPANY_TAGS_WIDGET_HEIGHT = 438;

export const CompanyTagsWidget = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies', { keyPrefix: 'tags' });
  const { isEnrichedFromCrunchbase } = useCrunchbaseEnrichmentCheck('tags');
  const { data: company } = useCompany();

  const { data, refetch } = useCompanyTags();
  const setModal = useSetRecoilState(modalState);
  const canEditCompany = useCanEditCompany();

  const tags = data?.tags ?? [];

  const onEditClick = () => {
    company &&
      setModal({
        state: 'manageTags',
        type: 'company',
        entityId: company.id,
        refetchTags: refetch,
      });
  };

  if (!tags.length && canEditCompany) {
    return (
      <EmptyStateWidget
        height={COMPANY_TAGS_WIDGET_HEIGHT}
        dataTestId="tags-widget"
        image={SvgTags}
        label={t`header`}
        linkText={t`button`}
        onEditClick={onEditClick}
        direction="column"
      />
    );
  }
  if (!tags.length)
    return (
      <ReadOnlyEmptyWidget
        height={COMPANY_TAGS_WIDGET_HEIGHT}
        header={<Header>{t`tags`}</Header>}
        fullHeight
      />
    );

  return (
    <TagsWidget
      className={className}
      testId="tags-widget"
      disabled={!canEditCompany}
      onEditClick={onEditClick}
      tags={tags}
      widgetHeight={COMPANY_TAGS_WIDGET_HEIGHT}
      showEditStamp={isEnrichedFromCrunchbase}
      crunchbasePermalink={company?.crunchbasePermalink ?? null}
    />
  );
};
