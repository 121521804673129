import type { RefObject } from 'react';
import { styled } from 'goober';

import { isInvestorOption } from '@/helpers/other';
import type { InvestorOption } from '@/types';
import { OptionsList } from '@/ui/select/async/components/selected-options-list/selected-options-list';
import { SelectedInvestorOption } from '@/ui/select/async/components/selected-options-list/options/selected-investor-option';

interface Props {
  height?: string;
  list?: InvestorOption[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: InvestorOption) => void;
  isDisabled?: boolean;
}

const OPTION_ITEM_HEIGHT = 60;

export function SelectedInvestorsOptionList({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
  isDisabled,
}: Props) {
  const investorsOptions = list ? list.filter(isInvestorOption) : [];

  return (
    <List
      height={height}
      name="investors-options-list"
      direction="column"
      defaultRef={listRef}
      isScrollable={Boolean(isListExpanded || onRemove)}
      maxHeight="360px"
    >
      {investorsOptions.map((item, index) => {
        return (
          <SelectedInvestorOption
            key={item.value}
            option={item}
            index={index}
            isDisabled={isDisabled}
            onRemove={onRemove}
          />
        );
      })}
    </List>
  );
}

const List = styled(OptionsList)`
  max-height: calc(${OPTION_ITEM_HEIGHT}px * 4);

  &:not(:empty) {
    margin-top: 12px;
  }
`;
