import { GENERAL_DATE_FORMAT } from '@/constants';

import { formatDate } from './format-date';

export const generateUntitledEntityName = (entity: 'project' | 'team') => {
  const date = formatDate(new Date(), {
    format: `${GENERAL_DATE_FORMAT} HH:mm`,
  });
  switch (entity) {
    case 'project':
      return `Untitled Project (${date})`;

    case 'team':
      return `Team (${date})`;

    default:
      throw new Error('Unhandled entity');
  }
};
