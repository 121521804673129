import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import SvgImpact from '@/assets/widgets/empty-state-other.svg';
import SvgProblemStatement from '@/assets/widgets/empty-state-problem-statement.svg';
import { PageGrid } from '@/components/layout/page-grid';
import { ImpactWidget } from '@/components/widgets/impact-widget/impact-widget';
import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { modalState } from '@/state/modal.state';
import { Stack } from '@/ui/line/line';
import { SubHeaderBold } from '@/ui/typography/widgets';

import type { ProjectOverviewContentProps } from './project-overview-content';
import { ProjectTagsWidget } from './project-tags-widget';
import { CurrencyWidget } from '@/components/widgets/currency-widget/currency-widget';
import { styled } from 'goober';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ProjectExternalLinksWidget } from './external-links/project-external-links-widget';

type ProjectOverviewSectionProps = Pick<
  ProjectOverviewContentProps,
  | 'project'
  | 'onProblemStatementChange'
  | 'onProjectKpiChange'
  | 'onCurrencyChange'
  | 'onImpactChange'
  | 'onExpectedRoiChange'
> & { hasEditPermission: boolean };

export const ProjectOverviewSection = ({
  project,
  onProblemStatementChange,
  onProjectKpiChange,
  onCurrencyChange,
  onImpactChange,
  onExpectedRoiChange,
  hasEditPermission,
}: ProjectOverviewSectionProps) => {
  const { t } = useTranslation('projects');
  const setModal = useSetRecoilState(modalState);

  return (
    <Stack gap="24px">
      <PageGrid>
        <Stack gap="24px" minHeight="230px">
          <TextWidget
            disableBaseWidth
            height={231}
            paragraphVariant="medium"
            value={project.problemStatement}
            header={
              <SubHeaderBold>{t`projectOverview.problemStatement`}</SubHeaderBold>
            }
            placeholder={t`projectOverview.problemStatementPlaceholder`}
            emptyStateOptions={{
              label: t`projectOverview.emptyState.labelProblemStatement`,
              linkText: t`projectOverview.emptyState.linkTextProblemStatement`,
              image: SvgProblemStatement,
            }}
            onSave={onProblemStatementChange}
            disabled={!hasEditPermission}
          />
          <TextWidget
            disableBaseWidth
            height={208}
            value={project.projectKpi}
            header={
              <SubHeaderBold>{t`projectOverview.projectKpis`}</SubHeaderBold>
            }
            placeholder={t`projectOverview.projectKpisPlaceholder`}
            onSave={onProjectKpiChange}
            disabled={!hasEditPermission}
          ></TextWidget>
        </Stack>
        <Stack gap="24px" minHeight="230px" fullHeight>
          <ProjectExternalLinksWidget height={231} />
          <ImpactWidget
            height={208}
            defaultValue={project.impacts}
            header={<SubHeaderBold>{t`projectOverview.impact`}</SubHeaderBold>}
            emptyStateOptions={{
              label: t`projectOverview.emptyState.labelImpact`,
              linkText: t`projectOverview.emptyState.linkTextImpact`,
              image: SvgImpact,
            }}
            onSave={onImpactChange}
            disabled={!hasEditPermission}
          />
        </Stack>
      </PageGrid>
      <RowContainer name="project-overview-row">
        <Flexbox
          name="project-overview-row-widgets"
          grow="1"
          fullWidth
          justify="space-between"
        >
          <StartWidget
            internalExpectedCost={project.internalExpectedCost}
            externalExpectedCost={project.externalExpectedCost}
            projectBudget={project.projectBudget}
            onSave={onCurrencyChange}
            height={260}
            disabled={!hasEditPermission}
          />

          <MiddleWidget
            value={project.expectedRoi}
            height={260}
            disableBaseWidth
            header={
              <SubHeaderBold>{t`projectOverview.expectedRoi`}</SubHeaderBold>
            }
            placeholder={t`projectOverview.expectedRoiPlaceholder`}
            onSave={onExpectedRoiChange}
            disabled={!hasEditPermission}
          />
        </Flexbox>
        <LastWidget
          tags={project.tags}
          disabled={!hasEditPermission}
          onEditClick={() =>
            project.id &&
            setModal({
              state: 'manageTags',
              type: 'project',
              entityId: project.id,
            })
          }
        />
      </RowContainer>
    </Stack>
  );
};

const RowContainer = styled(Flexbox)`
  width: 100%;
`;

const StartWidget = styled(CurrencyWidget)`
  flex-grow: 1;
  margin-right: 24px;
`;

const MiddleWidget = styled(TextWidget)`
  min-width: 360px;
  max-width: 360px;

  .DraftEditor-root {
    width: 90%;
  }
`;

const LastWidget = styled(ProjectTagsWidget)`
  margin-left: 24px;
  min-width: 480px;
  max-width: 480px;
`;
