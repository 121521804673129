import type { CompanyRankings } from '@/api/v4/organization-ratings.api';
import {
  addCompanyRanking,
  removeCompanyRanking,
} from '@/api/v4/organization-ratings.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useAddCompanyRanking = (companyId: string | null) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('default');

  const queryKey = [QueryKey.CompanyRankings, companyId];

  const onErrorHandler = (
    _err: unknown,
    _var: unknown,
    context:
      | {
          previousCompanyRatings: unknown;
        }
      | undefined,
  ) => {
    queryClient.setQueryData(queryKey, context?.previousCompanyRatings);

    notify({
      message: t`unknownError`,
    });
  };

  const onSettledHandler = () => {
    return queryClient.invalidateQueries({
      queryKey: [QueryKey.CompanyRankings, companyId],
    });
  };

  return {
    addRating: useMutation(addCompanyRanking, {
      onMutate: async userRating => {
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey,
        });

        const previousCompanyRatings = queryClient.getQueryData(queryKey);

        queryClient.setQueryData<CompanyRankings>(queryKey, old => {
          const copy = { ...old };
          if (copy && copy[userRating.type]) {
            copy[userRating.type] = {
              averageRating: copy[userRating.type]?.averageRating ?? null,
              userRating: userRating.rating,
            };
          }
          return old;
        });

        return { previousCompanyRatings };
      },
      onError: onErrorHandler,
      onSettled: onSettledHandler,
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([QueryKey.Company, companyId]),
          queryClient.invalidateQueries([QueryKey.LastActivities]),
          queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
        ]);
      },
    }),
    removeRating: useMutation(removeCompanyRanking, {
      onError: onErrorHandler,
      onSettled: onSettledHandler,
    }),
  };
};
