import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';

export const AuthLayout = ({
  children,
  padding = '98px 64px 64px 64px',
  gap = '32px',
}: {
  children: React.ReactNode;
  padding?: string;
  gap?: string;
}) => (
  <LayoutContainer
    name="auth-layout"
    direction="column"
    justify="space-between"
    gap={gap}
    padding={padding}
  >
    {children}
  </LayoutContainer>
);

const Content = ({
  children,
  gap = '32px',
}: {
  children: React.ReactNode;
  gap?: string;
}) => (
  <ContentContainer
    name="auth-content"
    direction="column"
    justify="center"
    alignItems="center"
    gap={gap}
  >
    {children}
  </ContentContainer>
);

const ContentContainer = styled(Flexbox)`
  flex: 1;
`;

const LayoutContainer = styled(ContentContainer)`
  position: relative;
  min-height: 100%;
`;

const SubmitButton = ({
  children,
  onClick,
  disabled,
}: {
  children: string;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Button fullWidth textCenter size="big" disabled={disabled} onClick={onClick}>
    {children}
  </Button>
);

AuthLayout.Content = Content;
AuthLayout.SubmitButton = SubmitButton;
