import { styled } from 'goober';

import { componentsPalette } from '@/theme/colors';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { CompanyActivityStatus } from '../company.state';

interface ActivityStatusProps {
  status: CompanyActivityStatus;
}

type IStatusDotVariant = 'green' | 'red';

export const ActivityStatus = ({ status }: ActivityStatusProps) => {
  const colorVariant =
    status === CompanyActivityStatus.CLOSED ? 'red' : 'green';

  return (
    <Tooltip content={status}>
      <StatusDot variant={colorVariant} />
    </Tooltip>
  );
};

const StatusDot = styled('div')<{ variant: IStatusDotVariant }>`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${({ variant }) =>
    componentsPalette.company.activityStatusDot[variant]};
  margin: 0 10px;
`;
