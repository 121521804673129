import { styled } from 'goober';

import type { CompanyOfficeLocation } from '@/api/v4/organization.api';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { SmallText, SubHeader } from '@/ui/typography/widgets';

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconWrapper onClick={onClick}>
      <Icon icon="Close" color={colors.gray.c13} />
    </IconWrapper>
  );
};

export const LocationCard = ({
  location,
  shouldShowDeleteButton,
  onDelete,
}: {
  location: CompanyOfficeLocation;
  shouldShowDeleteButton: boolean;
  onDelete: (locationId: string) => void;
}) => {
  return (
    <Container name="location-card" justify="space-between">
      <Stack gap="12px" fullWidth>
        <SubHeader>{location.city?.name}</SubHeader>
        <SmallText>
          {location.region?.name + ', ' + location.country?.name}
        </SmallText>
      </Stack>

      {shouldShowDeleteButton && (
        <DeleteButton onClick={() => onDelete(location.id)} />
      )}
    </Container>
  );
};

const Container = styled(Flexbox)`
  padding: 26px 32px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.blue.c9};
`;

const IconWrapper = styled('div')`
  cursor: pointer;
  padding: 2px;
`;
