import { styled } from 'goober';

import { useVisibleTableData } from '../helpers/use-visible-data';
import type { ITable } from '../table.types';

import { DraggableRow } from './draggable-row';
import { DraggableRowLayer } from './draggable-row-layer';
import { DraggableTableHeaderCell } from './draggable-table-header-cell';

export const DraggableTable = <T,>({
  header,
  rows,
  horizontalScroll = false,
  sort,
}: ITable<T>) => {
  const { visibleColumns, visibleRows } = useVisibleTableData({ rows, header });

  if (!rows.length) {
    return null;
  }

  return (
    <Container horizontalScroll={horizontalScroll}>
      <DraggableRowLayer />
      <Table>
        <thead>
          <tr>
            {visibleColumns.map(cell => (
              <DraggableTableHeaderCell<T>
                key={cell.columnKey}
                cell={cell}
                sort={sort}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {visibleRows.map(row => (
            <DraggableRow key={row.id} row={row} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled('div')<{
  horizontalScroll: boolean;
}>`
  overflow-x: auto;
  height: 100%;

  margin: 0px -32px;
  padding: 0px 32px;

  ${({ theme }) => theme.mixins.hideScrollbar}

  ${({ horizontalScroll }) =>
    horizontalScroll &&
    `
      overflow-y: auto;
    `}
`;

const Table = styled('table')`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 12px;
`;
