import { formatDate } from '@/helpers/format-date';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline } from '@/ui/line/line';
import { ParagraphMedium, SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import newsImagePlaceholderSrc from '@/assets/images/news-signals-placeholder.png';
import { openNewTab } from '@/helpers/open-new-tab';
import { ImageWithFallback } from '@/components/img-with-fallback/img-with-fallback';
import { Tooltip } from '@/ui/tooltip/tooltip';

interface NewsSlideProps {
  articleUrl: string;
  title: string;
  imageUrl: string | null;
  publisher?: string;
  postedOn: string;
}

export const NewsSlide = ({
  articleUrl,
  title,
  imageUrl,
  publisher,
  postedOn,
}: NewsSlideProps) => {
  const titleRef = useRef<HTMLParagraphElement>(null);
  const [publisherWithLongDate, publisherWithShortDate] = useMemo(() => {
    const longDate = formatDate(postedOn, {
      format: 'dd MMMM yyyy',
    });

    const shortDate = formatDate(postedOn, {
      format: 'MMM dd',
    });

    const textPrefix = publisher ? `${publisher}, ` : '';

    return [`${textPrefix}${longDate}`, `${textPrefix}${shortDate}`];
  }, [postedOn, publisher]);

  const openArticle = useCallback(() => openNewTab(articleUrl), [articleUrl]);
  const [isEllipsis, setIsEllipsis] = useState(false);

  useEffect(() => {
    const offsetHeight = titleRef.current?.offsetHeight;
    const scrollHeight = titleRef.current?.scrollHeight;

    if (!offsetHeight || !scrollHeight) {
      return;
    }

    if (offsetHeight + 1 < scrollHeight) {
      setIsEllipsis(true);
    }
  }, [titleRef, title]);

  return (
    <Container name="news-slide" direction="column">
      <ImageWrapper>
        <Img
          src={imageUrl ?? newsImagePlaceholderSrc}
          fallbackSrc={newsImagePlaceholderSrc}
          alt={title}
          height="181px"
        />
      </ImageWrapper>
      <ContentWrapper
        name="news-slide-content"
        direction="column"
        justify="space-between"
      >
        <Tooltip content={title} visible={isEllipsis}>
          <Link
            href={articleUrl}
            target="_blank"
            rel="noreferrer"
            data-testid="news-title-link"
          >
            <Title ref={titleRef}>{title}</Title>
          </Link>
        </Tooltip>

        <Inline justifyContent="space-between">
          <Tooltip content={publisherWithLongDate}>
            <SmallText
              color={colors.basics.black}
              data-testid="news-publisher-and-date"
            >
              {publisherWithShortDate}
            </SmallText>
          </Tooltip>
          <StyledIconButton
            icon="VectorUp"
            variant="ghost"
            color={colors.basics.black}
            onClick={openArticle}
          />
        </Inline>
      </ContentWrapper>
    </Container>
  );
};

const Title = styled(ParagraphMedium, forwardRef)`
  ${({ theme }) => theme.mixins.lineClamp(3)};
`;

const Link = styled('a')`
  text-decoration: none;
`;

const Img = styled(ImageWithFallback)`
  width: 100%;
  height: 181px;
  object-fit: cover;
  border-radius: 4px 20px 0px 0px;
`;

const ImageWrapper = styled('div')`
  display: block;
  width: 100%;
  flex-basis: 45%;
`;

const ContentWrapper = styled(Flexbox)`
  padding: 24px 32px;
  flex-grow: 1;
`;

const Container = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 4px 20px 20px 20px;
  height: 360px;
  margin: 0 15px 36px 20px;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.widgets};
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;
