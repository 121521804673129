import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';

export interface SwitchOption {
  icon: IconVariant;
  label: string;
  isOn: boolean;
}

interface SwitchProps {
  switchOptions: SwitchOption[];
  toggleMode: () => void;
}

export const SwitchModePicker = ({
  switchOptions,
  toggleMode,
}: SwitchProps) => {
  return (
    <Wrapper name="switch">
      {switchOptions.map((switchOption, index) => (
        <SwitchElement
          key={`${switchOption.label}-${index}`}
          variant="secondary"
          startIcon={switchOption.icon}
          onClick={toggleMode}
          isOn={switchOption.isOn}
        >
          {switchOption.label}
        </SwitchElement>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Flexbox)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.blue.c8};
  border-radius: 40px;
  padding: 3px;
`;

const SwitchElement = styled(Button)<{ isOn: boolean }>`
  ${({ theme, isOn }) => isOn && `background-color: ${theme.colors.blue.c4};`};
`;
