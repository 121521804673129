import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import type { ListingStage } from '@/api/v4/project-listings.api';
import { getListingStageByDefaultName } from '@/features/listing-stage/helpers/get-listing-stage-by-default-name';
import { getListingStageName } from '@/features/listing-stage/helpers/get-listing-stage-name';
import { ListingStageName } from '@/features/listing-stage/types';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { withPermission } from '@/hoc/with-permission';
import { modalState } from '@/state/modal.state';
import { Dropzone as DropzoneComponent } from '@/ui/dropzone/dropzone';
import { Stack } from '@/ui/line/line';
import type { DraggableItem } from '@/ui/table/draggable-table/draggable-row';
import { DND_DRAGGABLE_ITEM } from '@/ui/table/draggable-table/draggable-row';
import { PERMISSION } from '@/user/permissions/permission.type';

import { useUpdateProjectListingStage } from '../../use-update-project-listing-stage.mutation';

export const CompanyDragDrop = withPermission(
  ({ dragTo }: { dragTo: ListingStage }) => {
    const { t } = useTranslation('projects');
    const changeProjectListingStage = useUpdateProjectListingStage();
    const setModalState = useSetRecoilState(modalState);
    const { listingStages } = useListingStages();

    const onDropToNextStage = async (draggedRow: DraggableItem) => {
      const listingId = draggedRow.id;

      if (dragTo.name === 'Implementation') {
        setModalState({ state: 'deal', listingId });
        return;
      }

      await changeProjectListingStage({
        projectListingId: listingId,
        newProjectListingStageId: dragTo.id,
      });
    };

    const onDropToNotRelevant = async (draggedRow: DraggableItem) => {
      await changeProjectListingStage({
        projectListingId: draggedRow.id,
        newProjectListingStageId:
          getListingStageByDefaultName(
            ListingStageName.notRelevant,
            listingStages,
          )?.id ?? '',
      });
    };

    return (
      <Container gap="12px">
        <Dropzone
          variant="primary"
          accept={DND_DRAGGABLE_ITEM}
          onDrop={onDropToNextStage}
          icon="ArrowDownFromBracket"
        >
          <Stack gap="4px">
            {t`dragTo`}
            <DestinationName>
              {dragTo.name === 'Implementation'
                ? t`deal`
                : getListingStageName(dragTo, listingStages)}
            </DestinationName>
          </Stack>
        </Dropzone>
        <Dropzone
          variant="secondary"
          accept={DND_DRAGGABLE_ITEM}
          onDrop={onDropToNotRelevant}
          icon="CloseCircle"
        >
          <Stack gap="4px">
            {t`dropIn`}
            <DestinationName>{t`notRelevant`}</DestinationName>
          </Stack>
        </Dropzone>
      </Container>
    );
  },
  PERMISSION.UPDATE_COMPANY_LISTING_STATUS,
);

const DestinationName = styled('p')`
  ${({ theme }) => theme.typography.atoms.dragDrop}
  font-family: ${({ theme }) => theme.fonts.wallop};
  font-weight: 600;
`;

const Container = styled(Stack)`
  height: 100%;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 48px;

  @media (min-height: 700px) {
    grid-template-rows: 7fr 3fr;
  }
`;

const Dropzone = styled(DropzoneComponent<DraggableItem>)`
  width: 127px;
`;
