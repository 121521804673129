import { useTranslation } from 'react-i18next';

import SvgTelescope from '@/assets/widgets/empty-state-editor-note.svg';
import { Loader } from '@/components/loader/loader';
import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { useEditCompany } from '@/features/companies/hooks/use-edit-company.mutation';
import {
  COLUMN_WIDTH,
  WIDGET_HEADER_MARGIN,
  WIDGET_ROW_3_HEIGHT,
} from '@/features/companies/overview/utils/constants';
import { WidgetHeaderWithBackground } from '@/features/companies/overview/widget-header-with-background';
import { componentsPalette } from '@/theme/colors';

import { useCanEditCompany } from '../hooks/use-can-edit-company';

import { useCompany } from './use-company.query';
import { WidgetLoaderContainer } from './widget-loader-container';

const EDITOR_NOTE_WIDGET_WIDTH = 420;

export const EditorNoteWidget = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const { mutateAsync, isLoading: isUpdating } = useEditCompany();
  const { data: company, isLoading: isInitialLoading } = useCompany();

  const canEditCompany = useCanEditCompany();
  const {
    backgroundColor,
    editModeBackgroundColor,
    editModeHeaderBackgroundColor,
    readMoreTextColor,
    editIconColor,
    editIconBackgroundColor,
  } = componentsPalette.company.editorNote;

  if (isUpdating || isInitialLoading) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_3_HEIGHT} className={className}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!company) {
    return null;
  }

  const handleEdit = (editorNoteValue: string | null) => {
    return mutateAsync({
      editorNote: editorNoteValue ?? '',
    });
  };

  return (
    <TextWidget
      dataTestId="editor-note-widget"
      paragraphVariant="medium"
      value={company.editorNote}
      height={WIDGET_ROW_3_HEIGHT}
      width={EDITOR_NOTE_WIDGET_WIDTH}
      maxWidth={COLUMN_WIDTH}
      disabled={!canEditCompany}
      className={className}
      onSave={handleEdit}
      header={
        <WidgetHeaderWithBackground
          margin={WIDGET_HEADER_MARGIN}
        >{t`company.editorNote`}</WidgetHeaderWithBackground>
      }
      backgroundColor={backgroundColor}
      editModeOptions={{
        backgroundColor: editModeBackgroundColor,
        header: (
          <WidgetHeaderWithBackground
            margin={WIDGET_HEADER_MARGIN}
            backgroundColor={editModeHeaderBackgroundColor}
          >{t`company.editorNote`}</WidgetHeaderWithBackground>
        ),
      }}
      editIconProps={{
        borderRadius: '100px',
        color: editIconColor,
        background: editIconBackgroundColor,
        padding: '6px 10px',
      }}
      emptyStateOptions={{
        label: t`company.emptyState.editorNoteStatement`,
        linkText: t`company.emptyState.clickToAdd`,
        image: SvgTelescope,
        backgroundColor: 'white',
        direction: 'column',
        labelAlign: 'center',
        width: COLUMN_WIDTH,
        maxWidth: COLUMN_WIDTH,
      }}
      bottomContentProps={{
        padding: '11px 0 0 0',
      }}
      readMoreProps={{
        color: readMoreTextColor,
      }}
    />
  );
};
