import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { useTranslation } from 'react-i18next';
import { useUpdateProject } from '../use-update-project.mutation';
import type { Project } from '@/api/v4/projects.api';
import { SkeletonWidget } from './project-sidebar';

export const KpiWidget = ({
  isDataLoading,
  projectId,
  projectKpi,
  hasEditPermission,
  widgetHeight,
  widgetWidth,
}: {
  isDataLoading: boolean;
  projectId?: Project['id'];
  projectKpi?: Project['projectKpi'];
  hasEditPermission: boolean;
  widgetHeight: number;
  widgetWidth: number;
}) => {
  const { t } = useTranslation('projects');
  const { editProject } = useUpdateProject();

  if (isDataLoading || !projectId) {
    return <SkeletonWidget width={widgetWidth / 3} />;
  }

  const handleEdit = async (projectKpi: string | null) => {
    await editProject({ projectId, projectKpi });
  };
  return (
    <TextWidget
      dataTestId="project-kpi-widget"
      disableBaseWidth
      height={widgetHeight}
      maxWidth={widgetWidth}
      value={projectKpi ?? ''}
      header={<SubHeaderBold>{t`projectOverview.projectKpis`}</SubHeaderBold>}
      placeholder={t`projectOverview.projectKpisPlaceholder`}
      onSave={handleEdit}
      disabled={!hasEditPermission}
    />
  );
};
