import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';

import type { Team } from '@/api/v4/team.api';

import type { TabConfig } from '../../../../ui/tabs/tabs';
import { Tabs } from '../../../../ui/tabs/tabs';
import {
  teamMembersByStatusState,
  teamMembersSortState,
} from '../../team.state';
import { TeamMemberTabControl } from '../../team-member-tab-control';

import type { TeamMemberStatus } from './basic-tab-config';
import { basicTabConfig } from './basic-tab-config';
import { TeamMembersTable } from './team-members-table';
import { useTeamMembersTableConfiguration } from './use-team-members-table-configuration';
import type { CurrentUserStatus } from '@/api/v4/auth.api';

export const TeamMembers = ({ team }: { team: Team }) => {
  const [activeTab, setActiveTab] = useState<CurrentUserStatus>('active');
  const { t } = useTranslation('teams');

  const teamMembersLoadable = useRecoilValueLoadable(
    teamMembersByStatusState({ status: activeTab }),
  );

  const refreshTeamMembers = useRecoilRefresher_UNSTABLE(
    teamMembersByStatusState({ status: activeTab }),
  );

  const data =
    teamMembersLoadable.state === 'hasValue' && teamMembersLoadable.contents
      ? teamMembersLoadable.contents
      : [];

  const isLoading = teamMembersLoadable.state === 'loading';

  useEffect(() => {
    refreshTeamMembers();
  }, [team.teamMembersCount, refreshTeamMembers]);

  const getMembersCountByStatus = (tab: CurrentUserStatus) => {
    switch (tab) {
      case 'active':
        return team.activeTeamMembersCount;
      case 'invited':
        return team.invitedTeamMembersCount;
      default:
        return team.teamMembersCount;
    }
  };

  const sort = useRecoilValue(teamMembersSortState);

  useEffect(() => {
    refreshTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const { header, rows } = useTeamMembersTableConfiguration(data, activeTab);

  const tabsConfig: TabConfig<CurrentUserStatus>[] = basicTabConfig.map(tab => {
    return {
      id: tab,
      title: (
        <TeamMemberTabControl
          name={t(tab)}
          countIndicator={getMembersCountByStatus(tab)}
        />
      ),
      content: (
        <TeamMembersTable isLoading={isLoading} header={header} rows={rows} />
      ),
    };
  });

  return (
    <TeamMembersContainer>
      <Tabs<TeamMemberStatus>
        tabsConfig={tabsConfig}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        border
        width="max-content"
        noShadow
        padding="0 32px"
      />
    </TeamMembersContainer>
  );
};

const TeamMembersContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 4px;
  width: 100%;

  padding: 22px 0;
`;
