import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import type { SetterOrUpdater } from 'recoil';
import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Inline } from '@/ui/line/line';
import { ParagraphSmall } from '@/ui/typography/widgets';

import type { ExampleCompany } from '../explore.state';

import { ExampleCompanyItem } from './example-company-item';

export const ExampleCompaniesSelect = ({
  exampleCompanies,
  setExampleCompanies,
}: {
  exampleCompanies: ExampleCompany[];
  setExampleCompanies: SetterOrUpdater<ExampleCompany[]>;
}) => {
  const { t } = useTranslation('explore');
  const setModalState = useSetRecoilState(modalState);

  return (
    <Container
      justifyContent="flex-start"
      alignItems="center"
      onClick={event => {
        event.stopPropagation();
        setModalState({
          state: 'addExampleCompaniesToExploreForm',
          exampleCompanies,
          setExampleCompanies,
        });
      }}
    >
      {exampleCompanies.length ? (
        exampleCompanies.map(company => (
          <ExampleCompanyItem
            key={company.id}
            company={company}
            setExampleCompanies={setExampleCompanies}
          />
        ))
      ) : (
        <Placeholder>{t`searchByCompanyName`}</Placeholder>
      )}
    </Container>
  );
};

const Placeholder = styled(ParagraphSmall)`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.gray.c10};
`;

const Container = styled(Inline)`
  padding: 8px 10px;
  border-radius: 10px;
  background-color: ${({ theme }) =>
    theme.palettes.component.explore.form.background};

  &:hover {
    cursor: pointer;
  }
`;
