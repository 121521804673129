import { useCallback, useEffect } from 'react';
import { uniqBy } from 'lodash-es';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import {
  editTeamContactProgressState,
  teamContactDeletedIdState,
  teamContactsAtom,
  teamIdState,
} from '../../team.state';
import { teamContactsState } from '../../team.state';
import { teamContactsPageState } from '../../team.state';

import { useDeleteTeamContact } from './use-delete-team-contact.mutation';
import { useGetTeamContacts } from './use-get-team-contacts.query';
import type { ContactFormItem } from '@/features/contacts/contacts';

type UseTeamContactsProps = {
  keepDataAfterUnmount: boolean;
};

export const useTeamContacts = (props?: UseTeamContactsProps) => {
  const [contacts, setContacts] = useRecoilState(teamContactsAtom);
  const teamId = useRecoilValue(teamIdState);
  const resetPagination = useResetRecoilState(teamContactsPageState);
  const refreshTeamContacts = useRecoilRefresher_UNSTABLE(teamContactsState);
  const setDeletedContact = useSetRecoilState(teamContactDeletedIdState);
  const { page, pageSize } = useRecoilValue(teamContactsPageState);

  const { data: teamContacts, isLoading } = useGetTeamContacts(teamId, {
    page,
    items: pageSize,
  });

  const { mutateAsync: deleteTeamContact } = useDeleteTeamContact();

  useEffect(() => {
    if (!teamContacts || isLoading) return;
    setContacts(old =>
      uniqBy([...old, ...teamContacts], contact => contact.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamContacts, isLoading]);

  useEffect(() => {
    return () => {
      if (props?.keepDataAfterUnmount) return;
      setContacts([]);
    };
  }, [props?.keepDataAfterUnmount, setContacts, teamId]);

  useEffect(() => {
    resetPagination();
  }, [resetPagination]);

  const resetEditTeamContactState = useResetRecoilState(
    editTeamContactProgressState,
  );

  const reset = useCallback(() => {
    resetEditTeamContactState();
  }, [resetEditTeamContactState]);

  const refetch = () => {
    resetPagination();
    refreshTeamContacts();
  };

  const addContact = useCallback(
    (contact: ContactFormItem) => {
      setContacts(contacts => [...contacts, contact]);
    },
    [setContacts],
  );

  const updateContact = useCallback(
    (updatedContact: ContactFormItem) => {
      setContacts(contacts =>
        contacts.map(oldContact => {
          if (oldContact.id !== updatedContact.id) {
            return oldContact;
          }

          return updatedContact;
        }),
      );
    },
    [setContacts],
  );

  const deleteContact = useCallback(
    async (id: string, executeDeleteApi: boolean) => {
      if (executeDeleteApi && teamId)
        await deleteTeamContact({
          teamId,
          teamContactId: id,
        });
      setDeletedContact(id);
      setContacts(contacts =>
        contacts.filter(oldContact => {
          return oldContact.id !== id;
        }),
      );
    },
    [deleteTeamContact, setContacts, setDeletedContact, teamId],
  );

  const hasNextPage = Boolean(teamContacts && teamContacts.length >= pageSize);

  return {
    isLoading,
    contacts,
    hasNextPage,
    reset,
    refetch,
    addContact,
    updateContact,
    deleteContact,
  };
};
