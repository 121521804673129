import type { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { FundingStageSelector } from '@/features/companies/overview/funding/funding-stage-selector';
import {
  formatCalendarDateAsOptionValue,
  parseNullishStringToDate,
} from '@/helpers/format-date';
import { colors } from '@/theme/colors';
import type { IOption } from '@/types';
import { CustomInputAsyncSelect } from '@/ui/date-picker/custom-input/custom-input-async-select';
import { DatePicker } from '@/ui/date-picker/date-picker';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { CurrencyInput } from '@/ui/input/currency-input';
import { Stack } from '@/ui/line/line';
import { sharedProps } from '@/ui/select/select-shared';
import { SmallText } from '@/ui/typography/widgets';
import type { CompanyFundingStateType } from './company-funding.types';

interface CompanyFundingViewModeProps {
  fundingData: CompanyFundingStateType;
  setFundingData: (value: CompanyFundingStateType) => void;
  disabled: boolean;
}

export const CompanyFundingEditMode = ({
  fundingData,
  setFundingData,
  disabled,
}: CompanyFundingViewModeProps) => {
  const { t } = useTranslation('companies');

  const handleCapitalRaisedChanged = (value: string | null) => {
    setFundingData({ ...fundingData, capitalRaised: value });
  };

  const handleFundingStageChanged = (item: IOption) => {
    setFundingData({
      ...fundingData,
      fundingStageId: item.value,
      fundingStageName: item.label,
    });
  };

  const handleLastFundingDateChanged: ReactDatePickerProps['onChange'] =
    newDate => {
      setFundingData({
        ...fundingData,
        lastFundingDate: formatCalendarDateAsOptionValue(newDate),
      });
    };

  return (
    <Flexbox
      name="company-funding-input-container"
      fullHeight
      grow={1}
      direction="column"
      justify="space-between"
      padding="36px 0px 0px 0px"
    >
      <InputGroup>
        <FundingStageSelector
          isDisabled={disabled}
          defaultValue={
            fundingData.fundingStageId
              ? {
                  label: fundingData.fundingStageName || '',
                  value: fundingData.fundingStageId || '',
                }
              : null
          }
          onChange={handleFundingStageChanged}
        />
        <SmallText
          color={colors.gray.c12}
        >{t`funding.lastFundingRound`}</SmallText>
      </InputGroup>
      <InputGroup>
        <DatePicker
          selected={parseNullishStringToDate(fundingData.lastFundingDate)}
          onChange={handleLastFundingDateChanged}
          placeholderText={t`funding.fundingRoundDateInputPlaceholder`}
          customInput={
            <CustomInputAsyncSelect
              disabled={disabled}
              components={{ ...sharedProps.components }}
            />
          }
        />
        <SmallText
          color={colors.gray.c12}
        >{t`funding.fundingRoundDate`}</SmallText>
      </InputGroup>
      <InputGroup>
        <CurrencyInput
          isEditMode={!disabled}
          value={fundingData.capitalRaised}
          onChange={handleCapitalRaisedChanged}
          width="194px"
          placeholder="100,000,000"
          allowDecimals={false}
        />
        <SmallText
          color={colors.gray.c12}
        >{t`funding.totalCapitalRaised`}</SmallText>
      </InputGroup>
    </Flexbox>
  );
};

const InputGroup = styled(Stack)`
  width: 100%;
`;
