import { isBefore, parseISO } from 'date-fns';
import { forwardRef, useMemo } from 'react';

import type { User, UsersListSort } from '@/api/v4/user.api';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import type { Row } from '@/ui/table/table.types';

import { styled } from 'goober';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../helpers/format-date';
import { Tooltip } from '../../../ui/tooltip/tooltip';
import { UserName } from '../../../ui/typography/across-platform';
import { InvitedUsersActionsMenu } from './invited-users-actions-menu';
import { invitedUsersTableHeaders } from './invited-users-table-configuration';

export const useInvitedUsersTableConfiguration = (users: User[]) => {
  const { t } = useTranslation('settings');

  const formatUserInvitationDate = (
    invitationDate: string,
    expirationDate: string | null,
  ) => {
    if (!expirationDate) {
      throw new Error('For invited users - Expiration date is required.');
    }
    if (isBefore(parseISO(expirationDate), new Date())) {
      return (
        <Tooltip
          content={`${t('invitationExpired')}: ${formatDate(invitationDate)}`}
        >
          <InvitationExpired>Invitation Expired</InvitationExpired>
        </Tooltip>
      );
    }
    return formatDate(invitationDate);
  };

  const header = useTranslateHeaders<UsersListSort>(
    invitedUsersTableHeaders,
    'settings',
  );
  const rows: Row[] = useMemo(() => {
    return users.map(user => {
      return {
        id: `${user.id}`,
        cells: [
          {
            value: (
              <EllipsisTextTooltip
                text={user.email}
                Component={<CellText data-testid="invited-user-email" />}
              />
            ),
          },

          {
            value: <CellText>{user.role.name}</CellText>,
            align: 'left',
            padding: '0 20px',
            width: '200px',
          },
          {
            value: (
              <CellContainer>
                {formatUserInvitationDate(
                  user.createdAt,
                  user.invitationExpiryDate,
                )}
              </CellContainer>
            ),
            width: '20px',
          },
          {
            value: <InvitedUsersActionsMenu userId={user.id} />,
            verticalAlign: 'middle',
            padding: '0',
            width: '5px',
          },
        ],
      };
    });
  }, [users]);

  return {
    rows,
    header,
  };
};

const InvitationExpired = styled('label')`
  color: ${({ theme }) => theme.colors.system.lowFit};
`;

const CellText = styled(UserName, forwardRef)`
  max-width: 400px;
  ${({ theme }) => theme.mixins.ellipsis}
`;

const CellContainer = styled('div')`
  max-width: 400px;
  ${({ theme }) => theme.mixins.ellipsis}
`;
