import { styled } from 'goober';

import { componentsPalette } from '@/theme/colors';

import type { RoundedBarChartProps } from './rounded-bar-chart';
import { RoundedBarChart } from './rounded-bar-chart';

export const EmptyStateRoundedBarChart = (props: RoundedBarChartProps) => (
  <DisabledChart
    {...props}
    fillColor={componentsPalette.chart.disabledBackground}
    withDataLabelList={false}
    height={150}
    marginBottom={15}
    labelListHorizontalPosition={15}
    labelColor={componentsPalette.chart.disabledLabel}
  />
);

const DisabledChart = styled(RoundedBarChart)`
  pointer-events: none;
`;
