import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { TeamOnList, TeamsSortParams } from '@/api/v4/team.api';
import { formatEmpty } from '@/helpers/format-empty';
import { paths } from '@/routes/helpers/paths';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { TeamLogo } from '@/ui/logo/logo';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import {
  TablePrimaryCell,
  TableSecondaryCell,
} from '@/ui/table/infinite-table/table-cells';
import type { Row } from '@/ui/table/table.types';

import { teamsHeaders } from './teams-table-configuration';

const rowPadding = {
  left: '60px',
  right: '60px',
};

export const useTeamsTableConfiguration = (teams: TeamOnList[]) => {
  const header = useTranslateHeaders<TeamsSortParams>(teamsHeaders, 'teams');

  const rows: Row[] = useMemo(
    () =>
      teams.map(
        ({ id, activeTeamMembersCount, relatedProjectsCount, name }) => {
          return {
            id,
            rowPadding,
            cells: [
              {
                value: (
                  <Flexbox
                    name="team-name-wrapper"
                    alignItems="center"
                    gap="8px"
                  >
                    <TeamLogo name={name} />
                    <TablePrimaryCell maxWidth={300}>
                      <EllipsisTextTooltip
                        text={name}
                        Component={<Link to={paths.team({ teamId: id })} />}
                      />
                    </TablePrimaryCell>
                  </Flexbox>
                ),
              },
              {
                value: (
                  <TableSecondaryCell textAlign={teamsHeaders[1].align}>
                    {formatEmpty(activeTeamMembersCount)}
                  </TableSecondaryCell>
                ),
              },
              {
                value: (
                  <TableSecondaryCell textAlign={teamsHeaders[2].align}>
                    {formatEmpty(relatedProjectsCount)}
                  </TableSecondaryCell>
                ),
              },
            ],
          };
        },
      ),
    [teams],
  );

  return {
    header,
    rows,
    skeletonConfig: {
      rowPadding,
    },
  };
};
