import { styled } from 'goober';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import SvgOther from '@/assets/widgets/empty-state-other.svg';
import { Loader } from '@/components/loader/loader';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header } from '@/ui/typography/widgets';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';

import { useCompaniesStatistics } from './use-companies-statistics';

const WIDGET_WIDTH = 464;

export const CompaniesStatsWidget = () => {
  const { t } = useTranslation('companies');

  const { data, isLoading } = useCompaniesStatistics();
  const setModalState = useSetRecoilState(modalState);

  if (isLoading)
    return (
      <Widget showEditButton={false} width={WIDGET_WIDTH}>
        <Flexbox name="company-stats-loading" justify="center" height="100%">
          <Loader />
        </Flexbox>
      </Widget>
    );

  if (!data || data.organizationsCount === 0)
    return (
      <EmptyStateWidget
        label={t`stats.emptyStateMsg`}
        linkText={t`stats.emptyStateLinkText`}
        labelAlign="center"
        width={WIDGET_WIDTH}
        image={SvgOther}
        direction="column"
        onEditClick={() => setModalState({ state: 'createNewCompany' })}
      />
    );

  const statsConfig = [
    { value: data.organizationsCount, label: t`stats.infoTotalCompanies` },
    {
      value: `${data.numberOfOrganizationsCreatedLast30Days}`,
      label: t`stats.infoCompaniesGrowthPastMonth`,
    },
    { value: data.projectsCount, label: t`stats.infoTotalInProjects` },
  ];

  return (
    <Widget
      showEditButton={false}
      width={WIDGET_WIDTH}
      header={<Header>{t`stats.title`}</Header>}
    >
      <Flexbox
        name="companies-stats-container"
        direction="column"
        gap="56px"
        margin="56px 0px 0px 0px"
      >
        <Stack gap="30px">
          {statsConfig.map(config => (
            <StatItem key={config.label}>
              <HighlightedInfo>{config.value}</HighlightedInfo>
              <SubInfo>{config.label}</SubInfo>
            </StatItem>
          ))}
        </Stack>
      </Flexbox>
    </Widget>
  );
};

const StatItem = styled('div')`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.gray.c3}`};
  padding-bottom: 20px;
`;

const HighlightedInfo = styled('div')`
  ${({ theme }) => theme.typography.widget.header}
  color: ${({ theme }) => theme.colors.blue.primaryB};
`;

const SubInfo = styled('div')`
  ${({ theme }) => theme.typography.companyInfo.subHeader}
  color: ${({ theme }) => theme.colors.gray.c12};
`;
