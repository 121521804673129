import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import type { Company } from '@/api/v4/organization.api';
import { modalState } from '@/state/modal.state';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

interface CompanyActionsMenuProps {
  company: Company;
}

export const CompanyActionsMenu = ({ company }: CompanyActionsMenuProps) => {
  const { t } = useTranslation('companies');
  const setModal = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();

  const menu: DropdownOption[] = [
    {
      label: t`companies.companyActions.share`,
      isVisible: hasRequiredPermission(
        PERMISSION.SHARE_A_COMPANY_TO_EXISTING_Q_USERS,
      ),
      onClick: () => {
        setModal({ state: 'shareCompany', companyId: company.id });
      },
    },
    {
      label: t`companies.companyActions.delete`,
      isVisible: hasRequiredPermission(PERMISSION.DELETE_ORGANIZATION),
      onClick: () => {
        setModal({
          state: 'deleteCompany',
          company: {
            id: company.id,
            name: company.name,
          },
        });
      },
    },
  ];

  return (
    <WithDropdown
      items={menu}
      options={{ placement: 'bottom-end' }}
      testId="dropdown-menu"
    >
      <MenuButton />
    </WithDropdown>
  );
};
