import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Stack } from '@/ui/line/line';

import type { RequestHistoryItem } from './use-sorted-explore-history';

export const RequestsGroup = ({
  groupTitle,
  requests,
  onRequestClick,
}: {
  groupTitle: string;
  requests: RequestHistoryItem[];
  onRequestClick: (requestId: string) => void;
}) => {
  const { t } = useTranslation('explore');

  return (
    <Stack gap="6px">
      <Title>{t(groupTitle)}</Title>
      <Stack gap="0px">
        {requests.map(request => (
          <RequestButton
            key={request.id}
            onClick={() => onRequestClick(request.id)}
          >
            {request.title}
          </RequestButton>
        ))}
      </Stack>
    </Stack>
  );
};

const Title = styled('h2')`
  ${({ theme }) => theme.typography.exploreHistory.periodTitle};
  padding: 0px 7px;
`;

const RequestButton = styled('div')`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText};
  line-height: 20px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.gray.c12};
  padding: 6px 7px;
  ${({ theme }) => theme.mixins.ellipsis}

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue.c7};
  }
`;
