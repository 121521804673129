import { styled } from 'goober';

import { InvitedUsers } from './invited-users/invited-users';
import { ProfileSettings } from './profile-settings/profile-settings';
import { UserManagement } from './user-management/user-management';
import type { SettingsTabs } from './use-settings-tabs-config';

export const SettingsTabContent = ({ tab }: { tab: SettingsTabs }) => {
  const getTabContent = (tab: SettingsTabs) => {
    switch (tab) {
      case 'profileSettings':
        return <ProfileSettings />;
      case 'userManagement':
        return <UserManagement />;
      case 'invitedUsers':
        return <InvitedUsers />;
    }
  };

  return <TabContentContainer>{getTabContent(tab)}</TabContentContainer>;
};

const TabContentContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;
