import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

const parseSnapshotFunnelParams = (params: FunnelParams) => ({
  'createdDate[from]': params.from,
  'createdDate[to]': params.to,
});

type SnapshotParams = APIRequestQueryParams<'/api/v4/flow/snapshot'>;
type SnapshotResponse = APIResponse<'/api/v4/flow/snapshot'>;

export const fetchSnapshot = async (params?: SnapshotParams) =>
  get<SnapshotResponse>(`v4/flow/snapshot`, {
    params: params ? parseSnapshotFunnelParams(params) : undefined,
  });

type FunnelParams = APIRequestQueryParams<'/api/v4/flow/funnel'>;
type FunnelResponse = APIResponse<'/api/v4/flow/funnel'>;

export const fetchFunnel = async (params?: FunnelParams) =>
  get<FunnelResponse>(`v4/flow/funnel`, {
    params: params ? parseSnapshotFunnelParams(params) : undefined,
  });

type CompaniesStatisticsResponse = APIResponse<'/api/v4/flow/statistics'>;

export const fetchCompaniesStatistics = async () =>
  get<CompaniesStatisticsResponse>(`v4/flow/statistics`);
