import { useState } from 'react';
import type { SingleValue } from 'react-select';

import type { User } from '@/api/v4/user.api';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { APP_USER_ROLES } from '@/types';
import type { SelectOptionValue } from '@/ui/select/select';
import { Select } from '@/ui/select/select';
import { ResourceAccess } from '@/user/resource-access/resource-access.type';
import { useUserResourceAccess } from '@/user/resource-access/use-user-resource-access';

import { type UserRoleOption } from './settings.state';

type RoleDropdownSelectProps = {
  user: User;
  onChange: (value: UserRoleOption) => void;
  isDisabled?: boolean;
  getUserRole: (roleId: string) => UserRoleOption;
};

export const RoleDropdownSelect = ({
  user,
  isDisabled,
  onChange,
  getUserRole,
}: RoleDropdownSelectProps) => {
  const [selectedRole, setSelectedRole] = useState({
    label: getUserRole(user.role.id).label,
    value: user.role.id,
  });
  const { hasAccessToResource } = useUserResourceAccess();

  const { data: appRoles } = useRoles();

  const rolesOptions = appRoles
    ? appRoles
        .filter(role => {
          if (
            role.name === APP_USER_ROLES.ADMIN &&
            !hasAccessToResource(ResourceAccess.USER_RESOURCES_INVITE_ADMIN)
          )
            return false;
          else if (
            role.name === APP_USER_ROLES.MANAGER &&
            !hasAccessToResource(ResourceAccess.USER_RESOURCES_INVITE_MANAGER)
          )
            return false;
          return true;
        })
        .map(role => ({
          label: role.name,
          value: role.id,
        }))
    : [];

  const selectRole = (v: SingleValue<SelectOptionValue<string>>) => {
    if (v?.value === user.role.id || !v?.value) return;

    setSelectedRole({
      label: getUserRole(v.value).label,
      value: v.value,
    });

    onChange(getUserRole(v.value));
  };

  return (
    <Select
      value={selectedRole}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      onChange={selectRole}
      options={rolesOptions}
      styles={{
        menu: base => ({
          ...base,
          [`@media (max-width: 1180px)`]: {
            width: 'min-content',
          },
        }),
        singleValue: base => ({
          ...base,
          '.hidden-inside-single-value': {
            display: 'none',
          },
        }),
      }}
    />
  );
};
