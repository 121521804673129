import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual, omit } from 'lodash-es';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { ContactsInfiniteScroll } from '@/features/contacts/contact-infinite-scroll';
import type { ContactFormItem } from '@/features/contacts/contacts';
import { ContactsWidget } from '@/features/contacts/contacts-widget';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ReadMore as ShowMore } from '@/ui/typography/across-platform';

import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import {
  companyContactDeletedIdState,
  companyContactsAddCounterState,
  companyContactsAtomState,
  companyContactsEditModeState,
  companyContactsForceSubmitState,
  companyContactsPageState,
  companyIdState,
} from '../company.state';
import { EditStamp } from '../edit-stamp';
import { useCompany } from '../use-company.query';

import { useCompanyContacts } from './use-company-contacts';
import { useCreateCompanyContacts } from './use-create-company-contacts.mutation';
import { useEditCompanyContact } from './use-edit-company-contact.mutation';

const mapContactFormItemToAPIPayload = (contact: ContactFormItem) => ({
  firstName: contact.firstName,
  lastName: contact.lastName,
  email: contact.email ?? undefined,
  title: contact.title ?? undefined,
  isPrimary: contact.isPrimary,
  linkedinUrl: contact.linkedinUrl ?? undefined,
  mobilePhone: contact.mobilePhone ?? undefined,
  id: contact.id,
});

export const CompanyContactsWidget = ({
  className,
}: {
  className?: string;
}) => {
  const { t } = useTranslation('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editMode, setEditMode] = useRecoilState(companyContactsEditModeState);
  const resetSubmitState = useResetRecoilState(companyContactsForceSubmitState);
  const companyId = useRecoilValue(companyIdState);
  const { data: company } = useCompany();

  const { createContacts } = useCreateCompanyContacts();
  const { editContacts } = useEditCompanyContact();

  const canEditCompany = useCanEditCompany();

  const {
    allContacts,
    visibleContacts,
    isLoading,
    hasNextPage,
    reset,
    addContactList,
    editContactList,
    deleteContact,
    poweredByCrunchbase,
    isMoreContactsThanMaxVisible,
    toggleAllContacts,
    allContactsVisible,
  } = useCompanyContacts();

  const isEditStampVisible =
    company && allContacts.length > 0 && poweredByCrunchbase;

  const isShowMoreButtonVisible = useMemo(
    () => isMoreContactsThanMaxVisible && !editMode,
    [editMode, isMoreContactsThanMaxVisible],
  );

  const handleContactsToCreate = async (contacts: ContactFormItem[]) => {
    const contactsToCreate = contacts
      .filter(contact => !contact.synced)
      .map(mapContactFormItemToAPIPayload);

    if (contactsToCreate.length > 0) {
      const result = await createContacts({
        contacts: contactsToCreate,
      });

      if (result) {
        addContactList(result.map(contact => ({ ...contact, synced: true })));
      }
    }
  };

  const handleContactsToEdit = async (contacts: ContactFormItem[]) => {
    const contactsToEdit = contacts
      .filter(contact => contact.synced && contact.id)
      .map(mapContactFormItemToAPIPayload);

    if (contactsToEdit.length > 0) {
      const result = await editContacts({
        contacts: contactsToEdit,
      });

      if (result) {
        editContactList(result.map(contact => ({ ...contact, synced: true })));
      }
    }
  };
  const onFormSubmit = useCallback(
    async (formContacts: ContactFormItem[]) => {
      if (!companyId) return;
      const contactsToSave = formContacts.reduce((accu, formContact) => {
        const oldCopy = allContacts.find(({ id }) => id === formContact.id);

        if (!formContact.synced) return [...accu, formContact];

        if (
          (isEqual(omit(oldCopy, 'lastEditedDate'), formContact) &&
            formContact.synced) ||
          !oldCopy
        ) {
          return accu;
        }

        return [...accu, formContact];
      }, [] as ContactFormItem[]);

      await Promise.all([
        handleContactsToCreate(contactsToSave),
        handleContactsToEdit(contactsToSave),
      ]);

      resetSubmitState();
      setIsSubmitting(false);
      reset();
    },
    [companyId, resetSubmitState, reset, allContacts],
  );

  const submitHandler = useCallback(
    async (values: ContactFormItem[]) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      await onFormSubmit(values);
    },
    [isSubmitting, onFormSubmit],
  );

  if (!canEditCompany && allContacts.length === 0) return null;

  return (
    <div className={className}>
      <ContactsWidget
        contactFormSubmitState={companyContactsForceSubmitState}
        addContactCounterState={companyContactsAddCounterState}
        showEditButton={canEditCompany && !editMode && allContacts.length > 0}
        isEditMode={editMode}
        onEditClick={() => {
          canEditCompany && setEditMode(true);
        }}
      >
        <ContactsInfiniteScroll
          key={visibleContacts.length}
          addContactCounterState={companyContactsAddCounterState}
          contactsSubmitFormState={companyContactsForceSubmitState}
          contactDeletedIdState={companyContactDeletedIdState}
          contactsEditModeState={companyContactsEditModeState}
          formContactsState={companyContactsAtomState}
          contactsPageState={companyContactsPageState}
          visibleContacts={visibleContacts}
          onFormSubmit={submitHandler}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          deleteAction={deleteContact}
        />
        <Flexbox name="company-contacts-lower-content" justify="flex-end">
          {isEditStampVisible && (
            <EditStamp
              crunchbasePermalink={company.crunchbasePermalink}
              align="left"
            />
          )}

          {isShowMoreButtonVisible && (
            <ShowMore
              onClick={toggleAllContacts}
              label={allContactsVisible ? t`showLess` : t`showAll`}
              showMore={allContactsVisible}
            />
          )}
        </Flexbox>
      </ContactsWidget>
    </div>
  );
};
