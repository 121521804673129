import { styled } from 'goober';

import { Inline } from '@/ui/line/line';

export const PageGrid = styled(Inline)<{
  columnGap?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  grid-auto-flow: unset;
  grid-template-columns: 2fr 1fr;
  row-gap: 24px;
  column-gap: ${({ columnGap }) => columnGap ?? '24px'};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-top: ${marginBottom}`};
`;
