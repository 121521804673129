import type { HTMLProps, SyntheticEvent } from 'react';

type ImgProps = HTMLProps<HTMLImageElement> & {
  fallbackSrc: string;
  className?: string;
};

export const ImageWithFallback = ({
  fallbackSrc,
  className,
  ...props
}: ImgProps) => {
  const handleError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    target.src = fallbackSrc;
  };

  return <img onError={handleError} className={className} {...props} />;
};
