import { useTranslation } from 'react-i18next';

import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphSmall } from '@/ui/typography/widgets';

export const RelatedProjectsWidgetEmptyState = ({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation('teams');

  return (
    <Flexbox
      name="app-users-table-empty-container"
      justify="center"
      alignItems="center"
      direction="column"
      flex="1"
      height="100%"
      gap="30px"
    >
      <ParagraphSmall
        color={colors.gray.c12}
      >{t`emptyTeamRelatedProjects`}</ParagraphSmall>
      {!disabled && (
        <Button variant="secondary" startIcon="Plus" onClick={onClick}>
          {t`createProject`}
        </Button>
      )}
    </Flexbox>
  );
};
