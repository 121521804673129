import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Button } from '@/ui/button/button';
import { EmptyStateRoundedBarChart } from '@/ui/chart/empty-state-rounded-bar-chart';
import type { ChartData } from '@/ui/chart/rounded-bar-chart';
import { Stack } from '@/ui/line/line';
import { Header, ParagraphSmall } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { BlockContainer } from '../block-container';

import { NotRelevantCounter } from './not-relevant-counter';

export const CompaniesInProjectEmptyStateCharts = () => {
  const { t } = useTranslation('companies', { keyPrefix: 'charts' });
  const { hasRequiredPermission } = useUserPermissions();
  const navigateTo = useNavigateTo();

  const mockedData: ChartData = [
    { id: 'discovery', name: t`discovery`, count: 6 },
    { id: 'potentialFit', name: t`potentialFit`, count: 5 },
    { id: 'contacted', name: t`contacted`, count: 4 },
    { id: 'dueDiligence', name: t`dueDiligence`, count: 3 },
    { id: 'pilot', name: t`pilot`, count: 2 },
    { id: 'deal', name: t`deal`, count: 1 },
  ];

  return (
    <BlockContainer header={<Header>{t`header`}</Header>}>
      <Stack
        margin={
          hasRequiredPermission(PERMISSION.ADD_PROJECT)
            ? '10px 0 0 0'
            : '10px 0 100px 0'
        }
      >
        <ParagraphSmall>{t`emptyStateText`}</ParagraphSmall>
        {hasRequiredPermission(PERMISSION.ADD_PROJECT) && (
          <StyledButton
            startIcon="Plus"
            onClick={() => navigateTo.createProject()}
          >{t`addProject`}</StyledButton>
        )}
      </Stack>
      <EmptyStateRoundedBarChart data={mockedData} />

      <DisabledCounter count={0} />
    </BlockContainer>
  );
};

const DisabledCounter = styled(NotRelevantCounter)`
  opacity: 50%;
`;

const StyledButton = styled(Button)`
  margin: 100px auto 0;
`;
