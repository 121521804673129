import type { ChangeEvent } from 'react';
import { type Namespace, useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { useDebounce } from '@/hooks/use-debounce';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';

export const UserSearch = ({
  onSearchInputChange,
  translationNamespace,
}: {
  onSearchInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  translationNamespace: Namespace;
}) => {
  const { t } = useTranslation(translationNamespace);
  const setModal = useSetRecoilState(modalState);

  const handleSearchInputChange = useDebounce(onSearchInputChange);
  const handleInviteUser = () => setModal({ state: 'inviteUser' });

  return (
    <FixedContainer name="search-for-users-container" gap="24px">
      <Input
        onChange={handleSearchInputChange}
        placeholder={t`searchForUsers`}
        width="224px"
        padding="4px 8px"
        fontSize="12px"
      />
      <Button startIcon="Plus" onClick={handleInviteUser}>
        {t`default:invite.inviteUser`}
      </Button>
    </FixedContainer>
  );
};

const FixedContainer = styled(Flexbox)`
  position: absolute;
  top: 0px;
  right: 40px;
  padding-right: 32px;
`;
