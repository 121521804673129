import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const ListingStatusFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const companiesFilters = useRecoilValue(companiesFilterOptionsState);

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const setOptions = async (search: string) => {
    if (search && companiesFilters.listingStatuses) {
      return companiesFilters.listingStatuses.filter(item =>
        item.label.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return companiesFilters.listingStatuses;
  };

  return (
    <AsyncSelect
      id="filter-listing-stage"
      label={t`companies.filters.stage`}
      defaultValue={selectedFilters.listingStatuses}
      isMulti
      isSearchable
      className={className}
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;
        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          listingStatuses: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
