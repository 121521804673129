import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { fetchUsersCount } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export const useUsersCount = () => {
  const { t } = useTranslation('default');
  const { hasRequiredPermission } = useUserPermissions();

  return useQuery([QueryKey.UsersCount], fetchUsersCount, {
    staleTime: 1000 * 10 * 5,
    enabled: hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT),
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
  });
};
