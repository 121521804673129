import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';

import { CreatedByFilter } from './created-by-filter';
import { CreatedDateFilter } from './created-date-filter';
import { CreatedForFilter } from './created-for-filter';
import { LastEditedDateFilter } from './last-edited-date-filter';
import { ListingStatusFilter } from './listing-status-filter';
import { ProjectStatusFilter } from './project-status-filter';
import { TagsFilter } from './tags-filter';

export const Filters = ({ onApply }: { onApply: () => void }) => {
  const { t } = useTranslation('projects');
  return (
    <Flexbox name="filters-container" direction="column" gap="56px">
      <Grid gap="36px" templateColumns="repeat(4, 1fr)">
        <Stack gap="44px">
          <TagsFilter />
          <CreatedForFilter />
        </Stack>
        <Stack gap="44px">
          <ListingStatusFilter />
          <CreatedDateFilter />
        </Stack>
        <Stack gap="44px">
          <ProjectStatusFilter />
          <LastEditedDateFilter />
        </Stack>
        <Stack gap="44px">
          <CreatedByFilter />
        </Stack>
      </Grid>
      <ApplyFiltersButton
        onClick={onApply}
      >{t`projects.apply`}</ApplyFiltersButton>
    </Flexbox>
  );
};

const ApplyFiltersButton = styled(Button)`
  align-self: flex-end;
`;

const Grid = styled(Inline)`
  margin-right: 100px;
`;
