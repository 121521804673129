import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Inline, Stack } from '@/ui/line/line';
import { FullSizedHeader } from '@/ui/typography/across-platform';
import { useCurrentUser } from '@/user/use-current-user.query';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { ActiveProjectWidget } from '@/features/home/active-projects-widget/active-projects-widget';
import { CompaniesInProjectCharts } from '@/features/home/companies-in-project-charts/companies-in-project-charts';
import { CompaniesStatsWidget } from '@/features/home/companies-stats/companies-stats-widget';
import { RecentItemsWidget } from '@/features/home/recent-items/recent-items-widget';
import { useCurrentTimeOfDay } from '@/features/home/use-current-time-of-day';

export const HomePage = () => {
  const { currentTimeOfDay } = useCurrentTimeOfDay();
  const { t } = useTranslation('homepage');
  const { t: greeting } = useTranslation('homepage', {
    keyPrefix: 'greeting',
  });
  const { data: user } = useCurrentUser();
  const userName = user?.firstName ?? '';

  return (
    <WithHtmlSavvyTitle title={t`page.title`}>
      <Container>
        <FullSizedHeader data-testid="greetings-header">
          {greeting(currentTimeOfDay, {
            name: userName,
          })}
        </FullSizedHeader>
        <Stack maxWidth="1270px" margin="0px auto 50px auto" gap="24px">
          <RecentItemsWidget />
          <CompaniesInProjectCharts />
          <StatsContainer gap="24px" alignItems="stretch">
            <ActiveProjectWidget />
            <CompaniesStatsWidget />
          </StatsContainer>
        </Stack>
      </Container>
    </WithHtmlSavvyTitle>
  );
};

const Container = styled('div')`
  max-width: 1375px;
  padding: 0 70px;
  margin: 0 auto;
`;

const StatsContainer = styled(Inline)`
  height: 576px;
`;
