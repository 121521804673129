export const getRoundedBarPath = (
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: number[],
) => {
  const [topLeft, topRight, bottomRight, bottomLeft] = borderRadius;

  return `
    M${x + topLeft},${y}
    H${x + width - topRight}
    C${x + width},${y} ${x + width},${y} ${x + width},${y + topRight}
    V${y + height - bottomRight}
    C${x + width},${y + height} ${x + width},${y + height} ${
    x + width - bottomRight
  },${y + height}
    H${x + bottomLeft}
    C${x},${y + height} ${x},${y + height} ${x},${y + height - bottomLeft}
    V${y + topLeft}
    C${x},${y} ${x},${y} ${x + topLeft},${y}
    Z
  `;
};
