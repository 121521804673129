import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { Compliance } from '@/api/v4/compliance.api';
import { fetchCompliances } from '@/api/v4/compliance.api';

export const useCompliances = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.Compliances], fetchCompliances, {
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    select: mapCompliancesToOptions,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const mapCompliancesToOptions = (compliances: Compliance[]) => {
  return compliances.map(compliance => ({
    label: compliance.name,
    value: compliance.id,
  }));
};
