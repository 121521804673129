import type { DescopeError, DescopeFlowErrorEvent } from '../types';

import { getDescopeError } from './get-descope-error';

export const isInvalidInvitationTokenError = (
  event: string | Event | DescopeFlowErrorEvent | DescopeError,
) => {
  const descopeError = getDescopeError(event);

  return (
    !!descopeError &&
    'errorDescription' in descopeError &&
    descopeError.errorDescription === 'Token expired'
  );
};
