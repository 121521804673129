import { useCompanyFundings } from './funding/use-company-fundings.query';
import { useCompanyTags } from './tags/use-company-tags.query';
import { useCompany } from './use-company.query';

export const useCompanyEnrichedWithCrunchbase = () => {
  const { data: company } = useCompany();
  const { data: companyTags } = useCompanyTags();
  const { data: companyFundings } = useCompanyFundings();

  if (!company) return null;

  const tags = {
    poweredBy: companyTags?.poweredBy,
  };

  return {
    name: {
      value: company.name.value,
      poweredBy: company.name.poweredBy,
      lastEditedAt: company.name.lastEditedAt,
    },
    logoUrl: {
      value: company.logoUrl.value,
      poweredBy: company.logoUrl.poweredBy,
      lastEditedAt: company.logoUrl.lastEditedAt,
    },
    hqLocation: {
      value: company.hqLocation.value,
      poweredBy: company.hqLocation.poweredBy,
      lastEditedAt: company.hqLocation.lastEditedAt,
    },
    linkedinUrl: {
      value: company.linkedInUrl.value,
      poweredBy: company.linkedInUrl.poweredBy,
      lastEditedAt: company.linkedInUrl.lastEditedAt,
    },
    website: {
      value: company.website.value,
      poweredBy: company.website.poweredBy,
      lastEditedAt: company.website.lastEditedAt,
    },
    ...(company.activityStatus && {
      activityStatus: {
        id: company.activityStatus.value?.id,
        name: company.activityStatus.value?.name,
        poweredBy: company.activityStatus.poweredBy,
        lastEditedAt: company.activityStatus.lastEditedAt,
      },
    }),
    ...(company.ownershipStatus && {
      ownershipStatus: {
        id: company.ownershipStatus.value?.id,
        name: company.ownershipStatus.value?.name,
        poweredBy: company.ownershipStatus.poweredBy,
        lastEditedAt: company.ownershipStatus.lastEditedAt,
      },
    }),
    ...(company.stockExchangeSymbol && {
      stockExchangeSymbol: {
        value: company.stockExchangeSymbol.value,
        poweredBy: company.stockExchangeSymbol.poweredBy,
        lastEditedAt: company.stockExchangeSymbol.lastEditedAt,
      },
    }),
    ticker: {
      value: company.ticker.value,
      poweredBy: company.ticker.poweredBy,
      lastEditedAt: company.ticker.lastEditedAt,
    },
    yearEstablished: {
      value: company.yearEstablished.value,
      poweredBy: company.yearEstablished.poweredBy,
      lastEditedAt: company.yearEstablished.lastEditedAt,
    },
    ...(company.employeesRange && {
      employeesRange: company.employeesRange,
    }),
    capitalRaised: {
      lastEditedAt: companyFundings?.capitalRaised?.lastEditedAt,
      poweredBy: companyFundings?.capitalRaised.poweredBy,
      value: companyFundings?.capitalRaised.value,
    },
    lastFundingDate: {
      lastEditedAt: companyFundings?.lastFundingDate.lastEditedAt,
      poweredBy: companyFundings?.lastFundingDate.poweredBy,
      value: companyFundings?.lastFundingDate.value,
    },
    fundingStage: {
      lastEditedAt: companyFundings?.fundingStage?.lastEditedAt || '',
      poweredBy: companyFundings?.fundingStage?.poweredBy || '',
      value: companyFundings?.lastFundingDate.value,
    },
    keyOffering: {
      value: company.keyOffering.value,
      poweredBy: company.keyOffering.poweredBy,
      lastEditedAt: company.keyOffering.lastEditedAt,
    },
    ownedByIrm: {
      value: company.ownedByIrm.value,
      poweredBy: company.ownedByIrm.poweredBy,
      lastEditedAt: company.ownedByIrm.lastEditedAt,
    },
    detailedDescription: {
      value: company.detailedDescription.value,
      poweredBy: company.detailedDescription.poweredBy,
      lastEditedAt: company.detailedDescription.lastEditedAt,
    },
    tags,
  };
};
