import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  projectsFilterOptionsState,
  selectedProjectsFiltersState,
} from '@/features/projects/project.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import type { OptionType } from '@/ui/select/async/use-select-type';
import { sharedProps } from '@/ui/select/select-shared';

export const ProjectStatusFilter = () => {
  const { t } = useTranslation('projects');

  const { status } = useRecoilValue(projectsFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  const setOptions = (search: string) => {
    return new Promise<OptionType[]>(resolve => {
      if (search) {
        resolve(
          status.filter(option =>
            option.label.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      }
      resolve(status);
    });
  };

  return (
    <AsyncSelect
      id="filter-status"
      label={t`projects.projectStatus`}
      defaultValue={selectedFilters.status}
      setOptions={setOptions}
      isSearchable
      isMulti
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          status: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
