import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { fetchListingStages } from '@/api/v4/project-listings.api';
import {
  projectsFilterOptionsState,
  selectedProjectsFiltersState,
} from '@/features/projects/project.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

import { mapListingStatusFilter } from './project-filters-helpers';

export const ListingStatusFilter = () => {
  const { t } = useTranslation('projects');

  const setProjectsFilters = useSetRecoilState(projectsFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  const setOptions = async (search: string) => {
    const listingStatuses = await fetchListingStages({
      ...(search && { search }),
    });

    const listingStatusesOptions = listingStatuses.map(mapListingStatusFilter);

    setProjectsFilters(currentFilters => ({
      ...currentFilters,
      listingStatuses: listingStatusesOptions,
    }));
    return listingStatusesOptions;
  };

  return (
    <AsyncSelect
      id="filter-listing-stage"
      label={t`projects.stage`}
      defaultValue={selectedFilters.listingStatus}
      setOptions={setOptions}
      isMulti
      isSearchable
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          listingStatus: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
