import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { fetchProjectCreators } from '@/api/v4/project-filters.api';
import {
  projectsFilterOptionsState,
  selectedProjectsFiltersState,
} from '@/features/projects/project.state';
import { isNumberOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

import { mapProjectCreatedByFilter } from './project-filters-helpers';

export const CreatedByFilter = () => {
  const { t } = useTranslation('projects');

  const setProjectsFilters = useSetRecoilState(projectsFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  const setOptions = async (search: string) => {
    const creators = await fetchProjectCreators({
      ...(search && { search }),
    });
    const createdByOptions = creators.map(mapProjectCreatedByFilter);
    setProjectsFilters(currentFilters => ({
      ...currentFilters,
      createdBy: createdByOptions,
    }));
    return createdByOptions;
  };

  return (
    <AsyncSelect
      id="filter-created-by"
      label={t`projects.createdBy`}
      defaultValue={selectedFilters.createdBy}
      isSearchable
      isMulti
      setOptions={setOptions}
      onChange={option => {
        if (!isNumberOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          createdBy: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
