import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const editUserProfileValidationSchema = z.object({
  name: z.string().nonempty(t`default:fieldIsRequired`),
  lastName: z.string().nonempty(t`default:fieldIsRequired`),
  email: z.string().nonempty(t`default:fieldIsRequired`),
  position: z
    .string()
    .max(
      50,
      t('default:maxLengthError', {
        maxLengthValue: 50,
      }),
    )
    .optional(),
  accountName: z.string().optional(),
});
