import { useUpdateProject } from '../use-update-project.mutation';
import type { Project } from '@/api/v4/projects.api';
import { SkeletonWidget } from './project-sidebar';
import { CurrencyWidget } from '@/components/widgets/currency-widget/currency-widget';

export const ProjectCurrencyWidget = ({
  isDataLoading,
  projectId,
  projectBudget,
  internalExpectedCost,
  externalExpectedCost,
  hasEditPermission,
  widgetHeight,
  widgetWidth,
}: {
  isDataLoading: boolean;
  projectId?: Project['id'];
  projectBudget?: Project['projectBudget'];
  internalExpectedCost?: Project['internalExpectedCost'];
  externalExpectedCost?: Project['externalExpectedCost'];
  hasEditPermission: boolean;
  widgetHeight: number;
  widgetWidth: number;
}) => {
  const { editProject } = useUpdateProject();

  if (isDataLoading || !projectId) {
    return <SkeletonWidget width={widgetWidth / 3} />;
  }

  const handleEdit = async (
    options: Pick<
      Project,
      'projectBudget' | 'externalExpectedCost' | 'internalExpectedCost'
    >,
  ) =>
    editProject({
      projectId,
      projectBudget: options.projectBudget,
      externalExpectedCost: options.externalExpectedCost,
      internalExpectedCost: options.internalExpectedCost,
    });

  return (
    <CurrencyWidget
      internalExpectedCost={internalExpectedCost ?? null}
      externalExpectedCost={externalExpectedCost ?? null}
      projectBudget={projectBudget ?? null}
      onSave={handleEdit}
      height={widgetHeight}
      disabled={!hasEditPermission}
      testId="currency-widget"
    />
  );
};
