import type { Location } from '@/api/v4/locations.api';
import { fetchLocations } from '@/api/v4/locations.api';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

import { FormSelect } from '../ui/form-select';

export const LocationSelect = ({ ...props }: AsyncSelectProps) => {
  const setOptions = async (search: string) => {
    const results = await fetchLocations({
      search,
      page: 1,
      type: ['continent', 'country'],
    });

    return results.map((location: Location) => ({
      value: JSON.stringify({
        id: location.id,
        type: location.type,
      }),
      label: location.shortDescription,
    }));
  };

  return (
    <FormSelect
      setOptions={setOptions}
      isMulti
      isSearchable
      hideSelectedOptions
      {...props}
    />
  );
};
