import { useTranslation } from 'react-i18next';

import { isIntegrationOptionArray } from '@/helpers/other';
import type { IntegrationOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import { OptionWithLogo } from '@/ui/select/async/components/option/option-with-logo';
import { SelectedOptionsListAsLogo } from '@/ui/select/async/components/selected-options-list/selected-options-list-as-logo';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { fetchIntegrations } from '@/api/v4/integration.api';

interface CompanyProductIntegrationProps {
  setData: (integrations: IntegrationOption[]) => void;
  isEditMode: boolean;
  className?: string;
  defaultValue?: IntegrationOption[];
}

export const CompanyProductIntegration = ({
  setData,
  isEditMode,
  className,
  defaultValue,
}: CompanyProductIntegrationProps) => {
  const { t } = useTranslation('companies');

  const setOptions = async (search: string) => {
    const integrations = await fetchIntegrations({
      search,
    });

    const options = integrations.map(option => ({
      value: option.uniqueId,
      label: option.name,
      logo: option.logoUrl,
      crunchbaseId: option.crunchbaseId,
      integrationId: option.integrationId,
      source: option.source,
    }));

    return options;
  };

  if (defaultValue?.length === 0 && !isEditMode) return null;

  return (
    <Flexbox
      name="company-product-integration-widget"
      direction="column"
      gap="12px"
      className={className}
    >
      <SubHeaderBold>{t`products.integrations`}</SubHeaderBold>
      {!isEditMode ? (
        <SelectedOptionsListAsLogo
          list={defaultValue}
          isListExpanded
          logoSize="sm"
          itemsPerRow={5}
          wrapText
        />
      ) : (
        <AsyncSelectWithList
          defaultValue={defaultValue}
          setOptions={setOptions}
          placeholder={t`products.integrationSearch`}
          hideSelectedOptions={false}
          components={{ Option: OptionWithLogo }}
          SelectedOptionsListComponent={
            <SelectedOptionsListAsLogo logoSize="sm" itemsPerRow={4} wrapText />
          }
          onChange={async newValue => {
            isIntegrationOptionArray(newValue) && setData(newValue);
          }}
        />
      )}
    </Flexbox>
  );
};
