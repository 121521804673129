import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import useResizeObserver from 'use-resize-observer';

type IScroll = Record<'width' | 'height' | 'left' | 'top', number>;

interface useDebouncedResizeObserverProps {
  wait: number;
  ref: React.RefObject<HTMLElement>;
  onResize?: (rect?: DOMRectReadOnly, scroll?: IScroll) => void;
  // by default this hook triggers rerendering on resize, set false to disable this feature
  shouldTrigerRerenders?: boolean;
}

interface ResizeState {
  rect: DOMRectReadOnly | undefined;
  scroll: IScroll | undefined;
}

interface HookDebouncedResizeObserver {
  ref: (instance: HTMLElement | null) => void;
  rect: DOMRectReadOnly | undefined;
  scroll: IScroll | undefined;
  cancelDebouncedCallback: () => void;
}

const useDebouncedResizeObserver = (
  props: useDebouncedResizeObserverProps,
): HookDebouncedResizeObserver => {
  const [, setRerender] = useState(0);
  const resizeState = useRef<ResizeState>({
    rect: undefined,
    scroll: undefined,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce(
      () =>
        requestAnimationFrame(() => {
          const newScroll: IScroll = {
            width: props.ref.current?.scrollWidth ?? -1,
            height: props.ref.current?.scrollHeight ?? -1,
            top: props.ref.current?.scrollTop ?? -1,
            left: props.ref.current?.scrollLeft ?? -1,
          };
          const newRect = props.ref.current?.getBoundingClientRect();
          resizeState.current = { rect: newRect, scroll: newScroll };

          if (props.shouldTrigerRerenders !== false) {
            setRerender(render => render + 1);
          }
          props.onResize && props.onResize(newRect, newScroll);
        }),
      props.wait,
      { leading: true, maxWait: 1000 },
    ),
    [props],
  );

  useEffect(() => {
    if (!props.ref) debouncedCallback.cancel();
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback, props.ref]);

  const { ref } = useResizeObserver({
    ref: props.ref.current,
    onResize: debouncedCallback,
  });

  return {
    ref,
    rect: resizeState.current.rect,
    scroll: resizeState.current.scroll,
    cancelDebouncedCallback: debouncedCallback.cancel,
  };
};

export { useDebouncedResizeObserver };
