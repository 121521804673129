import { useTranslation } from 'react-i18next';

import type { ProjectKPIs } from '@/api/v4/projects.api';
import { KPILabel, KPIValue } from '@/components/kpi/kpi';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { useProjectKpi } from './use-projects-kpi.query';
import { KpiLoadingSkeleton } from '@/components/kpi/kpi-loading-skeleton';

export const ProjectsKPI = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'kpi' });
  const { data: kpis, isFetched } = useProjectKpi();

  if (!isFetched) {
    return <KpiLoadingSkeleton />;
  }

  return (
    <Flexbox name="projects-kpi" gap="92px" fullWidth>
      <SubHeaderBold>{t`title`}</SubHeaderBold>
      {kpis &&
        Object.keys(kpis).map(key => (
          <Flexbox name="kpi" key={key} alignItems="center" gap="12px">
            <KPILabel>{t(key)}</KPILabel>
            <KPIValue>{kpis[key as keyof ProjectKPIs]}</KPIValue>
          </Flexbox>
        ))}
    </Flexbox>
  );
};
