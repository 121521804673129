import { forwardRef } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import type { InvestorOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';

import {
  OptionsListItem,
  OptionsListItemLabel,
} from '../selected-options-list';
import { useEllipsisLabel } from '../use-ellipsis-label';

interface Props {
  isListExpanded?: boolean;
  onRemove?: (item: InvestorOption) => void;
  isDisabled?: boolean;
  option: InvestorOption;
  index: number;
}

export const OPTION_ITEM_HEIGHT = 60;

export function SelectedInvestorOption({
  onRemove,
  isDisabled,
  option,
  index,
}: Props) {
  const { isVisible, setRefItemLabel } = useEllipsisLabel(index);

  return (
    <OptionItem
      key={option.value}
      name="options-list-item"
      justify="space-between"
      alignItems="center"
      dataTestId="selected-investor-option"
    >
      <Tooltip placement="left" content={option.label} visible={isVisible}>
        <Flexbox name="option-content" gap="8px" alignItems="center">
          <Logo name={option.label} logoUrl={option.logoUrl} singleLetter />
          <Label ref={setRefItemLabel}>{option.label}</Label>
        </Flexbox>
      </Tooltip>
      <Actions name="actions" gap="20px" alignItems="center">
        {!isDisabled && onRemove && (
          <Icon
            icon="Close"
            width="15px"
            height="15px"
            color={colors.gray.c13}
            onClick={() => onRemove?.(option)}
          />
        )}
      </Actions>
    </OptionItem>
  );
}

const Actions = styled(Flexbox)`
  font-size: 14px;
`;

const OptionItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};
`;

const Label = styled(OptionsListItemLabel, forwardRef)`
  max-width: 250px;
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;
