import { useMetadata } from '@/features/companies/hooks/use-metadata';
import { ActivityStatus } from '@/features/companies/overview/header/activity-status-dot';
import { HeadquartersLocation } from '@/features/companies/overview/header/headquarters-location';
import { SocialIconButtons } from '@/features/companies/overview/header/social-icon-buttons';
import { Flexbox } from '@/ui/flexbox/flexbox';

export const Metadata = () => {
  const { activityStatus, hqLocation, socialUrlValues } = useMetadata();

  return (
    <Flexbox name="company-metadata" alignItems="center">
      {!!activityStatus && <ActivityStatus status={activityStatus} />}
      <HeadquartersLocation hqLocation={hqLocation?.value} />
      <SocialIconButtons urlValues={socialUrlValues} />
    </Flexbox>
  );
};
