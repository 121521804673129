import type { Investor } from '@/api/v4/investor.api';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const InvestorTooltipContent = memo(function InvestorTooltipContent({
  description,
  crunchbaseId,
}: {
  description: Investor['description'];
  crunchbaseId: Investor['crunchbaseId'];
}) {
  const { t } = useTranslation('companies');

  if (!description && crunchbaseId) return t`investors.noInfo`;
  return (
    <div>
      {description}
      {!crunchbaseId && (
        <>
          {description && <br />}
          {description && <br />}
          {t`investors.addedManually`}
        </>
      )}
    </div>
  );
});
