import { forwardRef } from 'react';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';

export const ContactCardBaseContainer = styled(Flexbox, forwardRef)<{
  focused?: boolean;
}>`
  border-radius: 20px;
  min-height: 292px;

  background-color: ${({ theme }) => theme.colors.basics.white};
  outline: ${({ theme, focused }) =>
    focused && `2px solid ${theme.colors.blue.primaryA}`};
  box-shadow: ${({ theme }) => theme.shadow.widgets};
`;
