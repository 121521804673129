import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { mapCompanyFundingStageFilter } from '@/features/companies/filters/company-filters-helpers';
import { useFundingStages } from '@/features/funding-stages/use-funding-stages';
import { isDefaultOption } from '@/helpers/other';
import type { IOption } from '@/types';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const FundingStageSelector = ({
  defaultValue,
  isDisabled = false,
  onChange,
}: {
  defaultValue: IOption | null;
  isDisabled?: boolean;
  onChange: (item: IOption) => void;
}) => {
  const { t } = useTranslation('companies');
  const { data: fundingStages = [] } = useFundingStages();

  const setOptions = async () => {
    const fundingStageOptions = fundingStages.map(mapCompanyFundingStageFilter);
    return fundingStageOptions;
  };

  const handleChange = (newValue: unknown) => {
    if (isDefaultOption(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <FundingStageSelect
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      setOptions={setOptions}
      onChange={handleChange}
      placeholder={t`funding.fundingStageSelectPlaceholder`}
      readOnly={false}
      disableFetchMore
      components={{ ...sharedProps.components }}
    />
  );
};

const FundingStageSelect = styled(AsyncSelect)`
  width: 100%;
  .single-value-container {
    font-size: 16px;
  }
`;
