import type { RefObject } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Tooltip } from '@/ui/tooltip/tooltip';
import {
  WIDGET_CONTAINER_MAX_HEIGHT,
  WIDGET_CONTAINER_PADDING_VERTICAL,
} from '@/ui/widget/widget';
import { Logo } from '@/ui/logo/logo';
import { openNewTab } from '@/helpers/open-new-tab';
import type { OrganizationInvestor } from '@/api/v4/organization.api';
import { InvestorTooltipContent } from './select/investor-tooltip-content';

export interface SelectedOptionsListProps {
  height?: string;
  list: OrganizationInvestor[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
}
export function InvestorsList({
  list,
  listRef,
  height,
  isListExpanded = false,
}: SelectedOptionsListProps) {
  const [maxHeight, setMaxHeight] = useState<number | null>();

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.scrollTop = 0;
    const widgetBottomHeight = (
      listRef.current.offsetParent?.querySelector(
        '[data-name="widget-bottom-content"]',
      ) as HTMLDivElement | null
    )?.offsetHeight;

    setMaxHeight(
      WIDGET_CONTAINER_MAX_HEIGHT -
        WIDGET_CONTAINER_PADDING_VERTICAL -
        (listRef?.current?.offsetTop ?? 0) -
        (widgetBottomHeight ? widgetBottomHeight + 12 : 0),
    );
  }, [isListExpanded, listRef]);

  return (
    <OptionsList
      height={height}
      name="options-list"
      direction="column"
      gap="16px"
      defaultRef={listRef}
      isScrollable={Boolean(isListExpanded)}
      {...(maxHeight && { maxHeight: `${maxHeight}px` })}
    >
      {list.map(item => (
        <Tooltip
          placement="left"
          content={
            <InvestorTooltipContent
              description={item.description}
              crunchbaseId={item.crunchbaseId}
            />
          }
          key={item.id}
        >
          <OptionsListItem
            name="applied-investor-list-item"
            gap="12px"
            onClick={() =>
              item.website ? openNewTab(item.website) : undefined
            }
          >
            <Logo name={item.name} logoUrl={item.logoUrl} singleLetter />

            <OptionsListItemLabel>{item.name}</OptionsListItemLabel>
          </OptionsListItem>
        </Tooltip>
      ))}
    </OptionsList>
  );
}

export const OptionsList = styled(Flexbox)<{
  height?: string;
  editMode?: boolean;
  isScrollable: boolean;
  maxHeight?: string;
}>`
  ${({ theme }) => theme.mixins.scrollbar}
  max-height: ${({ height }) => `${height ?? WIDGET_CONTAINER_MAX_HEIGHT}px`};
  user-select: none;
  overflow: hidden;

  ${({ isScrollable, maxHeight }) =>
    isScrollable &&
    maxHeight &&
    `
    overflow: auto;
    max-height: ${maxHeight};
  `}
`;

export const OptionsListItem = styled(Flexbox)`
  ${({ theme }) => theme.typography.widget.paragraphMedium}
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const OptionsListItemLabel = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 280px;
`;
