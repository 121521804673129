import { forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import type { RecoilState } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header } from '@/ui/typography/widgets';
import type { WidgetProps } from '@/ui/widget/widget';
import { Widget } from '@/ui/widget/widget';

type ContactsWidgetProps = Pick<
  WidgetProps,
  'onEditClick' | 'showEditButton' | 'isEditMode'
> & {
  children: React.ReactNode;
  contactFormSubmitState: RecoilState<boolean>;
  addContactCounterState: RecoilState<number>;
};

export const ContactsWidget = ({
  children,
  onEditClick,
  showEditButton,
  isEditMode,
  contactFormSubmitState,
  addContactCounterState,
}: ContactsWidgetProps) => {
  const { t } = useTranslation('contacts');

  const elementRef = useRef<HTMLDivElement>(null);
  const modal = useRecoilValue(modalState);
  const setForceFormSubmit = useSetRecoilState(contactFormSubmitState);
  const setAddContactCounterState = useSetRecoilState(addContactCounterState);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        !elementRef.current ||
        elementRef.current.contains(event.target as Node | null)
      ) {
        setForceFormSubmit(false);
        return;
      }

      if (modal || !isEditMode) return;
      setForceFormSubmit(true);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isEditMode, modal, setForceFormSubmit]);

  return (
    <ContactsWidgetContainer ref={elementRef}>
      <ContactsWidgetWithoutBackground
        className="contacts-widget"
        dataTestId="contacts-widget"
        onEditClick={onEditClick}
        showEditButton={showEditButton}
        isEditMode={isEditMode}
        shadowOnHover
      >
        <Stack gap="36px">
          <Flexbox
            name="contacts-header"
            justify="space-between"
            data-testid="contacts-widget-header"
          >
            <Header>{t`widgetHeader`}</Header>
            {isEditMode && (
              <Icon
                dataTestId="edit-mode-add-contact-button"
                onClick={() =>
                  setAddContactCounterState(counter => counter + 1)
                }
                role="button"
                icon="Plus"
                variant="secondary"
                size="12px"
              />
            )}
          </Flexbox>
          {children}
        </Stack>
      </ContactsWidgetWithoutBackground>
    </ContactsWidgetContainer>
  );
};

const ContactsWidgetContainer = styled('div', forwardRef)``;

const ContactsWidgetWithoutBackground = styled(Widget)`
  background: none;
`;

const Icon = styled(IconButton)`
  ${({ theme }) => theme.mixins.transition('transform')}
  height: 30px;
  margin-right: 12px;
`;
