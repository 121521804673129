import { useQuery } from '@tanstack/react-query';

import type { TeamContactParams } from '@/api/v4/team-contacts.api';
import { fetchTeamContacts } from '@/api/v4/team-contacts.api';
import { QueryKey } from '@/config/query-client';

export const useGetTeamContacts = (
  teamId: string | null,
  params: TeamContactParams,
) => {
  return useQuery(
    [QueryKey.TeamContacts, params.page, params.items],
    () => {
      if (!teamId) return null;

      return fetchTeamContacts(teamId, params);
    },
    {
      enabled: !!teamId,
    },
  );
};
