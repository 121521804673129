import { useAddCompanyRanking } from './use-company-rating.mutation';
import { useCompanyRankings } from './use-company-rating.query';

export const useCompanyRankingLoadingStates = (companyId: string | null) => {
  const { data: ratings, isFetching } = useCompanyRankings();
  const { addRating, removeRating } = useAddCompanyRanking(companyId);

  const isLoading = addRating.isLoading || removeRating.isLoading || isFetching;

  return { ratings, isLoading };
};
