import type {
  APIRequestBody,
  APIRequestPathParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type CompanyRankings =
  APIResponse<'/api/v4/organizations/{organizationId}/ratings'>;

export const fetchCompanyRatings = async (organizationId: string) =>
  get<CompanyRankings>(`/v4/organizations/${organizationId}/ratings`);

export type RateCompanyPayload =
  APIRequestBody<'/api/v4/organizations/{organizationId}/ratings/add'> & {
    organizationId: string;
  };

export const addCompanyRanking = async (payload: RateCompanyPayload) =>
  post<RateCompanyPayload>(
    `/v4/organizations/${payload.organizationId}/ratings/add`,
    payload,
  );

export type RemoveCompanyRatingParams =
  APIRequestPathParams<'/api/v4/organizations/{organizationId}/ratings/remove/{type}'>;

export const removeCompanyRanking = async (
  payload: RemoveCompanyRatingParams,
) =>
  remove<RateCompanyPayload>(
    `/v4/organizations/${payload.organizationId}/ratings/remove/${payload.type}`,
  );
