import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon, type IconVariant } from '@/ui/icons/icon';
import { SmallText } from '@/ui/typography/widgets';

export const FilterFeatureOption = ({
  label,
  icon,
  disabled,
  className,
}: {
  label: string;
  className?: string;
  icon?: IconVariant;
  disabled?: boolean;
}) => (
  <Flexbox
    name="filter-feature-option"
    justify="space-between"
    className={className}
  >
    <Label disabled={disabled}>{label}</Label>
    {icon && <Icon icon={icon} />}
  </Flexbox>
);

const Label = styled(SmallText)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray.c10 : theme.colors.basics.black};
`;
