import { IconButton } from '@/ui/button/icon-button';
import { Inline } from '@/ui/line/line';
import { colors } from '@/theme/colors';
import { styled } from 'goober';
import type { CompanyExternalLink } from '@/api/v4/organization-external-links.api';
import { prepareLink } from '@/utils/link';

export const ExternalLinkListItem = ({
  externalLink,
  onRemoveClick,
  isRemoveButtonVisible,
}: {
  externalLink: CompanyExternalLink;
  onRemoveClick: (id: string) => void;
  isRemoveButtonVisible: boolean;
}) => {
  return (
    <Inline justifyContent="space-between" alignItems="center">
      <Link
        href={prepareLink(externalLink.urlValue)}
        target="_blank"
        rel="noreferrer"
      >
        {externalLink.urlValue}
      </Link>

      {isRemoveButtonVisible && (
        <IconButton
          onClick={() => onRemoveClick(externalLink.id)}
          icon="Close"
          variant="ghost"
          color={colors.system.lowFit}
        />
      )}
    </Inline>
  );
};

const Link = styled('a')`
  ${({ theme }) => theme.mixins.ellipsis}
`;
