import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const urlDomainValidation = (domain: string) => {
  const checkDomain = (value: string, domain: string) => {
    return value.includes(domain);
  };

  return z
    .string()
    .refine(value => (value.length > 0 ? checkDomain(value, domain) : true), {
      message: t('default:urlDomainValidationMessage', { domain }),
    });
};
