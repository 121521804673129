import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, post, remove } from './base-fetch';

export type ProjectExternalLink =
  APIResponse<'/api/v4/projects/{projectId}/external-links'>[number];

export const fetchProjectExternalLinks = async (projectId: string) =>
  get<ProjectExternalLink[]>(`/v4/projects/${projectId}/external-links`);

export type AddProjectExternalLinkRequestBody =
  APIRequestBody<'/api/v4/projects/{projectId}/external-links/add'>;

export type RemoveProjectExternalLinkPayload = {
  projectId: string;
  linkId: string;
};

export interface AddProjectExternalLinkPayload {
  projectId: string;
  data: AddProjectExternalLinkRequestBody;
}

export const addProjectExternalLinkAPI = async ({
  projectId,
  data,
}: AddProjectExternalLinkPayload) =>
  post<ProjectExternalLink>(
    `/v4/projects/${projectId}/external-links/add`,
    data,
  );

export const removeProjectExternalLink = async ({
  linkId,
  projectId,
}: RemoveProjectExternalLinkPayload) =>
  remove<ProjectExternalLink>(
    `/v4/projects/${projectId}/external-links/remove/${linkId}`,
  );
