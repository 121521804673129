import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCompany } from '../overview/use-company.query';

export interface GeneralInformationValue {
  label: string;
  value: string | number;
  withEmptyState?: boolean;
}

export const useGeneralInformation = () => {
  const { data: company } = useCompany();

  const { t } = useTranslation('companies', { keyPrefix: 'company' });

  const generalInformationValues: GeneralInformationValue[] = useMemo(() => {
    if (!company) {
      return [];
    }

    const getStockSymbolValue = () => {
      if (!company.stockExchangeSymbol?.value || !company.ticker.value) {
        return '';
      }

      const symbolValue = `${company.ticker.value}:${company.stockExchangeSymbol.value}`;

      return symbolValue.toUpperCase();
    };
    return [
      {
        label: t`header.ownership`,
        value: company.ownershipStatus?.value?.name ?? '',
        withEmptyState: true,
      },
      { label: t`header.stockSymbol`, value: getStockSymbolValue() },
      {
        label: t`header.parentCompany`,
        value: company.ownedByIrm.value ?? '',
      },
      {
        label: t`header.founded`,
        value: company.yearEstablished.value ?? '',
        withEmptyState: true,
      },
      {
        label: t`header.employees`,
        value: company.employeesRange?.value?.name ?? '',
        withEmptyState: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const areAllFieldsValid = useMemo(() => {
    return generalInformationValues.every(info => !!info.value);
  }, [generalInformationValues]);

  return {
    generalInformationValues,
    areAllFieldsValid,
  };
};
