import { styled } from 'goober';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { ProjectListing } from '@/api/v4/projects.api';
import { type ProjectCompaniesSort } from '@/api/v4/projects.api';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { modalState } from '@/state/modal.state';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';
import type { ISort } from '@/ui/table/table.types';

import { getListingStageById } from '../../../listing-stage/helpers/get-listing-stage-by-id';
import { projectIdState } from '../../project.state';
import { projectListingSortState } from '../../project-listing.state';
import { StageManagementEmptyState } from '../stage-management-table/stage-management-empty-state';
import { useUpdateProjectListingStage } from '../use-update-project-listing-stage.mutation';

import { useProjectListingsTableConfiguration } from './use-project-listings-table-configuration';

export const ProjectListingsTable = ({
  listingsData,
  isLoading,
}: {
  listingsData: ProjectListing[];
  isLoading: boolean;
}) => {
  const projectId = useRecoilValue(projectIdState);
  const setModal = useSetRecoilState(modalState);
  const { listingStages } = useListingStages();

  const [sort, setSort] = useRecoilState(projectListingSortState);

  const updateProjectListingStage = useUpdateProjectListingStage();

  const handleListingStageUpdate = async (
    listingId: string,
    newListingStageId: string | null,
    isDeal?: boolean,
  ) => {
    if (isDeal) {
      setModal({
        state: 'deal',
        listingId: listingId,
      });
    }

    if (!newListingStageId) return;
    const newStage = getListingStageById(newListingStageId, listingStages);

    if (!newStage) return;
    await updateProjectListingStage({
      projectListingId: listingId,
      newProjectListingStageId: newListingStageId,
    });
  };

  const handleSort = (update: ISort<ProjectCompaniesSort>) => {
    setSort({ direction: update.direction, sort: update.sort });
  };

  const { headers, rows } = useProjectListingsTableConfiguration({
    data: listingsData,
    onListingStatusChanged: handleListingStageUpdate,
  });

  if (!projectId) {
    return null;
  }

  return (
    <TableSpacer height={300}>
      <Table>
        {!listingsData.length && !isLoading ? (
          <StageManagementEmptyState isProjectEmpty projectId={projectId} />
        ) : (
          <>
            <thead>
              <tr>
                {headers.cells.map(cell => (
                  <TableHeaderCell<ProjectCompaniesSort>
                    key={cell.columnKey}
                    cell={cell}
                    sort={{ ...sort, onSort: handleSort }}
                    sticky={false}
                  />
                ))}
              </tr>
            </thead>
            <TableBody
              id="project-listing-table-body"
              rows={isLoading ? [] : rows}
              isLoading={isLoading}
              rowCursor="default"
              skeletonConfig={{
                columns: 6,
              }}
            />
          </>
        )}
      </Table>
    </TableSpacer>
  );
};

const Table = styled('table')`
  cursor: grab;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: 100%;
`;

const TableSpacer = styled('div')<{ height: number }>`
  min-height: ${({ height }) => height}px;
`;
