import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { OwnershipStatus } from '@/api/v4/ownership-status.api';
import { fetchOwnershipStatus } from '@/api/v4/ownership-status.api';

export const useOwnershipStatuses = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.OwnershipStatuses], fetchOwnershipStatus, {
    select: mapOwnershipStatusesToOptions,
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const mapOwnershipStatusesToOptions = (
  ownershipStatuses: OwnershipStatus[],
) => {
  return ownershipStatuses.map(ownershipStatus => ({
    label: ownershipStatus.name,
    value: ownershipStatus.id,
  }));
};
