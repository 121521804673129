import type { HeaderCellConfig } from '@/ui/table/table.types';

import type { TeamMemberStatus } from './basic-tab-config';
import type { TeamMemberSortParams } from '@/api/v4/team.api';

export const teamMembersTableConfiguration = (
  status: TeamMemberStatus,
): HeaderCellConfig<TeamMemberSortParams>[] => [
  {
    columnKey: 'name',
    sortKey: 'firstName',
    isVisible: status === 'active',
  },
  {
    columnKey: 'email',
    sortKey: 'email',
  },
  {
    columnKey: 'title',
    sortKey: 'title',
    isVisible: status === 'active',
    width: '20%',
  },
  {
    columnKey: 'role',
    sortKey: 'role',
    align: 'left',
    width: '100px',
  },
  {
    columnKey: 'createdAt',
    sortKey: 'createdDate',
    isVisible: status === 'invited',
    width: '160px',
  },
  {
    columnKey: 'actions',
    isVisible: true,
    hideLabel: true,
    width: '32px',
  },
];
