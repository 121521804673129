import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { Project } from '@/api/v4/projects.api';
import { fetchTeams } from '@/api/v4/team.api';
import { projectTeamOptionsState } from '@/features/projects/project.state';
import { isDefaultOption } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';

import type { CustomAsyncSelectProps } from '../../../../ui/select/async/use-select-type';

interface DropdownCreatedForProps {
  onChange: (teamId: string | null) => void;
  team: Project['team'];
  readOnly?: boolean;
}

export function DropdownCreatedFor({
  team,
  onChange,
  readOnly = false,
}: DropdownCreatedForProps) {
  const { t } = useTranslation('projects');

  const [teamOptions, setTeamOptions] = useRecoilState(projectTeamOptionsState);

  const handleOnChange = async (option: unknown) => {
    if (option !== null && !isDefaultOption(option)) return;

    const newTeamId =
      teamOptions?.find(item => item.value === option?.value)?.value || null;
    onChange(newTeamId);
  };

  const defaultValue = team
    ? {
        value: team.id,
        label: team.name,
      }
    : null;

  const setOptions: CustomAsyncSelectProps['setOptions'] = async (
    search,
    page,
  ) => {
    const response = await fetchTeams({
      search,
      page,
    });
    const newTeamOptions = response.map(item => ({
      value: item.id,
      label: item.name,
    }));
    setTeamOptions(newTeamOptions);
    return newTeamOptions;
  };

  return (
    <AsyncSelect
      defaultValue={defaultValue}
      readOnlyValue={defaultValue?.label}
      label={t`projectOverview.createdFor`}
      controlWidth="200px"
      onChange={handleOnChange}
      setOptions={setOptions}
      placeholder={t`projectOverview.select`}
      isSearchable={true}
      readOnly={readOnly}
    />
  );
}
