import { useTranslation } from 'react-i18next';

import type { CompaniesKPIs } from '@/api/v4/organization.api';
import { KPILabel, KPIValue } from '@/components/kpi/kpi';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import { useCompaniesKpi } from './use-companies-kpi.query';
import { KpiLoadingSkeleton } from '@/components/kpi/kpi-loading-skeleton';

export const CompaniesKPI = () => {
  const { t } = useTranslation('companies', { keyPrefix: 'kpi' });
  const { data: kpis, isFetched } = useCompaniesKpi();
  const { hasRequiredProduct } = useUserProducts();

  if (!isFetched) {
    return <KpiLoadingSkeleton />;
  }

  const hasKPIVerifiedListAccess = hasRequiredProduct(
    PRODUCT.KPI_ORGANIZATION_VERIFIED_LIST,
  );
  return (
    <Flexbox name="companies-kpi" gap="92px" fullWidth>
      <SubHeaderBold>{t`title`}</SubHeaderBold>
      {kpis &&
        Object.keys(kpis).map(key => {
          if (
            (key === 'verified' && !hasKPIVerifiedListAccess) ||
            kpis[key as keyof CompaniesKPIs] === null
          )
            return null;
          return (
            <Flexbox name="kpi" key={key} alignItems="center" gap="12px">
              <KPILabel>{t(key)}</KPILabel>
              <KPIValue>{kpis[key as keyof CompaniesKPIs]}</KPIValue>
            </Flexbox>
          );
        })}
    </Flexbox>
  );
};
