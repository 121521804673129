import { useTranslation } from 'react-i18next';

import type { IOption } from '@/types';

import { CompanyProductSelectorComponent } from './company-product-selector-component';
import type { CompanyProducts } from '@/api/v4/organization.api';
import { useLanguages } from '@/hooks/queries/use-languages.query';

type CompanyLanguages = Pick<CompanyProducts, 'languageIds'>;

interface CompanyProductLanguagesProps {
  setData: (data: Partial<CompanyLanguages>) => void;
  isEditMode: boolean;
  className?: string;
  defaultValue: IOption[];
}

export const CompanyProductLanguages = ({
  setData,
  isEditMode,
  className,
  defaultValue,
}: CompanyProductLanguagesProps) => {
  const { t } = useTranslation('companies');

  const { data: languagesOptions } = useLanguages();

  const handleSelectionsChanged = (newValues: string[]) => {
    setData({
      languageIds: newValues,
    });
  };

  return (
    <CompanyProductSelectorComponent
      title={t`products.productLanguages`}
      className={className}
      defaultValue={defaultValue}
      isEditMode={isEditMode}
      onChange={handleSelectionsChanged}
      setOptions={languagesOptions}
    />
  );
};
