import type { LegacyRef } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

import { CapsuleHeader } from '@/ui/typography/widgets';

interface WidgetHeaderWithBackgroundProps {
  children: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  margin?: string;
}

export const WidgetHeaderWithBackground = forwardRef(
  (
    {
      children,
      backgroundColor,
      textColor,
      margin,
    }: WidgetHeaderWithBackgroundProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <Container backgroundColor={backgroundColor} margin={margin} ref={ref}>
        <Header textColor={textColor}>{children}</Header>
      </Container>
    );
  },
);

WidgetHeaderWithBackground.displayName = 'WidgetHeaderWithBackground';

const Container = styled('div', forwardRef)<
  Pick<WidgetHeaderWithBackgroundProps, 'backgroundColor' | 'margin'>
>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.colors.basics.white};
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: 8px 18px;
  border-radius: 100px;
  width: max-content;
`;

const Header = styled(CapsuleHeader)<
  Pick<WidgetHeaderWithBackgroundProps, 'textColor'>
>`
  display: inline;
  color: ${({ theme, textColor }) => textColor ?? theme.colors.blue.primaryA};
`;
