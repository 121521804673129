import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { useResizeObserver } from '@/hooks/use-resize-observer';
import type { EmptyStateWidgetProps } from '@/ui/widget/empty-state-widget';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';
import type { WidgetProps } from '@/ui/widget/widget';
import { WIDGET_CONTAINER_PADDING_HORIZONTAL } from '@/ui/widget/widget';
import { Widget, WIDGET_CONTAINER_MAX_HEIGHT } from '@/ui/widget/widget';
import type { TextWidgetParagraphVariant } from './text-variant.type';
import { RichTextField } from '@/components/rich-text-field/rich-text-field';
import { classList } from '@/helpers/class-list';
import type PluginEditor from '@draft-js-plugins/editor/lib/Editor';

interface EditModeOptions {
  header?: React.ReactNode;
  backgroundColor?: string;
}

interface TextWidgetProps extends WidgetProps {
  paragraphVariant?: TextWidgetParagraphVariant;
  gap?: string;
  value: string | null;
  placeholder?: string;
  emptyStateOptions?: Omit<EmptyStateWidgetProps, 'onClick'>;
  editModeOptions?: EditModeOptions;
  onSave?: (value: string | null) => void;
  hideOnEmpty?: boolean;
  maxHeight?: number;
  disableBaseWidth?: boolean;
  dataTestId?: string;
}

const WIDGET_CONTAINER_PADDING_VERTICAL = 36;
const WIDGET_CONTAINER_RIGHT_PADDING = 10;
const WIDGET_CONTAINER_DEFAULT_HEIGHT = 237;
const READ_MORE_BUTTON_HEIGHT = 26;
export const WIDGET_CONTAINER_GAP = 12;

const FLEXIBLE_TEXTAREA_FIX = 1;

export const TextWidget = ({
  value,
  height = WIDGET_CONTAINER_DEFAULT_HEIGHT,
  paragraphVariant = 'small',
  gap = `${WIDGET_CONTAINER_GAP}px`,
  emptyStateOptions,
  placeholder,
  disabled = false,
  onSave,
  className,
  backgroundColor,
  editModeOptions,
  header,
  showEditStamp,
  width,
  hideOnEmpty,
  maxHeight,
  disableBaseWidth = false,
  dataTestId,
  ...widgetProps
}: TextWidgetProps) => {
  const { t } = useTranslation('projects');
  const [showReadMore, setShowReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [widgetText, setWidgetText] = useState(value);
  const [lineClamp, setLineClamp] = useState<number>();
  const maxHeightValue =
    isExpanded || isEditMode
      ? maxHeight ?? WIDGET_CONTAINER_MAX_HEIGHT
      : height;
  const backgroundColorValue =
    isEditMode && editModeOptions?.backgroundColor
      ? editModeOptions.backgroundColor
      : backgroundColor;
  const headerValue =
    isEditMode && editModeOptions?.header ? editModeOptions.header : header;

  const [baseWidth, setBaseWidth] = useState<number | undefined>();

  const editorRef = useRef<PluginEditor>(null);

  useEffect(() => {
    setIsExpanded(false);
  }, [isEditMode]);

  // resize observer is used ONLY to toggle Read More button
  const ref = useResizeObserver(
    useCallback(
      (target: HTMLDivElement) => {
        setBaseWidth(target.offsetWidth);
        const textAreaWrapper = target.querySelector(
          '.text-editable-wrapper',
        ) as HTMLDivElement | null;

        const textArea = target.querySelector(
          '.DraftEditor-root',
        ) as HTMLDivElement | null;

        const containerTop = target.getBoundingClientRect().top;
        const textWrapperTop =
          textAreaWrapper?.getBoundingClientRect().top ?? 0;
        const flexibleHeight =
          (textArea?.scrollHeight ?? 0) +
          textWrapperTop -
          containerTop +
          WIDGET_CONTAINER_PADDING_VERTICAL -
          FLEXIBLE_TEXTAREA_FIX;
        const isScrollable =
          flexibleHeight >
          WIDGET_CONTAINER_MAX_HEIGHT - READ_MORE_BUTTON_HEIGHT;

        if (!textAreaWrapper) return;

        textAreaWrapper.style.overflowY =
          isScrollable && (isExpanded || isEditMode) ? 'auto' : '';

        // set line clamp
        if (showReadMore && !isExpanded && !isEditMode) {
          const lineHeight = parseInt(
            getComputedStyle(textAreaWrapper)['lineHeight'],
            10,
          );
          setLineClamp(Math.floor(textAreaWrapper.clientHeight / lineHeight));
        } else {
          setLineClamp(undefined);
        }

        !isEditMode &&
          setShowReadMore(Boolean(widgetText) && flexibleHeight > height);
      },
      // don't remove value from deps array, it is needed to
      // apply correct height & toggle ReadMore button
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [widgetText, isExpanded, isEditMode, height, value],
    ),
  );

  useEffect(() => {
    // force refresh default value on page load
    setWidgetText(value);
  }, [value]);

  const scrollToTextTop = useCallback(() => {
    const textareaWrapper = ref.current?.querySelector(
      '.text-editable-wrapper',
    );
    if (!textareaWrapper) {
      return;
    }
    textareaWrapper.scrollTop = 0;
  }, [ref]);

  const openEditMode = () => {
    setIsExpanded(false);
    setIsEditMode(true);

    editorRef.current?.focus();
  };

  const handleOnConfirm = () => {
    scrollToTextTop();
    widgetText !== value && onSave?.(widgetText);

    setIsEditMode(false);
  };

  const handleOnExpand = () => {
    scrollToTextTop();
    setIsExpanded(!isExpanded);
  };

  if (!isEditMode && emptyStateOptions && !disabled && !widgetText) {
    return (
      <EmptyStateWidget
        height={height}
        maxHeight={maxHeightValue}
        onEditClick={openEditMode}
        className={className}
        dataTestId={dataTestId}
        {...emptyStateOptions}
      />
    );
  }

  if (!widgetText && disabled) {
    return (
      <ReadOnlyEmptyWidget
        {...widgetProps}
        height={height}
        className={className}
        header={headerValue}
        maxHeight={maxHeightValue}
      />
    );
  }

  return (
    <Container
      ref={ref}
      {...widgetProps}
      {...(!disableBaseWidth && { width: baseWidth })}
      backgroundColor={backgroundColorValue}
      header={headerValue}
      className={className}
      height={height}
      gap={gap}
      paragraphVariant={paragraphVariant}
      isEditMode={isEditMode}
      lineClamp={lineClamp}
      showExpandButton={showReadMore && !isEditMode}
      disabled={disabled}
      labelExpand={t`projectOverview.readMore`}
      labelCollapse={t`projectOverview.seeLess`}
      onEditClick={openEditMode}
      maxHeight={maxHeightValue}
      onReadMoreClick={handleOnExpand}
      showMore={isExpanded}
      showEditStamp={!isEditMode && showEditStamp}
      isHidden={!isEditMode && !isExpanded}
      dataTestId={dataTestId}
    >
      {hideOnEmpty && value?.length === 0 ? null : (
        <RichTextField
          className={classList('text-editable-wrapper', className)}
          width={
            width
              ? width -
                WIDGET_CONTAINER_PADDING_HORIZONTAL -
                WIDGET_CONTAINER_RIGHT_PADDING
              : undefined
          }
          onSave={handleOnConfirm}
          defaultValue={widgetText}
          placeholder={placeholder}
          paragraphVariant={paragraphVariant}
          onChange={newValue => setWidgetText(newValue)}
          readOnly={!isEditMode}
          editorRef={editorRef}
        />
      )}
    </Container>
  );
};

interface StyledWidgetProps extends WidgetProps {
  paragraphVariant: Exclude<TextWidgetProps['paragraphVariant'], undefined>;
  lineClamp?: number;
  gap?: string;
  maxHeight?: number;
  isHidden?: boolean;
  width?: number;
}

const Container = styled(Widget, forwardRef)<StyledWidgetProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  padding-right: ${WIDGET_CONTAINER_RIGHT_PADDING}px;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
  ${({ width }) => width && `width: ${width}px;`}

  /**
    Textarea flexible height (CSS only).
    Based on:
    https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    https://css-tricks.com/auto-growing-inputs-textareas/#aa-other-ideas
   */
  height: calc(
    100% + ${FLEXIBLE_TEXTAREA_FIX}px
  ); /* somehow this is working to prevent jumping of the last empty line in textarea on enter */
`;
