import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Ai } from '@/assets/icons';
import { ReactComponent as Stars } from '@/assets/svg/stars-decorator.svg';
import { useBlockUnload } from '@/hooks/use-block-unload';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Button } from '@/ui/button/button';
import { Inline, Stack } from '@/ui/line/line';
import { Header } from '@/ui/typography/widgets';

import { ExploreHistoryPane } from '../history/explore-history-pane';

import { ExploreFormFields } from './explore-form-fields';
import { useExploreForm } from './use-explore-form';
import { useEffect } from 'react';
import { useExploreNavigationHistory } from '../use-explore-navigation-history';
import { useBlockNavigation } from '@/routes/hooks/use-block-navigation';
import { QueryKey } from '@/config/query-client';
import { useQueryClient } from '@tanstack/react-query';

export const MainExploreForm = () => {
  const { t } = useTranslation('explore');
  const {
    description,
    setDescription,
    locations,
    setLocations,
    exampleCompanies,
    setExampleCompanies,
    resetForm,
    isValid,
    isDirty,
  } = useExploreForm();
  const navigateTo = useNavigateTo();
  const { isFromErrorPage, isFromNoResultsPage } =
    useExploreNavigationHistory();
  const queryClient = useQueryClient();
  const isTryAgain = isFromErrorPage || isFromNoResultsPage;

  useBlockUnload(isDirty);

  useBlockNavigation({
    when: isDirty,
    exceptionRoutes: ['/explore/results'],
  });

  useEffect(() => {
    if (!isTryAgain) {
      resetForm();
    }
    queryClient.removeQueries([QueryKey.ExploreSuggestions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTryAgain]);

  return (
    <Grid>
      <PaddingBox>
        <MainContent>
          <Inline justifyContent="flex-start" gap="0">
            <StyledHeader>{t`explore`}</StyledHeader>
            <Ai />
          </Inline>
          <Form gap="20px">
            <ExploreFormFields
              description={description}
              setDescription={setDescription}
              locations={locations}
              setLocations={setLocations}
              exampleCompanies={exampleCompanies}
              setExampleCompanies={setExampleCompanies}
            />
            <SubmitButton
              startIcon="Sprinkles"
              disabled={!isValid}
              onClick={() => navigateTo.exploreResults()}
            >{t`explore`}</SubmitButton>
          </Form>
        </MainContent>
      </PaddingBox>

      <IconWrapper>
        <Stars />
      </IconWrapper>
      <FixedWrapper>
        <ExploreHistoryPane />
      </FixedWrapper>
    </Grid>
  );
};

const StyledHeader = styled(Header)`
  display: inline-block;
`;

const PaddingBox = styled('div')`
  padding: 30px 0 30px 40px;
`;

const MainContent = styled('div')`
  height: 100%;
  max-height: calc(100vh - 60px);
  width: 100%;
  z-index: 1;
  flex-grow: 1;
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;

  @media (max-width: 868px) {
    margin: 0 5%;
    width: auto;
  }
`;

const Grid = styled('div')`
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 13% 258px;
  height: 100%;
  flex-grow: 1;

  @media (max-width: 868px) {
    grid-template-columns: 70% 0% 30%;
    width: auto;
  }
`;

const Form = styled(Stack)`
  margin-top: 10px;
  padding-right: 10px;
`;

const IconWrapper = styled('div')`
  padding: 30px 20px 30px 10px;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
`;

const FixedWrapper = styled('div')`
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.palettes.component.explore.form.background};

  @media (min-width: 1600px) {
    min-width: unset;
    max-width: 680px;
  }
`;
