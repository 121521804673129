import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import type { ProjectSortOptions } from '@/api/v4/projects.api';
import type { ISort } from '@/ui/table/table.types';

import type { IProjectFilters } from '../project.state';
import { projectsSortState } from '../project.state';

import { stringifyProjectFilters } from './project-filters-helpers';

export const useProjectsFiltersSearchLocationSearch = () => {
  const navigate = useNavigate();
  const sort = useRecoilValue(projectsSortState);

  const updateLocationSearch = (
    filters: IProjectFilters,
    newSort?: ISort<ProjectSortOptions>,
  ) => {
    const locationSearch = stringifyProjectFilters({
      filters,
      sort: newSort || (sort.sort ? sort : undefined),
    });
    navigate({ ...location, search: locationSearch }, { replace: true });
  };

  const resetLocationSearch = () => {
    const search = sort.sort ? stringifyProjectFilters({ sort }) : '';
    navigate({ ...location, search }, { replace: true });
  };

  return {
    updateLocationSearch,
    resetLocationSearch,
  };
};
