import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Inline, Stack } from '@/ui/line/line';
import { Switch } from '@/ui/switch/switch';
import { SmallText } from '@/ui/typography/widgets';

import { useTwoFactorAuthentication } from './use-two-factor-authentication';
import { VerificationMethod } from '@/user/verification-method/verification-method.type';

export const TwoFactorAuthentication = () => {
  const { t } = useTranslation('settings');
  const {
    isTwoFactorAuthMethodActive,
    startEnablingTwoFactorAuthenticationMethod,
  } = useTwoFactorAuthentication();

  return (
    <Stack gap="16px">
      <Label>{t`twoFactorAuthentication`}</Label>
      <Inline gap="0px">
        <Inline gap="12px" templateColumns="max-content" alignItems="center">
          <Switch
            checked={isTwoFactorAuthMethodActive(VerificationMethod.Sms)}
            onChange={startEnablingTwoFactorAuthenticationMethod.bind(
              null,
              VerificationMethod.Sms,
            )}
          />
          <SmallText color={colors.gray.c13}>{t`textMessages`}</SmallText>
        </Inline>
        <Inline gap="12px" templateColumns="max-content" alignItems="center">
          <Switch
            checked={isTwoFactorAuthMethodActive(VerificationMethod.Otp)}
            onChange={startEnablingTwoFactorAuthenticationMethod.bind(
              null,
              VerificationMethod.Otp,
            )}
          />
          <SmallText color={colors.gray.c13}>{t`authenticationApp`}</SmallText>
        </Inline>
      </Inline>
    </Stack>
  );
};

const Label = styled('h2')`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.tab};
  color: ${({ theme }) => theme.colors.gray.c11};
`;
