import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import { CapitalRaisedFilter } from './filters/capital-raised-filter';
import { CreatedByFilter } from './filters/created-by-filter';
import { EmployeesFilter } from './filters/employees-filter';
import { FundingStageFilter } from './filters/funding-stage-filter';
import { ListingStatusFilter } from './filters/listing-status-filter';
import { OfficeHeadquartersFilter } from './filters/office-headquarters-filter';
import { RankingFilter } from './filters/ranking-filter';
import { TagsFilter } from './filters/tags-filter';
import { VerifiedFilter } from './filters/verified-filter';
import { YearFoundedFilter } from './filters/year-founded-filter';
import { useFetchCompanyFiltersOptions } from './filters/use-fetch-company-filters-options.query';

export const Filters = ({ onApply }: { onApply: () => void }) => {
  const { isFetchingCompanyFilters } = useFetchCompanyFiltersOptions();
  const { hasRequiredProduct } = useUserProducts();
  const { t } = useTranslation('projects');

  if (isFetchingCompanyFilters) {
    return <Loader isFullPage />;
  }

  return (
    <Flexbox name="filters-container" direction="column" gap="56px">
      <Grid>
        <Tags />
        <Status />
        <Office />
        <CreatedBy />
        {hasRequiredProduct(PRODUCT.VERIFY_COMPANY) && <Verified />}
        <Year />
        <Stage />
        <CapitalRaised />
        <Employees />
        <Rating />
      </Grid>
      <ApplyFiltersButton
        onClick={onApply}
      >{t`projects.apply`}</ApplyFiltersButton>
    </Flexbox>
  );
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 30px;
  grid-auto-flow: row;
  grid-template-areas:
    'tags tags status status office office createdBy createdBy verified verified'
    'year year stage stage capital capital employees employees ranking ranking';
`;

const Tags = styled(TagsFilter)`
  grid-area: tags;
`;

const Status = styled(ListingStatusFilter)`
  grid-area: status;
`;

const Year = styled(YearFoundedFilter)`
  grid-area: year;
`;

const Stage = styled(FundingStageFilter)`
  grid-area: stage;
`;

const Office = styled(OfficeHeadquartersFilter)`
  grid-area: office;
`;

const Verified = styled(VerifiedFilter)`
  grid-area: verified;
`;

const CapitalRaised = styled(CapitalRaisedFilter)`
  grid-area: capital;
`;

const Employees = styled(EmployeesFilter)`
  grid-area: employees;
`;

const Rating = styled(RankingFilter)`
  grid-area: ranking;
`;

const CreatedBy = styled(CreatedByFilter)`
  grid-area: createdBy;
`;

const ApplyFiltersButton = styled(Button)`
  align-self: flex-end;
`;
