import { fetchCompanyClients } from '@/api/v4/organization-clients.api';
import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';
import { companyIdState } from '../company.state';
import { useRecoilValue } from 'recoil';

export const useCompanyClients = () => {
  const companyId = useRecoilValue(companyIdState);

  return useQuery(
    [QueryKey.CompanyClients, companyId],
    () => (companyId ? fetchCompanyClients(companyId) : null),
    {
      enabled: !!companyId,
    },
  );
};
