import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { BorderedTextArea } from '../../../../ui/textarea/bordered-text-area';

import { ProductDescription } from './components/product-description';
import type { CompanyProducts } from '@/api/v4/organization.api';

interface CompanyProductPotentialImpactProps {
  potentialImpact: string | null;
  setData: Dispatch<SetStateAction<CompanyProducts | undefined>>;
  isEditMode: boolean;
  className?: string;
}

export const CompanyProductPotentialImpact = ({
  potentialImpact,
  setData,
  isEditMode,
  className,
}: CompanyProductPotentialImpactProps) => {
  const { t } = useTranslation('companies');

  const handlePotentialImpactChanged = (newValue: string) => {
    setData(
      original =>
        original && {
          ...original,
          potentialImpact: newValue,
        },
    );
  };

  if (!isEditMode && !potentialImpact) return <></>;

  return (
    <Flexbox
      name="company-product-potential-impact-widget"
      direction="column"
      gap="12px"
      className={className}
    >
      <SubHeaderBold>{t`products.potentialImpact`}</SubHeaderBold>
      {isEditMode ? (
        <BorderedTextArea
          height={115}
          value={potentialImpact}
          onChange={handlePotentialImpactChanged}
          resizeOnContentGrowth
        />
      ) : (
        <ProductDescription>{potentialImpact}</ProductDescription>
      )}
    </Flexbox>
  );
};
