import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import type { Company, CompanySortOptions } from '@/api/v4/organization.api';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { FixedBottomContainer } from '@/components/fixed-bottom-container/fixed-bottom-container';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { getElementOuterHeight } from '@/helpers/get-element-outer-height';
import { useCreateActions } from '@/hooks/use-create-actions';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { InfiniteScrollTable } from '@/ui/table/infinite-table/infinite-scroll-table';
import { NoResults } from '@/ui/table/no-results';
import type { ISort } from '@/ui/table/table.types';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { CompaniesFilters } from './filters/companies-filters';
import { useCompaniesFiltersSearchLocationSearch } from './filters/use-companies-filters-location-search';
import { useCompanies } from './hooks/use-companies.query';
import { useCompaniesTableConfiguration } from './hooks/use-companies-table-configuration';
import type { CompanyFilters } from './companies.state';
import {
  appliedCompaniesFiltersState,
  companiesSortState,
  selectedCompaniesFiltersState,
  selectedCompaniesState,
} from './companies.state';
import { CompaniesKPI } from './kpi/companies-kpi';

export const CompaniesPageContent = () => {
  const { t } = useTranslation('companies');
  const kpisContainerRef = useRef<HTMLDivElement>(null);
  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);
  const [kpisContainerHeight, setKPIsContainerHeight] = useState(0);

  const appliedFilters = useRecoilValue(appliedCompaniesFiltersState);

  const { onAddOrganization } = useCreateActions();
  const { data, isLoading, hasNextPage, fetchNextPage } = useCompanies();

  const companies = data ? data.pages.flat() : [];

  const { header, rows, skeletonConfig } = useCompaniesTableConfiguration({
    companies,
  });

  const { hasRequiredPermission } = useUserPermissions();
  const resetSelectedCompanies = useResetRecoilState(selectedCompaniesState);

  useEffect(() => {
    setKPIsContainerHeight(getElementOuterHeight(kpisContainerRef.current));
  }, []);

  const handleSort = (sortConfig: ISort<CompanySortOptions | undefined>) => {
    updateLocationSearch(appliedFilters, sortConfig);
    resetSelectedCompanies();
  };

  const { updateLocationSearch, resetLocationSearch } =
    useCompaniesFiltersSearchLocationSearch();

  const { clearAppliedFilters, hasAnyActiveFilters } =
    useHandleFilters<CompanyFilters>({
      appliedFiltersState: appliedCompaniesFiltersState,
      selectedFiltersState: selectedCompaniesFiltersState,
      updateLocationSearch,
      resetLocationSearch,
    });

  return (
    <CompaniesContentContainer
      name="companies-page-container"
      direction="column"
      fullHeight
    >
      <Flexbox
        name="companies-table"
        direction="column"
        padding="0 30px"
        fullHeight
      >
        <CompaniesFilters
          setFiltersContainerHeight={height =>
            setFiltersContainerHeight(height)
          }
          filtersDisabled={
            (!data || companies.length === 0) && !hasAnyActiveFilters
          }
        />
        {!isLoading && rows.length === 0 ? (
          <Flexbox
            name="companies-no-results-wrapper"
            justify="center"
            alignItems="center"
            fullHeight
          >
            {hasAnyActiveFilters ? (
              <NoResults
                header={t`companies.filters.noResults.header`}
                text={t`companies.filters.noResults.text`}
                buttonText={t`companies.filters.noResults.button`}
                buttonIcon="ArrowGoBack"
                onButtonClick={clearAppliedFilters}
              />
            ) : (
              <NoResults
                onButtonClick={onAddOrganization}
                buttonText={t`companies.noTableResults.button`}
                header={t`companies.noTableResults.header`}
                text={
                  hasRequiredPermission(PERMISSION.ADD_ORGANIZATION)
                    ? t`companies.noTableResults.text`
                    : ''
                }
                buttonIcon="Plus"
                showButton={hasRequiredPermission(PERMISSION.ADD_ORGANIZATION)}
              />
            )}
          </Flexbox>
        ) : (
          <InfiniteScrollTable<Company, CompanySortOptions | undefined>
            id="companies"
            isLoading={isLoading}
            headers={header}
            rows={rows}
            data={companies}
            hasMore={Boolean(hasNextPage)}
            onLoadMore={fetchNextPage}
            onSort={handleSort}
            sortState={companiesSortState}
            height={`calc(100vh - ${filtersContainerHeight}px - ${TOPBAR_HEIGHT} - ${
              kpisContainerHeight - 45
            }px)`}
            skeletonConfig={skeletonConfig}
          />
        )}
      </Flexbox>

      <FixedBottomContainer ref={kpisContainerRef}>
        <CompaniesKPI />
      </FixedBottomContainer>
    </CompaniesContentContainer>
  );
};

const CompaniesContentContainer = styled(Flexbox)`
  overflow: hidden;
  position: relative;
`;
