import { fetchCompanyExternalLinks } from '@/api/v4/organization-external-links.api';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { companyIdState } from '../company.state';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { QueryKey } from '@/config/query-client';

export const useCompanyExternalLinks = () => {
  const companyId = useRecoilValue(companyIdState);

  const getCompanyExternalLinks = async () => {
    if (!companyId) {
      return [];
    }

    return await fetchCompanyExternalLinks(companyId);
  };

  return useQuery(
    [QueryKey.CompanyExternalLinks, companyId],
    getCompanyExternalLinks,
    {
      staleTime: getTimeInMs(5, 'minute'),
      cacheTime: getTimeInMs(5, 'minute'),
    },
  );
};
