import { useMutation } from '@tanstack/react-query';

import { useRecoilValue } from 'recoil';
import type { EditCompanyClientsPayload } from '@/api/v4/organization.api';
import { editCompanyClients } from '@/api/v4/organization.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useTranslation } from 'react-i18next';
import { companyIdState } from '../company.state';

export const useEditCompanyClients = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  return useMutation(
    async (clients: EditCompanyClientsPayload['clients']) =>
      companyId ? editCompanyClients(companyId, { clients }) : undefined,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyClients,
          companyId,
        ]);
      },
      onError: () => {
        notify({
          message: t`default:unknownError`,
        });
      },
    },
  );
};
