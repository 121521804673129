import { forwardRef } from 'react';
import { styled } from 'goober';

import { FlexContainer } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { Tab } from '@/ui/typography/action-panel';
import { isNumber } from 'lodash-es';

export const WorkmodeTabControl = ({
  countIndicator,
  name,
  icon,
}: {
  countIndicator?: number;
  name: string;
  icon?: IconVariant;
}) => {
  return (
    <TabChip isItemDragged={false}>
      {!!icon && <Icon icon={icon} />}
      <Tab data-testid={`stage-tab-${name}`}>
        <FlexContainer gap="6px">
          <EllipsisTextTooltip Component={<EllipsisText />} text={name} />

          {isNumber(countIndicator) && (
            <span data-testid="stage-listings-count">{countIndicator}</span>
          )}
        </FlexContainer>
      </Tab>
    </TabChip>
  );
};

const TabChip = styled('div')<{ isItemDragged: boolean }>`
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  white-space: nowrap;
  ${({ isItemDragged, theme }) =>
    isItemDragged &&
    `
      &:hover {
        font-weight: 500;
        background-color: ${theme.colors.blue.c9};
        border-radius: 25px;
        height: 100%;
      }
    `}
`;

const EllipsisText = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  max-width: 210px;
`;
