import { styled } from 'goober';

import { TextEditable } from '@/ui/text-editable/text-editable';

export const EditableHeader = styled(TextEditable)<{
  margin?: string;
  height: string;
}>`
  ${({ theme }) => theme.typography.widget.projectNameHeader}
  height: ${({ height }) => height};
`;
