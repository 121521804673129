import { useTranslation } from 'react-i18next';
import type { IOption } from '@/types';

import { CompanyProductSelectorComponent } from './company-product-selector-component';
import type { CompanyProducts } from '@/api/v4/organization.api';
import { useDeploymentModels } from '@/hooks/queries/use-deployment-models.query';

type ProductDeploymentModels = Pick<CompanyProducts, 'deploymentModalIds'>;

interface CompanyProductDeploymentModelProps {
  setData: (data: Partial<ProductDeploymentModels>) => void;
  isEditMode: boolean;
  className?: string;
  defaultValue: IOption[];
}

export const CompanyProductDeploymentModel = ({
  setData,
  isEditMode,
  className,
  defaultValue,
}: CompanyProductDeploymentModelProps) => {
  const { t } = useTranslation('companies');
  const { data: deploymentModels } = useDeploymentModels();

  const handleSelectionsChanged = (newValues: string[]) => {
    setData({
      deploymentModalIds: newValues,
    });
  };

  return (
    <CompanyProductSelectorComponent
      title={t`products.productDeploymentModels`}
      className={className}
      defaultValue={defaultValue}
      isEditMode={isEditMode}
      onChange={handleSelectionsChanged}
      setOptions={deploymentModels}
    />
  );
};
