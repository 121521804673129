import { z } from 'zod';

import { urlDomainValidation } from '@/helpers/url-domain-validation';
import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

const KEY_OFFERING_MAX_LENGTH = 150;

export const editCompanyHeaderValidationSchema = z.object({
  name: z.string().optional(),
  linkedinUrl: urlDomainValidation('linkedin.').optional(),
  twitterUrl: urlDomainValidation('twitter.').optional(),
  activityStatusId: z.string().nonempty(t`default:fieldIsRequired`),
  ownershipId: z.string().optional(),
  stockExchangeSymbol: z.string().optional(),
  ticker: z.string().optional(),
  ownedBy: z.string().optional(),
  yearFounded: z.number().optional(),
  hqLocation: z
    .object({
      cityId: z.string().nullable(),
      countryId: z.string().nullable(),
      regionId: z.string().nullable(),
    })
    .optional()
    .nullable(),
  employeesRangeId: z.string().optional(),
  websiteUrl: z.string().optional(),
  keyOffering: z
    .string()
    .max(
      KEY_OFFERING_MAX_LENGTH,
      t('default:maxLengthError', {
        maxLengthValue: KEY_OFFERING_MAX_LENGTH,
      }),
    )
    .optional(),
  formerName: z.string().optional(),
  impacts: z.array(z.string()).optional(),
  logo: z.any().nullable(),
});
