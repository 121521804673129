import type { SVGProps } from 'react';
import { useCallback, useState } from 'react';
import { styled } from 'goober';
import type { LabelProps } from 'recharts';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from 'recharts';

import { Loader } from '@/components/loader/loader';
import { colors, componentsPalette } from '@/theme/colors';

import { RoundedBar } from './rounded-bar';

type CustomLabelProps = Omit<SVGProps<SVGTextElement>, 'viewBox'> & LabelProps;

interface BarData {
  id: string;
  name: string;
  count: number;
}

export type ChartData = BarData[];

export interface RoundedBarChartProps {
  data: ChartData | null;
  isLoading?: boolean;
  width?: number;
  height?: number;
  getBarLink?: (value: BarData['id']) => string;
  fillColor?: string;
  hoverFillColor?: string;
  className?: string;
  withDataLabelList?: boolean;
  marginBottom?: number;
  labelListHorizontalPosition?: number;
  labelColor?: string;
}

export interface BarProps {
  count: BarData['count'];
  name: BarData['name'];
  height: number;
  width: number;
  payload: BarData;
  x: number;
  y: number;
  fill?: string;
  barLink?: string | null;
}

const renderCustomizedLabel = (props: CustomLabelProps) => {
  const { x, y, value, width, height } = props;
  const middleBarHorizontalCoordinate = (1 / 2) * Number(width);
  const verticalSpacing = 35;

  if (!x || !y) {
    return null;
  }

  return (
    <g stroke={colors.blue.primaryB}>
      <text
        x={Number(x) + middleBarHorizontalCoordinate}
        y={Number(y) + Number(height) + verticalSpacing}
        fill={colors.blue.primaryB}
        fontSize={36}
        fontFamily={'Wallop'}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

export const RoundedBarChart = ({
  data,
  width = 1200,
  height = 300,
  isLoading,
  fillColor = componentsPalette.chart.background,
  hoverFillColor = componentsPalette.chart.hoverBackground,
  getBarLink,
  className,
  withDataLabelList = true,
  marginBottom = 75,
  labelListHorizontalPosition = 55,
  labelColor = componentsPalette.chart.label,
}: RoundedBarChartProps) => {
  const [hoveredBar, setHoveredBar] = useState<BarProps['name'] | null>(null);

  const renderShape = useCallback(
    (props: BarProps) => {
      const isHovered = props.name === hoveredBar;
      const link = getBarLink ? getBarLink(props.payload.id) : null;
      return (
        <RoundedBar
          {...props}
          barLink={link}
          fill={isHovered ? hoverFillColor : props.fill}
        />
      );
    },
    [getBarLink, hoverFillColor, hoveredBar],
  );

  if (!data && !isLoading) {
    return null;
  }

  const tickFormatter = (value: string) => {
    const MAX_CHAR_LIMIT = 25;
    if (value.length < MAX_CHAR_LIMIT) return value;
    return `${value.substring(0, MAX_CHAR_LIMIT)}...`;
  };

  return (
    <Container width={width} height={height} className={className}>
      {isLoading ? (
        <Loader />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={width}
            height={height}
            data={data ?? undefined}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: marginBottom,
            }}
          >
            0
            <XAxis
              padding={'no-gap'}
              dy={labelListHorizontalPosition}
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fill: labelColor }}
              tickFormatter={tickFormatter}
            />
            <Bar
              isAnimationActive={false}
              dataKey="count"
              fill={fillColor}
              shape={renderShape}
              minPointSize={12}
              barSize={160}
              onMouseEnter={(eventProps: BarProps) => {
                setHoveredBar(eventProps.name);
              }}
              onMouseLeave={() => setHoveredBar(null)}
            >
              {withDataLabelList && (
                <LabelList dataKey="count" content={renderCustomizedLabel} />
              )}
            </Bar>
            )
          </BarChart>
        </ResponsiveContainer>
      )}
    </Container>
  );
};

const Container = styled('div')<Pick<RoundedBarChartProps, 'width' | 'height'>>`
  margin-top: 10px;
  width: ${props => props.width}px;
  max-width: 100%;
  height: ${props => props.height}px;
`;
