import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { EmployeeRange } from '@/api/v4/organization.api';
import { fetchEmployeeRanges } from '@/api/v4/organization.api';

export const useEmployeesRanges = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.EmployeeRanges], fetchEmployeeRanges, {
    select: mapEmployeesRangesToOptions,
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const mapEmployeesRangesToOptions = (
  employeesRanges: EmployeeRange[],
) => {
  return employeesRanges.map(range => ({
    label: range.rangeValues,
    value: range.id,
  }));
};
