import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const EmployeesFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const filterOptions = useRecoilValue(companiesFilterOptionsState);

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  return (
    <AsyncSelect
      id="filter-employees-range"
      label={t`companies.filters.employeesRange`}
      defaultValue={selectedFilters.employeesRange}
      className={className}
      isMulti
      setOptions={filterOptions.employeesRange}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          employeesRange: option,
        }));
      }}
      {...sharedProps}
    />
  );
};
