import type { MutableRefObject } from 'react';
import { useMemo, useRef } from 'react';

enum TimeOfDayLabel {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  NIGHT = 'night',
}

interface TimeOfDayRange {
  hour: number;
  minutes: number;
}

interface TimeOfDayPeriod {
  start: TimeOfDayRange;
  end: TimeOfDayRange;
}

export const useCurrentTimeOfDay = () => {
  const timeOfDayData: MutableRefObject<
    Record<TimeOfDayLabel, TimeOfDayPeriod>
  > = useRef({
    [TimeOfDayLabel.MORNING]: {
      start: {
        hour: 6,
        minutes: 0,
      },
      end: {
        hour: 11,
        minutes: 59,
      },
    },
    [TimeOfDayLabel.AFTERNOON]: {
      start: {
        hour: 12,
        minutes: 0,
      },
      end: {
        hour: 17,
        minutes: 59,
      },
    },
    [TimeOfDayLabel.EVENING]: {
      start: {
        hour: 18,
        minutes: 0,
      },
      end: {
        hour: 22,
        minutes: 59,
      },
    },
    [TimeOfDayLabel.NIGHT]: {
      start: {
        hour: 23,
        minutes: 0,
      },
      end: {
        hour: 5,
        minutes: 59,
      },
    },
  });

  const currentTimeOfDay = useMemo(() => {
    const date = new Date();
    const currentHour = date.getHours();
    const currentMinutes = date.getMinutes();

    return (
      Object.entries(timeOfDayData.current) as [
        TimeOfDayLabel,
        TimeOfDayPeriod,
      ][]
    ).reduce((acc, prev) => {
      const [label, data] = prev;
      const { start, end } = data;

      if (
        start.hour <= currentHour &&
        start.minutes <= currentMinutes &&
        end.hour >= currentHour &&
        end.minutes >= currentMinutes
      ) {
        acc = label;
      }

      return acc;
    }, TimeOfDayLabel.MORNING);
  }, []);

  return {
    currentTimeOfDay,
  };
};
