import { useQuery } from '@tanstack/react-query';

import { fetchTeamRelatedProjects } from '@/api/v4/team-related-projects.api';
import { QueryKey } from '@/config/query-client';

export const useTeamRelatedProjects = (teamId: string | undefined) => {
  return useQuery(
    [QueryKey.ActiveProjects, teamId],
    () => {
      if (!teamId) return;
      return fetchTeamRelatedProjects(teamId);
    },
    {
      enabled: !!teamId,
    },
  );
};
