import { styled } from 'goober';

import { getRoundedBarPath } from './get-rounded-bar-path';
import type { BarProps } from './rounded-bar-chart';

export const RoundedBar = (props: BarProps) => {
  const { x, y, width, height } = props;
  const minRoundedShapeHeightValue = 30;
  const isLowHeightValue = height <= minRoundedShapeHeightValue;
  const borderRadius = isLowHeightValue ? [3, 12, 12, 12] : [8, 32, 32, 32];

  if (props.barLink) {
    return (
      <a href={props.barLink}>
        <Path d={getRoundedBarPath(x, y, width, height, borderRadius)} />
      </a>
    );
  }

  return (
    <Path d={getRoundedBarPath(x, y, width, height, borderRadius)} isDisabled />
  );
};

const Path = styled('path')<{ isDisabled?: boolean }>`
  fill: ${({ theme }) => theme.palettes.component.chart.background};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  stroke: none;

  &:hover {
    fill: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.palettes.component.chart.background
        : theme.palettes.component.chart.hoverBackground};
  }
`;
