import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';

export const CompanyPartialEmptyWidget = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation('companies');
  return (
    <Container
      name="empty-state-container"
      gap="10px"
      margin="48px 0px 0px 0px"
      alignItems="center"
      justify="space-between"
    >
      <Description>{t`products.emptyPartialLabel`}</Description>

      <LinkText onClick={onClick}>
        <Icon icon="ArrowLong" />
        <span>{t`products.emptyPartialLinkText`}</span>
      </LinkText>
    </Container>
  );
};

const Container = styled(Flexbox)`
  padding: 10px 32px;
  background-color: ${({ theme }) => theme.colors.blue.c9};
  border-radius: 20px;
  height: 100px;
`;

const Description = styled('span')`
  font-size: 22px;
`;

const LinkText = styled('div')`
  cursor: pointer;
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
  color: ${({ theme }) => theme.colors.gray.c12};
  display: inline-flex;
  gap: 12px;
  ${({ theme }) =>
    `
      :hover > [data-name='empty-state-container'] & {
        color: ${theme.colors.blue.primaryA};
        ${colorIcon(theme.colors.blue.primaryA)}
      }
  `}
`;
