import { useQuery } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { fetchCompanyProducts } from '@/api/v4/organization.api';
import { companyIdState } from '../company.state';
import { isBaseError } from '@/api/v4/base-fetch';

export const useCompanyProducts = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();
  const companyId = useRecoilValue(companyIdState);
  const navigateTo = useNavigateTo();

  const getCompanyProducts = () => {
    if (!companyId) return;
    return fetchCompanyProducts(companyId);
  };

  const query = useQuery(
    [QueryKey.CompanyProducts, companyId],
    getCompanyProducts,
    {
      onError: (error: unknown) => {
        if (isIdNotRecognizedError(error)) {
          navigateTo.notFound();
        }
      },
      retry: false,
      enabled:
        !!companyId &&
        hasAtLeastOnePermission([PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN]),
    },
  );

  return query;
};

const isIdNotRecognizedError = (error: unknown) => {
  return (
    isBaseError(error) &&
    (error.response.status === HttpStatusCode.NotFound ||
      error.response.status === HttpStatusCode.BadRequest)
  );
};
