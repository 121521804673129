import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { isClientOptionArray } from '@/helpers/other';
import type { ClientOption } from '@/types';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import { OptionWithLogo } from '@/ui/select/async/components/option/option-with-logo';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { Header } from '@/ui/typography/widgets';
import {
  EmptyStateWidget,
  type EmptyStateWidgetProps,
} from '@/ui/widget/empty-state-widget';
import { Widget, type WidgetProps } from '@/ui/widget/widget';

import { WIDGET_CONTAINER_GAP } from '../../../../components/widgets/text-widget/text-widget';
import { SelectedOptionsListAsLogo } from '../../../../ui/select/async/components/selected-options-list/selected-options-list-as-logo';
import { mapOrganizationClientsToOptions } from '../../helpers/map-clients-to-options';
import { useCanEditCompany } from '../../hooks/use-can-edit-company';

import { WIDGET_HEADER_MARGIN, WIDGET_ROW_1_HEIGHT } from '../utils/constants';
import { companyIdState } from '../company.state';
import { WidgetLoaderContainer } from '../widget-loader-container';
import { useRecoilValue } from 'recoil';
import { useCompanyClients } from './use-company-clients.query';
import type { CompanyClient } from '@/api/v4/organization-clients.api';
import { useEditCompanyClients } from './use-edit-company-clients.mutation';
import { fetchClients } from '@/api/v4/client.api';
import { mapClientsToOptions } from '@/api/v4/mappers/client-option.mapper';

interface ClientsWidgetProps extends WidgetProps {
  defaultValue?: CompanyClient[] | undefined;
  emptyStateOptions?: Omit<EmptyStateWidgetProps, 'onClick'>;
  optionsListLimit?: number;
  onSave?: (value: string[]) => void;
}

export function ClientsWidget({
  height,
  emptyStateOptions,
  optionsListLimit = 0,
  className,
  ...widgetProps
}: ClientsWidgetProps) {
  const { t } = useTranslation(['companies', 'default']);
  const [isEditMode, setIsEditMode] = useState(false);
  const { mutateAsync: editOrganizationClients, isLoading: isUpdating } =
    useEditCompanyClients();

  const companyId = useRecoilValue(companyIdState);

  const { data: companyClients = [], isLoading } = useCompanyClients();

  const clients = useMemo(
    () => (companyClients ? companyClients : []),
    [companyClients],
  );

  const [draftOptions, setDraftOptions] = useState<ClientOption[]>([]);

  const listRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string>();

  const canEditCompany = useCanEditCompany();
  const disabled = !canEditCompany;

  useEffect(() => {
    if (clients.length <= optionsListLimit) {
      setMaxHeight(undefined);
      return;
    }

    const item: HTMLDivElement | null | undefined =
      listRef.current?.querySelector('[data-name="options-list-item"]');

    const ITEM_GAP = 16;
    const ITEMS_PER_ROW = 3;
    const height =
      ((item?.offsetHeight ?? 0) / ITEMS_PER_ROW + ITEM_GAP) *
        (optionsListLimit - 1) -
      ITEM_GAP;

    setMaxHeight(`${height}px`);
  }, [optionsListLimit, clients]);

  const handleOnConfirm = useCallback(async () => {
    if (!isEditMode || !companyId) return;

    setIsEditMode(false);
    if (
      draftOptions === null ||
      (draftOptions.length === clients.length &&
        draftOptions.every((item, index) => item.value === clients[index].id))
    ) {
      return;
    }

    await editOrganizationClients(
      draftOptions.map(option => ({
        source: option.source,
        id: option.value,
      })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, clients, isEditMode, editOrganizationClients, draftOptions]);

  if (!companyId) {
    return null;
  }

  if (isUpdating || isLoading) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_1_HEIGHT} width={439}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!isEditMode && emptyStateOptions && !disabled && !clients.length) {
    return (
      <EmptyStateWidget
        className={className}
        height={height}
        onEditClick={() => setIsEditMode(true)}
        {...emptyStateOptions}
      />
    );
  }

  const handleOnExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const setOptions: CustomAsyncSelectProps['setOptions'] = async search => {
    const clients = await fetchClients({
      search,
    });

    return mapClientsToOptions(clients);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOnConfirm}>
      <Container
        className={className}
        header={<HeaderContainer>{t`clients.header`}</HeaderContainer>}
        height={height}
        isEditMode={isEditMode}
        onEditClick={() => setIsEditMode(true)}
        disabled={disabled}
        showExpandButton={!isEditMode && clients.length > optionsListLimit}
        labelExpand={t`default:showAll`}
        labelCollapse={t`default:showLess`}
        showMore={isExpanded}
        onReadMoreClick={handleOnExpand}
        {...widgetProps}
      >
        {!isEditMode ? (
          <SelectedOptionsListAsLogo
            list={mapOrganizationClientsToOptions(clients)}
            listRef={listRef}
            height={!isExpanded ? maxHeight : undefined}
            isListExpanded={isExpanded}
          />
        ) : (
          <AsyncSelectWithList
            defaultValue={mapOrganizationClientsToOptions(clients)}
            setOptions={setOptions}
            placeholder={t`default:search`}
            hideSelectedOptions={false}
            components={{ Option: OptionWithLogo }}
            SelectedOptionsListComponent={<SelectedOptionsListAsLogo />}
            onChange={newValue =>
              isClientOptionArray(newValue) && setDraftOptions(newValue)
            }
          />
        )}
      </Container>
    </OutsideClickHandler>
  );
}

const Container = styled(Widget)`
  min-width: 439px;
  display: flex;
  flex-direction: column;
  gap: ${WIDGET_CONTAINER_GAP}px;
`;

const HeaderContainer = styled(Header)`
  margin: ${WIDGET_HEADER_MARGIN};
`;
