import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchLastActivities } from '@/api/v4/last-activities.api';
import { QueryKey } from '@/config/query-client';

import { recentItemsTypeState } from '../recent-items.state';

export const useLastActivities = () => {
  const selectedRecentItemsType = useRecoilValue(recentItemsTypeState);

  const fetchLastUserActivities = () => {
    const params =
      selectedRecentItemsType === 'all'
        ? undefined
        : { type: selectedRecentItemsType };

    return fetchLastActivities(params);
  };

  return useQuery(
    [QueryKey.LastActivities, selectedRecentItemsType],
    fetchLastUserActivities,
    {
      staleTime: 1000 * 60 * 5,
    },
  );
};
