import { useState } from 'react';

import { UserStatus } from '@/types';
import { Switch } from '@/ui/switch/switch';
import { useEditUserStatus } from './user-management/use-edit-user-status.mutation';

type UserStatusSwitchProps = {
  userId: number;
  isActive: boolean;
  isDisabled?: boolean;
  onSuccess: (userId: number, status: UserStatus) => void;
};

export const UserStatusSwitch = ({
  userId,
  isActive,
  onSuccess,
  isDisabled,
}: UserStatusSwitchProps) => {
  const [isChecked, setIsChecked] = useState(isActive);

  const { changeUserStatus } = useEditUserStatus();

  const toggleUserStatus = async (userId: number) => {
    setIsChecked(!isChecked);
    const status = isActive ? UserStatus.Disabled : UserStatus.Active;

    await changeUserStatus({
      userId,
      status,
    });
    onSuccess(userId, status);
  };
  return (
    <Switch
      dataTestId="user-status-switch"
      checked={isChecked}
      onChange={() => toggleUserStatus(userId)}
      disabled={isDisabled}
    />
  );
};
