import { forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { ActivateFiltersButton } from '@/components/filters/activate-filters-button';
import { activeFilterTypeState } from '@/components/filters/filter.state';
import { FilterSearchInput } from '@/components/filters/filter-search-input';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { getElementOuterHeight } from '@/helpers/get-element-outer-height';
import type { Entries } from '@/helpers/other';
import {
  isDateRangeOption,
  isDefaultOption,
  isDefaultOptionArray,
  isNumberOptionArray,
} from '@/helpers/other';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import type { INumberOption, IOption, MinMaxRange } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox, FlexContainer } from '@/ui/flexbox/flexbox';

import type { IProjectFilters } from '../project.state';
import {
  appliedProjectsFiltersState,
  selectedProjectsFiltersState,
} from '../project.state';

import { Filters } from './filters';
import { useApplyProjectFiltersFromUrl } from './use-apply-project-filters-from-url';
import { useProjectsFiltersSearchLocationSearch } from './use-projects-filters-location-search';
import { useFetchProjectFiltersOptions } from './use-fetch-project-filters-options.query';
import { AppliedFiltersTags } from '@/components/filters/applied-filters-tags';

export const ProjectsFilters = ({
  setFiltersContainerHeight,
  filtersDisabled,
}: {
  setFiltersContainerHeight: (height: number) => void;
  filtersDisabled: boolean;
}) => {
  const { t } = useTranslation('projects');
  const [activeFilterType, setActiveFilterType] = useRecoilState(
    activeFilterTypeState,
  );

  const filtersContainerRef = useRef<HTMLDivElement>(null);

  const [appliedFilters, setAppliedFilters] = useRecoilState(
    appliedProjectsFiltersState,
  );

  const activateSearchAfterApplyingFiltersFromUrl = () => {
    if (!appliedFilters.search) return;

    setActiveFilterType('search');
  };

  const { isFetchingProjectFilters } = useFetchProjectFiltersOptions();

  useApplyProjectFiltersFromUrl({
    isPrefetching: isFetchingProjectFilters,
    onFiltersApply: activateSearchAfterApplyingFiltersFromUrl,
  });

  const { updateLocationSearch, resetLocationSearch } =
    useProjectsFiltersSearchLocationSearch();

  const {
    handleSearchInputChange,
    hasAnyActiveFilters,
    clearAppliedFilters,
    clearSearchInput,
    clearSelectedFilters,
    switchToSearchFilter,
    handleApplyFilters,
  } = useHandleFilters<IProjectFilters>({
    appliedFiltersState: appliedProjectsFiltersState,
    selectedFiltersState: selectedProjectsFiltersState,
    updateLocationSearch,
    resetLocationSearch,
  });

  useEffect(() => {
    setFiltersContainerHeight(
      getElementOuterHeight(filtersContainerRef.current),
    );
  }, [activeFilterType, appliedFilters]);

  const handleFilterRemove = (
    key: keyof IProjectFilters,
    value?: string | number | MinMaxRange,
  ) => {
    const appliedFilter = appliedFilters[key];
    let newFilterValue: IOption[] | INumberOption[] | null = null;

    if (value && appliedFilter && isDefaultOptionArray(appliedFilter)) {
      const filtered = appliedFilter.filter(v => v.value !== value);
      newFilterValue = filtered.length > 0 ? filtered : null;
    }
    if (value && appliedFilter && isNumberOptionArray(appliedFilter)) {
      const filtered = appliedFilter.filter(v => v.value !== value);
      newFilterValue = filtered.length > 0 ? filtered : null;
    }

    setAppliedFilters(prev => {
      const newAppliedFilters = { ...prev, [key]: newFilterValue };
      updateLocationSearch(newAppliedFilters);
      return newAppliedFilters;
    });
  };

  return (
    <FilterSearchContainer
      ref={filtersContainerRef}
      direction="column"
      gap="15px"
    >
      <Flexbox
        name="filter-buttons-container"
        justify="flex-end"
        gap="10px"
        alignItems="center"
      >
        <ActivateFiltersButton
          variant="text"
          isActive={activeFilterType === 'advanced'}
          startIcon="Filter"
          disabled={filtersDisabled}
          onMouseDown={() =>
            setActiveFilterType(current =>
              current === 'advanced' ? null : 'advanced',
            )
          }
        >
          {t`projects.filter`}
        </ActivateFiltersButton>

        <FilterSearchInput
          onChange={handleSearchInputChange}
          placeholder={t`projects.searchByName`}
          onRemove={clearSearchInput}
          isInputActivated={
            activeFilterType === 'search' || !!appliedFilters.search
          }
          onInputActivate={switchToSearchFilter}
          onInputDeactivate={() => setActiveFilterType(null)}
          defaultValue={appliedFilters.search ?? ''}
          disabled={filtersDisabled}
        />
      </Flexbox>
      <Flexbox name="filter-buttons" justify="space-between">
        {activeFilterType === 'advanced' && hasAnyActiveFilters && (
          <Button variant="underlined" onClick={clearSelectedFilters}>
            {t`projects.clearAll`}
          </Button>
        )}
      </Flexbox>
      {activeFilterType === 'advanced' ? (
        <Filters onApply={handleApplyFilters} />
      ) : (
        <AppliedFiltersTags
          getTagLabel={getTagLabel}
          tags={Object.entries(appliedFilters) as Entries<IProjectFilters>}
          handleRemove={handleFilterRemove}
          handleClear={clearAppliedFilters}
        />
      )}
    </FilterSearchContainer>
  );
};

const getTagLabel = (value: unknown): string => {
  if (typeof value !== 'object') return `${value}`;
  if (isDefaultOption(value)) return value.label;

  if (isDateRangeOption(value)) {
    const { start, end } = value;
    let label = start?.label ?? '';
    if (end) {
      label += ` - ${end.label}`;
    }
    return label;
  }
  throw new Error('Invalid value type');
};

const FilterSearchContainer = styled(FlexContainer, forwardRef)`
  padding-right: 20px;
  padding-left: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;
  z-index: ${Z_INDEX_FILTERS_DROPDOWN_MENU};
`;
