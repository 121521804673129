import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { sortBy } from 'lodash-es';

import { Stack } from '@/ui/line/line';
import { SmallText } from '@/ui/typography/widgets';

import { useFreeTextFields } from '../../free-text-fields/use-free-text-fields';
import { useOfficeLocations } from '../../office-locations/use-office-locations';
import { CompanySection } from '../sections-pane.state';

import {
  AdditionalSectionButton,
  type AdditionalSectionButtonProps,
} from './additional-section-button';

/* additionalSectionsList sort order according to UI requirements */

interface AdditionalSectionListItem extends AdditionalSectionButtonProps {
  order: number;
  section: CompanySection;
}

export const AdditionalSectionsPicker = () => {
  const { t } = useTranslation('companies', {
    keyPrefix: 'company',
  });
  const { add: addFreeTextField, canAddExtraField } = useFreeTextFields();
  const { addOfficeLocationsWidget, isOfficeLocationsWidgetAdded } =
    useOfficeLocations();

  const additionalSectionsList = useMemo(() => {
    const list: AdditionalSectionListItem[] = [
      {
        order: 3,
        section: CompanySection.FREE_TEXT,
        disabled: !canAddExtraField,
        icon: 'Letters',
        addSectionHandler: addFreeTextField,
        text: t`sections.textSection`,
        disabledHoverText: t`sections.disabledButtonHover`,
      },
      {
        order: 4,
        section: CompanySection.OFFICE_LOCATIONS,
        disabled: isOfficeLocationsWidgetAdded,
        icon: 'Pin',
        addSectionHandler: addOfficeLocationsWidget,
        text: t`sections.offices`,
        disabledHoverText: t`sections.disabledButtonHover`,
      },
    ];
    return sortBy(list, 'order');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addFreeTextField,
    addOfficeLocationsWidget,
    canAddExtraField,
    isOfficeLocationsWidgetAdded,
  ]);

  return (
    <Container>
      <Stack gap="24px">
        <SmallText>{t`sections.addSection`}</SmallText>
        <SectionButtonsGrid>
          {additionalSectionsList.map(sectionData => (
            <AdditionalSectionButton
              key={sectionData.section}
              icon={sectionData.icon}
              disabled={sectionData.disabled}
              addSectionHandler={sectionData.addSectionHandler}
              text={sectionData.text}
              disabledHoverText={sectionData.disabledHoverText}
            />
          ))}
        </SectionButtonsGrid>
      </Stack>
    </Container>
  );
};

const Container = styled('div')`
  padding: 24px 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray.c3};
  background: ${({ theme }) => theme.colors.basics.white};
  flex-grow: 1;
`;

const SectionButtonsGrid = styled('div')`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
`;
