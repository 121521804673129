import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import type { CompanyData } from '@/api/v4/organization.api';
import { uploadOrganizationLogo } from '@/api/v4/organization.api';
import { EditableHeader } from '@/components/editable-header/editable-header';
import { PageGrid } from '@/components/layout/page-grid';
import { Loader } from '@/components/loader/loader';
import { LogoUploader } from '@/components/logo-uploader/logo-uploader';
import { QueryKey } from '@/config/query-client';
import { useDropdownFormFields } from '@/features/companies/hooks/use-dropdown-form-fields';
import { useEditCompanyForm } from '@/features/companies/hooks/use-edit-company-form';
import { useVerifyCompany } from '@/features/companies/hooks/use-verify-company';
import { companyIdState } from '@/features/companies/overview/company.state';
import { COMPANY_NAME_HEIGHT } from '@/features/companies/overview/utils/constants';
import {
  isCompanyLocationOption,
  isDefaultOption,
  isDefaultOptionArray,
} from '@/helpers/other';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Error, Input } from '@/ui/input/input';
import { Inline, Stack } from '@/ui/line/line';
import { notify } from '@/ui/snackbar/notify';
import { TextArea } from '@/ui/text-editable/text-editable.styles';

import { CompanyHqLocationOption } from '../company-hq-location-option';
import { CompanyHqLocationSingleValue } from '../company-hq-location-single-value';

import { CompanyFormSelect } from './company-form-select';
import { WithLabel } from './with-label';
import { useEditCompany } from '@/features/companies/hooks/use-edit-company.mutation';

interface EditModeFormProps {
  onCancelEditMode: () => void;
  company: CompanyData;
}

export function EditModeForm({ onCancelEditMode, company }: EditModeFormProps) {
  const { t } = useTranslation(['companies', 'default']);
  const queryClient = useQueryClient();
  const { mutateAsync } = useEditCompany();

  const companyId = useRecoilValue(companyIdState);
  const { verificationIconColor } = useVerifyCompany();

  const {
    methods,
    defaultOwnershipOption,
    isPrivateOwnershipValueSelected,
    handleOwnershipValueChange,
    selectedOwnershipOption,
    defaultActivityStatusOption,
    defaultStockExchangeSymbolOption,
    defaultYearOption,
    defaultEmployeesRangeOption,
    setSelectedOptionFormValue,
    getPreparedFormData,
  } = useEditCompanyForm();

  const {
    ownershipOptions,
    activityStatusOptions,
    employeesRangeOptions,
    stockExchangeSymbolOptions,
    yearsOptions,
    setHQLocationOptions,
    hqLocationOptionDefaultValue,
    impactsDefaultValue,
    impactsOptions,
  } = useDropdownFormFields() || {};

  const {
    formState,
    formState: { errors, isDirty, isValid },
    setValue,
    register,
    trigger,
    watch,
  } = methods;

  const websiteUrlValue = watch('websiteUrl');
  const twitterUrlValue = watch('twitterUrl');
  const linkedinUrlValue = watch('linkedinUrl');
  const logoValue = watch('logo');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    void trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    setIsSubmitting(true);
    const data = getPreparedFormData();

    try {
      await Promise.all([
        Object.keys(data).length > 0 && mutateAsync(data),
        logoValue && uploadOrganizationLogo(company.id, logoValue),
      ]);

      await queryClient.invalidateQueries([
        QueryKey.CompanyOptionsCount,
        companyId,
      ]);
    } catch (error) {
      notify({
        message: t`company.error`,
      });
      setIsSubmitting(false);
      captureException(error);
      throw error;
    }

    setIsSubmitting(false);
    onCancelEditMode();
  };

  return (
    <Container withLowOpacity={isSubmitting}>
      <ActionButtons name="header-edit-action-buttons" gap="20px">
        <Button onClick={onCancelEditMode} variant="underlined">
          {t`default:cancel`}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!isValid || !isDirty}
        >{t`default:save`}</Button>
      </ActionButtons>

      <Stack minHeight="fit-content" maxWidth="650px">
        <LogoUploader
          name={company.name.value ?? ''}
          logoUrl={company.logoUrl.value ?? null}
          isVerified={Boolean(company.lastVerifiedAt)}
          verificationIconColor={verificationIconColor}
          onFileChange={file => {
            setValue('logo', file, {
              shouldDirty: true,
            });
          }}
        />

        <Inline data-testid="company-name-editable-header">
          <EditableHeader
            value={company?.name.value ?? ''}
            maxLength={90}
            height={COMPANY_NAME_HEIGHT}
            placeholder={t`company.companyName`}
            adjustFontSize
            focusAtEnd
            onConfirm={name =>
              setValue('name', name, {
                shouldDirty: true,
              })
            }
          />
        </Inline>
      </Stack>

      <PageGrid columnGap="140px" marginTop="30px" marginBottom="34px">
        <Stack gap="24px" minWidth="450px">
          <Inline gap="46px" templateColumns="1fr 1fr">
            <div>
              <CompanyFormSelect
                label={t`company.header.activityStatus`}
                setOptions={activityStatusOptions}
                defaultValue={defaultActivityStatusOption}
                onChange={selectedOption =>
                  isDefaultOption(selectedOption) &&
                  setSelectedOptionFormValue({
                    selectedOption,
                    fieldName: 'activityStatusId',
                  })
                }
                dataTestId="activity-status-select"
              />
              {errors['activityStatusId'] && (
                <Error>{errors['activityStatusId'].message}</Error>
              )}
            </div>

            <CompanyFormSelect
              label={t`company.header.ownership`}
              setOptions={ownershipOptions}
              defaultValue={defaultOwnershipOption}
              onChange={selectedOption =>
                isDefaultOption(selectedOption) &&
                handleOwnershipValueChange(selectedOption)
              }
            />
          </Inline>
          {Boolean(selectedOwnershipOption) &&
            !isPrivateOwnershipValueSelected && (
              <Inline gap="46px" templateColumns="1fr 1fr">
                <CompanyFormSelect
                  label={t`company.header.stockExchangeSymbol`}
                  setOptions={stockExchangeSymbolOptions}
                  defaultValue={defaultStockExchangeSymbolOption}
                  onChange={selectedOption =>
                    isDefaultOption(selectedOption) &&
                    setSelectedOptionFormValue({
                      selectedOption,
                      fieldName: 'stockExchangeSymbol',
                    })
                  }
                />

                <WithLabel
                  name="ticker"
                  labelText={t`company.header.companyTicker`}
                >
                  <Input
                    placeholder={t`company.header.companyTicker`}
                    {...register('ticker')}
                  />
                </WithLabel>
              </Inline>
            )}

          <WithLabel
            name="owned-by"
            labelText={t`company.header.parentCompany`}
          >
            <Input
              placeholder={t`company.header.parentCompany`}
              {...register('ownedBy')}
            />
          </WithLabel>

          <CompanyFormSelect
            id={'hq-location'}
            label={t`company.header.hqLocation`}
            setOptions={setHQLocationOptions}
            defaultValue={hqLocationOptionDefaultValue}
            onChange={selectedOption => {
              isCompanyLocationOption(selectedOption) &&
                setSelectedOptionFormValue({
                  selectedOption,
                  fieldName: 'hqLocation',
                });
            }}
            optionProps={{ withCheckIconOnSelect: false }}
            placeholder={t`company.header.locationPlaceholder`}
            components={{
              Option: CompanyHqLocationOption,
              SingleValue: CompanyHqLocationSingleValue,
            }}
          />

          <Inline gap="46px" templateColumns="1fr 1fr">
            <CompanyFormSelect
              label={t`company.header.yearFounded`}
              setOptions={yearsOptions}
              defaultValue={defaultYearOption}
              onChange={selectedOption =>
                isDefaultOption(selectedOption) &&
                setSelectedOptionFormValue({
                  selectedOption,
                  fieldName: 'yearFounded',
                  isNumber: true,
                })
              }
            />

            <CompanyFormSelect
              label={t`company.header.employees`}
              setOptions={employeesRangeOptions}
              defaultValue={defaultEmployeesRangeOption}
              onChange={selectedOption =>
                isDefaultOption(selectedOption) &&
                setSelectedOptionFormValue({
                  selectedOption,
                  fieldName: 'employeesRangeId',
                })
              }
            />
          </Inline>
          <WithLabel name="website-url" labelText={t`company.header.website`}>
            <Input
              placeholder={t`default:urlPrefix`}
              rightIcon={'Website'}
              iconColor={websiteUrlValue ? colors.basics.black : colors.gray.c7}
              {...register('websiteUrl')}
              name={'websiteUrl'}
              error={formState.errors['websiteUrl']?.message}
            />
          </WithLabel>
          <WithLabel name="linkedin-url" labelText={t`company.header.linkedin`}>
            <Input
              placeholder={t`default:urlPrefix`}
              rightIcon={'LinkedIn'}
              iconColor={
                linkedinUrlValue ? colors.basics.black : colors.gray.c7
              }
              {...register('linkedinUrl')}
              name={'linkedinUrl'}
              error={errors['linkedinUrl']?.message}
            />
          </WithLabel>
          <WithLabel name="twitter-url" labelText={t`company.header.twitter`}>
            <Input
              placeholder={t`default:urlPrefix`}
              rightIcon={'Twitter'}
              iconColor={twitterUrlValue ? colors.basics.black : colors.gray.c7}
              {...register('twitterUrl')}
              name={'twitterUrl'}
              error={errors['twitterUrl']?.message}
            />
          </WithLabel>
        </Stack>

        <Stack gap="24px" minHeight="230px" width="445px">
          <WithLabel
            name="key-offering"
            height="271px"
            labelText={t`company.header.keyOffering`}
          >
            <TextArea
              placeholder={t`company.header.keyOfferingPlaceholder`}
              spellCheck={false}
              border
              {...register('keyOffering')}
            />
            {errors['keyOffering'] && (
              <Error>{errors['keyOffering'].message}</Error>
            )}
          </WithLabel>
          <WithLabel
            name="former-name"
            labelText={t`company.header.formerName`}
          >
            <Input
              placeholder={t`company.header.formerNamePlaceholder`}
              {...register('formerName')}
            />
          </WithLabel>

          <CompanyFormSelect
            label={t`company.header.impact`}
            defaultValue={impactsDefaultValue}
            setOptions={impactsOptions}
            isMulti
            onChange={newValue => {
              isDefaultOptionArray(newValue) &&
                setValue(
                  'impacts',
                  newValue.map(selected => selected.value),
                  {
                    shouldDirty: true,
                  },
                );
            }}
            tagVariant="pink"
          />
        </Stack>
      </PageGrid>
      {isSubmitting && <TransparentLoader />}
    </Container>
  );
}

const Container = styled('div')<{ withLowOpacity?: boolean }>`
  position: relative;
  ${({ withLowOpacity }) => withLowOpacity && `opacity: 0.3;`};
`;

const ActionButtons = styled(Flexbox)`
  position: absolute;
  right: 0;
  top: 0;
`;

const TransparentLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
