import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { isCompanyLocationOption } from '@/helpers/other';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { IconButton } from '@/ui/button/icon-button';
import { colorIcon } from '@/ui/icons/functions';
import { Stack } from '@/ui/line/line';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { Header } from '@/ui/typography/widgets';
import { Widget } from '@/ui/widget/widget';

import { CompanyHqLocationOption } from '../header/company-hq-location-option';
import { CompanyHqLocationSingleValue } from '../header/company-hq-location-single-value';
import { CompanyFormSelect } from '../header/edit-form/company-form-select';

import { LocationCard } from './location-card';
import { officeLocationsEditModeState } from './office-locations.state';
import { useOfficeLocations } from './use-office-locations';

export const OfficeLocationsWidget = ({ className }: { className: string }) => {
  const { t } = useTranslation('companies', { keyPrefix: 'company' });
  const [isEditMode, setEditMode] = useRecoilState(
    officeLocationsEditModeState,
  );
  const [addCounter, setAddCounter] = useState(0);
  const {
    officeLocations,
    setLocationOptions,
    hasUserEditPermission,
    isOfficeLocationsWidgetAdded,
    onAdd,
    deleteOfficeLocation,
    deleteWidget,
    onOutsideClick,
    canAddExtraOfficeLocation,
  } = useOfficeLocations();

  if (!isOfficeLocationsWidgetAdded) {
    return null;
  }

  const triggerSelectComponentRerender = () => setAddCounter(prev => prev + 1);

  return (
    <div className={className} data-testid="office-locations-widget">
      <OutsideClickHandler
        onOutsideClick={onOutsideClick}
        disabled={!isEditMode}
      >
        <Widget
          onEditClick={() => setEditMode(!isEditMode)}
          isEditMode={isEditMode}
          header={<Header>{t`sections.officeLocations`}</Header>}
          showEditButton={hasUserEditPermission}
          dataTestId="office-locations-widget-container"
        >
          <Wrapper marginTop={isEditMode ? '24px' : '36px'} gap="24px">
            {isEditMode && (
              <Tooltip
                content={t`sections.disabledSelectHover`}
                visible={!canAddExtraOfficeLocation}
              >
                <CompanyFormSelect
                  key={addCounter}
                  isDisabled={!canAddExtraOfficeLocation}
                  setOptions={setLocationOptions}
                  onChange={selectedOption => {
                    if (!isCompanyLocationOption(selectedOption)) {
                      return;
                    }

                    onAdd({
                      payload: {
                        cityId: selectedOption.value.cityId ?? '',
                      },
                      onSuccess: () => triggerSelectComponentRerender(),
                    });
                  }}
                  optionProps={{ withCheckIconOnSelect: false }}
                  components={{
                    Option: CompanyHqLocationOption,
                    SingleValue: CompanyHqLocationSingleValue,
                  }}
                  autoFocus
                  dataTestId="office-locations-select"
                />
              </Tooltip>
            )}

            <LocationsGrid>
              {officeLocations?.map((location, index) => (
                <LocationCard
                  key={location.id + index}
                  location={location}
                  shouldShowDeleteButton={isEditMode}
                  onDelete={id => deleteOfficeLocation(id)}
                />
              ))}
            </LocationsGrid>
          </Wrapper>
          {isEditMode && (
            <DeleteIconButton
              icon="Bin"
              variant="ghost"
              size="16px"
              onClick={deleteWidget}
            />
          )}
        </Widget>
      </OutsideClickHandler>
    </div>
  );
};

const Wrapper = styled(Stack)<{ marginTop: string }>`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop};
`;

const LocationsGrid = styled('div')`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto;
  row-gap: 24px;
  justify-content: space-between;
`;

const DeleteIconButton = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 44px;
  padding: 0;
  z-index: ${Z_INDEX_FILTERS_DROPDOWN_MENU};

  ${({ theme }) =>
    `
    ${colorIcon(theme.colors.system.lowFit)}
  `};
`;
