/**
 * Trim a string to consistently fit fixed-width component containers
 * @param text The text to observe
 * @param maxLength The maximum character length the text can have before getting cropped
 * @param appendText The text to append to the end of the cropped original text (i.e. ellipsis)
 * @returns The handled text result.
 */
export const trimText = (
  text: string,
  maxLength: number,
  appendText?: string,
) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + appendText;
};
