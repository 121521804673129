import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  appliedProjectsFiltersState,
  defaultAppliedProjectsFilters,
  isProjectFilterKeyContainingPredefinedOptions,
  projectsFilterOptionsState,
} from '@/features/projects/project.state';
import { formatCalendarDate } from '@/helpers/format-date';
import { isObject } from '@/helpers/is-object';
import { isPresent } from '@/helpers/is-present';
import {
  isDateRangeOption,
  isDefaultOptionArray,
  isNumberOptionArray,
} from '@/helpers/other';
import type { DateRangeOption } from '@/types';

import { extractProjectSearchFilters } from './project-filters-helpers';

export const useApplyProjectFiltersFromUrl = ({
  isPrefetching,
  onFiltersApply,
}: {
  isPrefetching: boolean;
  onFiltersApply?: () => void;
}) => {
  const location = useLocation();

  const projectFilterOptions = useRecoilValue(projectsFilterOptionsState);
  const setAppliedFilters = useSetRecoilState(appliedProjectsFiltersState);

  useEffect(() => {
    if (!location.search || isPrefetching) return;

    const filtersFromUrl = extractProjectSearchFilters(location.search);

    const filtersToApply = Object.entries(filtersFromUrl).reduce(
      (acc, [key, value]) => {
        if (!(key in defaultAppliedProjectsFilters)) return acc;

        const options = isProjectFilterKeyContainingPredefinedOptions(key)
          ? projectFilterOptions[key]
          : null;

        // handled keys: status, listingStatus
        if (Array.isArray(value) && isDefaultOptionArray(options)) {
          const optionArray = options.filter(item =>
            value.includes(item.value),
          );
          return {
            ...acc,
            [key]: optionArray,
          };
        }

        // handled keys: createdBy
        if (Array.isArray(value) && isNumberOptionArray(options)) {
          const numberValues: number[] = value
            .map(stringValue => {
              const parsedValue = parseInt(stringValue);
              return isNaN(parsedValue) ? null : parsedValue;
            })
            .filter(isPresent);

          const numbersArray = options.filter(item =>
            numberValues.includes(item.value),
          );

          return {
            ...acc,
            [key]: numbersArray,
          };
        }

        // handled keys: createdDate, lastEditedDate
        if (isObject(value) && isDateRangeOption(value) && value.start) {
          const startEndFilter: DateRangeOption = {
            start: {
              value: value.start,
              label: formatCalendarDate(value.start, 'dd MMM yyyy') ?? '',
            },
            end: value.end
              ? {
                  value: value.end,
                  label: formatCalendarDate(value.end, 'dd MMM yyyy') ?? '',
                }
              : null,
          };

          return {
            ...acc,
            [key]: {
              ...startEndFilter,
            },
          };
        }

        // handled keys: search
        if (!options) {
          return { ...acc, [key]: value };
        }

        return acc;
      },
      defaultAppliedProjectsFilters,
    );

    setAppliedFilters(filtersToApply);
    onFiltersApply?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefetching]);
};
