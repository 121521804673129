import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { MainExploreForm } from '@/features/explore/form/main-explore-form';

export const ExploreFormPage = () => {
  return (
    <WithHtmlSavvyTitle title="Explore">
      <Page name="explore-page" direction="column">
        <MainExploreForm />
      </Page>
    </WithHtmlSavvyTitle>
  );
};

const Page = styled(Flexbox)`
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) =>
    theme.palettes.component.explore.page.background};
`;
