import { forwardRef } from 'react';
import type { CSSAttribute } from 'goober';
import { styled } from 'goober';

import { FlexContainer } from '../flexbox/flexbox';

import type { WidgetProps } from './widget';
import { Widget } from './widget';

interface ExtendedWidgetProps extends WidgetProps {
  maxHeight: number;
  $measuringRender: boolean;
  justify?: CSSAttribute['justifyContent'];
}
export const TRANSITION_WIDGET_GAP = 12;

export const TransitionedWidget = styled(
  Widget,
  forwardRef,
)<ExtendedWidgetProps>`
  display: flex;
  flex-direction: column;
  gap: ${TRANSITION_WIDGET_GAP}px;
  ${({ justify }) => justify && `justify-content: ${justify};`};

  ${({ theme }) =>
    theme.mixins.transition(['max-height', 'overflow', 'min-height'])}
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  ${({ $measuringRender }) => !$measuringRender && `overflow: hidden`};
  ${({ maxHeight }) => maxHeight >= 700 && `min-height: unset`};

  padding-right: 5px;
  position: relative;
`;

export const WidgetOverflowItemsContainer = styled(FlexContainer, forwardRef)<{
  $overflow: boolean;
  $measuringRender: boolean;
}>`
  ${({ $overflow, $measuringRender }) =>
    !$measuringRender && `overflow: ${$overflow ? 'auto' : 'hidden'}`};

  ${({ theme }) => theme.mixins.scrollbar}
`;
