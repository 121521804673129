import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { fetchHQLocations } from '@/api/v4/locations.api';
import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const OfficeHeadquartersFilter = ({
  className,
}: {
  className?: string;
}) => {
  const { t } = useTranslation('companies');

  const setCompaniesFiltersOptions = useSetRecoilState(
    companiesFilterOptionsState,
  );
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const setOptions = async (search: string) => {
    const locations = await fetchHQLocations({
      ...(search && { search }),
    });

    const hqLocations = locations.map(location => ({
      value: location.id,
      label: location.description,
    }));

    setCompaniesFiltersOptions(currentFilters => ({
      ...currentFilters,
      hqLocations,
    }));
    return hqLocations;
  };

  return (
    <AsyncSelect
      id="filter-headquarters"
      label={t`companies.filters.officeHeadquarters`}
      defaultValue={selectedFilters.hqCities}
      isMulti
      isSearchable
      className={className}
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;
        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          hqCities: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
