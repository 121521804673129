import { Rating } from '@/components/rating/rating';

const HALF_PRECISION = 0.5;

export const RankingStarInput = ({
  initialValue,
  onChange,
}: {
  initialValue?: number;
  onChange?: (value: number) => void;
}) => {
  return (
    <Rating
      precision={HALF_PRECISION}
      initialValue={initialValue}
      onChange={onChange}
    />
  );
};
