import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchProjects } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { prepareProjectApiFilters } from '../filters/project-filters-helpers';
import {
  appliedProjectsFiltersState,
  projectsSortState,
} from '../project.state';

export const useProjects = () => {
  const { hasRequiredPermission } = useUserPermissions();

  const appliedFilters = useRecoilValue(appliedProjectsFiltersState);

  const { sort, direction } = useRecoilValue(projectsSortState);

  const filters = prepareProjectApiFilters(appliedFilters);

  return useInfiniteQuery(
    [QueryKey.Projects, sort, direction, filters],
    ({ pageParam = 1 }: QueryFunctionContext) => {
      const params = {
        sort,
        direction,
        page: pageParam,
        items: 20,
        ...filters,
      };
      return fetchProjects(params);
    },
    {
      enabled: hasRequiredPermission(PERMISSION.VIEW_PROJECT_LIST),
      staleTime: 1000 * 10 * 5,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;
        return nextPage;
      },
    },
  );
};
