export interface NavigationState {
  from?: string;
}

export const isFromState = (
  state: NavigationState | undefined,
  stateKey: string,
) => {
  return Boolean(state && 'from' in state && state.from === stateKey);
};
