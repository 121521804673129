import type { CompanySortOptions } from '@/api/v4/organization.api';
import type { HeaderCellConfig } from '../../ui/table/table.types';

export const companiesHeaders: HeaderCellConfig<CompanySortOptions>[] = [
  {
    columnKey: 'company',
    width: '35%',
    sortKey: 'name',
    padding: '20px 20px 10px 60px',
    showCheckbox: true,
    defaultSortDirection: 'asc',
  },
  {
    columnKey: 'ranking',
    sortKey: 'rating',
    align: 'center',
  },
  {
    columnKey: 'yearFounded',
    sortKey: 'yearEstablished',
  },
  {
    columnKey: 'created',
    align: 'center',
    sortKey: 'createdDate',
  },
  {
    columnKey: 'modified',
    align: 'center',
    sortKey: 'lastEditedDate',
  },
  {
    columnKey: 'listedIn',
    align: 'center',
    width: '13%',
    sortKey: 'listingCount',
  },
  {
    columnKey: 'action',
    hideLabel: true,
  },
];
