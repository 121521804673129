import type { ListingStage } from '@/api/v4/project-listings.api';
import { getListingStageByDefaultName } from '@/features/listing-stage/helpers/get-listing-stage-by-default-name';
import { getListingStageById } from '@/features/listing-stage/helpers/get-listing-stage-by-id';
import i18n from '@/lib/i18n/i18n';
import type { DropdownOption } from '@/ui/dropdown/dropdown';

import { DealStageName, ListingStageName } from '../../../listing-stage/types';

export const generateListingStageMenu = ({
  listingId,
  isNotRelevant,
  status,
  onListingStatusChanged,
  listingStages,
}: {
  listingId: string;
  status: ListingStage;
  isNotRelevant: boolean;
  onListingStatusChanged: (
    listingId: string,
    newListingStatusId: string | null,
    isDeal?: boolean,
  ) => void;
  listingStages: ListingStage[];
}): DropdownOption[] => {
  const accountStatuses = listingStages.map<DropdownOption>(
    ({ id, name, displayName }) => ({
      label: displayName ?? name,
      icon: !isNotRelevant && status.id === id ? 'CheckMark' : undefined,
      onClick: () => onListingStatusChanged(listingId, id),
    }),
  );

  const statusesExcludingDealStatusesAndNotRelevant = accountStatuses.filter(
    ({ label }) => {
      return !(
        typeof label === 'string' &&
        (Object.values(DealStageName).includes(label as DealStageName) ||
          label === ListingStageName.notRelevant)
      );
    },
  );

  const listingStage = getListingStageById(status.id, listingStages);

  const notRelevantStage = getListingStageByDefaultName(
    ListingStageName.notRelevant,
    listingStages,
  );

  return [
    ...statusesExcludingDealStatusesAndNotRelevant,
    {
      label: i18n.t`companies:listingStatuses.deal`,
      icon:
        !isNotRelevant &&
        [
          ListingStageName.deal.toString(),
          DealStageName.implementation.toString(),
          DealStageName.investment.toString(),
          DealStageName.otherTransaction.toString(),
        ].includes(status.name)
          ? 'CheckMark'
          : undefined,
      onClick: () => onListingStatusChanged(listingId, null, true),
    },
    {
      label: i18n.t`companies:listingStatuses.notRelevant`,
      subtext: isNotRelevant
        ? `From: ${listingStage?.displayName ?? listingStage?.name}`
        : undefined,
      icon: isNotRelevant ? 'CheckMark' : 'CloseCircle',
      onClick: () =>
        notRelevantStage &&
        onListingStatusChanged(listingId, notRelevantStage.id),
    },
  ];
};
