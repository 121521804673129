import type { NavigationState } from '@/routes/helpers/is-from-state';
import { isFromState } from '@/routes/helpers/is-from-state';
import { useLocation } from 'react-router-dom';

export const EXPLORE_HISTORY_STATE = 'explore_history_state';
export const EXPLORE_RESULTS_ERROR_STATE = 'explore_results_error_state';
export const EXPLORE_NO_RESULTS_STATE = 'explore_no_results_state';

export const useExploreNavigationHistory = () => {
  const location = useLocation();
  const historyLocationState = (location.state as NavigationState) || undefined;

  const isFromHistory = isFromState(
    historyLocationState,
    EXPLORE_HISTORY_STATE,
  );

  const isFromErrorPage = isFromState(
    historyLocationState,
    EXPLORE_RESULTS_ERROR_STATE,
  );

  const isFromNoResultsPage = isFromState(
    historyLocationState,
    EXPLORE_NO_RESULTS_STATE,
  );

  return {
    isFromHistory,
    isFromErrorPage,
    isFromNoResultsPage,
  };
};
