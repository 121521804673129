import type { FundingStage } from '@/api/v4/funding-stages.api';
import { fetchFundingStages } from '@/api/v4/funding-stages.api';
import { fetchHQLocations } from '@/api/v4/locations.api';
import type { EmployeeRange } from '@/api/v4/organization.api';
import { fetchEmployeeRanges } from '@/api/v4/organization.api';
import type { ListingStage } from '@/api/v4/project-listings.api';
import { fetchListingStages } from '@/api/v4/project-listings.api';
import type { Tag } from '@/api/v4/tags.api';
import type { HQLocation } from '@/api/v4/locations.api';
import { fetchTags } from '@/api/v4/tags.api';
import { QueryKey } from '@/config/query-client';
import { useQueries } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { companiesFilterOptionsState } from '../companies.state';
import {
  getCapitalRaisedOptions,
  getRankingOptions,
  mapCompanyFundingStageFilter,
  mapCompanyStatusFilter,
} from './company-filters-helpers';
import { getYearsOptions } from '@/utils/years';
import { useEffect, useState } from 'react';
import { mapEmployeesRangesToOptions } from '@/hooks/queries/use-employees-ranges.query';
import { useLocation } from 'react-router-dom';

export const useFetchCompanyFiltersOptions = () => {
  const [isFetchingCompanyFilters, setIsFetchingCompanyFilters] =
    useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.split('?')[1]);
  const locationsParams = searchParams.getAll('hqLocationCity');
  const setDefaultFilterOptions = useSetRecoilState(
    companiesFilterOptionsState,
  );
  const staleTime = 5 * 60 * 1000;
  const cacheTime = 5 * 60 * 1000;

  const results = useQueries({
    queries: [
      {
        queryKey: [QueryKey.Tags],
        queryFn: fetchTags,
        onSuccess: (data: Tag[]) => {
          const tagOptions = data.map(tag => ({
            value: tag.id,
            label: tag.name,
          }));

          setDefaultFilterOptions(currentFilters => ({
            ...currentFilters,
            tags: tagOptions,
            yearFounded: getYearsOptions(),
            ranking: getRankingOptions(),
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.EmployeeRanges],
        queryFn: fetchEmployeeRanges,
        onSuccess: (data: EmployeeRange[]) => {
          setDefaultFilterOptions(currentFilters => ({
            ...currentFilters,
            employeesRange: mapEmployeesRangesToOptions(data),
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.FundingStages],
        queryFn: fetchFundingStages,
        onSuccess: (data: FundingStage[]) => {
          const fundingStageOptions = data.map(mapCompanyFundingStageFilter);

          setDefaultFilterOptions(currentFilters => ({
            ...currentFilters,
            fundingStages: fundingStageOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.ListingStages],
        queryFn: () => fetchListingStages(),
        onSuccess: (data: ListingStage[]) => {
          const stageOptions = data.map(mapCompanyStatusFilter);

          setDefaultFilterOptions(currentFilters => ({
            ...currentFilters,
            listingStatuses: stageOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.HqLocations, { cityId: locationsParams }],
        queryFn: () =>
          fetchHQLocations({
            ...(locationsParams.length > 0 && { cityId: locationsParams }),
          }),
        onSuccess: (data: HQLocation[]) => {
          const locationOptions = data.map(location => ({
            value: location.id,
            label: location.description,
          }));

          setDefaultFilterOptions(currentFilters => ({
            ...currentFilters,
            hqCities: locationOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
    ],
  });

  const allQueriesFinished = results.every(
    result => !result.isLoading && result.isSuccess,
  );

  useEffect(() => {
    if (allQueriesFinished) {
      setDefaultFilterOptions(currentFilters => ({
        ...currentFilters,
        yearFounded: getYearsOptions(),
        ranking: getRankingOptions(),
        capitalRaised: getCapitalRaisedOptions(),
      }));
      setIsFetchingCompanyFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQueriesFinished]);

  return {
    isFetchingCompanyFilters,
  };
};
