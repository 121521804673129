import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import SvgProducts from '@/assets/widgets/empty-state-company-products.svg';
import { Loader } from '@/components/loader/loader';
import type { IntegrationOption } from '@/types';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';

import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import { WIDGET_ROW_2_HEIGHT } from '../utils/constants';
import { WidgetLoaderContainer } from '../widget-loader-container';

import { CompanyPartialEmptyWidget } from './company-partial-empty-widget';
import { CompanyProductCompliance } from './company-product-compliance';
import { CompanyProductDeploymentModel } from './company-product-deployment-models';
import { CompanyProductDescription } from './company-product-description';
import { CompanyProductHeader } from './company-product-header';
import { CompanyProductIntegration } from './company-product-integrations';
import { CompanyProductLanguages } from './company-product-languages';
import { CompanyProductPotentialImpact } from './company-product-potential-impact';
import type { CompanyProducts } from '@/api/v4/organization.api';
import { useCompanyProducts } from './use-company-products.query';
import { useLanguages } from '@/hooks/queries/use-languages.query';
import { useCompliances } from '@/hooks/queries/use-compliances.query';
import { useDeploymentModels } from '@/hooks/queries/use-deployment-models.query';
import { useEditCompanyProducts } from './use-edit-company-products.mutation';
import { isPresent } from '@/helpers/is-present';

function isCompanyProductDataEmpty(companyProducts: CompanyProducts) {
  return (
    !companyProducts.productTitle &&
    !companyProducts.productDescription &&
    isCompanyProductDataPartialEmpty(companyProducts)
  );
}

function isFormDataEmpty(formData: CompanyProducts) {
  return Object.values(formData).reduce(
    (result, value) =>
      result && (!value || (Array.isArray(value) && value.length === 0)),
    true,
  );
}

function isCompanyProductDataPartialEmpty(companyProducts: CompanyProducts) {
  return (
    (!companyProducts.languageIds ||
      companyProducts.languageIds.length === 0) &&
    (!companyProducts.deploymentModalIds ||
      companyProducts?.deploymentModalIds?.length === 0) &&
    (!companyProducts.complianceIds ||
      companyProducts.complianceIds.length === 0) &&
    (!companyProducts.integrations || companyProducts.integrations.length === 0)
  );
}

export const mapOrganizationIntegrationsToOptions = (
  integrations: CompanyProducts['integrations'],
): IntegrationOption[] => {
  return integrations.map(i => ({
    value: i.id,
    label: i.name,
    integrationId: i.id,
    logo: i.logoUrl,
    source: 'savvy',
  }));
};

export const CompanyProductsWidget = ({
  className,
}: {
  className?: string;
}) => {
  const canEditCompany = useCanEditCompany();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveAttempted, setIsSaveAttempted] = useState(false);
  const { mutateAsync: editCompanyProducts, isLoading } =
    useEditCompanyProducts();
  const { data: companyProducts } = useCompanyProducts();

  const { data: deploymentModels = [] } = useDeploymentModels();
  const { data: languages = [] } = useLanguages();
  const { data: compliances = [] } = useCompliances();

  const [data, setData] = useState<CompanyProducts>();
  const [integrations, setIntegrations] = useState<IntegrationOption[]>([]);

  const { t } = useTranslation('companies');

  useEffect(() => {
    if (!companyProducts) return;
    setData(companyProducts);
    setIntegrations(
      mapOrganizationIntegrationsToOptions(companyProducts.integrations),
    );
  }, [companyProducts]);

  const handleEditMode = () => {
    if (canEditCompany) setIsEditMode(true);
  };

  const handleSaveData = async () => {
    if (
      data &&
      isFormDataEmpty(data) &&
      isCompanyProductDataEmpty(data) &&
      integrations.length < 1
    ) {
      setIsSaveAttempted(false);
      return setIsEditMode(false);
    }

    setIsSaveAttempted(true);
    if (!data || !data?.productTitle || !data.productDescription) return;

    await editCompanyProducts({
      languageIds: data.languageIds,
      productDescription: data.productDescription,
      productTitle: data.productTitle,
      complianceIds: data.complianceIds,
      deploymentModalIds: data.deploymentModalIds,
      enterpriseReady: data.enterpriseReady,
      potentialImpact: data.potentialImpact,
      integrations: integrations.map(integration => ({
        id: integration.value,
        source: integration.source,
      })),
    });

    setIsSaveAttempted(false);
    setIsEditMode(false);
  };

  const handleDataChange = (newData: Partial<CompanyProducts>) => {
    setData(prevData => {
      if (!prevData)
        return {
          complianceIds: newData.complianceIds ?? [],
          deploymentModalIds: newData.deploymentModalIds ?? [],
          languageIds: newData.languageIds ?? [],
          productDescription: newData.productDescription ?? null,
          productTitle: newData.productTitle ?? null,
          potentialImpact: newData.potentialImpact ?? null,
          enterpriseReady: newData.enterpriseReady ?? false,
          integrations: newData.integrations ?? [],
        };

      return {
        ...prevData,
        ...newData,
      };
    });
  };

  if (
    !data ||
    (!isEditMode && isCompanyProductDataEmpty(data) && !canEditCompany)
  )
    return null;

  if (isLoading) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_2_HEIGHT} className={className}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!isEditMode && isCompanyProductDataEmpty(data) && canEditCompany) {
    return (
      <EmptyStateWidget
        label={t`products.emptyLabel`}
        className={className}
        linkText={t`products.emptyLinkText`}
        height={388}
        onEditClick={handleEditMode}
        padding="36px 60px"
        image={SvgProducts}
        direction="column"
        labelGrow={1}
        labelJustify="space-around"
        labelAlign="center"
      />
    );
  }

  const showPartialEmpty =
    !isEditMode &&
    !!companyProducts &&
    isCompanyProductDataPartialEmpty(companyProducts);

  return (
    <div className={className}>
      <OutsideClickHandler
        onOutsideClick={handleSaveData}
        disabled={!isEditMode}
      >
        <Container
          header={
            <CompanyProductHeader
              data={data}
              setData={handleDataChange}
              isEditMode={isEditMode}
              showErrors={isSaveAttempted}
            />
          }
          height={WIDGET_ROW_2_HEIGHT}
          onEditClick={handleEditMode}
          disabled={!canEditCompany || isLoading}
          isEditMode={isEditMode}
        >
          <ProductWidgetLayout showPartialEmpty={showPartialEmpty}>
            <CompanyProductDescription
              productDescription={data.productDescription}
              setData={handleDataChange}
              isEditMode={isEditMode}
              showErrors={isSaveAttempted}
            />
            <CompanyProductPotentialImpact
              potentialImpact={data?.potentialImpact}
              setData={setData}
              isEditMode={isEditMode}
            />
            <CompanyProductLanguages
              setData={handleDataChange}
              isEditMode={isEditMode}
              defaultValue={data.languageIds
                .map((languageId: string) =>
                  languages.find(language => languageId === language.value),
                )
                .filter(isPresent)}
            />
            <CompanyProductCompliance
              setData={handleDataChange}
              isEditMode={isEditMode}
              defaultValue={data.complianceIds
                .map(complianceId =>
                  compliances.find(
                    compliance => complianceId === compliance.value,
                  ),
                )
                .filter(isPresent)}
            />
            <CompanyProductIntegration
              setData={setIntegrations}
              isEditMode={isEditMode}
              defaultValue={
                companyProducts?.integrations &&
                mapOrganizationIntegrationsToOptions(
                  companyProducts.integrations,
                )
              }
            />
            <CompanyProductDeploymentModel
              setData={handleDataChange}
              isEditMode={isEditMode}
              defaultValue={data.deploymentModalIds
                .map(deploymentId =>
                  deploymentModels.find(
                    deployment => deployment.value === deploymentId,
                  ),
                )
                .filter(isPresent)}
            />
          </ProductWidgetLayout>
          {showPartialEmpty && (
            <CompanyPartialEmptyWidget onClick={handleEditMode} />
          )}
        </Container>
      </OutsideClickHandler>
    </div>
  );
};

const Container = styled(Widget)`
  display: flex;
  flex-direction: column;
  padding: 80px 114px;
  position: relative;
`;

const ProductWidgetLayout = styled('div')<{ showPartialEmpty: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px 100px;
  padding-top: 48px;
`;
