import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { TeamOnList, TeamsSortParams } from '@/api/v4/team.api';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { stringifySort } from '../../helpers/stringify-sort';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Flexbox } from '../../ui/flexbox/flexbox';
import { InfiniteScrollTable } from '../../ui/table/infinite-table/infinite-scroll-table';
import { NoResults } from '../../ui/table/no-results';
import type { ISort } from '../../ui/table/table.types';
import { WithHtmlSavvyTitle } from '../../with-html-savvy-title';

import { useNavigate } from 'react-router-dom';
import { TeamsFilters } from '@/features/teams/filters/teams-filters';
import { useTeamsFiltersSearchLocationSearch } from '@/features/teams/filters/use-teams-filters-location-search';
import type { TeamFilters } from '@/features/teams/team.state';
import {
  appliedTeamsFiltersState,
  selectedTeamsFiltersState,
  teamsSortState,
} from '@/features/teams/team.state';
import { useTeamsTableConfiguration } from '@/features/teams/use-teams-table-configuration';
import { useTeams } from '@/features/teams/use-teams.query';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';

export const TeamsPage = () => {
  const { t } = useTranslation('teams');
  const navigateTo = useNavigateTo();
  const navigate = useNavigate();
  const { hasRequiredPermission } = useUserPermissions();
  const { data, hasNextPage, isLoading, fetchNextPage } = useTeams();
  const teams = data ? data.pages.flat() : [];
  const { header, rows, skeletonConfig } = useTeamsTableConfiguration(teams);
  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);

  const handleSort = (sort: ISort) => {
    const locationSearch = stringifySort(sort);
    navigate({ ...location, search: locationSearch }, { replace: true });
  };

  const { updateLocationSearch, resetLocationSearch } =
    useTeamsFiltersSearchLocationSearch();

  const { clearAppliedFilters, hasAnyActiveFilters } =
    useHandleFilters<TeamFilters>({
      appliedFiltersState: appliedTeamsFiltersState,
      selectedFiltersState: selectedTeamsFiltersState,
      updateLocationSearch,
      resetLocationSearch,
    });

  return (
    <WithHtmlSavvyTitle title="Teams">
      <TeamsContentContainer
        name="teams-page-container"
        direction="column"
        padding="0 30px"
        fullHeight
      >
        <TeamsFilters
          setFiltersContainerHeight={height =>
            setFiltersContainerHeight(height)
          }
          filtersDisabled={
            (!data || teams.length === 0) && !hasAnyActiveFilters
          }
        />

        {!isLoading && rows.length === 0 ? (
          <Flexbox
            name="teams-no-results-wrapper"
            justify="center"
            alignItems="center"
            fullHeight
          >
            {hasAnyActiveFilters ? (
              <NoResults
                header={t`noResults.header`}
                text={t`noResults.text`}
                buttonText={t`noResults.button`}
                buttonIcon="ArrowGoBack"
                onButtonClick={clearAppliedFilters}
              />
            ) : (
              <NoResults
                onButtonClick={navigateTo.createTeam}
                buttonText={t`noTableResults.button`}
                header={t`noTableResults.header`}
                text={t`noTableResults.text`}
                buttonIcon="Plus"
                showButton={hasRequiredPermission(PERMISSION.MANAGE_TEAMS)}
              />
            )}
          </Flexbox>
        ) : (
          <InfiniteScrollTable<TeamOnList, TeamsSortParams>
            dataTestId="all-teams-table"
            isLoading={isLoading}
            headers={header}
            rows={rows}
            data={teams}
            hasMore={Boolean(hasNextPage)}
            onLoadMore={fetchNextPage}
            onSort={handleSort}
            sortState={teamsSortState}
            height={`calc(100vh - ${TOPBAR_HEIGHT} - ${filtersContainerHeight}px)`}
            skeletonConfig={skeletonConfig}
          />
        )}
      </TeamsContentContainer>
    </WithHtmlSavvyTitle>
  );
};

const TeamsContentContainer = styled(Flexbox)`
  position: relative;
  overflow: hidden;
`;
