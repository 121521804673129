import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { useTranslation } from 'react-i18next';
import SvgProblemStatement from '@/assets/widgets/empty-state-problem-statement.svg';
import { useUpdateProject } from '../use-update-project.mutation';
import type { Project } from '@/api/v4/projects.api';
import { SkeletonWidget } from './project-sidebar';

export const ProblemStatementWidget = ({
  isDataLoading,
  projectId,
  problemStatement,
  hasEditPermission,
  widgetHeight,
  widgetWidth,
}: {
  isDataLoading: boolean;
  projectId?: Project['id'];
  problemStatement?: Project['problemStatement'];
  hasEditPermission: boolean;
  widgetHeight: number;
  widgetWidth: number;
}) => {
  const { t } = useTranslation('projects');
  const { editProject } = useUpdateProject();

  if (isDataLoading || !projectId) {
    return <SkeletonWidget width={widgetWidth} />;
  }

  const handleEdit = async (problemStatement: string | null) => {
    await editProject({ projectId, problemStatement });
  };

  return (
    <TextWidget
      dataTestId="problem-statement-widget"
      disableBaseWidth
      height={widgetHeight}
      maxWidth={widgetWidth}
      paragraphVariant="medium"
      value={problemStatement ?? ''}
      header={
        <SubHeaderBold>{t`projectOverview.problemStatement`}</SubHeaderBold>
      }
      placeholder={t`projectOverview.problemStatementPlaceholder`}
      emptyStateOptions={{
        label: t`projectOverview.emptyState.labelProblemStatement`,
        linkText: t`projectOverview.emptyState.linkTextProblemStatement`,
        image: SvgProblemStatement,
      }}
      onSave={handleEdit}
      disabled={!hasEditPermission}
    />
  );
};
