import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchGenAiRequestHistory } from '@/api/v4/genai-request-history.api';
import { QueryKey } from '@/config/query-client';

export const useExploreHistory = () => {
  return useInfiniteQuery(
    [QueryKey.ExploreHistory],
    ({ pageParam = 1 }: QueryFunctionContext) => {
      const params = {
        page: pageParam,
        size: 20,
      };
      return fetchGenAiRequestHistory(params);
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;
        return nextPage;
      },
    },
  );
};
