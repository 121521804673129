import { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';

export const useBlockNavigation = ({
  when,
  additionalActionOnConfirm,
  exceptionRoutes,
}: {
  when: boolean;
  additionalActionOnConfirm?: () => void;
  exceptionRoutes?: string[];
}) => {
  const navigate = useNavigate();
  const setModalState = useSetRecoilState(modalState);
  const [lastLocationPathname, updateLastLocationPathname] = useState<string>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const { navigator } = useContext(UNSAFE_NavigationContext);

  const handleBlockedNavigation = useCallback(
    (location: string) => {
      if (!confirmedNavigation && !exceptionRoutes?.includes(location)) {
        updateLastLocationPathname(location);
        setModalState({
          state: 'confirmDiscardChanges',
          mainAction: () => {
            updateConfirmedNavigation(true);
            additionalActionOnConfirm?.();
          },
        });
        return false; // Block the navigation
      }
      return true; // Allow the navigation
    },
    [
      confirmedNavigation,
      exceptionRoutes,
      setModalState,
      additionalActionOnConfirm,
    ],
  );

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;
    navigator.push = (...args) => {
      const [location] = args;
      const pathname =
        typeof location === 'string' ? location : location.pathname;

      const allowNavigation = pathname && handleBlockedNavigation(pathname);
      if (allowNavigation) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, when, handleBlockedNavigation]);

  useEffect(() => {
    if (confirmedNavigation && lastLocationPathname) {
      navigate(lastLocationPathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocationPathname, navigate]);
};
