import { useState } from 'react';
import { styled } from 'goober';

import { classList } from '@/helpers/class-list';
import { Stack } from '@/ui/line/line';

import { useCrunchbaseEnrichmentCheck } from '../../hooks/use-crunchbase-enrichment-check';
import { EditStamp } from '../edit-stamp';
import { useCompany } from '../use-company.query';
import { COMPANY_MAIN_CONTENT_WIDTH } from '../utils/constants';

import { MainContent } from './header-main-content';

export function CompanyHeader() {
  const [isEditMode, setIsEditMode] = useState(false);

  const { isEnrichedFromCrunchbase } = useCrunchbaseEnrichmentCheck('header');
  const { data: company } = useCompany();

  return (
    <Container
      className={classList('company-page-header', isEditMode && 'edit-mode')}
      data-testid="company-page-header"
    >
      <MainContentContainer gap="36px">
        {company && (
          <MainContent
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            company={company}
          />
        )}
        {isEnrichedFromCrunchbase && !isEditMode && (
          <EditStamp
            crunchbasePermalink={company?.crunchbasePermalink ?? null}
          />
        )}
      </MainContentContainer>
    </Container>
  );
}

const Container = styled('div')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  padding: 30px 82px;
  min-height: 458px;
  margin-bottom: 40px;
  box-shadow: ${({ theme }) => theme.shadow.widgets};
`;

const MainContentContainer = styled(Stack)`
  max-width: ${COMPANY_MAIN_CONTENT_WIDTH}px;
  margin: 0 auto;
  height: 100%;
`;
