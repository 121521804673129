import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { FilterFeatureOption } from '@/components/filter-feature-select/filter-feature-option';
import { Z_INDEX_TABS_CONTAINER } from '@/theme/z-index';
import type { ChartData } from '@/ui/chart/rounded-bar-chart';
import { RoundedBarChart } from '@/ui/chart/rounded-bar-chart';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import type { TabConfig } from '@/ui/tabs/tabs';
import { Tabs } from '@/ui/tabs/tabs';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { Tab } from '@/ui/typography/action-panel';
import { Header, ParagraphSmall } from '@/ui/typography/widgets';

import { BlockContainer } from '../block-container';

import type { SelectedDateRange } from './components/companies-in-project-date-range-selector';
import { CompaniesInProjectDateRangeSelector } from './components/companies-in-project-date-range-selector';
import {
  formatCustomDateRangeLabel,
  formatDateToAPIRequestFormat,
  isCustomDateRange,
} from './helpers/parse-date-range';
import { useFunnelData } from './hooks/use-funnel-data';
import { useRedirectToCompanies } from './hooks/use-redirect-to-companies';
import { useSnapshotData } from './hooks/use-snapshot-data';
import type { ChartType } from './companies-in-project.state';
import {
  companiesInProjectActiveTabState,
  companiesInProjectSelectedDateRangeTypeState,
  definedDateRangeTypes,
} from './companies-in-project.state';
import { CompaniesInProjectEmptyStateCharts } from './companies-in-project-empty-state-charts';
import { NotRelevantCounter } from './not-relevant-counter';

export const CompaniesInProjectCharts = () => {
  const { t } = useTranslation('companies', { keyPrefix: 'charts' });

  const [activeTab, setActiveTab] = useRecoilState(
    companiesInProjectActiveTabState,
  );
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  const { data: snapshotData, isLoading: loadingSnapshot } = useSnapshotData();
  const { data: funnelData, isLoading: loadingFunnel } = useFunnelData();

  const { getRedirectToFilteredCompaniesLink } = useRedirectToCompanies();

  const [
    companiesInProjectSelectedDateRange,
    setCompaniesInProjectSelectedDateRange,
  ] = useRecoilState(companiesInProjectSelectedDateRangeTypeState);

  const chartTabs: ChartType[] = ['snapshot', 'funnel'];

  const funnel = funnelData ?? null;
  const snapshot = snapshotData ? snapshotData.snapshot : null;

  const totalNotRelevantCount = snapshotData
    ? snapshotData.totalNotRelevantCount
    : 0;

  const chartsData: Record<ChartType, ChartData | null> = {
    snapshot: snapshot ?? [],
    funnel: funnel ?? [],
  };

  const tabsConfig: TabConfig<ChartType>[] = chartTabs.map(tab => ({
    id: tab,
    title: <Tab>{t(tab)}</Tab>,
    content: (
      <RoundedBarChart
        data={chartsData[tab]}
        isLoading={tab === 'snapshot' ? loadingSnapshot : loadingFunnel}
        getBarLink={
          tab === 'snapshot' ? getRedirectToFilteredCompaniesLink : undefined
        }
      />
    ),
  }));

  if (
    (!snapshot?.length && !loadingSnapshot) ||
    (!funnel?.length && !loadingFunnel)
  ) {
    return <CompaniesInProjectEmptyStateCharts />;
  }

  const handleCalendarClose = ({ from, to }: SelectedDateRange) => {
    setCompaniesInProjectSelectedDateRange({
      from: formatDateToAPIRequestFormat(from),
      to: formatDateToAPIRequestFormat(to),
    });
    setIsCalendarOpened(false);
  };

  const getRangeSelectorLabel = () => {
    if (isCustomDateRange(companiesInProjectSelectedDateRange)) {
      return formatCustomDateRangeLabel(companiesInProjectSelectedDateRange);
    }

    return t('dateRangeDropdown.' + companiesInProjectSelectedDateRange);
  };

  const menu: DropdownOption[] = definedDateRangeTypes.map(dateRange => ({
    label: (
      <MenuOption
        label={t('dateRangeDropdown.' + dateRange)}
        icon={
          companiesInProjectSelectedDateRange === dateRange
            ? 'CheckMark'
            : undefined
        }
      />
    ),
    onClick: () => setCompaniesInProjectSelectedDateRange(dateRange),
  }));

  menu.unshift({
    label: <MenuOption label={t`dateRangeDropdown.custom`} icon="ArrowLeft" />,
    onClick: () => setIsCalendarOpened(true),
  });

  return (
    <BlockContainer
      dataTestId="companies-in-projects-widget"
      header={
        <Flexbox
          name="companies-in-projects-header-container"
          justify="space-between"
        >
          <Header>{t`header`}</Header>
          {activeTab === 'funnel' ? (
            <CompaniesInProjectDateRangeSelector
              menu={menu}
              isCalendarOpened={isCalendarOpened}
              setIsCalendarOpened={isCalendarOpened =>
                setIsCalendarOpened(isCalendarOpened)
              }
              onCalendarClose={handleCalendarClose}
              dateRangeSelectorLabel={getRangeSelectorLabel()}
            />
          ) : (
            <RealTimeDataInfo>{t`realTimeData`}</RealTimeDataInfo>
          )}
        </Flexbox>
      }
    >
      <Container margin="10px 0 0 0" gap="25px">
        <ParagraphSmall>{t`text`}</ParagraphSmall>
        <SnapshotFunnelTooltip />

        <Tabs<ChartType>
          tabsConfig={tabsConfig}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          border
          width="max-content"
          noShadow
          padding="0 32px"
        />
      </Container>

      <NotRelevantCounter count={totalNotRelevantCount} />
    </BlockContainer>
  );
};

const Container = styled(Stack)`
  position: relative;
`;

const MenuOption = styled(FilterFeatureOption)`
  width: 160px;
`;

const RealTimeDataInfo = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.gray.c12};
`;

const SnapshotFunnelTooltip = () => {
  const { t } = useTranslation('companies', { keyPrefix: 'charts' });

  return (
    <PositionedIconContainer>
      <Tooltip
        visible
        content={
          <>
            <p>{t`tooltip.snapshot`}</p>
            <p>{t`tooltip.funnel`}</p>
          </>
        }
      >
        <Icon icon="Info" />
      </Tooltip>
    </PositionedIconContainer>
  );
};

const PositionedIconContainer = styled('div')`
  position: absolute;
  top: 62px;
  left: 200px;
  z-index: ${Z_INDEX_TABS_CONTAINER + 1};
  ${({ theme }) => colorIcon(theme.colors.gray.c12)};
`;
