import { useTranslation } from 'react-i18next';

import type { Project } from '@/api/v4/projects.api';
import { ProjectStatus } from '@/api/v4/projects.api';
import { isOption } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import type { OptionType } from '@/ui/select/async/use-select-type';

import { projectStatusOptions } from './project-status-options';

interface DropdownStatusProps {
  status: Project['status'];
  onChange: (status: ProjectStatus | null) => void;
  readOnly?: boolean;
}

interface OptionProjectStatus extends Omit<OptionType, 'value'> {
  value: ProjectStatus;
}

export function DropdownStatus({
  status,
  onChange,
  readOnly = false,
}: DropdownStatusProps) {
  const { t } = useTranslation('projects');

  const isProjectStatusType = (
    option: unknown,
  ): option is OptionProjectStatus => {
    if (!isOption(option)) return false;

    const arr: string[] = Object.values(ProjectStatus);
    return arr.includes(option.value);
  };

  const handleOnChange = async (option: unknown) => {
    if (option !== null && !isProjectStatusType(option)) return;
    const status = option?.value || null;
    onChange(status);
  };

  const defaultValue = projectStatusOptions.find(item => item.value === status);

  return (
    <AsyncSelect
      defaultValue={defaultValue ?? null}
      readOnlyValue={defaultValue?.label}
      setOptions={projectStatusOptions}
      label={t`projectOverview.status`}
      controlWidth="130px"
      onChange={handleOnChange}
      placeholder={t`projectOverview.select`}
      readOnly={readOnly}
    />
  );
}
