import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';

export const OverviewPageContainer = styled(Flexbox)`
  position: relative;
  padding: 10px 80px 58px 80px;
  height: 100%;
  overflow-x: hidden;
`;
