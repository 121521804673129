import type { EntityTag } from '@/features/tags/tags';
import { Icon } from '@/ui/icons/icon';
import { GeneralTag } from '@/ui/tag/general-tag';

import { getCategoryIcon } from '../../../features/modals/manage-tags-modal/helpers/get-category-icon';

import { TagsOverflowContainer } from './tags-overflow-container';

export const TagsWidget = ({
  tags,
  onEditClick,
  subHeader,
  widgetHeight,
  disabled = false,
  showEditStamp,
  crunchbasePermalink,
  testId,
  className,
}: {
  tags: EntityTag[];
  onEditClick: () => void;
  subHeader?: string;
  widgetHeight: number;
  disabled?: boolean;
  showEditStamp?: boolean;
  crunchbasePermalink: string | null;
  testId?: string;
  className?: string;
}) => {
  return (
    <TagsOverflowContainer
      dataTestId={testId}
      onEditClick={onEditClick}
      subHeader={subHeader}
      widgetHeight={widgetHeight}
      disabled={disabled}
      showEditStamp={showEditStamp}
      editStampProps={{ crunchbasePermalink }}
      className={className}
    >
      {tags.map(tag => (
        <GeneralTag key={tag.id} variant="blue" size="l">
          {tag.name.toLowerCase()}
          {tag.category && (
            <Icon
              icon={getCategoryIcon(tag.category.name)}
              height="18px"
              width="18px"
            />
          )}
        </GeneralTag>
      ))}
    </TagsOverflowContainer>
  );
};
