import type { Project } from '@/api/v4/projects.api';
import { useTranslation } from 'react-i18next';
import { useUpdateProject } from '../use-update-project.mutation';
import { SubHeaderBold } from '@/ui/typography/widgets';
import SvgImpact from '@/assets/widgets/empty-state-other.svg';
import { ImpactWidget } from '@/components/widgets/impact-widget/impact-widget';
import { SkeletonWidget } from './project-sidebar';

export const ProjectImpactWidget = ({
  isDataLoading,
  projectId,
  impacts,
  hasEditPermission,
  widgetHeight,
  widgetWidth,
  className,
}: {
  isDataLoading: boolean;
  projectId?: Project['id'];
  impacts?: Project['impacts'];
  hasEditPermission: boolean;
  widgetHeight: number;
  widgetWidth: number;
  className?: string;
}) => {
  const { t } = useTranslation('projects');
  const { editProject } = useUpdateProject();

  if (isDataLoading || !projectId) {
    return <SkeletonWidget width={widgetWidth} />;
  }

  const handleEdit = async (impactIds: string[]) => {
    await editProject({ projectId, impactIds });
  };
  return (
    <ImpactWidget
      dataTestId="impact-widget"
      height={widgetHeight}
      maxWidth={widgetWidth}
      width={widgetWidth}
      defaultValue={impacts}
      header={<SubHeaderBold>{t`projectOverview.impact`}</SubHeaderBold>}
      emptyStateOptions={{
        label: t`projectOverview.emptyState.labelImpact`,
        linkText: t`projectOverview.emptyState.linkTextImpact`,
        image: SvgImpact,
        maxWidth: widgetWidth,
      }}
      onSave={handleEdit}
      disabled={!hasEditPermission}
      className={className}
    />
  );
};
