import { forwardRef, useRef } from 'react';
import ReactCurrencyInput from 'react-currency-input-field';
import { styled } from 'goober';

import { getAbbreviateNumber } from '@/helpers/format-number';

import { Flexbox } from '../../ui/flexbox/flexbox';
import { inputContainerMixin, inputMixin } from '../../ui/input/input-mixin';

interface CurrencyInputProps {
  value: string | null;
  isEditMode?: boolean;
  isEmptyWidget?: boolean;
  placeholder?: string;
  onChange: (value: string | null) => void;
  width?: string;
  allowDecimals?: boolean;
}

export function CurrencyInput({
  value,
  onChange,
  isEditMode = false,
  isEmptyWidget = true,
  placeholder = '550,000,000',
  width,
  allowDecimals = true,
}: CurrencyInputProps) {
  const ref = useRef<HTMLInputElement>(null);

  const handleOnClick = () => {
    ref.current?.focus();
  };

  if (!isEditMode && !isEmptyWidget) {
    return <Value>{!value ? 'N/A' : `$${getAbbreviateNumber(value)}`}</Value>;
  }

  return (
    <CurrencyInputContainer
      name="currency-container"
      isDisabled={!isEditMode}
      onClick={handleOnClick}
      width={width}
    >
      <StyledCurrencyInput
        ref={ref}
        placeholder={placeholder}
        value={value ?? undefined}
        decimalsLimit={2}
        allowDecimals={allowDecimals}
        onValueChange={value => onChange(value ?? null)}
        maxLength={12}
      />
      <Currency name="currency-indicator" alignItems="center" justify="center">
        $
      </Currency>
    </CurrencyInputContainer>
  );
}
const CurrencyInputContainer = styled(Flexbox)<{
  isDisabled: boolean;
  width?: string;
}>`
  ${({ theme }) => inputContainerMixin(theme)}
  gap: 4px;
  padding: 0;
  width: ${({ width = '176px' }) => width};
  height: 48px;
  border-color: ${({ theme }) => theme.colors.gray.c2};
  border-radius: 12px;
  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}

  &:hover {
    border-color: ${({ theme }) => theme.colors.blue.c3};
  }
`;

const StyledCurrencyInput = styled(ReactCurrencyInput, forwardRef)`
  ${({ theme }) => inputMixin(theme)}
  ${({ theme }) => theme.typography.widget.paragraphMedium}
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.basics.black};
  min-width: 0;

  &:focus-within {
    caret-color: ${({ theme }) => theme.colors.blue.primaryA};
  }
`;

const Currency = styled(Flexbox)`
  ${({ theme }) => theme.typography.widget.paragraphMedium}
  width: 42px;
  min-width: 42px;
  flex-grow: 1;
  height: 40px;
  border-left: 2px solid ${({ theme }) => theme.colors.gray.c7};
  color: ${({ theme }) => theme.colors.gray.c7};

  :not(:placeholder-shown) + &,
  :not(:placeholder-shown):focus-within + & {
    color: ${({ theme }) => theme.colors.basics.black};
  }
`;

const Value = styled('div')`
  ${({ theme }) => theme.typography.widget.header}
  color: ${({ theme }) => theme.colors.blue.primaryB};
  text-transform: uppercase;
`;
