import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { useDebounce } from '@/hooks/use-debounce';
import { Input } from '@/ui/input/input';

import { ActivateFiltersButton } from './activate-filters-button';

export const FilterSearchInput = ({
  placeholder,
  onChange,
  onRemove,
  onInputActivate,
  onInputDeactivate,
  isInputActivated,
  defaultValue,
  disabled,
}: {
  placeholder: string;
  onChange: (searchPhrase: string) => void;
  onRemove: () => void;
  onInputActivate: () => void;
  onInputDeactivate: () => void;
  isInputActivated: boolean;
  defaultValue: string;
  disabled: boolean;
}) => {
  const { t } = useTranslation('default');
  const handleInputChange = useDebounce(onChange);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlurInput = () => {
    if (!inputRef.current) return;

    if (inputRef.current.value === '') {
      onInputDeactivate();
    }
  };

  const clearInput = () => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
    onRemove();
  };

  const currentValue = inputRef.current ? inputRef.current.value : '';

  return isInputActivated ? (
    <SearchInput
      ref={inputRef}
      placeholder={placeholder}
      onChange={e => handleInputChange(e.target.value)}
      onClearInput={clearInput}
      autoFocus
      onBlur={handleBlurInput}
      showClearIcon={currentValue.length > 0}
      defaultValue={defaultValue}
      data-testid="filter-search-input"
      padding="4px 7px"
      fontSize="14px"
    />
  ) : (
    <ActivateFiltersButton
      variant="text"
      startIcon="SearchFilter"
      onClick={onInputActivate}
      disabled={disabled}
    >
      {t`search`}
    </ActivateFiltersButton>
  );
};

const SearchInput = styled(Input, forwardRef)`
  width: 250px;
`;
