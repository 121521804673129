import { useRef } from 'react';

export const useLinkInputForm = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isInputDirty = Boolean(inputRef.current?.value);

  const clearInput = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = '';
  };

  return {
    inputRef,
    isInputDirty,
    clearInput,
  };
};
