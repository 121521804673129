import { useRecoilState, useRecoilValue } from 'recoil';

import { useCurrentUser } from '@/user/use-current-user.query';

import { projectIdState, projectOverviewExpandedState } from '../project.state';

export const useExpandProjectOverviewPart = () => {
  const { data: user } = useCurrentUser();
  const projectId = useRecoilValue(projectIdState);

  const [isExpanded, setIsExpanded] = useRecoilState(
    projectOverviewExpandedState([
      projectId ?? '',
      user ? user.id.toString() : '',
    ]),
  );

  const toggleExpand = () => setIsExpanded(prevValue => !prevValue);

  return {
    isExpanded,
    toggleExpand,
  };
};
