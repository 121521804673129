import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import type { UsersListSort } from '@/api/v4/user.api';
import { Loader } from '@/components/loader/loader';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { AppUsersTable } from '../../app-users/app-users-table';
import { AppUserEmptyState } from '../../app-users/empty-state';
import { AppUsersTableNoResults } from '../../app-users/no-results';
import { UserSearch } from '../../app-users/user-search';
import {
  settingsUsersLoadingState,
  settingsUsersSearchPhraseState,
  settingsUsersSortState,
} from '../settings.state';

import { useActiveAndDisabledUsers } from './use-active-and-disabled-users';
import { useUserManagementTableConfiguration } from './use-user-management-table-configuration';

export const UserManagement = () => {
  const { t } = useTranslation('settings');
  const { hasRequiredPermission } = useUserPermissions();
  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );

  const { users, isLoading } = useActiveAndDisabledUsers();

  const [userSearchPhrase, setUserSearchPhrase] = useRecoilState(
    settingsUsersSearchPhraseState,
  );

  const isUsersTableLoading = useRecoilValue(settingsUsersLoadingState);
  const resetUsersSearchPhrase = useResetRecoilState(
    settingsUsersSearchPhraseState,
  );

  const { header, rows } = useUserManagementTableConfiguration(users);

  return (
    <>
      {isUsersTableLoading && <Loader isFullPage />}
      <UserSearch
        onSearchInputChange={e => {
          setUserSearchPhrase(e.target.value);
        }}
        translationNamespace={'settings'}
      />
      <AppUsersTable<UsersListSort>
        users={users}
        withSearchPhrase={Boolean(userSearchPhrase)}
        hasNextPage={false}
        sortState={settingsUsersSortState}
        isLoading={isLoading}
        emptyStateElement={
          <AppUserEmptyState
            onClick={() => void 0}
            buttonText={t`addUser`}
            text={t`emptyUsers`}
            disabled={!hasManageTeamPermission}
          />
        }
        noResultsElement={
          <AppUsersTableNoResults
            onReset={resetUsersSearchPhrase}
            resetResultsText={t`resetResults`}
            noResultsText={t`noResults`}
          />
        }
        header={header}
        rows={rows}
      />
    </>
  );
};
