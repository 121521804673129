import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ProtectedAppLayout } from './protect/protected-app-layout';
import { useAuthorization } from '@/auth/hooks/use-authorization';
import protect from './protect/protect';
import { PRODUCT } from '@/user/products/product.type';
import { PERMISSION } from '@/user/permissions/permission.type';
import { AuthPageLayout } from '@/auth/ui/auth-page-layout';
import { PageNotFound } from './page-not-found';
import { ApiProvider } from '@/api/provider/provider';
import { AppConfigProvider } from './app-config-provider';
import { userLoader } from '@/user/user-loader';
import { useQueryClient } from '@tanstack/react-query';
import { SignInPage } from '@/auth/sign-in.page';
import { SignUpInvitationExpiredPage } from '@/auth/sign-up-invitation-expired.page';
import { SignUpPage } from '@/auth/sign-up.page';
import { ExploreFormPage } from '@/features/explore/explore-form.page';
import { ExploreResultsPage } from '@/features/explore/explore-results.page';
import { HomePage } from '@/features/home/home.page';
import { SettingsPage } from '@/features/settings/settings.page';
import { Companies } from './companies';
import { Projects } from './projects';
import { Teams } from './teams';

export const useRouter = () => {
  const { isUserAuthorized } = useAuthorization();
  const queryClient = useQueryClient();

  const router = createBrowserRouter([
    {
      element: <AppConfigProvider />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: '/',
          element: (
            <ApiProvider>
              <ProtectedAppLayout isUserAuthorized={isUserAuthorized} />
            </ApiProvider>
          ),
          children: [
            { index: true, element: <Navigate to="home" /> },
            {
              path: 'home',
              element: <HomePage />,
              loader: isUserAuthorized && userLoader(queryClient),
            },
            {
              path: 'companies/*',
              element: <Companies />,
            },
            {
              path: 'projects/*',
              element: <Projects />,
            },
            {
              path: 'teams/*',
              element: <Teams />,
            },
            {
              path: 'explore/results/:requestId?',
              element: protect(<ExploreResultsPage />, {
                products: [PRODUCT.EXPLORE],
              }),
            },
            {
              path: 'settings',
              element: <SettingsPage />,
            },
          ],
        },
        {
          element: (
            <ProtectedAppLayout
              isUserAuthorized={isUserAuthorized}
              withTopbar={false}
            />
          ),
          children: [
            {
              path: 'explore',
              element: protect(
                <ExploreFormPage />,
                {
                  permissions: [PERMISSION.ADD_COMPANY_TO_STREAM],
                  products: [PRODUCT.CRUNCHBASE, PRODUCT.EXPLORE],
                },
                true,
              ),
            },
          ],
        },
        {
          path: 'auth',
          element: <AuthPageLayout isUserAuthorized={isUserAuthorized} />,
          children: [
            {
              path: 'signin',
              element: <SignInPage />,
            },
            {
              path: 'signup',
              element: <SignUpPage />,
            },
            {
              path: 'invitation-expired',
              element: <SignUpInvitationExpiredPage />,
            },
          ],
        },
      ],
    },
  ]);

  return router;
};
