import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  appliedTeamsFiltersState,
  defaultAppliedTeamsFilters,
} from '../team.state';

import { extractTeamSearchFilters } from './teams-filters-helpers';
import { useLocation } from 'react-router-dom';

export const useApplyTeamFiltersFromUrl = ({
  isPrefetching,
  onFiltersApply,
}: {
  isPrefetching: boolean;
  onFiltersApply?: () => void;
}) => {
  const location = useLocation();

  const setAppliedFilters = useSetRecoilState(appliedTeamsFiltersState);

  useEffect(() => {
    if (!location.search || isPrefetching) return;

    const filtersFromUrl = extractTeamSearchFilters(location.search);

    const filtersToApply = Object.entries(filtersFromUrl).reduce(
      (acc, [key, value]) => {
        if (!(key in defaultAppliedTeamsFilters)) return acc;

        // handled keys: search
        return { ...acc, [key]: value };
      },
      defaultAppliedTeamsFilters,
    );

    setAppliedFilters(filtersToApply);
    onFiltersApply?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefetching]);
};
