import { format, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';

import { CALENDAR_DATE_FORMAT } from '@/constants';
import { formatDate } from '@/helpers/format-date';
import { isOfType } from '@/helpers/other';

import type {
  CompaniesInProjectCustomDateRange,
  CompaniesInProjectDateRangeType,
} from '../companies-in-project.state';

export const parseDateRangeTypeToParams = (
  dateRange: CompaniesInProjectDateRangeType,
): CompaniesInProjectCustomDateRange | undefined => {
  if (isCustomDateRange(dateRange)) {
    return dateRange;
  }

  const today = new Date();
  switch (dateRange) {
    case 'thisMonth': {
      const from = startOfMonth(today);
      return {
        from: formatDateToAPIRequestFormat(from),
        to: formatDateToAPIRequestFormat(today),
      };
    }
    case 'thisQuarter': {
      const from = startOfQuarter(today);
      return {
        from: formatDateToAPIRequestFormat(from),
        to: formatDateToAPIRequestFormat(today),
      };
    }
    case 'thisYear': {
      const from = startOfYear(today);
      return {
        from: formatDateToAPIRequestFormat(from),
        to: formatDateToAPIRequestFormat(today),
      };
    }

    case 'allProjects':
    default:
      return undefined;
  }
};

export const formatDateToAPIRequestFormat = (date: Date) => {
  return format(date, CALENDAR_DATE_FORMAT);
};

export const formatCustomDateRangeLabel = (
  dateRange: CompaniesInProjectCustomDateRange,
) => formatDate(dateRange.from) + ' - ' + formatDate(dateRange.to);

export function isCustomDateRange(
  dateRange: CompaniesInProjectDateRangeType,
): dateRange is CompaniesInProjectCustomDateRange {
  return (
    dateRange !== null &&
    typeof dateRange !== 'string' &&
    isOfType<CompaniesInProjectCustomDateRange>(dateRange, 'to') &&
    isOfType<CompaniesInProjectCustomDateRange>(dateRange, 'from')
  );
}
