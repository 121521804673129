interface AdjustFontSizeProps {
  el: HTMLTextAreaElement;
  baseFontSize: number;
  value: string;
  previousValue: string;
}

export function handleFontSize(options: AdjustFontSizeProps) {
  const { el, value, previousValue, baseFontSize } = options;
  const elStyles = getComputedStyle(el);
  const vPadding =
    parseFloat(elStyles.paddingTop) + parseFloat(elStyles.paddingBottom);
  let newFontSize = parseInt(elStyles.fontSize, 10);

  if (value.length > (previousValue ?? '').length) {
    while (el.scrollHeight > el.clientHeight + vPadding) {
      newFontSize--;
      el.style.fontSize = `${newFontSize}px`;
    }
  }

  if (value.length < (previousValue ?? '').length) {
    while (
      el.scrollHeight <= el.clientHeight + vPadding &&
      newFontSize < baseFontSize
    ) {
      newFontSize++;
      el.style.fontSize = `${newFontSize}px`;
    }
  }

  while (el.scrollHeight > el.clientHeight + vPadding) {
    newFontSize--;
    el.style.fontSize = `${newFontSize}px`;
  }
}
