import type { ClientOption } from '@/types';
import type { Client } from '../client.api';

export const mapClientsToOptions = (clients: Client[]): ClientOption[] => {
  return clients.map(client => ({
    value: client.uniqueId,
    label: client.name,
    clientId: client.clientId,
    crunchbaseId: client.crunchbaseId,
    logo: client.logoUrl,
    source: client.source,
  }));
};
