import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled } from 'goober';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import type { Project } from '@/api/v4/projects.api';
import { OverviewPageContainer } from '@/components/layout/overview-page-container';
import { formatDate } from '@/helpers/format-date';
import { generateUntitledEntityName } from '@/helpers/generate-untitled-entity-name';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphSmall } from '@/ui/typography/widgets';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';

import { useActionPanel } from '../action-panel/use-action-panel';

import { ProjectOverviewContent } from './overview/project-overview-content';
import { useProject } from './overview/use-project.query';
import { useUpdateProject } from './overview/use-update-project.mutation';
import { projectFormState, projectIdState } from './project.state';
import { useCreateProject } from './use-create-project';
import { useActionPanelSearchParams } from '../action-panel/use-action-panel-search-params';
import { ProjectSidebar } from './overview/sidebar/project-sidebar';
import { useProjectSidebar } from './overview/sidebar/use-project-sidebar';

interface ProjectOverviewPageProps {
  createNew?: boolean;
}

export const ProjectOverviewPage = ({
  createNew = false,
}: ProjectOverviewPageProps) => {
  const { projectId } = useParams();
  const { t } = useTranslation('projects');
  const { editProject } = useUpdateProject();
  const { createProject } = useCreateProject();
  const resetProjectForm = useResetRecoilState(projectFormState);
  const {
    closeActionPanel,
    openActionPanelOnSearchParamTab,
    isAlreadyOpenedOnLoad,
    setIsOpenOnLoad,
  } = useActionPanel();
  const setProjectId = useSetRecoilState(projectIdState);
  const { data: fetchedProject, isLoading, isFetched } = useProject();
  const navigateTo = useNavigateTo();
  const [projectForm, setProjectForm] = useRecoilState(projectFormState);
  const { actionPanelSearchParam } = useActionPanelSearchParams();
  const { isOpen, handleChangeToggleProjectSidebar } = useProjectSidebar();

  useEffect(() => {
    if (
      !actionPanelSearchParam ||
      (actionPanelSearchParam && !isFetched) ||
      isAlreadyOpenedOnLoad
    ) {
      return;
    }

    openActionPanelOnSearchParamTab(actionPanelSearchParam, {
      context: 'project',
      title: fetchedProject?.name ?? '',
    });

    setIsOpenOnLoad(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, isAlreadyOpenedOnLoad]);

  useEffect(() => {
    setProjectId(projectId ?? null);

    return () => {
      closeActionPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (fetchedProject) {
      resetProjectForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedProject]);

  const createNewProject = async (input: string) => {
    const name = input === '' ? generateUntitledEntityName('project') : input;

    setProjectForm(currentForm => ({
      ...currentForm,
      name,
    }));

    const data = await createProject({ name });
    navigateTo.project({ projectId: data.id });
  };

  const generateProjectCreatedBy = (
    project: Pick<Project, 'creator' | 'createdDate'>,
  ) =>
    t('projectOverview.createdBy', {
      userName: project.creator
        ? `${project.creator.firstName} ${project.creator.lastName}`
        : 'user',
      teamName:
        (project.creator?.team?.name && `, ${project.creator?.team.name}`) ||
        '',
      date: project.createdDate ? formatDate(project.createdDate) : undefined,
    });

  return (
    <WithHtmlSavvyTitle
      title={
        fetchedProject
          ? t(`projectOverview.page.title`, {
              projectName: fetchedProject.name,
            })
          : t`projects.project`
      }
    >
      <Relative>
        <OverviewPageContainer
          name="project-page-container"
          direction="column"
          justify="space-between"
        >
          <Main name="project-page-main-content" direction="column">
            {projectId && fetchedProject ? (
              <ProjectOverviewContent
                project={fetchedProject}
                isProjectLoading={isLoading}
                createNew={createNew}
                onHeaderConfirm={name => {
                  if (name === fetchedProject.name) return;

                  return editProject({ projectId, name });
                }}
                onCreatedForChange={async teamId => {
                  await editProject({
                    projectId,
                    teamId,
                  });
                }}
                onCurrencyChange={options =>
                  editProject({
                    projectId,
                    projectBudget: options.projectBudget,
                    externalExpectedCost: options.externalExpectedCost,
                    internalExpectedCost: options.internalExpectedCost,
                  })
                }
                onExpectedRoiChange={expectedRoi =>
                  editProject({ projectId, expectedRoi })
                }
                onImpactChange={impactIds =>
                  editProject({ projectId, impactIds })
                }
                onProblemStatementChange={problemStatement =>
                  editProject({ projectId, problemStatement })
                }
                onProjectKpiChange={projectKpi =>
                  editProject({ projectId, projectKpi })
                }
                onStatusChange={async status => {
                  await editProject({ projectId, status });
                }}
                onTimeframeChange={async range => {
                  await editProject({
                    projectId,
                    timeframeStart: range.timeframeStart,
                    timeframeEnd: range.timeframeEnd,
                  });
                }}
              />
            ) : (
              <ProjectOverviewContent
                project={projectForm}
                createNew={createNew}
                onHeaderConfirm={name => createNewProject(name)}
                onCreatedForChange={() => void 0}
                onCurrencyChange={() => void 0}
                onExpectedRoiChange={() => void 0}
                onImpactChange={() => void 0}
                onProblemStatementChange={() => void 0}
                onProjectKpiChange={() => void 0}
                onStatusChange={() => void 0}
                onTimeframeChange={() => void 0}
              />
            )}
          </Main>
          <Footer>
            <ParagraphSmall>
              {fetchedProject && generateProjectCreatedBy(fetchedProject)}
            </ParagraphSmall>
          </Footer>
        </OverviewPageContainer>

        {isOpen && (
          <ProjectSidebar onClose={handleChangeToggleProjectSidebar} />
        )}
      </Relative>
    </WithHtmlSavvyTitle>
  );
};

const Relative = styled('div')`
  position: relative;
`;

const Main = styled(Flexbox)`
  flex-grow: 1;
`;

const Footer = styled('div')`
  padding-top: 40px;
`;
