import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { QueryKey } from '@/config/query-client';
import { projectIdState } from '../../project.state';
import { fetchProjectExternalLinks } from '@/api/v4/project-external-links.api';

export const useProjectExternalLinks = () => {
  const projectId = useRecoilValue(projectIdState);

  const getProjectExternalLinks = async () => {
    if (!projectId) {
      return [];
    }

    return await fetchProjectExternalLinks(projectId);
  };

  return useQuery(
    [QueryKey.ProjectExternalLinks, projectId],
    getProjectExternalLinks,
    {
      staleTime: getTimeInMs(5, 'minute'),
      cacheTime: getTimeInMs(5, 'minute'),
    },
  );
};
