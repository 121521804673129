import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { formatNotApplicable } from '@/helpers/format-empty';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';

import type { CompanyRatingType } from '../company.state';

import { RankingStarIcon } from './ranking-star-icon';
import { RankingStarInput } from './ranking-star-input';
import { Loader } from '@/components/loader/loader';

const USER_RANKING_STAR_SIZE = '20px';
const AVERAGE_TEXT_HEIGHT = '36px';
const DEFAULT_CHILD_GAP = '36px';
const COMPACT_CHILD_GAP = '4px';

export const CompanyRatingCard = ({
  type,
  rating,
  initialValue,
  onChange,
  loading,
  hideRankingAverage,
}: {
  type: CompanyRatingType;
  rating: number | null;
  initialValue?: number | null;
  onChange: (value: number) => void;
  loading?: boolean;
  hideRankingAverage?: boolean;
}) => {
  const { t } = useTranslation('companies');

  return (
    <RankingCardContainer data-testid={`ranking-card-container-${type}`}>
      <Stack gap={hideRankingAverage ? COMPACT_CHILD_GAP : DEFAULT_CHILD_GAP}>
        <Title>{t(`ranking.${type}`)}</Title>
        {!hideRankingAverage &&
          (loading ? (
            <Loader />
          ) : (
            <AveratingRatingContainer name="ranking-card-average" gap="8px">
              <AveratingRating data-testid="average-rating">
                {formatNotApplicable(rating?.toFixed(1))}
              </AveratingRating>
              <RankingStarIcon filled size={USER_RANKING_STAR_SIZE} />
            </AveratingRatingContainer>
          ))}
        <Flexbox
          name="ranking-user-input"
          gap="15px"
          alignItems="center"
          justify="space-between"
          wrap="wrap"
        >
          <YourRanking>{t`ranking.yourRanking`}</YourRanking>
          <RankingStarInput
            onChange={onChange}
            initialValue={initialValue ? Number(initialValue) : undefined}
          />
        </Flexbox>
      </Stack>
    </RankingCardContainer>
  );
};

const RankingCardContainer = styled('div')`
  width: 100%;
  padding: 32px;
  border-radius: 20px;
  background-color: ${({ theme }) =>
    theme.palettes.component.company.ranking.cardBackground};
`;

const AveratingRatingContainer = styled(Flexbox)`
  height: ${AVERAGE_TEXT_HEIGHT};
`;

const Title = styled('p')`
  ${({ theme }) => theme.typography.companyRanking.card.title};
`;

const YourRanking = styled('p')`
  ${({ theme }) => theme.typography.companyRanking.card.yourRanking};
`;

const AveratingRating = styled('p')`
  ${({ theme }) => theme.typography.companyRanking.card.averageRating};
`;
