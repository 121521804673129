import { styled } from 'goober';
import { forwardRef, useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import type { User, UsersListSort } from '@/api/v4/user.api';
import { getUserInitials } from '@/helpers/get-user-initials';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { APP_USER_ROLES } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { CircleIcon } from '@/ui/icons/circle';
import { Stack } from '@/ui/line/line';
import { Link } from '@/ui/link/link';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import type { Row } from '@/ui/table/table.types';
import { useCurrentUser } from '@/user/use-current-user.query';
import { RoleDropdownSelect } from '../role-dropdown-select';
import type { UserRoleOption } from '../settings.state';
import { settingsUsersLoadingState } from '../settings.state';
import { UserStatusSwitch } from '../user-status-switch';

import { UserName } from '../../../ui/typography/across-platform';
import { useActiveAndDisabledUsers } from './use-active-and-disabled-users';
import { UserManagementActionsMenu } from './user-management-actions-menu';
import { userManagementTableHeaders } from './user-management-table-configuration';
import { fetchSharedCompanies } from '@/api/v4/shared-organizations';
import { fetchSharedProjects } from '@/api/v4/shared-projects';

export const useUserManagementTableConfiguration = (users: User[]) => {
  const header = useTranslateHeaders<UsersListSort>(
    userManagementTableHeaders,
    'settings',
  );
  const { refreshUsers } = useActiveAndDisabledUsers();
  const { data: appRoles } = useRoles();

  const { data: currentUser } = useCurrentUser();
  const setModal = useSetRecoilState(modalState);
  const setIsUsersTableLoading = useSetRecoilState(settingsUsersLoadingState);

  const getUserRole = useCallback(
    (roleId: string): UserRoleOption => {
      const appRoleById = appRoles?.find(role => role.id === roleId);

      const guestRole = appRoles?.find(
        role => role.name === APP_USER_ROLES.GUEST,
      );

      return {
        label: (appRoleById?.name as APP_USER_ROLES) || APP_USER_ROLES.GUEST,
        id: appRoleById?.id || guestRole?.id || '',
      };
    },
    [appRoles],
  );

  const getLinkedEntitiesStatus = async (email: string) => {
    const sharedCompanies = await fetchSharedCompanies({
      uid: email,
      items: 20,
      page: 1,
      explicitlySharedOnly: true,
    });

    const sharedProjects = await fetchSharedProjects({
      uid: email,
      items: 20,
      page: 1,
      explicitlySharedOnly: true,
    });

    return sharedCompanies.length > 0 || sharedProjects.length > 0;
  };

  const onRoleSelectorChange = useCallback(
    async (role: UserRoleOption, user: User) => {
      setIsUsersTableLoading(true);
      const hasLinkedEntities = await getLinkedEntitiesStatus(user.email);

      setModal({
        state: 'changeUserRole',
        userId: user.id,
        userDisplayName: `${user.firstName} ${user.lastName}`,
        currentRole: getUserRole(user.role.id),
        newRole: getUserRole(role.id),
        hasLinkedEntities,
      });

      setIsUsersTableLoading(false);
    },
    [getUserRole, setIsUsersTableLoading, setModal],
  );

  const rows: Row[] = useMemo(() => {
    return users.map(user => {
      const actionDisabled = Number(currentUser?.id) === user.id;
      return {
        id: `${user.id}`,
        cells: [
          {
            value: (
              <Flexbox name="app-user-base-info" alignItems="center" gap="22px">
                <CircleIcon
                  bgColor={colors.blue.primaryA}
                  label={getUserInitials(user)}
                />
                <Stack gap="4px">
                  <EllipsisTextTooltip
                    text={`${user.firstName} ${user.lastName}`}
                    Component={<CellText data-testid="user-name" />}
                  />
                  {user.team && (
                    <EllipsisTextTooltip
                      text={user.team.name}
                      Component={
                        <TeamNameLink
                          to={paths.team({ teamId: user.team.id })}
                          color={colors.gray.c10}
                        />
                      }
                    />
                  )}
                </Stack>
              </Flexbox>
            ),
          },
          {
            value: (
              <EllipsisTextTooltip
                text={user.email}
                Component={<CellText data-testid="user-email" />}
              />
            ),
          },
          {
            value: (
              <RoleDropdownSelect
                user={user}
                isDisabled={actionDisabled}
                onChange={role => onRoleSelectorChange(role, user)}
                getUserRole={getUserRole}
              />
            ),
            align: 'left',
            padding: '0 20px',
            minWidth: '170px',
          },
          {
            value: (
              <UserStatusSwitch
                isActive={user.status === 'active'}
                userId={user.id}
                onSuccess={refreshUsers}
                isDisabled={actionDisabled}
              />
            ),
            align: 'left',
            padding: '0 20px',
            width: '20px',
          },
          {
            value: <UserManagementActionsMenu user={user} />,
            verticalAlign: 'middle',
            padding: '0',
            width: '5px',
          },
        ],
      };
    });
  }, [getUserRole, onRoleSelectorChange, refreshUsers, currentUser?.id, users]);

  return {
    rows,
    header,
  };
};

const TeamNameLink = styled(Link)`
  max-width: 160px;
  ${({ theme }) => theme.mixins.ellipsis}
  display: block;
`;

const CellText = styled(UserName, forwardRef)`
  max-width: 400px;
  ${({ theme }) => theme.mixins.ellipsis}
`;
