import type { DescopeError, DescopeFlowErrorEvent } from '../types';

export const getDescopeError = (
  event: string | Event | DescopeFlowErrorEvent | DescopeError,
) => {
  if (typeof event === 'object' && 'detail' in event) {
    return event.detail;
  }

  if (typeof event === 'object' && 'error' in event) {
    return event.error;
  }

  return null;
};
