import { colors } from '@/theme/colors';
import type { Variant } from '@/ui/button/button';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphSmall } from '@/ui/typography/widgets';

export const AppUserEmptyState = ({
  onClick,
  buttonText,
  text,
  variant = 'primary',
  disabled = false,
}: {
  onClick: () => void;
  buttonText: string;
  text: string;
  variant?: Variant;
  disabled: boolean;
}) => {
  return (
    <Flexbox
      name="app-users-table-empty-container"
      justify="center"
      alignItems="center"
      direction="column"
      flex="1"
      height="100%"
      gap="30px"
    >
      <ParagraphSmall color={colors.gray.c12}>{text}</ParagraphSmall>
      {!disabled && (
        <Button variant={variant} startIcon="Plus" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </Flexbox>
  );
};
