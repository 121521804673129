import { useCompanyProjectsListings } from '@/hooks/queries/use-company-projects-listings.query';

export const useRelatedProjects = (organizationId: string | null) => {
  const { data, isFetching, refetch } =
    useCompanyProjectsListings(organizationId);
  return {
    relatedProjects: data?.listings || [],
    otherProjectsCount: data?.otherProjectsCount || 0,
    isLoading: isFetching,
    refetch,
  };
};
