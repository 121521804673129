import { useQuery } from '@tanstack/react-query';

import { fetchProjects } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';

const ACTIVE_PROJECTS_QUANTITY = 5;

export const useActiveProjects = () => {
  return useQuery([QueryKey.ActiveProjects], () => {
    return fetchProjects({
      items: ACTIVE_PROJECTS_QUANTITY,
      excludeNoActiveListings: true,
      excludeCompletedProjects: true,
    });
  });
};
