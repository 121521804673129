import type { Ref } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetHeaderWithBackground } from '@/features/companies/overview/widget-header-with-background';
import { componentsPalette } from '@/theme/colors';

export const WidgetHeader = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation('companies', { keyPrefix: 'company' });
  const { headerBackgroundColor, headerTextColor } =
    componentsPalette.company.listedInProjects;

  return (
    <WidgetHeaderWithBackground
      backgroundColor={headerBackgroundColor}
      textColor={headerTextColor}
      ref={ref}
    >
      {t`listedIn`}
    </WidgetHeaderWithBackground>
  );
});

WidgetHeader.displayName = 'WidgetHeader';
