import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import type { Team } from '@/api/v4/team.api';
import { AppUserEmptyState } from '@/features/app-users/empty-state';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { StatusTag } from '@/ui/tag/status-tag';
import { ReadMore } from '@/ui/typography/across-platform';
import { Header } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { TeamMembers } from './team-members';
import { parseSearchParams } from '@/routes/helpers/parse-search-params';

export const TeamMembersSection = ({ team }: { team: Team }) => {
  const { t } = useTranslation('teams');

  const setModal = useSetRecoilState(modalState);
  const handleAddMember = () => setModal({ state: 'addTeamMember' });
  const { hasRequiredPermission } = useUserPermissions();
  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );

  return (
    <>
      <Flexbox name="team-members-header" justify="space-between">
        <Flexbox name="team-members-section-title" gap="13px">
          <Header>{t`teamMembers`}</Header>
          <StatusTag variant="blue">{team.teamMembersCount}</StatusTag>
        </Flexbox>
        {hasManageTeamPermission && (
          <Link
            to={paths.settings() + parseSearchParams({ tab: 'userManagement' })}
          >
            <ReadMore label={t`userManagement`} />
          </Link>
        )}
      </Flexbox>
      {team.teamMembersCount === 0 ? (
        <AppUserEmptyState
          onClick={handleAddMember}
          buttonText={t`addMember`}
          text={t`emptyTeamMembers`}
          disabled={!hasManageTeamPermission}
        />
      ) : (
        <TeamMembers team={team} />
      )}
    </>
  );
};
