import { Tab } from '@/ui/typography/action-panel';

import { Flexbox } from '../../ui/flexbox/flexbox';

export const TeamMemberTabControl = ({
  name,
  countIndicator,
}: {
  name: string;
  countIndicator: number;
}) => {
  return (
    <Flexbox
      name="tags-tab-control"
      alignItems="center"
      justify="center"
      height="100%"
      gap="5px"
    >
      <Tab>
        {name} ({countIndicator})
      </Tab>
    </Flexbox>
  );
};
