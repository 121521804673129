import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchFunnel } from '@/api/v4/snapshot-funnel.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companiesInProjectSelectedDateRangeTypeState } from '../companies-in-project.state';
import { parseDateRangeTypeToParams } from '../helpers/parse-date-range';

export const useFunnelData = () => {
  const { t } = useTranslation('default');

  const selectedDateRange = useRecoilValue(
    companiesInProjectSelectedDateRangeTypeState,
  );

  const fetchFunnelData = async () => {
    const params = parseDateRangeTypeToParams(selectedDateRange);

    return fetchFunnel(params);
  };

  return useQuery([QueryKey.Funnel, selectedDateRange], fetchFunnelData, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    staleTime: 1000 * 60 * 5,
  });
};
