import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { fetchCompanyCreators } from '@/api/v4/organization.api';
import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isNumberOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const CreatedByFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const [search, setSearch] = useState<string | undefined>();

  const setCompaniesFiltersOptions = useSetRecoilState(
    companiesFilterOptionsState,
  );
  const setOptions = async () => {
    const creators = await fetchCompanyCreators({
      search,
    });

    const createdByOptions = creators.map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));

    setCompaniesFiltersOptions(currentFilters => ({
      ...currentFilters,
      createdBy: createdByOptions,
    }));

    return createdByOptions;
  };
  return (
    <AsyncSelect
      id="filter-created-by"
      label={t`companies.filters.createdBy`}
      defaultValue={selectedFilters.createdBy}
      setOptions={setOptions}
      className={className}
      isMulti
      isSearchable
      onInputChange={setSearch}
      onChange={option => {
        if (!isNumberOptionArray(option)) return;

        setSelectedFilters(currentFilters => {
          return {
            ...currentFilters,
            createdBy: option.length ? option : null,
          };
        });
      }}
      {...sharedProps}
    />
  );
};
