import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { formatLocation } from '@/helpers/format-location';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SubHeader } from '@/ui/typography/widgets';
import type { CompanyData } from '@/api/v4/organization.api';

export const HeadquartersLocation = ({
  hqLocation,
}: {
  hqLocation?: CompanyData['hqLocation']['value'];
}) => {
  const { t } = useTranslation('companies');

  const locationText = hqLocation
    ? formatLocation(
        {
          cityName: hqLocation.city?.name,
          regionName: hqLocation.region?.name,
          countryName: hqLocation.country?.name,
        },
        { compact: true },
      )
    : t`companies.headquarters`;
  return (
    <StyledHeader isEmpty={!hqLocation}>
      {hqLocation ? (
        <Tooltip
          content={formatLocation({
            cityName: hqLocation.city?.name,
            regionName: hqLocation.region?.name,
            countryName: hqLocation.country?.name,
          })}
        >
          {<p>{locationText}</p>}
        </Tooltip>
      ) : (
        locationText
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled(SubHeader)<{ isEmpty: boolean }>`
  color: ${({ isEmpty, theme }) =>
    isEmpty ? theme.colors.gray.c9 : 'inherit'};
`;
