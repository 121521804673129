import { useQuery } from '@tanstack/react-query';

import { fetchFundingStages } from '@/api/v4/funding-stages.api';
import { QueryKey } from '@/config/query-client';

export const useFundingStages = () => {
  return useQuery([QueryKey.FundingStages], fetchFundingStages, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
