import type { TogglePinnedItemPayload } from '@/api/v4/pinned-items.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { useRecoilValue } from 'recoil';
import { recentItemsTypeState } from './recent-items.state';
import type { LastActivity } from '@/api/v4/last-activities.api';
import type { PinnedItemsContext } from './pinned-items/use-toggle-pinned-item.mutation';
import { orderBy } from 'lodash-es';

export const useOptimisticRecentItemsUpdate = () => {
  const selectedRecentItemsType = useRecoilValue(recentItemsTypeState);

  const updateLastActivities = (activities: LastActivity[]) => {
    queryClient.setQueryData(
      [QueryKey.LastActivities, selectedRecentItemsType],
      activities,
    );
  };

  const handleOptimisticChange = async (data: TogglePinnedItemPayload) => {
    const itemId = data.id;

    await queryClient.cancelQueries({
      queryKey: [QueryKey.LastActivities, selectedRecentItemsType],
    });

    const prevAllLastActivities =
      queryClient.getQueryData<LastActivity[]>([
        QueryKey.LastActivities,
        selectedRecentItemsType,
      ]) || [];

    const newAllLastActivities = prevAllLastActivities.reduce<LastActivity[]>(
      (acc, item) => {
        if (item.id === itemId) {
          acc.unshift({
            ...item,
            isPinned: !item.isPinned,
            lastEditedDate: new Date().toISOString(),
          });
        } else {
          acc.push(item);
        }
        return acc;
      },
      [],
    );

    updateLastActivities(
      orderBy(
        newAllLastActivities,
        ['isPinned', 'lastEditedDate'],
        ['desc', 'desc'],
      ),
    );

    return {
      prevAllLastActivities,
    };
  };

  const rollbackToPreviousData = ({
    prevAllLastActivities,
  }: PinnedItemsContext) => {
    prevAllLastActivities &&
      queryClient.setQueryData(
        [QueryKey.LastActivities, selectedRecentItemsType],
        prevAllLastActivities,
      );
  };

  return {
    handleOptimisticChange,
    rollbackToPreviousData,
  };
};
