import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchTeams } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { prepareTeamApiFilters } from './filters/teams-filters-helpers';
import { appliedTeamsFiltersState, teamsSortState } from './team.state';

export const useTeams = () => {
  const { hasRequiredPermission } = useUserPermissions();
  const appliedFilters = useRecoilValue(appliedTeamsFiltersState);
  const { sort, direction } = useRecoilValue(teamsSortState);
  const filters = prepareTeamApiFilters(appliedFilters);

  return useInfiniteQuery(
    [QueryKey.Teams, sort, direction, filters],
    ({ pageParam = 1 }: QueryFunctionContext) => {
      const params = {
        sort,
        direction,
        page: pageParam,
        items: 20,
        ...filters,
      };
      return fetchTeams(params);
    },
    {
      enabled:
        hasRequiredPermission(PERMISSION.VIEW_ACCOUNT_DETAILS) ||
        hasRequiredPermission(PERMISSION.MANAGE_TEAMS),
      staleTime: 1000 * 10 * 5,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;
        return nextPage;
      },
    },
  );
};
