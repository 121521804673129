import { TextWidget } from '@/components/widgets/text-widget/text-widget';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { useTranslation } from 'react-i18next';
import { useUpdateProject } from '../use-update-project.mutation';
import type { Project } from '@/api/v4/projects.api';
import { SkeletonWidget } from './project-sidebar';

export const ExpectedRoiWidget = ({
  isDataLoading,
  projectId,
  expectedRoi,
  hasEditPermission,
  widgetHeight,
  widgetWidth,
}: {
  isDataLoading: boolean;
  projectId?: Project['id'];
  expectedRoi?: Project['expectedRoi'];
  hasEditPermission: boolean;
  widgetHeight: number;
  widgetWidth: number;
}) => {
  const { t } = useTranslation('projects');
  const { editProject } = useUpdateProject();

  if (isDataLoading || !projectId) {
    return <SkeletonWidget width={widgetWidth / 2} />;
  }

  const handleEdit = async (expectedRoi: string | null) => {
    await editProject({ projectId, expectedRoi });
  };
  return (
    <TextWidget
      dataTestId="expected-roi-widget"
      value={expectedRoi ?? ''}
      height={widgetHeight}
      maxWidth={widgetWidth}
      disableBaseWidth
      header={<SubHeaderBold>{t`projectOverview.expectedRoi`}</SubHeaderBold>}
      placeholder={t`projectOverview.expectedRoiPlaceholder`}
      onSave={handleEdit}
      disabled={!hasEditPermission}
    />
  );
};
