import { useTranslation } from 'react-i18next';

import type { IOption } from '@/types';

import { CompanyProductSelectorComponent } from './company-product-selector-component';
import { useCompliances } from '@/hooks/queries/use-compliances.query';
import type { CompanyProducts } from '@/api/v4/organization.api';

interface CompanyProductComplianceProps {
  setData: (data: Partial<CompanyProducts>) => void;
  isEditMode: boolean;
  className?: string;
  defaultValue: IOption[];
}

export const CompanyProductCompliance = ({
  setData,
  isEditMode,
  className,
  defaultValue,
}: CompanyProductComplianceProps) => {
  const { t } = useTranslation('companies');

  const { data: compliances } = useCompliances();

  const handleSelectionsChanged = (newValues: string[]) => {
    setData({ complianceIds: newValues });
  };

  return (
    <CompanyProductSelectorComponent
      title={t`products.productCompliances`}
      className={className}
      defaultValue={defaultValue}
      isEditMode={isEditMode}
      onChange={handleSelectionsChanged}
      setOptions={compliances}
    />
  );
};
