import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { resendInvitationEmail } from '@/api/v4/user.api';
import { modalState } from '@/state/modal.state';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useInvitedUsers } from './use-invited-users';

export const InvitedUsersActionsMenu = ({ userId }: { userId: number }) => {
  const { t } = useTranslation('settings');

  const { hasRequiredPermission } = useUserPermissions();
  const { refreshUsers } = useInvitedUsers();
  const setModalState = useSetRecoilState(modalState);

  const menu: DropdownOption[] = [
    {
      label: t`resendInvitation`,
      isVisible: hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT),
      onClick: async () => {
        await resendInvitationEmail(userId);
        notify({ message: t`invitationResent` });
        await refreshUsers();
      },
    },
    {
      label: t`revokeInvitation`,
      isVisible: hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT),
      onClick: () =>
        setModalState({
          state: 'revokeInvitation',
          userId,
        }),
    },
  ];

  return (
    <WithDropdown
      items={menu}
      options={{
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [0, 5.5] } }],
      }}
      data-testid="user-actions-menu"
    >
      <MenuButton />
    </WithDropdown>
  );
};
