/**
 * Retrieve a previous prop using useRef(), which can be later used in useEffect().
 *
 * @template T
 * @param {T} value
 * @return {*}  {(T | undefined)}
 */
import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { usePrevious };
