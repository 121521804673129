import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { Language } from '@/api/v4/language.api';
import { fetchLanguages } from '@/api/v4/language.api';

export const useLanguages = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.Languages], fetchLanguages, {
    select: mapLanguagesToOptions,
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const mapLanguagesToOptions = (languages: Language[]) => {
  return languages.map(language => ({
    label: language.name,
    value: language.id,
  }));
};
