import { useMutation } from '@tanstack/react-query';

import { useRecoilValue } from 'recoil';
import { companyIdState } from '../company.state';
import type { EditCompanyFundingsPayload } from '@/api/v4/organization.api';
import { editCompanyFundings } from '@/api/v4/organization.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useTranslation } from 'react-i18next';

export const useEditCompanyFundings = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  return useMutation(
    async (payload: EditCompanyFundingsPayload) =>
      companyId ? editCompanyFundings(companyId, payload) : undefined,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyFundings,
          companyId,
        ]);
      },
      onError: () => {
        notify({
          message: t`default:unknownError`,
        });
      },
    },
  );
};
