import type { TeamsQueryParams, TeamsSortParams } from '@/api/v4/team.api';
import { defaultAppliedProjectsFilters } from '@/features/projects/project.state';
import type { ExtractedFilters } from '@/helpers/filters-helpers';
import { stringifyFilter } from '@/helpers/filters-helpers';
import { filterOutEmptyFilterValues } from '@/helpers/filters-helpers';
import { stringifySort } from '@/helpers/stringify-sort';
import type { ISort } from '@/ui/table/table.types';

import type { TeamFilters } from '../team.state';
import { defaultAppliedTeamsFilters } from '../team.state';

export const stringifyTeamsFilters = ({
  sort,
  filters = defaultAppliedTeamsFilters,
}: {
  filters?: TeamFilters;
  sort?: ISort<TeamsSortParams>;
}): string => {
  // Keys of filters that have the same property name (keyof TeamsFilters) as apiKey
  const keys: Array<Partial<keyof TeamFilters>> = ['search'];

  const common = keys
    .map(key => stringifyFilter({ ...filters }, key))
    .filter(Boolean)
    .flat();

  const sortParams = sort ? stringifySort(sort) : '';

  return [...common, sortParams]
    .filter(filterOutEmptyFilterValues)
    .join('&')
    .replace(/&$/, '');
};

export const prepareTeamApiFilters = (filters: TeamFilters) => {
  let params: TeamsQueryParams = {};

  if (filters.search) {
    params = { ...params, search: filters.search };
  }

  return params;
};

export const extractTeamSearchFilters = (url: string): ExtractedFilters => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const filters: ExtractedFilters = {};

  for (const [key, value] of searchParams.entries()) {
    if (!(key in defaultAppliedProjectsFilters)) {
      continue;
    }

    if (key === 'search') {
      filters.search = value;
      continue;
    }
  }

  return filters;
};
