export function getElementOuterHeight(el: HTMLElement | null) {
  if (!el) return 0;

  const properties = [
    'margin-top',
    'margin-bottom',
    'border-top',
    'border-bottom',
    'padding-top',
    'padding-bottom',
    'height',
  ];

  const style = window.getComputedStyle(el);

  return properties
    .map(k => parseInt(style.getPropertyValue(k), 10))
    .reduce((prev, cur) => prev + cur);
}
