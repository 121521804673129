import { styled } from 'goober';
import { useEffect, useMemo } from 'react';
import type { RecoilState } from 'recoil';

import type { User } from '@/api/v4/user.api';
import { colors } from '@/theme/colors';
import { InfiniteScrollTable } from '@/ui/table/infinite-table/infinite-scroll-table';
import { INFINITE_TABLE_ROW_HEIGHT } from '@/ui/table/infinite-table/table-body';
import type { IHeaderRow, Row, ISort } from '@/ui/table/table.types';
import type { FetchNextPageOptions } from '@tanstack/react-query';

export const AppUsersTable = <Sort extends string>({
  users,
  isLoading,
  withSearchPhrase = false,
  sortState,
  hasNextPage,
  onLoadMore,
  emptyStateElement,
  noResultsElement,
  header,
  rows,
}: {
  users: User[];
  isLoading: boolean;
  withSearchPhrase?: boolean;
  hasNextPage: boolean;
  onLoadMore?: (options?: FetchNextPageOptions | undefined) => Promise<unknown>;
  sortState: RecoilState<ISort<Sort>>;
  emptyStateElement: React.ReactElement;
  noResultsElement: React.ReactElement;
  header: IHeaderRow<Sort>;
  rows: Row[];
}) => {
  useEffect(() => {
    // overflow-y: auto is being added on "style" on body. This prevents body scroll when infinite table is mounted.
    document.body.classList.add('overflow');
    return () => {
      document.body.classList.remove('overflow');
    };
  }, []);

  const defaultHeight = useMemo(
    () =>
      users.length <= 3
        ? INFINITE_TABLE_ROW_HEIGHT * 4
        : users.length * INFINITE_TABLE_ROW_HEIGHT,
    [users],
  );

  return (
    <TableContainer data-testid="users-table">
      {!isLoading && rows.length === 0 ? (
        withSearchPhrase ? (
          noResultsElement
        ) : (
          emptyStateElement
        )
      ) : (
        <InfiniteScrollTable<User, Sort>
          isLoading={isLoading}
          headers={header}
          rows={rows}
          data={users}
          hasMore={hasNextPage}
          onLoadMore={onLoadMore}
          sortState={sortState}
          height={`${defaultHeight}px`}
          borderColor={colors.gray.c2}
        />
      )}
    </TableContainer>
  );
};

const TableContainer = styled('div')`
  width: 100%;
  min-height: 140px;
  padding: 20px 22px;
`;
