import { useCallback, useEffect, useRef } from 'react';
import { styled } from 'goober';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';

import { ContactCardSkeleton } from '@/features/contacts/card/contact-card-skeleton';
import { ContactsFormContainer } from '@/features/contacts/contacts-form-container';
import { modalState } from '@/state/modal.state';
import { Stack } from '@/ui/line/line';
import type { IPageState } from '@/ui/table/table.types';
import type { CompanyContact } from '@/api/v4/organization-contacts.api';

type ContactsInfiniteScrollProps = {
  formContactsState: RecoilState<CompanyContact[]>;
  visibleContacts?: CompanyContact[];
  addContactCounterState: RecoilState<number>;
  contactsEditModeState: RecoilState<boolean>;
  contactsSubmitFormState: RecoilState<boolean>;
  contactDeletedIdState: RecoilState<string | null>;
  contactsPageState: RecoilState<IPageState>;
  onFormSubmit: (contacts: CompanyContact[]) => void;
  deleteAction: (contactId: string, synced: boolean) => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
};

export const ContactsInfiniteScroll = ({
  formContactsState,
  visibleContacts,
  addContactCounterState,
  contactsEditModeState,
  contactDeletedIdState,
  contactsSubmitFormState,
  contactsPageState,
  onFormSubmit,
  hasNextPage,
  isLoading,
  deleteAction,
}: ContactsInfiniteScrollProps) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  const contactsEditMode = useRecoilValue(contactsEditModeState);
  const forceSubmit = useRecoilValue(contactsSubmitFormState);
  const addContactCounter = useRecoilValue(addContactCounterState);
  const setModal = useSetRecoilState(modalState);
  const setPage = useSetRecoilState(contactsPageState);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }

        !isLoading && !contactsEditMode && hasNextPage
          ? setPage(({ page, ...rest }) => ({ ...rest, page: page + 1 }))
          : undefined;
      });
    },
    [hasNextPage, isLoading, setPage, contactsEditMode],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '50%',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  const onDeleteHandler = useCallback(
    (contactId: string, synced: boolean) => {
      setModal({
        state: 'confirmDelete',
        onConfirm: () => deleteAction(contactId, synced),
      });
    },
    [deleteAction, setModal],
  );

  return (
    <Stack gap="0">
      <Grid>
        <ContactsFormContainer
          editMode={contactsEditMode}
          forceSubmit={forceSubmit}
          addContactCounter={addContactCounter}
          onSubmit={onFormSubmit}
          editState={contactsEditModeState}
          onDelete={onDeleteHandler}
          formContactsState={formContactsState}
          visibleContacts={visibleContacts}
          onContactDeletedState={contactDeletedIdState}
        />
        {isLoading && <ContactCardSkeleton />}
      </Grid>
      <span ref={loaderRef} />
    </Stack>
  );
};

const Grid = styled('div')`
  display: grid;
  grid-gap: 55px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
`;
