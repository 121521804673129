import { useEffect, useRef } from 'react';
import type { Swiper } from 'swiper/types';

export const useSwiperWithPagination = (
  amountOfPages: number,
  pageSize: number,
) => {
  const swiperRef = useRef<Swiper | null>(null);

  useEffect(() => {
    if (
      swiperRef.current &&
      amountOfPages > 1 &&
      swiperRef.current.isBeginning
    ) {
      const firstNewSlide = (amountOfPages - 1) * pageSize;
      if (swiperRef.current.activeIndex !== firstNewSlide) {
        swiperRef.current.slideTo(firstNewSlide, 0);
      }
    }
  }, [amountOfPages, pageSize]);

  return {
    swiperRef,
  };
};
