import { selector } from 'recoil';

import { fetchImpacts } from '@/api/v4/impact.api';
import type { IOption } from '@/types';

export const impactsOptionsState = selector<IOption[]>({
  key: 'impactsOptionsState',
  get: async () => {
    const data = await fetchImpacts();

    return data.map(item => ({
      value: item.id,
      label: item.name,
    }));
  },
});
