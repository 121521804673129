import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';
import type { SetterOrUpdater } from 'recoil';

import { isDefaultOptionArray } from '@/helpers/other';
import type { IOption } from '@/types';
import { Stack } from '@/ui/line/line';
import { BorderedTextArea } from '@/ui/textarea/bordered-text-area';
import { ParagraphSmall } from '@/ui/typography/widgets';

import type { ExampleCompany } from '../explore.state';

import { ExampleCompaniesSelect } from './example-companies-select';
import { LocationSelect } from './location-select';

export const ExploreFormFields = ({
  description,
  setDescription,
  locations,
  setLocations,
  exampleCompanies,
  setExampleCompanies,
  isLoadingDefaultValues,
}: {
  description: string;
  setDescription: SetterOrUpdater<string>;
  locations: IOption[];
  setLocations: SetterOrUpdater<IOption[]>;
  exampleCompanies: ExampleCompany[];
  setExampleCompanies: SetterOrUpdater<ExampleCompany[]>;
  isLoadingDefaultValues?: boolean;
}) => {
  const { t } = useTranslation('explore');

  return (
    <>
      <Stack gap="8px">
        <Question>{t`detailsQuestionTitle`}</Question>
        {isLoadingDefaultValues ? (
          <Skeleton height="300px" />
        ) : (
          <TextArea
            placeholder={t`detailsQuestionPlaceholder`}
            maxLength={1000}
            height={300}
            onChange={setDescription}
            value={description}
            resize="vertical"
          />
        )}
      </Stack>
      <Stack gap="8px">
        <Question>{t`locationQuestionTitle`}</Question>
        {isLoadingDefaultValues ? (
          <Skeleton height="50px" />
        ) : (
          <LocationSelect
            placeholder={t`locationPlaceholder`}
            defaultValue={locations}
            onChange={option => {
              if (!isDefaultOptionArray(option) || option.length < 0) return;
              setLocations(option);
            }}
          />
        )}
      </Stack>
      <Stack gap="8px">
        <Question>{t`exampleCompaniesQuestionTitle`}</Question>
        {isLoadingDefaultValues ? (
          <Skeleton height="50px" />
        ) : (
          <ExampleCompaniesSelect
            exampleCompanies={exampleCompanies}
            setExampleCompanies={setExampleCompanies}
          />
        )}
      </Stack>
    </>
  );
};

const Question = styled(ParagraphSmall)`
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.gray.c13};
`;

const TextArea = styled(BorderedTextArea, forwardRef)`
  ${({ theme }) => theme.typography.widget.paragraphSmall};
  min-height: 301px;
  background-color: ${({ theme }) =>
    theme.palettes.component.explore.form.background};
  padding: 8px;

  &::placeholder {
    ${({ theme }) => theme.typography.widget.paragraphSmall};
    color: ${({ theme }) => theme.colors.gray.c10};
  }
`;
