import { styled } from 'goober';

export const WidgetLoaderContainer = styled('div')<{
  height: number;
  width?: number;
}>`
  background-color: ${({ theme }) => theme.colors.basics.white};
  padding: 30px 82px;
  min-height: ${({ height }) => `${height}px`};
  ${({ width }) => width && `min-width: ${width}px`};
  box-shadow: ${({ theme }) => theme.shadow.widgets};
  border-radius: 20px;
  display: flex;
  justify-content: center;
`;
