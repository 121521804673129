import { styled } from 'goober';

import { Icon } from '@/ui/icons/icon';

import { Flexbox } from '../../../../ui/flexbox/flexbox';
import { strokeColorIcon } from '../../../../ui/icons/functions';

const DEFAULT_STAR_SIZE = '15px';

export const RankingStarIcon = ({
  filled,
  size = DEFAULT_STAR_SIZE,
  percentage,
}: {
  filled?: boolean;
  size?: string;
  percentage?: number;
}) => {
  return (
    <RankingStarContainer
      name="icon-star-container"
      filled={filled}
      percentage={percentage}
    >
      <Icon icon="Star" width={size} height={size} />
    </RankingStarContainer>
  );
};

const RankingStarContainer = styled(Flexbox)<{
  filled?: boolean;
  percentage?: number;
}>`
  ${({ theme, filled }) =>
    `
    stroke-width: 1.2px;
    ${strokeColorIcon(
      theme.colors.accent.green.c3,
      filled
        ? undefined
        : theme.palettes.component.company.ranking.cardBackground,
    )}
  `};
  ${({ percentage }) =>
    percentage ? `clip-path: inset(0 ${percentage}% 0 0)` : ''};
`;
