import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Stack } from '@/ui/line/line';

import { TwoFactorAuthentication } from './two-factor-authentication/two-factor-authentication';
import { EditProfileFormFields } from './edit-profile-form-fields';
import { useEditProfileForm } from './use-edit-profile-form';
import type { CurrentUser } from '@/api/v4/auth.api';

export const EditProfileForm = ({ user }: { user: CurrentUser }) => {
  const { t } = useTranslation('settings');

  const setModal = useSetRecoilState(modalState);

  const defaultValues = {
    name: user.firstName ?? '',
    lastName: user.lastName ?? '',
    position: user.title ?? undefined,
    email: user.email ?? '',
    accountName: user.account ? user.account.name : '',
  };

  const { methods, handleEditProfileSubmit, isSubmitting } =
    useEditProfileForm(defaultValues);

  const {
    formState: { isValid, isDirty },
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container gap="40px" withLowOpacity={isSubmitting}>
      <FormProvider {...methods}>
        <EditProfileFormFields />
      </FormProvider>

      <Button
        variant="ghost"
        onClick={() => setModal({ state: 'changePassword' })}
      >
        Change password
      </Button>
      <SubmitButton
        variant="primary"
        disabled={!isDirty || !isValid || isSubmitting}
        onClick={handleEditProfileSubmit}
      >{t`applyChanges`}</SubmitButton>
      {isSubmitting && <TransparentLoader />}
      <TwoFactorAuthentication />
    </Container>
  );
};

const Container = styled(Stack)<{ withLowOpacity: boolean }>`
  position: relative;
  padding: 52px 32px 0px;
  ${({ withLowOpacity }) => withLowOpacity && `opacity: 0.3;`};
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
`;

const TransparentLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
