import { styled } from 'goober';

import { SubHeaderBold } from '@/ui/typography/widgets';

export const KPILabel = styled('p')`
  ${({ theme }) => theme.typography.kpi.kpiLabel};
`;

export const KPIValue = styled(SubHeaderBold)`
  color: ${({ theme }) => theme.colors.blue.primaryA};
`;
