import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { colorIcon } from '@/ui/icons/functions';

export const ActivateFiltersButton = styled(Button)`
  color: ${({ theme }) => theme.colors.blue.primaryA};
  ${({ theme }) => colorIcon(theme.colors.blue.primaryA)};
  height: 31px;
  &:disabled {
    ${({ theme }) => colorIcon(theme.colors.gray.c9)};
  }
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.blue.primaryB};
    ${({ theme }) => colorIcon(theme.colors.blue.primaryB)};
  }
`;
