import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import type { SignUpPayload } from '@/api/v4/auth.api';
import { signUp } from '@/api/v4/auth.api';
import { BaseExceptions, isBaseError } from '@/api/v4/base-fetch';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';

import { jwtTokenState } from '../auth.state';

export const useSignUp = () => {
  const navigateTo = useNavigateTo();

  const { t } = useTranslation('auth');
  const queryClient = useQueryClient();
  const setToken = useSetRecoilState(jwtTokenState);

  const signUpMutation = useMutation(
    async (payload: SignUpPayload) => await signUp(payload),
    {
      onSuccess: async data => {
        setToken('Bearer ' + data.token);
        await queryClient.invalidateQueries([QueryKey.WhoAmI]);
        navigateTo.home();
      },
      onError: error => {
        if (isBaseError(error)) {
          if (error.response.status === BaseExceptions.UNAUTHORIZED) {
            notify({ message: t`loginErrorNoUser` });
            return;
          }
        }
        notify({ message: t`loginError` });
      },
    },
  );

  const handleSignUp = (payload: SignUpPayload) =>
    signUpMutation.mutateAsync(payload);

  return {
    handleSignUp,
  };
};
