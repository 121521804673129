import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { fetchCompaniesKPIs } from '@/api/v4/organization.api';
import { appliedCompaniesFiltersState } from '../companies.state';
import { prepareCompanyApiFilters } from '../filters/company-filters-helpers';

export const useCompaniesKpi = () => {
  const appliedFilters = useRecoilValue(appliedCompaniesFiltersState);

  const getCompaniesKPIs = async () =>
    fetchCompaniesKPIs({
      ...prepareCompanyApiFilters(appliedFilters),
    });

  return useQuery([QueryKey.CompaniesKpi, appliedFilters], getCompaniesKPIs, {
    staleTime: getTimeInMs(5, 'minute'),
    cacheTime: getTimeInMs(15, 'minute'),
  });
};
