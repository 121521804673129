import type { FlexBoxProps } from '@/ui/flexbox/flexbox';

import { ContactCardBaseContainer } from './contact-card-base';

export const ContactCardContainer = ({
  children,
  ...props
}: React.PropsWithChildren<FlexBoxProps>) => {
  return (
    <ContactCardBaseContainer gap="12px" padding="36px 32px" {...props}>
      {children}
    </ContactCardBaseContainer>
  );
};
