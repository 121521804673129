import { useTranslation } from 'react-i18next';

import SvgTags from '@/assets/widgets/empty-state-tags.svg';
import { TagsWidget } from '@/components/widgets/tags-widget/tags-widget';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';

import type { EntityTag } from '../../tags/tags';

const TAGS_WIDGET_HEIGHT = 260;

export const ProjectTagsWidget = ({
  tags,
  onEditClick,
  disabled = false,
  testId,
  className,
}: {
  tags: EntityTag[];
  onEditClick: () => void;
  disabled?: boolean;
  testId?: string;
  className?: string;
}) => {
  const { t } = useTranslation('projects');

  if (!tags.length && !disabled) {
    return (
      <EmptyStateWidget
        dataTestId={testId}
        height={TAGS_WIDGET_HEIGHT}
        image={SvgTags}
        label={t`projectOverview.emptyState.labelTags`}
        linkText={t`projectOverview.emptyState.linkTextTags`}
        onEditClick={onEditClick}
        className={className}
      />
    );
  }
  if (!tags.length)
    return (
      <ReadOnlyEmptyWidget
        height={TAGS_WIDGET_HEIGHT}
        header={<SubHeaderBold>{t`projectOverview.tags`}</SubHeaderBold>}
        className={className}
      />
    );

  return (
    <TagsWidget
      onEditClick={onEditClick}
      tags={tags}
      widgetHeight={TAGS_WIDGET_HEIGHT}
      disabled={disabled}
      crunchbasePermalink={null}
      className={className}
    />
  );
};
