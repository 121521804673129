import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';
import { z } from 'zod';

import type { CompanyFreeTextField } from '@/api/v4/organization.api';
import { useCanEditCompany } from '@/features/companies/hooks/use-can-edit-company';
import { companyFreeTextFieldsEditModeState } from '@/features/companies/overview/free-text-fields/free-text-fields.state';
import {
  COMPANY_MAIN_CONTENT_WIDTH,
  GRID_GAP,
} from '@/features/companies/overview/utils/constants';
import { useZodForm } from '@/hooks/use-zod-form';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Input } from '@/ui/input/input';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { TextEditable } from '@/ui/text-editable/text-editable';
import { Header } from '@/ui/typography/widgets';
import { Widget } from '@/ui/widget/widget';

const TEXT_FIELD_MIN_HEIGHT = 22;
const TEXT_FIELD_LINE_HEIGHT = 22;
const TEXT_FIELD_PADDING = 42;

const MAX_TEXT_LINES = 18;
const MAX_READ_MODE_TEXT_LINES = 26;

export const FreeTextFieldWidget = ({
  fieldId,
  title,
  content,
  handleOnChange,
  onDelete,
}: {
  fieldId: string;
  title: string | null;
  content: string | null;
  onEditClick?: () => void;
  handleOnChange?: (value: CompanyFreeTextField) => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation('companies');
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentInnerScrollHeight, setContentScrollHeight] =
    useState<number>(0);
  const canEditCompany = useCanEditCompany();
  const [editModeFieldId, setEditModeFieldId] = useRecoilState(
    companyFreeTextFieldsEditModeState,
  );

  const isEditMode = editModeFieldId === fieldId;

  const validationSchema = z.object({
    id: z.string(),
    title: z
      .string()
      .trim()
      .min(1, t`freeTextField.titleFormError`),
    content: z.string().nullable(),
  });

  const methods = useZodForm({
    schema: validationSchema,
    defaultValues: {
      id: fieldId,
      title: title ?? '',
      content,
    },
  });

  const titleValue = methods.watch('title');
  const contentValue = methods.watch('content');

  useEffect(() => {
    if (contentValue?.trim().length === 0) {
      methods.setValue('content', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentValue]);

  const contentIsEmpty = contentValue?.length === 0 || !contentValue;

  const getSimulatedTextAreaHeight = useCallback((noOfLines: number) => {
    const numberOfLineBreaks = noOfLines;
    // min-height + lines x line-height + padding + border
    const newHeight =
      TEXT_FIELD_MIN_HEIGHT +
      numberOfLineBreaks * TEXT_FIELD_LINE_HEIGHT +
      TEXT_FIELD_PADDING +
      2;
    return newHeight;
  }, []);

  const handleOnSubmit = useCallback(async () => {
    if (!isEditMode) return;
    await methods.handleSubmit(v => {
      handleOnChange?.(v);
      setEditModeFieldId(null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleOnChange,
    isEditMode,
    methods.formState.isDirty,
    titleValue.length,
  ]);

  const showReadMore = contentInnerScrollHeight > getSimulatedTextAreaHeight(6);

  const onEditModeChange = useCallback(
    (enabled: boolean) => {
      setEditModeFieldId(enabled ? fieldId : null);
    },
    [fieldId, setEditModeFieldId],
  );

  const maxVisibleHeight = getSimulatedTextAreaHeight(MAX_TEXT_LINES);

  const visibleHeight = useMemo(() => {
    const minVisibleHeight = getSimulatedTextAreaHeight(4);

    if (isEditMode) {
      if (contentValue?.length === 0 || !contentValue) {
        return minVisibleHeight;
      }

      if (contentInnerScrollHeight >= maxVisibleHeight) {
        return maxVisibleHeight + TEXT_FIELD_PADDING;
      }

      if (contentInnerScrollHeight <= minVisibleHeight) {
        return minVisibleHeight;
      }

      if (contentInnerScrollHeight > minVisibleHeight) {
        return contentInnerScrollHeight;
      }

      return 0;
    }

    if (contentInnerScrollHeight === 0) return 0;

    if (isExpanded) {
      return getSimulatedTextAreaHeight(MAX_READ_MODE_TEXT_LINES);
    }

    if (showReadMore && !isExpanded) {
      return minVisibleHeight + TEXT_FIELD_PADDING;
    }

    return contentInnerScrollHeight;
  }, [
    contentInnerScrollHeight,
    contentValue,
    getSimulatedTextAreaHeight,
    isEditMode,
    isExpanded,
    maxVisibleHeight,
    showReadMore,
  ]);

  useEffect(() => {
    if (!isEditMode) return;

    setIsExpanded(false);
  }, [isEditMode]);

  return (
    <OutsideClickHandler onOutsideClick={handleOnSubmit}>
      <WidgetContainer
        onEditClick={() => onEditModeChange(true)}
        height={contentIsEmpty ? 0 : undefined}
        showMore={isExpanded}
        header={
          isEditMode ? (
            <Controller
              control={methods.control}
              name="title"
              render={({ field: { value, onChange } }) => (
                <TitleContainer name="free-text-widget-title">
                  <Title
                    value={value}
                    spellCheck={false}
                    placeholder={t`freeTextField.titleInputPlaceholder`}
                    onChange={onChange}
                    autoFocus
                    error={methods.formState.errors.title?.message}
                    errorJustifyContent="end"
                    errorFontSize="16px"
                  />
                </TitleContainer>
              )}
            />
          ) : (
            <EllipsisTextTooltip
              text={titleValue}
              Component={
                <TitleDisplay noMargin={contentIsEmpty}>
                  {titleValue}
                </TitleDisplay>
              }
            />
          )
        }
        disabled={!canEditCompany}
        isEditMode={isEditMode}
        padding={!isEditMode ? '54px 46px' : '32px 30px'}
        showExpandButton={!isEditMode && !contentIsEmpty && showReadMore}
        onReadMoreClick={() => setIsExpanded(!isExpanded)}
        bottomContentProps={{
          padding: '5px 0px',
        }}
      >
        <Controller
          control={methods.control}
          name="content"
          render={({ field: { value, onChange } }) => (
            <ContentTextField
              value={value ?? ''}
              placeholder={t`freeTextField.contentInputPlaceholder`}
              allowLineBreaks={true}
              readOnly={!isEditMode}
              border={isEditMode}
              hideOnEmpty={!isEditMode}
              onChange={onChange}
              disableEditButton
              noMargin={!isEditMode && !isExpanded}
              height={visibleHeight}
              overflow={isEditMode || isExpanded}
              onScrollHeightChange={setContentScrollHeight}
              isExpanded={isExpanded}
            />
          )}
        />
        {isEditMode && (
          <DeleteIconButton
            icon="Bin"
            variant="ghost"
            size="16px"
            onClick={onDelete}
          />
        )}
      </WidgetContainer>
    </OutsideClickHandler>
  );
};

const WidgetContainer = styled(Widget)<{ maxHeight?: number }>`
  position: relative;
  max-width: ${COMPANY_MAIN_CONTENT_WIDTH - GRID_GAP}px;

  height: calc(100% + 1px);
  ${({ theme, isEditMode }) =>
    !isEditMode && theme.mixins.transition('max-height')}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
`;

const ContentTextField = styled(TextEditable, forwardRef)<{
  noMargin?: boolean;
  height?: number;
  overflow?: boolean;
}>`
  ${({ noMargin }) => `
    margin-bottom: ${noMargin ? 0 : 28}px;
  `}
  caret-color: ${({ theme }) => theme.colors.blue.primaryA};

  overflow: ${({ overflow }) => (overflow ? 'auto' : 'hidden')};
  height: ${({ height }) => height ?? 0}px;
`;

const DeleteIconButton = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 44px;
  padding: 0;
  z-index: ${Z_INDEX_FILTERS_DROPDOWN_MENU};

  ${({ theme }) =>
    `
    ${colorIcon(theme.colors.system.lowFit)}
  `};
`;

const Title = styled(Input)`
  ${({ theme }) => theme.typography.widget.header};
  width: 100%;
  &:focus-within {
    caret-color: ${({ theme }) => theme.colors.blue.primaryA};
  }
`;

const TitleContainer = styled(Flexbox)`
  width: 500px;
  margin-bottom: 18px;
`;

const TitleDisplay = styled(Header, forwardRef)<{ noMargin?: boolean }>`
  ${({ theme }) => theme.mixins.ellipsis}
  width: 60%;

  letter-spacing: 0.05em;

  ${({ noMargin }) => `
    margin-bottom: ${noMargin ? 0 : 50}px;
  `}
`;
