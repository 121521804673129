import { useEffect } from 'react';
import { useDescope, useSession } from '@descope/react-sdk';
import { useRecoilValue } from 'recoil';

import { jwtTokenState } from '../auth.state';

export const useAuthorization = () => {
  const jwtToken = useRecoilValue(jwtTokenState);
  const { isJwtExpired, refresh, getSessionToken } = useDescope();
  const { isSessionLoading, sessionToken } = useSession();

  const descopeToken = getSessionToken();

  useEffect(() => {
    if (!sessionToken || !isSessionLoading) return;

    if (isJwtExpired(sessionToken)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refresh();
    }
  }, [refresh, sessionToken]);

  const isUserAuthorized = !!jwtToken && !!descopeToken;

  return {
    isUserAuthorized,
  };
};
