import type { ReactElement } from 'react';
import { Children, cloneElement } from 'react';
import { useEffect, useState } from 'react';

export const useMeasuringRender = ({
  children,
}: {
  children: ReactElement[];
}) => {
  const [measuringRender, setMeasuringRender] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [allElements, setAllElements] = useState<ReactElement[]>([]);

  useEffect(() => {
    setIsMounted(true);
    setMeasuringRender(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMeasuringRender(true);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const measureRender = () => {
      setMeasuringRender(true);
      const allElements = Children.map(children, (element, index) => {
        return cloneElement(element, {
          key: index,
        });
      });
      setAllElements(allElements);
    };
    // postpone to let previous useLayoutEffect finish and change measuringRender to false
    let measureOnceFinished: NodeJS.Timeout | null = null;

    if (measuringRender) {
      measureOnceFinished = setTimeout(measureRender, 300);
    } else {
      measureRender();
    }

    return () => {
      !!measureOnceFinished && clearTimeout(measureOnceFinished);
    };
  }, [children]);

  return {
    isMounted,
    measuringRender,
    allElements,
    setMeasuringRender,
  };
};
