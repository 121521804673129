import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'goober';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { Company, CompanySortOptions } from '@/api/v4/organization.api';
import { selectedCompaniesState } from '@/features/companies/companies.state';
import { companiesHeaders } from '@/features/companies/companies-table-configuration';
import { CompanyActionsMenu } from '@/features/companies/company-actions-menu';
import { Rating } from '@/features/projects/project-listings/stage-management-table/table/ranking';
import { formatDate } from '@/helpers/format-date';
import { formatEmpty, formatNotApplicable } from '@/helpers/format-empty';
import { openNewTab } from '@/helpers/open-new-tab';
import { trimText } from '@/helpers/text-helper';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import { Checkbox } from '@/ui/checkbox/checkbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import {
  TablePrimaryCell,
  TableSecondaryCell,
} from '@/ui/table/infinite-table/table-cells';
import type { Row } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { RelatedProjectsCount } from '../related-projects/related-projects-count';

const rowPadding = {
  left: '60px',
  right: '0px',
};

interface UseCompaniesTableConfigurationProps {
  companies: Company[];
  onSelect?: (id: string) => void;
}

const openCompanyWebsiteTab = (url: string | null) => (event: MouseEvent) => {
  if (!url) return;
  event.stopPropagation();
  openNewTab(url);
};

export const useCompaniesTableConfiguration = ({
  companies,
}: UseCompaniesTableConfigurationProps) => {
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesState,
  );
  const { hasRequiredPermission } = useUserPermissions();

  const header = useTranslateHeaders<CompanySortOptions>(
    companiesHeaders.map(item => {
      if (item.showCheckbox) {
        item.onCheckboxChange = isChecked => {
          setSelectedCompanies(
            isChecked
              ? companies.map(company => ({ organizationId: company.id }))
              : [],
          );
        };

        item.checkboxCount = selectedCompanies.length;
        if (selectedCompanies.length) {
          item.isChecked = Boolean(selectedCompanies.length);
          item.checkboxIcon =
            selectedCompanies.length !== companies.length
              ? 'CheckMarkMinus'
              : undefined;
          item.checkboxActions = <CompanyActions />;
        } else {
          item.isChecked = false;
          item.checkboxActions = undefined;
        }
      }

      return item;
    }),
    'companies',
    'companies',
  );

  const rows: Row[] = useMemo(
    () =>
      companies.map(company => {
        return {
          id: company.id,
          rowPadding,
          ...(selectedCompanies.some(
            selected => selected.organizationId === company.id,
          ) && {
            rowBackgroundColor: colors.blue.c7,
          }),
          cells: [
            {
              value: (
                <CompanyNameTableCell
                  company={company}
                  shouldLinkToCompany={hasRequiredPermission(
                    PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN,
                  )}
                />
              ),
            },
            {
              align: 'center',
              width: '20%',
              value: (
                <InlineBlock>
                  <Rating ranking={company.averageRating ?? null} />
                </InlineBlock>
              ),
            },
            {
              width: '12%',
              value: (
                <TableSecondaryCell>
                  {company.yearEstablished}
                </TableSecondaryCell>
              ),
            },
            {
              width: '12%',
              align: 'center',
              value: (
                <TableSecondaryCell>
                  {company.createdDate &&
                    formatDate(company.createdDate, {
                      format: 'dd MMM, yyyy',
                    })}
                </TableSecondaryCell>
              ),
            },
            {
              width: '12%',
              align: 'center',
              value: (
                <TableSecondaryCell>
                  {company.lastEditedDate &&
                    formatDate(company.lastEditedDate, {
                      format: 'dd MMM, yyyy',
                    })}
                </TableSecondaryCell>
              ),
            },
            {
              width: '64px',
              align: 'center',
              value: (
                <InlineBlock>
                  <RelatedProjectsCount
                    value={company.listingCount}
                    companyId={company.id}
                  />
                </InlineBlock>
              ),
            },
            {
              value: <CompanyActionsMenu company={company} />,
              hoverOnly: true,
              verticalAlign: 'middle',
              padding: '0',
            },
          ],
        };
      }),
    [companies, hasRequiredPermission, selectedCompanies],
  );

  return {
    header,
    rows,
    skeletonConfig: {
      rowPadding,
      // minus one because of actions column that is invisible on load
      columns: header.cells.length - 1,
      numberOfRows: 6,
    },
  };
};

const CompanyNameTableCell = ({
  company,
  shouldLinkToCompany,
}: {
  company: Company;
  shouldLinkToCompany: boolean;
}) => {
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesState,
  );

  const handleSelectCompany = useCallback(() => {
    setSelectedCompanies(old => {
      if (old.some(someCompany => someCompany.organizationId === company.id)) {
        return old.filter(
          someCompany => someCompany.organizationId !== company.id,
        );
      }
      return [...old, { organizationId: company.id }];
    });
  }, [company.id, setSelectedCompanies]);

  return (
    <Flexbox name="company-name-container" gap="8px" alignItems="end">
      <RowCheckbox
        id={company.id}
        label=""
        checked={selectedCompanies.some(
          item => item.organizationId === company.id,
        )}
        onChange={handleSelectCompany}
      />
      <Logo
        singleLetter
        name={company.name}
        logoUrl={company.logoUrl ?? undefined}
        bgColor={colors.accent.green.c3}
        border={company.logoUrl ? `2px solid ${colors.gray.c3}` : undefined}
      />
      <TablePrimaryCell maxWidth={230}>
        <Stack gap="0px">
          <Flexbox name="company-row-details" alignItems="end">
            <Tooltip
              content={trimText(
                formatNotApplicable(company.keyOffering),
                120,
                '...',
              )}
              maxWidth="500px"
              placement="top-start"
            >
              {shouldLinkToCompany ? (
                <Link to={paths.company({ companyId: company.id })}>
                  {company.name}
                </Link>
              ) : (
                <>{company.name}</>
              )}
            </Tooltip>
            {company.website && (
              <Tooltip
                content={company.website}
                placement="top"
                maxWidth="fit-content"
              >
                <IconButton
                  icon="Globe"
                  variant="ghost"
                  color={colors.basics.black}
                  onClick={openCompanyWebsiteTab(company.website)}
                />
              </Tooltip>
            )}
          </Flexbox>
          <Tooltip
            disabled={!company.hqShortLocationDisplayName}
            content={formatEmpty(company.hqShortLocationDisplayName)}
          >
            <Headquarters>
              {formatEmpty(company.hqLocationDisplayName)}
            </Headquarters>
          </Tooltip>
        </Stack>
      </TablePrimaryCell>
    </Flexbox>
  );
};

export const CompanyActions = () => {
  const { t } = useTranslation('companies');
  const setModalState = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();

  return (
    <>
      {hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) && (
        <AddToProjectButton
          variant="text"
          startIcon="Plus"
          onClick={e => {
            e.stopPropagation();
            setModalState({ state: 'addCompanyToProjects' });
          }}
        >
          {t`companies.checkboxActions.addToProject`}
        </AddToProjectButton>
      )}

      {hasRequiredPermission(PERMISSION.DELETE_ORGANIZATION) && (
        <Button
          variant="dangerText"
          startIcon="Bin"
          onClick={e => {
            e.stopPropagation();
            setModalState({
              state: 'deleteCompanies',
            });
          }}
        >
          {t`companies.checkboxActions.delete`}
        </Button>
      )}
    </>
  );
};

const Headquarters = styled('span')`
  color: ${({ theme }) => theme.colors.gray.c11};
`;

const AddToProjectButton = styled(Button)`
  color: ${({ theme }) => theme.colors.blue.primaryA};
  &:hover {
    color: ${({ theme }) => theme.colors.blue.primaryA};
  }
`;
const InlineBlock = styled('div')`
  display: inline-block;
`;

const RowCheckbox = styled(Checkbox)`
  & + div {
    margin-right: 16px;
  }
`;
