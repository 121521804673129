import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import type { TeamMemberSortParams } from '@/api/v4/team.api';
import { AppUserEmptyState } from '@/features/app-users/empty-state';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Table } from '@/ui/table/table';
import type { IHeaderRow, Row } from '@/ui/table/table.types';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { teamMembersSortState } from '../../team.state';

export const TeamMembersTable = ({
  header,
  rows,
  isLoading,
}: {
  header: IHeaderRow<TeamMemberSortParams>;
  rows: Row[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation('teams');
  const { hasRequiredPermission } = useUserPermissions();

  const setModal = useSetRecoilState(modalState);

  const handleAddMember = () => setModal({ state: 'addTeamMember' });

  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );

  return (
    <TableContainer>
      {!isLoading && rows.length === 0 ? (
        <AppUserEmptyState
          onClick={handleAddMember}
          buttonText={t`addMember`}
          text={t`emptyTeamMembers`}
          disabled={!hasManageTeamPermission}
        />
      ) : (
        <Table<TeamMemberSortParams>
          isLoading={isLoading}
          headers={header}
          rows={rows}
          sortState={teamMembersSortState}
          borderColor={colors.gray.c2}
        />
      )}
    </TableContainer>
  );
};

const TableContainer = styled('div')`
  width: 100%;
  min-height: 140px;
  padding: 20px 22px;
`;
