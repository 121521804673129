import { forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useRecoilValue } from 'recoil';

import { activeFilterTypeState } from '@/components/filters/filter.state';
import { FilterSearchInput } from '@/components/filters/filter-search-input';
import { useHandleFilters } from '@/components/filters/use-handle-filters';
import { getElementOuterHeight } from '@/helpers/get-element-outer-height';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { Button } from '@/ui/button/button';
import { Flexbox, FlexContainer } from '@/ui/flexbox/flexbox';

import type { TeamFilters } from '../team.state';
import {
  appliedTeamsFiltersState,
  selectedTeamsFiltersState,
} from '../team.state';

import { useApplyTeamFiltersFromUrl } from './use-apply-team-filters-from-url';
import { useTeamsFiltersSearchLocationSearch } from './use-teams-filters-location-search';

export const TeamsFilters = ({
  setFiltersContainerHeight,
  filtersDisabled,
}: {
  setFiltersContainerHeight: (height: number) => void;
  filtersDisabled: boolean;
}) => {
  const { t } = useTranslation('projects');
  const [activeFilterType, setActiveFilterType] = useRecoilState(
    activeFilterTypeState,
  );

  const filtersContainerRef = useRef<HTMLDivElement>(null);

  const appliedFilters = useRecoilValue(appliedTeamsFiltersState);

  const activateSearchAfterApplyingFiltersFromUrl = () => {
    if (!appliedFilters.search) return;

    setActiveFilterType('search');
  };

  useApplyTeamFiltersFromUrl({
    isPrefetching: false,
    onFiltersApply: activateSearchAfterApplyingFiltersFromUrl,
  });

  const { updateLocationSearch, resetLocationSearch } =
    useTeamsFiltersSearchLocationSearch();

  const {
    handleSearchInputChange,
    hasAnyActiveFilters,
    clearSearchInput,
    clearSelectedFilters,
    switchToSearchFilter,
  } = useHandleFilters<TeamFilters>({
    appliedFiltersState: appliedTeamsFiltersState,
    selectedFiltersState: selectedTeamsFiltersState,
    updateLocationSearch,
    resetLocationSearch,
  });

  useEffect(() => {
    setFiltersContainerHeight(
      getElementOuterHeight(filtersContainerRef.current),
    );
  }, [activeFilterType, appliedFilters]);

  return (
    <FilterSearchContainer
      ref={filtersContainerRef}
      direction="column"
      gap="15px"
    >
      <Flexbox
        name="filter-buttons-container"
        justify="flex-end"
        gap="10px"
        alignItems="center"
      >
        <FilterSearchInput
          onChange={handleSearchInputChange}
          placeholder={t`projects.searchByName`}
          disabled={filtersDisabled}
          onRemove={clearSearchInput}
          isInputActivated={
            activeFilterType === 'search' || !!appliedFilters.search
          }
          onInputActivate={switchToSearchFilter}
          onInputDeactivate={() => setActiveFilterType(null)}
          defaultValue={appliedFilters.search ?? ''}
        />
      </Flexbox>
      <Flexbox name="filter-buttons" justify="flex-end">
        {hasAnyActiveFilters && (
          <Button variant="underlined" onClick={clearSelectedFilters}>
            {t`projects.clearAll`}
          </Button>
        )}
      </Flexbox>
    </FilterSearchContainer>
  );
};

const FilterSearchContainer = styled(FlexContainer, forwardRef)`
  padding-right: 20px;
  padding-left: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;
  z-index: ${Z_INDEX_FILTERS_DROPDOWN_MENU};
`;
