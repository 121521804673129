import type { CompanyClient } from '@/api/v4/organization-clients.api';
import type { ClientOption } from '@/types';

export const mapOrganizationClientsToOptions = (
  clients: CompanyClient[],
): ClientOption[] => {
  return clients.map(client => ({
    value: client.id,
    label: client.name,
    domain: client.domain,
    description: client.name,
    logo: client.logoUrl,
    clientId: client.id,
    source: 'savvy',
  }));
};
