import type { EditOrganizationInvestorsPayload } from '@/api/v4/organization.api';
import { editOrganizationInvestors } from '@/api/v4/organization.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { companyIdState } from '../company.state';
import { QueryKey } from '@/config/query-client';

export const useEditCompanyInvestors = () => {
  const companyId = useRecoilValue(companyIdState);
  const queryClient = useQueryClient();

  return useMutation(
    async (investors: EditOrganizationInvestorsPayload['investors']) => {
      if (!companyId) return;

      return editOrganizationInvestors(companyId, { investors });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyInvestors,
          companyId,
        ]);
        await queryClient.invalidateQueries([QueryKey.LastActivities]);
        await queryClient.invalidateQueries([QueryKey.LastActivitiesCount]);
      },
    },
  );
};
