import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';

import { WidgetLoaderContainer } from '@/features/companies/overview/widget-loader-container';
import { Stack } from '@/ui/line/line';

export const ProjectListingsLoadingSkeleton = () => (
  <Wrapper height={300}>
    <Stack gap="30px">
      <Skeleton height="40px" width="150px" />
      <Stack gap="15px">
        <Skeleton height="20px" width="400px" />
        <Skeleton height="20px" width="300px" />
        <Skeleton height="20px" width="300px" />
        <Skeleton height="20px" width="200px" />
        <Skeleton height="20px" width="200px" />
      </Stack>
    </Stack>
  </Wrapper>
);

const Wrapper = styled(WidgetLoaderContainer)`
  justify-content: flex-start;
`;
