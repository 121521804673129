import { Route, Routes } from 'react-router-dom';
import protect from './protect/protect';
import { PERMISSION } from '@/user/permissions/permission.type';
import { ProjectOverviewPage } from '@/features/projects/project-overview.page';
import { ProjectsPage } from '@/features/projects/projects.page';

export const Projects = () => {
  return (
    <Routes>
      <Route index element={<ProjectsPage />} />
      <Route
        path="create"
        element={protect(
          <ProjectOverviewPage createNew />,
          { permissions: [PERMISSION.ADD_PROJECT] },
          true,
        )}
      />
      <Route
        path=":projectId"
        element={protect(<ProjectOverviewPage />, {
          permissions: [PERMISSION.VIEW_PROJECT_HOME_PAGE],
        })}
      />
    </Routes>
  );
};
