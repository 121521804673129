import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { fetchCompaniesStatistics } from '@/api/v4/snapshot-funnel.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

export const useCompaniesStatistics = () => {
  const { t } = useTranslation('default');

  return useQuery([QueryKey.CompaniesStatistics], fetchCompaniesStatistics, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    staleTime: 1000 * 60 * 5,
  });
};
