import type { ProjectListing } from '@/api/v4/projects.api';
import { DealStageName } from '@/features/listing-stage/types';

export const getDealListingsCount = (listings: ProjectListing[]) => {
  return listings.reduce((count, listing) => {
    return Object.values(DealStageName).some(
      name => name === listing.status.name,
    ) && !listing.notRelevant
      ? count + 1
      : count;
  }, 0);
};
