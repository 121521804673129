import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { classList } from '@/helpers/class-list';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';

import { AdditionalSectionsPicker } from './additional-sections/additional-sections-picker';
import { useSectionsPane } from './use-sections-pane';

export function SectionsPane() {
  const { t } = useTranslation('companies');
  const {
    sectionsList,
    isSectionsPaneOpen,
    closeSectionPane,
    selectedSection,
    onSectionClick,
  } = useSectionsPane();

  return (
    <Container
      name="company-sections-container"
      direction="column"
      className={classList(isSectionsPaneOpen && 'active')}
    >
      <Header
        name="action-panel-header"
        alignItems="center"
        justify="space-between"
        padding="0 30px"
      >
        <TitleContainer
          name="action-panel-title"
          alignItems="center"
          gap="12px"
          onClick={closeSectionPane}
        >
          <Icon icon="ArrowRightSections" color={colors.gray.c12} />
          <EllipsisTextTooltip
            text={t`company.sections.title`}
            Component={<TitleValue />}
          />
        </TitleContainer>
      </Header>
      <List>
        {sectionsList.map(item => (
          <ListItem
            key={item.section}
            onClick={() => onSectionClick(item.section)}
            selected={selectedSection === item.section}
          >
            <Flexbox name="list-item" alignItems="center" height="100%">
              {item.name}
            </Flexbox>
          </ListItem>
        ))}
      </List>
      <AdditionalSectionsPicker />
    </Container>
  );
}

const Container = styled(Flexbox)`
  --slidingSidebarHeight: 72px;
  background-color: ${({ theme }) => theme.colors.basics.canvas};
  box-shadow: ${({ theme }) => theme.shadow.companySections};
  height: calc(100vh - var(--slidingSidebarHeight));
  position: sticky;
  top: 0;
  width: 0;
  opacity: 0;
  overflow-x: hidden;
  transform: translateZ(0);
  ${({ theme }) => theme.mixins.transition(['width', 'opacity'])}

  &.active {
    width: 292px;
    opacity: 1;
  }
`;

const Header = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const TitleContainer = styled(Flexbox)`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
  cursor: pointer;
  height: 70px;
`;

const TitleValue = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 420px;
`;

const List = styled('ul')`
  ${({ theme }) => theme.mixins.scrollbar}
  scrollbar-gutter: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.basics.canvas};
  padding: 8px 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 4px;
`;

const ListItem = styled('li')<{ selected: boolean }>`
  ${({ theme }) => theme.typography.navigationPanel.nonSelected}
  height: 48px;
  padding: 0 18px;
  border-radius: 8px;
  cursor: pointer;

  ${({ theme, selected }) =>
    selected
      ? `
        color: ${theme.colors.blue.primaryA};
        background-color: ${theme.colors.blue.c8};
        `
      : `
        &:hover {
          background-color: ${theme.colors.blue.c9};
        }`}
`;
