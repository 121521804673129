import { CompaniesPage } from '@/features/companies/companies.page';
import { CompanyPage } from '@/features/companies/company.page';
import { Routes, Route } from 'react-router-dom';

export const Companies = () => {
  return (
    <Routes>
      <Route index element={<CompaniesPage />} />
      <Route path=":companyId" element={<CompanyPage />} />
    </Routes>
  );
};
