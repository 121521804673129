import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { BorderedTextArea } from '../../../../ui/textarea/bordered-text-area';

import { ProductDescription } from './components/product-description';
import type { CompanyProducts } from '@/api/v4/organization.api';

type ProductDescription = Pick<CompanyProducts, 'productDescription'>;

interface CompanyProductDescriptionProps {
  className?: string;
  productDescription: string | null;
  setData: (data: Partial<ProductDescription>) => void;
  isEditMode: boolean;
  showErrors: boolean;
}

export const CompanyProductDescription = ({
  className,
  productDescription,
  setData,
  isEditMode,
  showErrors,
}: CompanyProductDescriptionProps) => {
  const { t } = useTranslation('companies');

  const handleDescriptionChanged = (newValue: string) => {
    setData({ productDescription: newValue });
  };

  return (
    <Flexbox
      name="company-product-description-widget"
      direction="column"
      gap="12px"
      className={className}
    >
      <SubHeaderBold>{t`products.description`}</SubHeaderBold>
      {isEditMode ? (
        <>
          <BorderedTextArea
            height={115}
            value={productDescription}
            onChange={handleDescriptionChanged}
            error={showErrors && !productDescription}
            resizeOnContentGrowth
          />
          {showErrors && !productDescription && (
            <FormErrorMessage>{t`products.descriptionMissingError`}</FormErrorMessage>
          )}
        </>
      ) : productDescription ? (
        <ProductDescription>{productDescription}</ProductDescription>
      ) : (
        <ProductDescription color={colors.gray.c9}>
          {t`products.descriptionPlaceholder`}
        </ProductDescription>
      )}
    </Flexbox>
  );
};

const FormErrorMessage = styled('p')`
  color: ${({ theme }) => theme.colors.system.lowFit};
  display: flex;
  justify-content: end;
`;
