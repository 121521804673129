import {
  addCompanyExternalLinkAPI,
  type AddCompanyExternalLinkPayload,
} from '@/api/v4/organization-external-links.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useAddCompanyExternalLink = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  return useMutation(
    async ({ companyId, data }: AddCompanyExternalLinkPayload) => {
      return addCompanyExternalLinkAPI({ companyId, data });
    },
    {
      onSuccess: async (_data, variables) => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyExternalLinks,
          variables.companyId,
        ]);
      },
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
