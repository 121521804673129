import { CompaniesPageContent } from '@/features/companies/companies-page-content';
import { WithHtmlSavvyTitle } from '../../with-html-savvy-title';

export const CompaniesPage = () => {
  return (
    <WithHtmlSavvyTitle title={'Companies'}>
      <CompaniesPageContent />
    </WithHtmlSavvyTitle>
  );
};
