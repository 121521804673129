import { useRecoilValue } from 'recoil';

import { companyIdState } from '../company.state';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@/config/query-client';
import { fetchCompanyRatings } from '@/api/v4/organization-ratings.api';
import { notify } from '@/ui/snackbar/notify';

export const useCompanyRankings = () => {
  const companyId = useRecoilValue(companyIdState);
  const { t } = useTranslation('default');

  return useQuery(
    [QueryKey.CompanyRankings, companyId],
    () => (companyId ? fetchCompanyRatings(companyId) : undefined),
    {
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      enabled: !!companyId,
    },
  );
};
