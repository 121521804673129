import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { notify } from '@/ui/snackbar/notify';
import type { ChangeUserStatusPayload } from '@/api/v4/user.api';
import { changeUserStatus as changeUserStatusAPI } from '@/api/v4/user.api';

export const useEditUserStatus = () => {
  const { t } = useTranslation('default');

  const mutation = useMutation(changeUserStatusAPI, {
    onError: () => {
      notify({
        message: t('unknownError'),
      });
    },
  });

  const changeUserStatusFn = (payload: ChangeUserStatusPayload) =>
    mutation.mutateAsync({
      userId: payload.userId,
      status: payload.status,
    });

  return {
    changeUserStatus: changeUserStatusFn,
    isLoading: mutation.isLoading,
  };
};
