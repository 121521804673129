import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { companyIdState } from '../company.state';
import type { OrganizationInvestor } from '@/api/v4/organization.api';
import { fetchOrganizationInvestors } from '@/api/v4/organization.api';
import type { InvestorOption } from '@/types';

export const useCompanyInvestors = () => {
  const companyId = useRecoilValue(companyIdState);

  const getInvestors = async () => {
    if (!companyId)
      return {
        investors: [],
        poweredBy: null,
        lastEditedAt: null,
      };

    return fetchOrganizationInvestors(companyId);
  };

  return useQuery([QueryKey.CompanyInvestors, companyId], getInvestors, {
    enabled: !!companyId,
    staleTime: 1000 * 60 * 5,
  });
};

export const mapOrganizationInvestorsToOptions = (
  investors: OrganizationInvestor[],
): InvestorOption[] => {
  return investors.map(i => ({
    value: i.id,
    label: i.name,
    investorId: i.id,
    crunchbaseId: i.crunchbaseId,
    website: i.website,
    logoUrl: i.logoUrl,
    description: i.description,
    source: i.source,
  }));
};
