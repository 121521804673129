import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import type { IOption } from '@/types';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';

import { useParams } from 'react-router-dom';
import { exploreResultsSortState } from './explore.state';
import { useExploreForm } from './form/use-explore-form';
import { useRequestHistorySuggestions } from './history/use-request-history-suggestions';
import { ExploreErrorView } from './results/explore-error-view';
import { ExploreNoResultsView } from './results/explore-no-results-view';
import { ExploreResults } from './results/explore-results';
import { ExploreLoadingView } from './results/loading/explore-loading-view';
import { useExploreNavigationHistory } from './use-explore-navigation-history';
import { useExploreSuggestions } from './use-explore-suggestions';

export const ExploreResultsPage = () => {
  const { requestId } = useParams();
  const { isFromHistory } = useExploreNavigationHistory();
  const { data: requestHistoryData, isLoading: isLoadingRequestHistoryData } =
    useRequestHistorySuggestions(requestId, isFromHistory);
  const {
    data: generatedSuggestions,
    isLoading: isLoadingGeneratedSuggestions,
    isError,
  } = useExploreSuggestions();
  const {
    description: formDescription,
    exampleCompanies: formExampleCompanies,
    locations: formLocations,
  } = useExploreForm();
  const sort = useRecoilValue(exploreResultsSortState);

  const inputData = useMemo(() => {
    if (!requestHistoryData)
      return {
        description: formDescription,
        exampleCompanies: formExampleCompanies,
        locations: formLocations,
      };

    const locations: IOption[] = [
      ...requestHistoryData.locationContinent?.map(continent => ({
        label: continent.shortDescription,
        value: JSON.stringify({ id: continent.id, type: 'continent' }),
      })),
      ...requestHistoryData.locationCountry?.map(country => ({
        label: country.shortDescription,
        value: JSON.stringify({ id: country.id, type: 'country' }),
      })),
    ];

    const exampleCompanies = requestHistoryData.companies.map(company => ({
      id: company.organizationId ?? company.crunchbaseId ?? '',
      name: company.name ?? '',
      website: company.website ?? '',
      logoUrl: company.logoUrl ?? '',
    }));

    return {
      description: requestHistoryData?.requestStatement ?? '',
      exampleCompanies,
      locations,
    };
  }, [
    requestHistoryData,
    formDescription,
    formExampleCompanies,
    formLocations,
  ]);

  const suggestionData = useMemo(() => {
    if (isFromHistory) {
      return requestHistoryData?.result ?? [];
    }

    return generatedSuggestions?.results ?? [];
  }, [
    isFromHistory,
    generatedSuggestions?.results,
    requestHistoryData?.result,
  ]);

  const dataQueryKey = isFromHistory
    ? QueryKey.ExploreRequestHistorySuggestions
    : QueryKey.ExploreSuggestions;

  const isLoading = isFromHistory
    ? isLoadingRequestHistoryData
    : isLoadingGeneratedSuggestions;

  return (
    <WithHtmlSavvyTitle title="Explore">
      {suggestionData?.length && requestId ? (
        <ExploreResults
          inputData={inputData}
          suggestionData={suggestionData}
          key={sort.direction}
          dataQueryKey={dataQueryKey}
          requestId={requestId}
        />
      ) : suggestionData?.length === 0 && requestId && !isLoading ? (
        <ExploreNoResultsView />
      ) : isLoading && !isError ? (
        <ExploreLoadingView />
      ) : (
        <ExploreErrorView />
      )}
    </WithHtmlSavvyTitle>
  );
};
