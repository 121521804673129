import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import type { TeamsSortParams } from '@/api/v4/team.api';
import type { ISort } from '@/ui/table/table.types';

import { type TeamFilters, teamsSortState } from '../team.state';

import { stringifyTeamsFilters } from './teams-filters-helpers';

export const useTeamsFiltersSearchLocationSearch = () => {
  const navigate = useNavigate();
  const sort = useRecoilValue(teamsSortState);

  const updateLocationSearch = (
    filters: TeamFilters,
    newSort?: ISort<TeamsSortParams>,
  ) => {
    const locationSearch = stringifyTeamsFilters({
      filters,
      sort: newSort || (sort.sort ? sort : undefined),
    });
    navigate({ ...location, search: locationSearch }, { replace: true });
  };

  const resetLocationSearch = () => {
    const search = sort.sort ? stringifyTeamsFilters({ sort }) : '';
    navigate({ ...location, search }, { replace: true });
  };

  return {
    updateLocationSearch,
    resetLocationSearch,
  };
};
