import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { StatusTag as Badge } from '@/ui/tag/status-tag';

export const ImpactsEmptyState = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('companies');

  return (
    <Badge variant="pink">
      {t`company.header.impactsEmptyState1`}
      <Link onClick={onClick}>{t`company.header.impactsEmptyState2`}</Link>
    </Badge>
  );
};

const Link = styled('span')`
  text-decoration: underline;
  cursor: pointer;
`;
