import type { MutableRefObject } from 'react';
import { useMemo, useRef } from 'react';

import stockExchangeSymbols from '@/features/companies/stock-exchange-symbols.json';
import { useLoadableData } from '@/hooks/use-loadable-results';
import { impactsOptionsState } from '@/state/impacts-options.state';
import type { CompanyLocationOption } from '@/types';
import type { SelectOptionValue } from '@/ui/select/select';
import { getYearsOptions } from '@/utils/years';

import { getCompanyHeadquarterLocationOptionList } from '../helpers/get-company-headquarter-location-option-list';
import { useCompany } from '../overview/use-company.query';

import { useMetadata } from './use-metadata';
import { useEmployeesRanges } from '@/hooks/queries/use-employees-ranges.query';
import { useActivityStatuses } from '@/hooks/queries/use-activity-statuses.query';
import { useOwnershipStatuses } from '@/hooks/queries/use-ownership-statuses.query';

export const useDropdownFormFields = () => {
  const { hqLocation } = useMetadata();
  const { data: company } = useCompany();
  const { data: impactsOptions } = useLoadableData(impactsOptionsState);

  const { data: employeesRangeOptions } = useEmployeesRanges();
  const { data: activityStatusOptions } = useActivityStatuses();
  const { data: ownershipOptions } = useOwnershipStatuses();

  const yearsOptions: MutableRefObject<SelectOptionValue<string>[]> = useRef([
    ...getYearsOptions(),
  ]);

  const mapStockExchangeSymbolsJSONtoOptionsArray = () => {
    const options = [];
    for (const key in stockExchangeSymbols) {
      options.push({ label: key, value: key.toLowerCase() });
    }
    return options;
  };

  const stockExchangeSymbolOptions: MutableRefObject<
    SelectOptionValue<string>[]
  > = useRef([...mapStockExchangeSymbolsJSONtoOptionsArray()]);

  const setHQLocationOptions = async (search: string) =>
    await getCompanyHeadquarterLocationOptionList(search);

  const hqLocationOptionDefaultValue: CompanyLocationOption | null =
    useMemo(() => {
      if (!hqLocation) {
        return null;
      }

      return {
        label: {
          cityName: hqLocation.value?.city?.name ?? '',
          countryName: hqLocation.value?.country?.name ?? '',
          regionName: hqLocation.value?.region?.name ?? '',
        },
        value: {
          countryId: hqLocation.value?.country?.id ?? null,
          cityId: hqLocation.value?.city?.id ?? null,
          regionId: hqLocation.value?.region?.id ?? null,
        },
      };
    }, [hqLocation]);

  const impactsDefaultValue =
    company?.impacts
      ?.map(impact => {
        const impactOption = impactsOptions.find(
          opt => opt.value === impact.id,
        );
        return impactOption;
      })
      .filter(value => !!value) || [];

  return {
    ownershipOptions,
    activityStatusOptions,
    employeesRangeOptions,
    yearsOptions: yearsOptions.current,
    stockExchangeSymbolOptions: stockExchangeSymbolOptions.current,
    setHQLocationOptions,
    hqLocationOptionDefaultValue,
    impactsOptions,
    impactsDefaultValue,
  };
};
