import { useRef } from 'react';

import { Icon } from '@/ui/icons/icon';

import type { InvestorOption as InvestorOptionType } from '@/types';
import { Logo } from '@/ui/logo/logo';
import { OptionLabel } from '@/ui/select/async/async-select.styles';
import { CustomOptionWrapper } from '@/ui/select/async/components/option/custom-option-wrapper';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { InvestorTooltipContent } from './investor-tooltip-content';

interface Props extends AsyncSelectOptionProps {
  data: InvestorOptionType;
}

export const InvestorAsyncSelectOption = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CustomOptionWrapper
      dataTestId="investor-async-select-option"
      tooltipContent={
        <InvestorTooltipContent
          description={props.data.description}
          crunchbaseId={props.data.crunchbaseId}
        />
      }
      {...props}
    >
      <Logo name={props.data.label} logoUrl={props.data.logoUrl} singleLetter />
      <OptionLabel ref={ref}>{props.data.label}</OptionLabel>
      {props.data.source === 'crunchbase' && !props.isSelected ? (
        <Icon icon="FromCb" />
      ) : null}
    </CustomOptionWrapper>
  );
};
