import { useMemo, useState } from 'react';
import queryString from 'query-string';

export const useSignUpParams = () => {
  const [areParamsFetched, setFetchedParams] = useState(false);

  const invitedUserParams = useMemo(() => {
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery && typeof parsedQuery.email === 'string') {
      return {
        email: parsedQuery.email,
      };
    }
    return null;
  }, []);

  return {
    invitedUserParams,
    areParamsFetched,
    setFetchedParams,
  };
};
