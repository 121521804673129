import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline } from '@/ui/line/line';
import { SmallText, SubHeader } from '@/ui/typography/widgets';

import type { GeneralInformationValue } from '../../hooks/use-general-information';
import { useGeneralInformation } from '../../hooks/use-general-information';

const InfoBlock = ({
  label,
  value,
  withEmptyState,
}: GeneralInformationValue) => {
  const { t } = useTranslation('companies', { keyPrefix: 'company' });

  if (!value && !withEmptyState) {
    return null;
  }

  const valueToDisplay = withEmptyState && !value ? t`header.notAdded` : value;

  return (
    <Flexbox name="company-general-info-block-element" direction="column">
      <SubHeader
        color={!value && withEmptyState ? colors.gray.c9 : 'inherit'}
        data-testid={
          'company-general-info-' + label.replaceAll(' ', '-').toLowerCase()
        }
      >
        {valueToDisplay}
      </SubHeader>
      <SmallText color={colors.gray.c13}>{label}</SmallText>
    </Flexbox>
  );
};

export const GeneralInformation = () => {
  const { generalInformationValues, areAllFieldsValid } =
    useGeneralInformation();

  return (
    <Container isFullLengthList={areAllFieldsValid}>
      {generalInformationValues?.map(info => (
        <InfoBlock
          key={info.label}
          label={info.label}
          value={info.value}
          withEmptyState={info.withEmptyState}
        />
      ))}
    </Container>
  );
};

const Container = styled(Inline)<{ isFullLengthList: boolean }>`
  width: fit-content;
  gap: ${({ isFullLengthList }) => `${isFullLengthList ? '30px' : '48px'};`};
  ${({ isFullLengthList }) =>
    `@media
    (min-width: 1440px) {
    gap: ${isFullLengthList ? '48px' : '64px'};
  }`};
`;
