import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { selectedCompaniesFiltersState } from '@/features/companies/companies.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Switch } from '@/ui/switch/switch';

export const VerifiedFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  return (
    <Flexbox
      name="async-select-container"
      direction="column"
      gap="26px"
      padding="0 0 0 77px"
      className={className}
    >
      <Switch
        checked={selectedFilters.verified ?? false}
        onChange={({ target }) =>
          setSelectedFilters(currentFilters => ({
            ...currentFilters,
            verified: target.checked,
          }))
        }
      />
      <Label>{t`companies.filters.verified`}</Label>
    </Flexbox>
  );
};

const Label = styled('label')`
  ${({ theme }) => theme.typography.inputs.select.label}
`;
