import { useMemo } from 'react';
import {
  differenceInDays,
  format,
  getYear,
  isBefore,
  isSameDay,
  isSameYear,
  subDays,
} from 'date-fns';

import type { GenAiRequest } from '@/api/v4/genai-request-history.api';

import { useExploreHistory } from './use-explore-history';

export type RequestHistoryItem = GenAiRequest;

type SortedRequestHistoryByDate = Record<string, RequestHistoryItem[]>;

enum DatePeriod {
  Today = 'today',
  Yesterday = 'yesterday',
  PastWeek = 'pastWeek',
  PastMonth = 'pastMonth',
}

export const useSortedExploreHistory = () => {
  const { data } = useExploreHistory();

  const sortedRequests = useMemo(() => {
    if (!data?.pages?.length || !data?.pages?.[0]?.length) {
      return null;
    }

    const historyRequests = data.pages.flat();
    const today = new Date();
    const yesterday = subDays(today, 1);
    const currentYear = getYear(new Date());

    const assignRequestToPeriod = (
      acc: SortedRequestHistoryByDate,
      period: string,
      request: RequestHistoryItem,
    ) => {
      acc[period] = acc[period] || [];
      acc[period].push(request);
      return acc;
    };

    const grouped = historyRequests.reduce((acc, currentRequestItem) => {
      const requestDate = new Date(currentRequestItem.createdDate);
      const yearOfRequestDate = getYear(requestDate);
      const withinSevenDays = differenceInDays(today, requestDate) < 7;
      const withinThirtyDays = differenceInDays(today, requestDate) < 30;

      if (isSameDay(requestDate, today)) {
        return assignRequestToPeriod(acc, DatePeriod.Today, currentRequestItem);
      }

      if (isSameDay(requestDate, yesterday)) {
        return assignRequestToPeriod(
          acc,
          DatePeriod.Yesterday,
          currentRequestItem,
        );
      }

      if (isBefore(requestDate, today) && withinSevenDays) {
        return assignRequestToPeriod(
          acc,
          DatePeriod.PastWeek,
          currentRequestItem,
        );
      }

      if (isBefore(requestDate, today) && withinThirtyDays) {
        return assignRequestToPeriod(
          acc,
          DatePeriod.PastMonth,
          currentRequestItem,
        );
      }

      if (isSameYear(requestDate, today)) {
        const monthName = format(requestDate, 'MMMM');
        return assignRequestToPeriod(acc, monthName, currentRequestItem);
      }

      if (yearOfRequestDate === currentYear - 1) {
        const year = String(yearOfRequestDate);
        return assignRequestToPeriod(acc, year, currentRequestItem);
      }

      return acc;
    }, {} as SortedRequestHistoryByDate);

    return Object.entries(grouped).map(([period, requests]) => ({
      period,
      requests,
    }));
  }, [data]);

  return {
    sortedRequests,
  };
};
