import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import type { EditMyProfilePayload } from '@/api/v4/user.api';
import { editMyProfile } from '@/api/v4/user.api';

export const useEditProfile = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const mutation = useMutation(editMyProfile, {
    onError: () => {
      notify({
        message: t('unknownError'),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.WhoAmI]);
    },
  });

  const editProfile = (payload: EditMyProfilePayload) =>
    mutation.mutateAsync(payload);

  return {
    editProfile,
    isLoading: mutation.isLoading,
  };
};
