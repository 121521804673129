import { styled } from 'goober';

import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { forwardRef } from 'react';
import { Flexbox } from '@/ui/flexbox/flexbox';

export const KpiLoadingSkeleton = () => {
  const kpiElementsCount = 5;
  return (
    <Flexbox name="kpi-skeleton" justify="space-between">
      {Array.from({ length: kpiElementsCount }).map((_, index) => (
        <ElementBox key={index}>
          <SkeletonElement withRandomWidth />
        </ElementBox>
      ))}
    </Flexbox>
  );
};

const ElementBox = styled('div')`
  width: 200px;
  padding: 0 20px;
`;

const SkeletonElement = styled(SkeletonText, forwardRef)`
  height: 26px;
`;
