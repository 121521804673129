import { useMemo } from 'react';

import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { paths } from '@/routes/helpers/paths';

import { isDealStage, ListingStageName } from '../../../listing-stage/types';

const STATUS_SEARCH_PARAM = 'listingStatuses';

export const useRedirectToCompanies = () => {
  const { listingStages } = useListingStages();

  const dealStagesIds = useMemo(
    () =>
      listingStages.reduce((acc: string[], stage) => {
        if (isDealStage(stage.name)) {
          acc.push(stage.id);
        }
        return acc;
      }, []),
    [listingStages],
  );

  const dealStagesSearchParam = useMemo(
    () =>
      `?${dealStagesIds
        ?.map(stageId => `${STATUS_SEARCH_PARAM}=${stageId}`)
        .join('&')}`,
    [dealStagesIds],
  );

  const getStageId = (stageName: string) =>
    listingStages.find(
      stage =>
        stage.name.toLowerCase() === stageName.toLowerCase() ||
        stage.displayName?.toLowerCase() === stageName.toLowerCase(),
    )?.id;

  const createStageIdSearchParam = (stageId?: string) =>
    stageId ? `?${STATUS_SEARCH_PARAM}=${stageId}` : '';

  const getRedirectToFilteredCompaniesLink = (stageName: string) => {
    const params =
      stageName === ListingStageName.deal
        ? dealStagesSearchParam
        : createStageIdSearchParam(getStageId(stageName));
    return paths.companies() + params;
  };

  return { getRedirectToFilteredCompaniesLink };
};
