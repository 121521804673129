import { styled } from 'goober';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import { useVisibleTableData } from '@/ui/table/helpers/use-visible-data';
import type { SkeletonRowConfig } from '@/ui/table/infinite-table/skeleton-row';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';
import type { IHeaderRow, Row, ISort } from '@/ui/table/table.types';

interface Props<Sort extends string = string> {
  onSort?: (sort: ISort<Sort>) => void;
  isLoading: boolean;
  rows: Row[];
  headers: IHeaderRow<Sort>;
  sortState: RecoilState<ISort<Sort>>;
  borderColor?: string;
  skeletonConfig?: Pick<SkeletonRowConfig, 'rowPadding'>;
}

export const Table = <Sort extends string = string>({
  onSort,
  isLoading,
  rows,
  headers,
  sortState,
  borderColor,
  skeletonConfig,
}: Props<Sort>) => {
  const [sortData, setSortData] = useRecoilState(sortState);

  const { visibleColumns, visibleRows } = useVisibleTableData({
    rows,
    header: headers,
  });

  const handleSort = (sort: ISort<Sort>) => {
    if (isLoading) return; // prevent multisorting while previously loading
    setSortData(sort);
    onSort?.(sort);
  };

  return (
    <TableComponent>
      <thead>
        <tr>
          {visibleColumns.map(column => (
            <TableHeaderCell<Sort>
              key={column.columnKey}
              cell={column}
              sort={{ ...sortData, onSort: handleSort }}
            />
          ))}
        </tr>
      </thead>
      <TableBody
        rows={visibleRows}
        isLoading={isLoading}
        borderColor={borderColor}
        skeletonConfig={{
          columns: headers.cells.length,
          rowConfig: headers.cells.map(({ align }) => ({ align })),
          ...skeletonConfig,
        }}
      />
    </TableComponent>
  );
};

const TableComponent = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 15px);
  position: relative;
`;
