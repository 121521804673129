import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import { type CompanyResponse } from '@/api/v3/organizations-api';
import { Loader } from '@/components/loader/loader';
import { isInvestorOption, isInvestorOptionArray } from '@/helpers/other';
import type { InvestorOption as InvestorOptionType } from '@/types';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { Header } from '@/ui/typography/widgets';
import {
  EmptyStateWidget,
  type EmptyStateWidgetProps,
} from '@/ui/widget/empty-state-widget';
import { Widget, type WidgetProps } from '@/ui/widget/widget';

import { WIDGET_CONTAINER_GAP } from '../../../../components/widgets/text-widget/text-widget';
import { mapInvestorsToOptions } from '../../../../api/v4/mappers/investor-option.mapper';
import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import { useCrunchbaseEnrichmentCheck } from '../../hooks/use-crunchbase-enrichment-check';

import { fetchInvestors } from '@/api/v4/investor.api';
import { useEditCompanyInvestors } from './use-edit-company-investors.mutation';
import {
  mapOrganizationInvestorsToOptions,
  useCompanyInvestors,
} from './use-company-investors.query';
import { useCompany } from '../use-company.query';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { InvestorsList } from './investors-list';
import { SelectedInvestorsOptionList } from './select/selected-investors-options-list';
import { InvestorAsyncSelectOption } from './select/investor-async-select-option';
import { WidgetLoaderContainer } from '../widget-loader-container';
import { WIDGET_HEADER_MARGIN, WIDGET_ROW_1_HEIGHT } from '../utils/constants';

interface InvestorsWidgetProps extends WidgetProps {
  defaultValue?: CompanyResponse['investors'] | undefined;
  emptyStateOptions?: Omit<EmptyStateWidgetProps, 'onClick'>;
  optionsListLimit?: number;
  onSave?: (value: string[]) => void;
}

export function InvestorsWidget({
  height,
  emptyStateOptions,
  optionsListLimit = 0,
  className,
  ...widgetProps
}: InvestorsWidgetProps) {
  const { t } = useTranslation(['companies', 'default']);
  const [isEditMode, setIsEditMode] = useState(false);

  const { data: company } = useCompany();
  const { data, isLoading } = useCompanyInvestors();

  const { isEnrichedFromCrunchbase } =
    useCrunchbaseEnrichmentCheck('investors');
  const [draftOptions, setDraftOptions] = useState<InvestorOptionType[]>([]);

  const {
    mutateAsync: editOrganizationInvestors,
    isLoading: isEditInProgress,
  } = useEditCompanyInvestors();

  const investors = useMemo(() => (data ? data.investors : []), [data]);

  useEffect(() => {
    if (isEditMode) {
      setDraftOptions(mapOrganizationInvestorsToOptions(investors));
    }
  }, [isEditMode]);

  const listRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string>();

  const canEditCompany = useCanEditCompany();
  const disabled = !canEditCompany;

  useEffect(() => {
    if (investors.length <= optionsListLimit) {
      setMaxHeight(undefined);
      return;
    }

    const ITEM_HEIGHT = 30;
    const ITEM_GAP = 16;
    const height = (ITEM_HEIGHT + ITEM_GAP) * (optionsListLimit - 1) - ITEM_GAP;

    setMaxHeight(`${height}px`);
  }, [optionsListLimit, investors]);

  const handleOnConfirm = async () => {
    if (!isEditMode || !company?.id) return;

    setIsEditMode(false);
    if (
      draftOptions.length === investors.length &&
      draftOptions.every((item, index) => item.value === investors[index].id)
    ) {
      return;
    }

    await editOrganizationInvestors(
      draftOptions.map(option => ({
        source: option.source,
        id: option.value,
      })),
    );
  };

  if (!company) return null;

  if (isLoading || isEditInProgress) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_1_HEIGHT}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!isEditMode && emptyStateOptions && !disabled && !investors.length) {
    return (
      <EmptyStateWidget
        className={className}
        height={height}
        onEditClick={() => setIsEditMode(true)}
        {...emptyStateOptions}
      />
    );
  }

  const handleOnExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const setOptions: CustomAsyncSelectProps['setOptions'] = async search => {
    const investors = await fetchInvestors({ search });
    return mapInvestorsToOptions(investors);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOnConfirm}>
      <Container
        className={className}
        header={<HeaderContainer>{t`investors.header`}</HeaderContainer>}
        height={height}
        isEditMode={isEditMode}
        onEditClick={() => setIsEditMode(true)}
        disabled={disabled}
        showExpandButton={!isEditMode && investors.length > optionsListLimit}
        labelExpand={t`default:showAll`}
        labelCollapse={t`default:showLess`}
        showMore={isExpanded}
        onReadMoreClick={handleOnExpand}
        showEditStamp={!isEditMode && isEnrichedFromCrunchbase}
        editStampProps={{ crunchbasePermalink: company.crunchbasePermalink }}
        {...widgetProps}
      >
        {!isEditMode ? (
          <InvestorsList
            list={investors}
            listRef={listRef}
            height={!isExpanded ? maxHeight : undefined}
            isListExpanded={isExpanded}
          />
        ) : (
          <AsyncSelectWithList
            defaultValue={mapOrganizationInvestorsToOptions(investors)}
            setOptions={setOptions}
            placeholder={t`investors.inputPlaceholder`}
            hideSelectedOptions={false}
            components={{ Option }}
            disableFetchMore
            SelectedOptionsListComponent={<SelectedInvestorsOptionList />}
            onChange={newValue => {
              isInvestorOptionArray(newValue) && setDraftOptions(newValue);
            }}
          />
        )}
      </Container>
    </OutsideClickHandler>
  );
}

function Option({ data, ...props }: AsyncSelectOptionProps) {
  if (isInvestorOption(data)) {
    return <InvestorAsyncSelectOption {...props} data={data} />;
  }

  return null;
}

const Container = styled(Widget)`
  display: flex;
  flex-direction: column;
  gap: ${WIDGET_CONTAINER_GAP}px;
`;

const HeaderContainer = styled(Header)`
  margin: ${WIDGET_HEADER_MARGIN};
`;
