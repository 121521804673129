import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useResetRecoilState } from 'recoil';

import type { TabConfig } from '@/ui/tabs/tabs';
import { Tabs } from '@/ui/tabs/tabs';

import { settingsActiveTabState } from './settings.state';
import { SettingsTabContent } from './settings-tab-content';
import { SettingsTabControl } from './settings-tab-control';
import type { SettingsTabs } from './use-settings-tabs-config';
import { useSettingsTabsConfig } from './use-settings-tabs-config';
import { useUsersCount } from './use-users-count.query';
import { useSearchParams } from 'react-router-dom';

type SettingsTabCounterMapper = Record<SettingsTabs, number>;

export const SettingsContent = () => {
  const { t } = useTranslation('settings');

  const [activeTab, setActiveTab] = useRecoilState(settingsActiveTabState);
  const resetActiveTab = useResetRecoilState(settingsActiveTabState);

  const { data: usersCount } = useUsersCount();
  const [searchParams, setSearchParams] = useSearchParams();

  const settingsTabsConfig = useSettingsTabsConfig();

  useEffect(() => {
    const tab = searchParams.get('tab') as SettingsTabs;

    if (tab) {
      setActiveTab(tab);
      return;
    }

    setSearchParams({ tab: activeTab });
  }, [activeTab, searchParams, setActiveTab, setSearchParams]);

  useEffect(() => {
    return () => {
      resetActiveTab();
    };
  }, []);

  const mappedTabCounterValues: SettingsTabCounterMapper = {
    invitedUsers: usersCount?.invitedUsers ?? 0,
    userManagement: usersCount?.activeAndDisabledUsers ?? 0,
    profileSettings: 0,
  };

  const tabsConfig: TabConfig<SettingsTabs>[] = settingsTabsConfig.map(tab => ({
    id: tab.id,
    title: (
      <SettingsTabControl
        id={tab.id}
        name={t(tab.id)}
        countIndicator={mappedTabCounterValues[tab.id]}
      />
    ),
    content: <SettingsTabContent tab={tab.id} />,
  }));

  return (
    <SettingsContainer>
      <Tabs<SettingsTabs>
        tabsConfig={tabsConfig}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        border
        width="max-content"
        noShadow
        padding="0px 30px"
      />
    </SettingsContainer>
  );
};
const SettingsContainer = styled('div')`
  padding: 32px 0px;
  background-color: ${({ theme }) => theme.colors.basics.white};
`;
