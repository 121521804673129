import type { LegacyRef } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { Team } from '@/api/v4/team.api';
import { useNavigateToProjectFormWithCompanyData } from '@/features/projects/use-navigate-to-project-form-with-company-data';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { StatusTag } from '@/ui/tag/status-tag';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { Widget } from '@/ui/widget/widget';

import { RelatedProjectsWidgetEmptyState } from './related-project-empty-state';
import { RelatedProjectOverflowContainer } from './related-project-overflow-container';
import { RelatedProjectRow } from './related-project-row';
import type { TeamRelatedProject } from '@/api/v4/team-related-projects.api';

const WidgetHeader = forwardRef(
  (_props, ref: LegacyRef<HTMLHeadingElement>) => {
    const { t } = useTranslation('teams');

    return <SubHeaderBold ref={ref}>{t`relatedProjects`}</SubHeaderBold>;
  },
);

WidgetHeader.displayName = 'WidgetHeader';

export const RelatedProjectsWidget = ({
  relatedProjects,
  organization,
  defaultHeight,
  disabled,
  className,
}: {
  relatedProjects: TeamRelatedProject[];
  organization: Team['organization'] | null;
  defaultHeight: number;
  disabled: boolean;
  className?: string;
}) => {
  const { t } = useTranslation('teams');

  const { navigateToProjectFormWithCompanyData } =
    useNavigateToProjectFormWithCompanyData();

  if (relatedProjects.length === 0)
    return (
      <Widget
        height={defaultHeight}
        showEditButton={false}
        className={className}
      >
        <Flexbox name="related-projects-widget-header" gap="10px">
          <SubHeaderBold>{t`relatedProjects`}</SubHeaderBold>
          <StatusTag variant="blue" weight="semibold">
            {relatedProjects.length}
          </StatusTag>
        </Flexbox>

        <RelatedProjectsWidgetEmptyState
          onClick={() => navigateToProjectFormWithCompanyData(organization)}
          disabled={disabled}
        />
      </Widget>
    );

  return (
    <RelatedProjectOverflowContainer
      Header={WidgetHeader}
      defaultHeight={defaultHeight}
      disabled={disabled}
      disableAddCompany
    >
      {relatedProjects.map(project => {
        const { id, name, listingsCount, stage } = project;

        return (
          <RelatedProjectRow
            key={id}
            projectId={id}
            projectName={name}
            listingsCount={listingsCount}
            stage={stage}
          />
        );
      })}
    </RelatedProjectOverflowContainer>
  );
};
