import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { ProjectOnList, ProjectSortOptions } from '@/api/v4/projects.api';
import { ListStatusTag } from '@/components/list-status-tag/list-status-tag';
import { formatDate } from '@/helpers/format-date';
import { paths } from '@/routes/helpers/paths';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import {
  TablePrimaryCell,
  TableSecondaryCell,
} from '@/ui/table/infinite-table/table-cells';
import type { Row } from '@/ui/table/table.types';

import { useListingsSectionSearchParams } from '../project-listings/use-listings-section-search-params';

import { projectsHeaders } from './projects-table-configuration';

const rowPadding = {
  left: '60px',
  right: '60px',
};

export const useProjectsTableConfiguration = (projects: ProjectOnList[]) => {
  const { t } = useTranslation('projects');
  const listingsSectionSearchParams = useListingsSectionSearchParams();
  const header = useTranslateHeaders<ProjectSortOptions>(
    projectsHeaders,
    'projects',
    'projects',
  );
  const rows: Row[] = useMemo(
    () =>
      projects.map(project => {
        return {
          id: project.id,
          rowPadding,
          cells: [
            {
              value: (
                <>
                  <TablePrimaryCell maxWidth={300}>
                    <EllipsisTextTooltip
                      text={project.name}
                      Component={
                        <Link to={paths.project({ projectId: project.id })} />
                      }
                    />
                  </TablePrimaryCell>
                  {project.listingCount > 0 ? (
                    <TableSecondaryCell>
                      <Link
                        to={{
                          pathname: paths.project({ projectId: project.id }),
                          search: listingsSectionSearchParams,
                        }}
                      >
                        {t('projects.company', {
                          count: project.listingCount,
                        })}
                      </Link>
                    </TableSecondaryCell>
                  ) : (
                    <TableSecondaryCell>-</TableSecondaryCell>
                  )}
                </>
              ),
            },
            {
              value: project.mostAdvancedListingStatus ? (
                <ListStatusTag text={project.mostAdvancedListingStatus} />
              ) : (
                <TableSecondaryCell>-</TableSecondaryCell>
              ),
            },
            {
              value: (
                <TableSecondaryCell maxWidth={173}>
                  {project.createdForTeam?.id && (
                    <EllipsisTextTooltip
                      text={project.createdForTeam.name}
                      Component={
                        <Link
                          to={paths.team({ teamId: project.createdForTeam.id })}
                        />
                      }
                    />
                  )}
                </TableSecondaryCell>
              ),
            },
            {
              value: (
                <EllipsisTextTooltip
                  text={`${project.creator.firstName} ${project.creator.lastName}`}
                  Component={<TableSecondaryCell maxWidth={173} />}
                />
              ),
            },
            {
              value: (
                <TableSecondaryCell>
                  {project.createdDate ? formatDate(project.createdDate) : null}
                </TableSecondaryCell>
              ),
            },
            {
              value: (
                <TableSecondaryCell>
                  {project.lastEditedDate
                    ? formatDate(project.lastEditedDate)
                    : null}
                </TableSecondaryCell>
              ),
            },
          ],
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projects],
  );

  return {
    header,
    rows,
    skeletonConfig: {
      numberOfRows: 7,
      rowPadding,
    },
  };
};
