import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'goober';

import type { ProjectOnList } from '@/api/v4/projects.api';
import { ListStatusTag } from '@/components/list-status-tag/list-status-tag';
import { getListingStageByDefaultName } from '@/features/listing-stage/helpers/get-listing-stage-by-default-name';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { useListingsSectionSearchParams } from '@/features/projects/project-listings/use-listings-section-search-params';
import { paths } from '@/routes/helpers/paths';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { TableSecondaryCell } from '@/ui/table/infinite-table/table-cells';
import type { Row } from '@/ui/table/table.types';
import { RouteLink } from '@/ui/link/route-link';

export const useActiveProjectTableConfig = (projects: ProjectOnList[] = []) => {
  const { t } = useTranslation('projects');
  const { listingStages } = useListingStages();
  const listingsSectionSearchParams = useListingsSectionSearchParams();

  const rows = useMemo<Row[]>(() => {
    if (!projects.length) return [];
    return projects.map(rowEntry => {
      const listingStage = rowEntry.mostAdvancedListingStatus
        ? getListingStageByDefaultName(
            rowEntry.mostAdvancedListingStatus,
            listingStages,
          )
        : null;

      return {
        cells: [
          {
            value: (
              <Flexbox
                name="active-project-row-container"
                justify="space-between"
                alignItems="center"
                fullWidth
                gap="20px"
              >
                <Stack gap="0px">
                  <ProjectLink
                    to={{
                      pathname: paths.project({ projectId: rowEntry.id }),
                    }}
                  >
                    {rowEntry.name}
                  </ProjectLink>
                  <TableSecondaryCell>
                    <Link
                      to={{
                        pathname: paths.project({
                          projectId: rowEntry.id,
                        }),
                        search: listingsSectionSearchParams,
                      }}
                    >
                      {t('projects.company', {
                        count: rowEntry.listingCount || 0,
                      })}
                    </Link>
                  </TableSecondaryCell>
                </Stack>

                {!rowEntry.mostAdvancedListingStatus ? (
                  '-'
                ) : (
                  <ListStatusTag
                    text={
                      listingStage?.displayName ||
                      rowEntry.mostAdvancedListingStatus
                    }
                  />
                )}
              </Flexbox>
            ),
          },
        ],
        id: rowEntry.id,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return { rows };
};

const ProjectLink = styled(RouteLink)`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  ${({ theme }) => theme.mixins.ellipsis}
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
