import { Route, Routes } from 'react-router-dom';
import protect from './protect/protect';
import { PERMISSION } from '@/user/permissions/permission.type';
import { TeamsPage } from '@/features/teams/teams.page';
import { TeamOverviewPage } from '@/features/teams/team-overview.page';

export const Teams = () => {
  return (
    <Routes>
      <Route
        index
        element={protect(
          <TeamsPage />,
          {
            permissions: [PERMISSION.VIEW_ACCOUNT_DETAILS],
          },
          true,
        )}
      />
      <Route path=":teamId" element={<TeamOverviewPage />} />
      <Route
        path="create"
        element={protect(
          <TeamOverviewPage createNew />,
          {
            permissions: [PERMISSION.MANAGE_TEAMS],
          },
          true,
        )}
      />
    </Routes>
  );
};
