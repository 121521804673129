import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type LastActivity = APIResponse<'/api/v4/last-activities'>[number];
type LastActivitiesQueryParams =
  APIRequestQueryParams<'/api/v4/last-activities'>;

export const fetchLastActivities = async (params?: LastActivitiesQueryParams) =>
  get<LastActivity[]>('/v4/last-activities', { params });

export type LastActivitiesCounts =
  APIResponse<'/api/v4/last-activities/counts'>;

export const fetchLastActivitiesCount = async () =>
  get<LastActivitiesCounts>('/v4/last-activities/counts');
