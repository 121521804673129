import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { signIn } from '@/api/v4/auth.api';
import { BaseExceptions, isBaseError } from '@/api/v4/base-fetch';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';

import { jwtTokenState } from '../auth.state';
import { currentUserQuery } from '@/user/use-current-user.query';

export const useSignIn = () => {
  const navigateTo = useNavigateTo();

  const { t } = useTranslation('auth');
  const queryClient = useQueryClient();
  const setToken = useSetRecoilState(jwtTokenState);

  const signInMutation = useMutation(
    async (descopeToken: string) =>
      await signIn({
        descopeToken,
      }),
    {
      onSuccess: async data => {
        setToken('Bearer ' + data.token);

        const userQuery = currentUserQuery();

        await queryClient.fetchQuery(userQuery);
        navigateTo.home();
      },
      onError: error => {
        if (isBaseError(error)) {
          if (error.response.status === BaseExceptions.UNAUTHORIZED) {
            notify({ message: t`loginErrorNoUser` });
            return;
          }
        }
        notify({ message: t`loginError` });
      },
    },
  );

  const handleSignIn = (descopeToken: string) =>
    signInMutation.mutateAsync(descopeToken);

  return {
    handleSignIn,
  };
};
