import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { AppUsersTable } from '../../app-users/app-users-table';
import { AppUserEmptyState } from '../../app-users/empty-state';
import { AppUsersTableNoResults } from '../../app-users/no-results';
import { UserSearch } from '../../app-users/user-search';
import {
  settingsInvitedUsersSearchPhraseState,
  settingsInvitedUsersSortState,
} from '../settings.state';

import { useInvitedUsers } from './use-invited-users';
import { useInvitedUsersTableConfiguration } from './use-invited-users-table-configuration';
import type { UsersListSort } from '@/api/v4/user.api';

export const InvitedUsers = () => {
  const { t } = useTranslation('settings');
  const setModal = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();
  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );
  const { invitedUsers, isLoading } = useInvitedUsers();

  const [userSearchPhrase, setUserSearchPhrase] = useRecoilState(
    settingsInvitedUsersSearchPhraseState,
  );
  const { header, rows } = useInvitedUsersTableConfiguration(invitedUsers);

  const resetInvitedUsersSearchPhrase = useResetRecoilState(
    settingsInvitedUsersSearchPhraseState,
  );

  const handleInviteUser = () => setModal({ state: 'inviteUser' });

  return (
    <>
      <UserSearch
        onSearchInputChange={e => {
          setUserSearchPhrase(e.target.value);
        }}
        translationNamespace="settings"
      />
      <AppUsersTable<UsersListSort>
        users={invitedUsers}
        isLoading={isLoading}
        withSearchPhrase={Boolean(userSearchPhrase)}
        sortState={settingsInvitedUsersSortState}
        hasNextPage={false}
        noResultsElement={
          <AppUsersTableNoResults
            onReset={resetInvitedUsersSearchPhrase}
            resetResultsText={t`resetResults`}
            noResultsText={t`noResults`}
          />
        }
        emptyStateElement={
          <AppUserEmptyState
            onClick={handleInviteUser}
            buttonText={t`default:invite.inviteUser`}
            text={t`emptyUsers`}
            disabled={!hasManageTeamPermission}
          />
        }
        header={header}
        rows={rows}
      />
    </>
  );
};
