import type { FC, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

import { Tooltip } from '../tooltip/tooltip';

interface AdjustableHeaderProps {
  initialFontSize: number;
  smallerFontSize: number;
  containerMaxWidth: number;
  text: string;
}

export const AdjustableHeader: FC<AdjustableHeaderProps> = ({
  text,
  initialFontSize,
  smallerFontSize,
  containerMaxWidth,
}) => {
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const textRef: RefObject<HTMLHeadingElement> = useRef(null);
  const [fontSize, setFontSize] = useState(initialFontSize);
  const [isTooltipRequired, setTooltipRequired] = useState(false);

  useEffect(() => {
    const containerWidth =
      containerRef.current?.getBoundingClientRect().width || 0;
    const textWidth = textRef.current?.getBoundingClientRect().width || 0;

    if (textWidth > containerWidth) {
      setFontSize(smallerFontSize);
      setTooltipRequired(true);
    } else {
      setFontSize(initialFontSize);
    }
  }, [text, initialFontSize, smallerFontSize]);

  return (
    <Tooltip content={text} visible={isTooltipRequired} maxWidth="unset">
      <HeaderContainer ref={containerRef} containerMaxWidth={containerMaxWidth}>
        <Header fontSize={fontSize} ref={textRef}>
          {text}
        </Header>
      </HeaderContainer>
    </Tooltip>
  );
};

const HeaderContainer = styled('div', forwardRef)<{
  containerMaxWidth: number;
}>`
  max-width: ${({ containerMaxWidth }) => containerMaxWidth}px;
  ${({ theme }) => theme.mixins.ellipsis}
`;

const Header = styled('h1', forwardRef)<{
  fontSize: number;
}>`
  ${({ theme }) => theme.typography.widget.header}
  display: inline;
  font-size: ${({ fontSize }) => fontSize}px;
`;
