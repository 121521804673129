import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { DeploymentModel } from '@/api/v4/deployment-model.api';
import { fetchDeploymentModels } from '@/api/v4/deployment-model.api';

export const useDeploymentModels = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.DeploymentModels], fetchDeploymentModels, {
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    select: mapDeploymentModelsToOptions,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const mapDeploymentModelsToOptions = (deploymentModels: DeploymentModel[]) => {
  return deploymentModels.map(models => ({
    label: models.name,
    value: models.id,
  }));
};
