import { useTranslation } from 'react-i18next';
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil';

import type { TeamMember } from '@/api/v4/team.api';
import { modalState } from '@/state/modal.state';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { teamMembersByStatusState } from '../../team.state';

import type { TeamMemberStatus } from './basic-tab-config';

export const TeamMemberMenu = ({
  teamMember,
  status,
}: {
  teamMember: TeamMember;
  status: TeamMemberStatus;
}) => {
  const { t } = useTranslation('teams');
  const setModal = useSetRecoilState(modalState);
  const refreshTeamMembers = useRecoilRefresher_UNSTABLE(
    teamMembersByStatusState({ status }),
  );

  const { hasRequiredPermission } = useUserPermissions();

  const menu: DropdownOption[] = [
    {
      label: t`removeTeamMember`,
      isVisible: hasRequiredPermission(PERMISSION.MANAGE_TEAMS),
      onClick: () => {
        setModal({
          state: 'deleteTeamMember',
          user: teamMember,
          onConfirm: refreshTeamMembers,
        });
      },
    },
  ];

  return (
    <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
      <MenuButton />
    </WithDropdown>
  );
};
