import { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsYouType, isPossiblePhoneNumber } from 'libphonenumber-js';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { useZodForm } from '@/hooks/use-zod-form';
import { LINKEDIN_URL_REGEX } from '@/regexes';
import { modalState } from '@/state/modal.state';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { ContactCard } from './card/contact-card';
import { sortContactsByPrimaryAndFirstName } from './helpers/sorter';
import { EmptyContacts } from './empty-contacts';
import type { CompanyContact } from '@/api/v4/organization-contacts.api';

export const ContactsFormContainer = ({
  editMode,
  forceSubmit,
  addContactCounter = 0,
  onSubmit,
  onDelete,
  editState,
  formContactsState,
  visibleContacts,
  onContactDeletedState,
}: {
  editMode?: boolean;
  forceSubmit?: boolean;
  addContactCounter: number;
  onSubmit: (contacts: CompanyContact[]) => void;
  editState: RecoilState<boolean>;
  formContactsState: RecoilState<CompanyContact[]>;
  visibleContacts?: CompanyContact[];
  onDelete: (contactId: string, synced: boolean) => void;
  onContactDeletedState: RecoilState<string | null>;
}) => {
  const { t } = useTranslation('contacts');
  const setEditState = useSetRecoilState(editState);
  const contacts = useRecoilValue(formContactsState);
  const deletedContact = useRecoilValue(onContactDeletedState);
  const { hasRequiredPermission } = useUserPermissions();
  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );

  const modal = useRecoilValue(modalState);

  const validationSchema = z.object({
    contacts: z.array(
      z.object({
        id: z.string(),
        firstName: z.string().nonempty(t`forms.errors.firstNameIsRequired`),
        lastName: z.string().nonempty(t`forms.errors.lastNameIsRequired`),
        email: z
          .string()
          .email(t`forms.errors.invalidEmailAddress`)
          .nullable()
          .or(z.literal('')),
        title: z.string().nullable(),
        mobilePhone: z
          .string()
          .refine((value: string) => {
            const isValid = isPossiblePhoneNumber(
              new AsYouType().input(`+${value}`),
            ).valueOf();
            return isValid;
          }, t`forms.errors.invalidPhoneNumber`)
          .nullable()
          .or(z.literal('')),
        linkedinUrl: z
          .string()
          .refine((value: string) => {
            return LINKEDIN_URL_REGEX.test(value);
          }, t`forms.errors.invalidLinkedInURL`)
          .nullable()
          .or(z.literal('')),
        isPrimary: z.boolean().default(false),
        synced: z.boolean().default(false),
      }),
    ),
  });

  const methods = useZodForm({
    schema: validationSchema,
    defaultValues: {
      contacts,
    },
    shouldUnregister: false,
  });

  const { control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    name: 'contacts',
    control,
    keyName: 'key',
  });

  const addNewContact = useCallback(() => {
    const newContact = {
      id: `${addContactCounter}`,
      firstName: '',
      lastName: '',
      linkedinUrl: null,
      mobilePhone: null,
      isPrimary: false,
      title: null,
      email: null,
      synced: false,
    };

    append(newContact);
  }, [append, addContactCounter]);

  useEffect(() => {
    if (editMode) return;

    const sortedContacts = sortContactsByPrimaryAndFirstName([...contacts]);
    setValue(
      'contacts',
      sortedContacts.map(c => ({ ...c, synced: true })),
    );
  }, [contacts, editMode, setValue]);

  useEffect(() => {
    if (addContactCounter < 1) {
      return;
    }

    addNewContact();
  }, [addContactCounter, addNewContact, append]);

  useEffect(() => {
    if (!deletedContact) return;
    const deletedIndex = fields.findIndex(f => f.id === deletedContact);
    remove(deletedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedContact]);

  useEffect(() => {
    if (!forceSubmit) {
      return;
    }

    void methods.handleSubmit(formValue => onSubmit(formValue.contacts))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceSubmit]);

  return (
    <FormProvider {...methods}>
      {fields.length === 0 ? (
        <EmptyContacts
          onClick={() => {
            setEditState(true);
            addNewContact();
          }}
          disabled={!hasManageTeamPermission}
        />
      ) : !editMode && visibleContacts ? (
        <>
          {visibleContacts.map((field, index) => (
            <ContactCard
              key={index + field.id}
              contact={field}
              editMode={false}
              index={index}
            />
          ))}
        </>
      ) : (
        <>
          {fields.map((field, index) => {
            return (
              <ContactCard
                key={field.key}
                contact={field}
                editMode={editMode}
                index={index}
                onDelete={onDelete}
                disableFocusListener={!!modal}
              />
            );
          })}
        </>
      )}
    </FormProvider>
  );
};
