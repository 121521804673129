import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

export const NotRelevantCounter = ({
  count,
  className,
}: {
  count: number;
  className?: string;
}) => {
  const { t } = useTranslation('companies', { keyPrefix: 'charts' });

  return (
    <Container className={className}>
      <Value>{t('notRelevant', { count })}</Value>
    </Container>
  );
};

const Container = styled('div')`
  position: absolute;
  right: 40px;
  bottom: 0;
  padding: 4px 35px;
  background-color: ${({ theme }) => theme.colors.gray.c2};
  border-radius: 4px;
`;

const Value = styled('span')`
  font-family: ${({ theme }) => theme.fonts.wallop};
  color: ${({ theme }) => theme.colors.gray.c13};
  line-height: 20px;
`;
