import { useCallback, useEffect, useRef, useState } from 'react';

import { ContactForm } from '../contact-form';

import { ContactCardBaseContainer } from './contact-card-base';
import { ContactCardPreview } from './contact-card-preview';
import type { ContactFormItem } from '../contacts';

export const ContactCard = ({
  contact,
  editMode = false,
  index,
  onDelete,
  disableFocusListener = false,
}: {
  index: number;
  contact: ContactFormItem;
  editMode?: boolean;
  onDelete?: (contactId: string, synced: boolean) => void;
  disableFocusListener?: boolean;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (disableFocusListener || !editMode) return;
      const shouldFocus =
        !!elementRef.current &&
        elementRef.current.contains(event.target as Node | null);
      setFocused(shouldFocus);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [disableFocusListener, editMode]);

  const onDeleteHandler = useCallback(() => {
    onDelete?.(contact.id, contact.synced ?? false);
  }, [contact]);

  return (
    <ContactCardBaseContainer
      role="listitem"
      name="contact-card-container"
      direction="column"
      gap="12px"
      padding="36px 32px"
      focused={focused}
    >
      <div ref={elementRef}>
        {!editMode ? (
          <ContactCardPreview contact={contact} />
        ) : (
          <ContactForm
            index={index}
            onDelete={onDeleteHandler}
            onFocus={() => setFocused(true)}
          />
        )}
      </div>
    </ContactCardBaseContainer>
  );
};
