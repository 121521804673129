import { forwardRef } from 'react';
import { styled } from 'goober';

import { FlexContainer } from '@/ui/flexbox/flexbox';

export const FixedBottomContainer = styled(FlexContainer, forwardRef)`
  box-shadow: ${({ theme }) => theme.shadow.companiesKPI};
  background-color: ${({ theme }) => theme.colors.basics.white};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px 20px 30px;
`;
