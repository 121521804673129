import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { MinimizedHeader } from '@/ui/typography/across-platform';

export const AppUsersTableNoResults = ({
  onReset,
  noResultsText,
  resetResultsText,
}: {
  noResultsText: string;
  resetResultsText: string;
  onReset: () => void;
}) => {
  return (
    <Flexbox
      name="projects-page-no-results-container"
      direction="column"
      gap="16px"
      alignItems="center"
      justify="center"
      padding="60px 0"
    >
      <MinimizedHeader>{noResultsText}</MinimizedHeader>
      <Button startIcon="ArrowGoBack" variant="secondary" onClick={onReset}>
        {resetResultsText}
      </Button>
    </Flexbox>
  );
};
