import { useMemo } from 'react';

import type { EnrichedFromCrunchbaseCompanyData } from '../overview/company.state';
import { useCompanyTags } from '../overview/tags/use-company-tags.query';
import { useCompanyEnrichedWithCrunchbase } from '../overview/use-company-enriched-with-crunchbase';
import { useCompanyInvestors } from '../overview/investors/use-company-investors.query';

type EnrichedFromCbCompanyPageElement =
  | 'header'
  | 'about'
  | 'investors'
  | 'tags'
  | 'funding';

type OmitFromUnion<T, K> = T extends K ? never : T;

type CompanyFieldsEnrichedFromCrunchbase = Record<
  OmitFromUnion<EnrichedFromCbCompanyPageElement, 'investors'>,
  (keyof EnrichedFromCrunchbaseCompanyData)[]
>;

export const useCrunchbaseEnrichmentCheck = (
  companyPageElement: EnrichedFromCbCompanyPageElement,
) => {
  const enrichedFromCbData = useCompanyEnrichedWithCrunchbase();
  const { data: companyInvestors } = useCompanyInvestors();

  const { data: companyTags } = useCompanyTags();

  const companyFieldsEnrichedFromCrunchbase: CompanyFieldsEnrichedFromCrunchbase =
    {
      header: [
        'name',
        'activityStatus',
        'logoUrl',
        'linkedinUrl',
        'website',
        'ownershipStatus',
        'stockExchangeSymbol',
        'ticker',
        'yearEstablished',
        'employeesRange',
        'keyOffering',
        'ownedByIrm',
        'hqLocation',
      ],
      about: ['detailedDescription'],
      tags: ['tags'],
      funding: ['capitalRaised', 'fundingStage', 'lastFundingDate'],
    };

  const isEnrichedFromCrunchbase = useMemo(() => {
    if (!enrichedFromCbData) {
      return false;
    }

    if (companyPageElement === 'investors') {
      return companyInvestors?.poweredBy === 'crunchbase';
    }

    if (companyPageElement === 'tags') {
      return companyTags?.poweredBy === 'crunchbase';
    }

    const areAllFieldsEnriched = companyFieldsEnrichedFromCrunchbase[
      companyPageElement
    ]
      .filter(field => !!enrichedFromCbData?.[field])
      .every(field => enrichedFromCbData?.[field]?.poweredBy === 'crunchbase');

    return areAllFieldsEnriched;
  }, [
    companyFieldsEnrichedFromCrunchbase,
    companyInvestors?.poweredBy,
    companyPageElement,
    companyTags?.poweredBy,
    enrichedFromCbData,
  ]);

  return {
    isEnrichedFromCrunchbase,
  };
};
