import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isNumberOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const RankingFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const filterOptions = useRecoilValue(companiesFilterOptionsState);

  return (
    <AsyncSelect
      id="filter-ranking"
      label={t`companies.filters.ranking`}
      defaultValue={selectedFilters.ranking}
      setOptions={filterOptions.ranking}
      isSearchable={false}
      isMulti
      className={className}
      onChange={option => {
        if (!isNumberOptionArray(option)) return;

        setSelectedFilters(currentFilters => {
          return {
            ...currentFilters,
            ranking: option.length ? option : null,
          };
        });
      }}
      {...sharedProps}
    />
  );
};
