import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import type { User } from '@/api/v4/user.api';
import { modalState } from '@/state/modal.state';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export const UserManagementActionsMenu = ({ user }: { user: User }) => {
  const { t } = useTranslation('settings');
  const setModalState = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();

  const menu: DropdownOption[] = [
    {
      label: t`sharedAssets.showSharedAssets`,
      isVisible: hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT),
      onClick: () =>
        setModalState({
          state: 'showSharedAssets',
          email: user.email,
          userDisplayName: `${user.firstName} ${user.lastName}`,
        }),
    },
  ];

  return (
    <WithDropdown
      items={menu}
      options={{
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [0, 5.5] } }],
      }}
    >
      <MenuButton />
    </WithDropdown>
  );
};
