import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import {
  isClientOption,
  isEllipsis,
  isIntegrationOption,
  isOfType,
} from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { IOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { SquareLogo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';
import {
  WIDGET_CONTAINER_MAX_HEIGHT,
  WIDGET_CONTAINER_PADDING_VERTICAL,
} from '@/ui/widget/widget';

import type { SelectedOptionsListProps } from './selected-options-list';

interface IOptionLogo extends IOption {
  logoUrl: string | null;
}

type LogoSizes = 'sm' | 'md';

interface SelectedOptionsListAsLogo extends SelectedOptionsListProps {
  logoSize?: LogoSizes;
  itemsPerRow?: number;
  wrapText?: boolean;
}

export function SelectedOptionsListAsLogo({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
  logoSize = 'md',
  itemsPerRow = 3,
  wrapText,
}: SelectedOptionsListAsLogo) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);
  const editMode = Boolean(onRemove);
  const [maxHeight, setMaxHeight] = useState<number | null>();

  const MAX_HEIGHT_OFFSET = 5;

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.scrollTop = 0;
    const widgetBottomHeight = (
      listRef.current.offsetParent?.querySelector(
        '[data-name="widget-bottom-content"]',
      ) as HTMLDivElement | null
    )?.offsetHeight;

    setMaxHeight(
      WIDGET_CONTAINER_MAX_HEIGHT -
        WIDGET_CONTAINER_PADDING_VERTICAL -
        (listRef?.current?.offsetTop ?? 0) -
        (widgetBottomHeight ? widgetBottomHeight + 12 : 0) -
        MAX_HEIGHT_OFFSET,
    );
  }, [isListExpanded, listRef, onRemove]);

  const getLogo = (item: IOption) => {
    // edit mode
    if (isClientOption(item) || isIntegrationOption(item)) return item.logo;

    // view mode
    if (isOfType<IOptionLogo>(item, 'logoUrl'))
      return item.logoUrl || undefined;

    return undefined;
  };

  if (!list) return null;

  return (
    <OptionsList
      height={height}
      name="options-list"
      direction="row"
      rowGap="36px"
      wrap="wrap"
      defaultRef={listRef}
      editMode={editMode}
      isScrollable={Boolean(isListExpanded || onRemove)}
      {...(maxHeight && { maxHeight: `${maxHeight}px` })}
    >
      {list.map((item, index) => (
        <OptionsListItem
          key={item.value}
          name="options-list-item"
          justify="center"
          alignItems="flex-start"
          itemsPerRow={itemsPerRow}
          asLogo={true}
        >
          <Tooltip
            placement="left"
            content={item.label}
            visible={isReady && isEllipsis(refItemLabel.current[index])}
          >
            <StyledTooltipContent
              name="options-list-item-tooltip-content"
              direction="column"
              alignItems="center"
            >
              <SquareLogo
                name={item.label ?? ''}
                logoUrl={getLogo(item)}
                size={`${logoSize === 'sm' ? '40' : '60'}px`}
                radius={`${logoSize === 'sm' ? '10' : '20'}px`}
                border={
                  getLogo(item) ? `2px solid ${colors.gray.c3}` : undefined
                }
              />
              <ItemLabel
                ref={element => {
                  refItemLabel.current[index] = element;
                }}
                asLogo={true}
                wrapText={wrapText}
              >
                {item.label}
              </ItemLabel>
            </StyledTooltipContent>
          </Tooltip>
          {onRemove && (
            <IconContainer logoSize={logoSize}>
              <Icon
                icon="Close"
                color={colors.gray.c13}
                onClick={() => onRemove?.(item)}
              />
            </IconContainer>
          )}
        </OptionsListItem>
      ))}
    </OptionsList>
  );
}

const OptionsList = styled(Flexbox)<{
  height?: string;
  editMode?: boolean;
  isScrollable: boolean;
  maxHeight?: string;
}>`
  ${({ theme }) => theme.mixins.scrollbar}
  max-height: ${({ height = `${WIDGET_CONTAINER_MAX_HEIGHT}px` }) => height};
  user-select: none;
  overflow: hidden;

  ${({ editMode }) =>
    editMode &&
    `
    margin-top: 29px;
    padding-right: 20px;
  `}

  ${({ isScrollable, maxHeight }) =>
    isScrollable &&
    maxHeight &&
    `
    overflow: auto;
    max-height: ${maxHeight};
  `}
`;

const OptionsListItem = styled(Flexbox)<{
  asLogo: boolean;
  itemsPerRow: number;
}>`
  ${({ theme }) => theme.typography.widget.paragraphMedium}
  ${({ asLogo, itemsPerRow }) =>
    asLogo &&
    `
    flex: 0 0 ${100 / itemsPerRow}%;
  `}
  position: relative;
`;

const ItemLabel = styled('div', forwardRef)<{
  asLogo?: boolean;
  wrapText?: boolean;
}>`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 280px;
  cursor: default;

  ${({ asLogo, wrapText, theme }) =>
    asLogo &&
    `
    text-align: center;
    margin-top: 12px;
    max-width: 105px;
    ${wrapText && 'white-space: normal'};
    ${theme.typography.widget.smallText}
    color: ${theme.colors.gray.c12};
  `}
`;

const IconContainer = styled('div')<{
  logoSize: LogoSizes;
}>`
  position: absolute;
  right: ${({ logoSize }) => (logoSize === 'sm' ? '12px' : '4px')};
  top: 4px;
`;

const StyledTooltipContent = styled(Flexbox)`
  cursor: default;
`;
