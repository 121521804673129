import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'goober';
import { AsYouType } from 'libphonenumber-js';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { StatusTag } from '@/ui/tag/status-tag';
import type { CompanyContact } from '@/api/v4/organization-contacts.api';

export const ContactCardPreview = ({
  contact,
}: {
  contact?: CompanyContact;
}) => {
  const { t } = useTranslation('teams');

  if (!contact) return null;

  const formatPhoneNumber = (phoneString: string) =>
    new AsYouType().input(`+${phoneString}`);

  return (
    <>
      <Stack gap="12px">
        <Flexbox name="contact-heading" justify="space-between">
          <Icon icon="Contact" />
          {contact?.isPrimary && (
            <Stack>
              <StatusTag variant="navy">{t`primary`}</StatusTag>
            </Stack>
          )}
        </Flexbox>
      </Stack>
      <ContactDetailsContainer>
        <div>
          <ContactCardNameAndLinkedInContainer
            name="contact-card-container"
            alignItems="center"
            gap="10px"
          >
            <EllipsisTextTooltip
              text={`${contact.firstName} ${contact.lastName}`}
              Component={<ContactName />}
            />
            {contact.linkedinUrl && (
              <Link to={contact.linkedinUrl} target="_blank">
                <Icon icon="LinkedIn" onClick={() => void 0} />
              </Link>
            )}
          </ContactCardNameAndLinkedInContainer>
          <EllipsisTextTooltip
            text={contact.title}
            Component={<ContactTitle />}
          />
        </div>
        <div>
          <EllipsisTextTooltip
            text={contact.email}
            Component={<ContactInfo />}
          />
          {contact.mobilePhone && (
            <ContactInfo>{formatPhoneNumber(contact.mobilePhone)}</ContactInfo>
          )}
        </div>
      </ContactDetailsContainer>
    </>
  );
};

const ContactCardNameAndLinkedInContainer = styled(Flexbox)`
  div {
    display: inline-grid;
  }
`;

const ContactDetailsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 70%;
  margin: 16px 0px;
`;

const ContactName = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  ${({ theme }) => theme.typography.contacts.name};
`;

const ContactTitle = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  ${({ theme }) => theme.typography.contacts.title};
`;

const ContactInfo = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  ${({ theme }) => theme.typography.contacts.info};
`;
