import { styled } from 'goober';

import { paths } from '@/routes/helpers/paths';
import { Tab } from '@/ui/typography/action-panel';
import { RouteLink } from '@/ui/link/route-link';
import { parseSearchParams } from '@/routes/helpers/parse-search-params';

export const SettingsTabControl = ({
  countIndicator,
  name,
  id,
}: {
  countIndicator?: number;
  name: string;
  id: string;
}) => {
  return (
    <TabLink to={paths.settings() + parseSearchParams({ tab: id })}>
      <TabChip>
        <Tab>
          {name}&nbsp;
          {Boolean(countIndicator) && `(${countIndicator})`}
        </Tab>
      </TabChip>
    </TabLink>
  );
};

const TabChip = styled('div')`
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  white-space: nowrap;
`;

const TabLink = styled(RouteLink)`
  text-decoration: none;
  &:visited {
    color: inherit;
  }
`;
