import { chunk } from 'lodash-es';

import { APP_USER_ROLES } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { useUserRole } from '@/user/use-user-role';

import { ImpactsEmptyState } from './impacts-empty-state';
import type { CompanyData } from '@/api/v4/organization.api';

export const Impacts = ({
  data,
  emptyStateClickHandler,
}: {
  data: CompanyData['impacts'] | [];
  emptyStateClickHandler: () => void;
}) => {
  const [impactsBasic, impactsExtended] = chunk(data, 2);
  const role = useUserRole();
  const isAllowedToEdit = role !== APP_USER_ROLES.GUEST;

  const renderBasicImpacts = () =>
    impactsBasic?.map(impact => (
      <GeneralTag key={impact.id} variant="pink">
        {impact.name}
      </GeneralTag>
    ));

  const renderExtendedImpacts = () =>
    impactsExtended?.length ? (
      <Tooltip
        visible
        content={impactsExtended.map(item => item.name).join(', ')}
      >
        <GeneralTag variant="pink">+{impactsExtended.length}</GeneralTag>
      </Tooltip>
    ) : null;

  return (
    <Flexbox name="impacts-container" gap="12px" wrap="wrap">
      {impactsBasic?.length
        ? renderBasicImpacts()
        : isAllowedToEdit && (
            <ImpactsEmptyState onClick={emptyStateClickHandler} />
          )}
      {renderExtendedImpacts()}
    </Flexbox>
  );
};
