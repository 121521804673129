import type { DefaultTheme } from 'goober';

export type TextWidgetParagraphVariant = 'small' | 'medium' | 'large';

export const getParagraphVariant = ({
  paragraphVariant,
  theme,
}: {
  paragraphVariant: TextWidgetParagraphVariant;
  theme: DefaultTheme;
}) => {
  switch (paragraphVariant) {
    case 'large': {
      return theme.typography.widget.paragraphLarge;
    }
    case 'medium': {
      return theme.typography.widget.paragraphMedium;
    }
    case 'small':
    default: {
      return theme.typography.widget.paragraphSmall;
    }
  }
};
