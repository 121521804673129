import { useCallback, useEffect } from 'react';
import { styled } from 'goober';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { type CompanyFreeTextField } from '@/api/v4/organization.api';
import { FreeTextFieldWidget } from '@/components/widgets/free-text-field-widget/free-text-field-widget';
import { modalState } from '@/state/modal.state';
import { Stack } from '@/ui/line/line';

import { companyIdState } from '../company.state';

import { useFreeTextFields } from './use-free-text-fields';
import { useCreateCompanyFreeTextField } from './use-create-company-free-text-field.mutation';
import { useEditCompanyFreeTextField } from './use-edit-company-free-text-field.mutation';
import { useDeleteCompanyFreeTextField } from './use-delete-company-free-text-field.mutation';

export const FreeTextFieldsWidget = ({ className }: { className?: string }) => {
  const { fields, edit, onSync, deleteItem, refresh } = useFreeTextFields();
  const { createFreeTextField } = useCreateCompanyFreeTextField();
  const { editFreeTextField } = useEditCompanyFreeTextField();
  const { deleteFreeTextField } = useDeleteCompanyFreeTextField();

  const organizationId = useRecoilValue(companyIdState);
  const setModal = useSetRecoilState(modalState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [organizationId, refresh]);

  const handleOnChange = useCallback(
    async (value: CompanyFreeTextField, synced: boolean) => {
      if (!organizationId) return;

      const content =
        value.content && value.content.trim().length > 0
          ? value.content.trim()
          : null;

      const payload = {
        title: value.title.trim(),
        content,
      };

      if (!synced) {
        const response = await createFreeTextField(payload);
        if (!response) return;

        onSync(value.id, response);
        return;
      }

      await editFreeTextField({
        id: value.id,
        ...payload,
      });
    },
    [onSync, organizationId],
  );

  const handlePersistedItemOnDelete = useCallback(
    async (fieldId: string) => {
      if (!organizationId) return;
      await deleteFreeTextField(fieldId);
    },
    [organizationId],
  );

  const openDeleteModal = async (fieldId: string, synced: boolean) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: async () => {
        synced && (await handlePersistedItemOnDelete(fieldId));
        deleteItem(fieldId);
      },
    });
  };

  return (
    <FreeTextFieldsWidgetContainer className={className} gap="42px" fullWidth>
      {fields.map(field => (
        <FreeTextFieldWidget
          key={field.id}
          fieldId={field.id}
          title={field.title}
          content={field.content}
          onEditClick={() => edit(field.id)}
          handleOnChange={values => handleOnChange(values, field.synced)}
          onDelete={async () => {
            if (!field.synced) {
              deleteItem(field.id);
              return;
            }
            await openDeleteModal(field.id, field.synced);
          }}
        />
      ))}
    </FreeTextFieldsWidgetContainer>
  );
};

const FreeTextFieldsWidgetContainer = styled(Stack)`
  padding: 40px 0;
`;
