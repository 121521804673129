import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { deleteTeamContact } from '@/api/v4/team-contacts.api';
import { notify } from '@/ui/snackbar/notify';

type DeleteTeamContactMutationParams = {
  teamId: string;
  teamContactId: string;
};

export const useDeleteTeamContact = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, teamContactId }: DeleteTeamContactMutationParams) => {
      return deleteTeamContact(teamId, teamContactId);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
