import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { notify } from '@/ui/snackbar/notify';
import type { EditAccountPayload } from '@/api/v4/account.api';
import { editMyAccount } from '@/api/v4/account.api';

export const useEditAccount = () => {
  const { t } = useTranslation('default');

  const mutation = useMutation(editMyAccount, {
    onError: () => {
      notify({
        message: t('unknownError'),
      });
    },
  });

  const editAccount = (payload: EditAccountPayload) =>
    mutation.mutateAsync(payload);

  return {
    editAccount,
    isLoading: mutation.isLoading,
  };
};
