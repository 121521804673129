import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { Project, ProjectStatus } from '@/api/v4/projects.api';
import { EditableHeader } from '@/components/editable-header/editable-header';
import type { SaveCurrencyCallback } from '@/components/widgets/currency-widget/currency-widget';
import { GENERAL_DATE_FORMAT } from '@/constants';
import { scrollTo } from '@/helpers/scroll-to';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { notify } from '@/ui/snackbar/notify';
import { MinimizedHeader } from '@/ui/typography/across-platform';
import { SmallText } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { type IProjectForm } from '../project.state';
import { ProjectListings } from '../project-listings/project-listings';
import { useProjectListings } from '../project-listings/use-project-listings.query';

import { DropdownCreatedFor } from './dropdowns/dropdown-created-for';
import { DropdownStatus } from './dropdowns/dropdown-status';
import type { TimeframeRange } from './dropdowns/dropdown-timeframe';
import { DropdownTimeframe } from './dropdowns/dropdown-timeframe';
import { ProjectOverviewSection } from './project-overview-section';
import { useExpandProjectOverviewPart } from './use-expand-project-overview-part';
import { useSearchParams } from 'react-router-dom';

type NullishArgCallback = (value: string | null) => void;
type ArrayArgCallback = (value: string[]) => void;

export interface ProjectOverviewContentProps {
  project: IProjectForm & Partial<Pick<Project, 'id'>>;
  isProjectLoading?: boolean;
  createNew: boolean;
  onHeaderConfirm: (name: string) => void;
  onCreatedForChange: NullishArgCallback;
  onStatusChange: (value: ProjectStatus | null) => void;
  onTimeframeChange: (range: TimeframeRange) => void;
  onProblemStatementChange: NullishArgCallback;
  onProjectKpiChange: NullishArgCallback;
  onCurrencyChange: SaveCurrencyCallback;
  onImpactChange: ArrayArgCallback;
  onExpectedRoiChange: NullishArgCallback;
}

export const ProjectOverviewContent = ({
  project,
  isProjectLoading,
  createNew,
  onHeaderConfirm,
  onCreatedForChange,
  onProblemStatementChange,
  onStatusChange,
  onTimeframeChange,
  onProjectKpiChange,
  onCurrencyChange,
  onImpactChange,
  onExpectedRoiChange,
}: ProjectOverviewContentProps) => {
  const { t } = useTranslation('projects');
  const { hasRequiredPermission } = useUserPermissions();
  const { data: projectListings } = useProjectListings(project?.id);
  const hasEditPermission = hasRequiredPermission(PERMISSION.EDIT_PROJECT);
  const { isExpanded, toggleExpand } = useExpandProjectOverviewPart();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('section') === 'listings') {
      scrollTo('#project-listings', {
        inline: 'end',
      });
    }
  }, [searchParams]);

  return (
    <>
      <Inline gap="24px" templateColumns="2fr 1fr" margin="0 0 30px 0">
        <Stack gap="4px">
          {!createNew && (
            <SmallText>
              {projectListings?.length ?? 0} {t`Companies`}
            </SmallText>
          )}
          <EditableHeader
            dataTestId="project-name-header"
            value={project.name}
            maxLength={90}
            height="47px"
            adjustFontSize
            placeholder={t`projectOverview.nameOfProject`}
            shouldFocus={createNew}
            focusAtEnd
            onConfirm={onHeaderConfirm}
            allowEmptyValue={createNew}
            onError={() =>
              notify({ message: t('projectOverview.noEmptyName') })
            }
            disableEditButton={!hasEditPermission}
          />
        </Stack>

        <Flexbox
          name="project-overview-dropdowns"
          gap="20px"
          justify="flex-end"
        >
          <DropdownCreatedFor
            team={project.team}
            onChange={onCreatedForChange}
            readOnly={!hasEditPermission}
          />
          <Flexbox name="dropdown-timeframe-container" grow="1">
            <DropdownTimeframe
              defaultValue={{
                timeframeStart: project.timeframeStart,
                timeframeEnd: project.timeframeEnd,
              }}
              onChange={onTimeframeChange}
              readOnly={!hasEditPermission}
              controlWidth="190px"
              displayFormat={GENERAL_DATE_FORMAT}
            />
          </Flexbox>
          <DropdownStatus
            status={project.status}
            onChange={onStatusChange}
            readOnly={!hasEditPermission}
          />
        </Flexbox>
      </Inline>

      <SectionHeaderWrapper
        alignItems="center"
        justifyContent="space-between"
        withBottomBorder={!isExpanded}
      >
        <ClickableSectionHeader
          onClick={toggleExpand}
        >{t`projectOverview.overview`}</ClickableSectionHeader>
        <ToggleIcon
          variant="ghost"
          icon="NaviChevron"
          color={colors.basics.black}
          isOpen={isExpanded}
          onClick={toggleExpand}
        />
      </SectionHeaderWrapper>
      {isExpanded ? (
        <ProjectOverviewSection
          project={project}
          onProblemStatementChange={onProblemStatementChange}
          hasEditPermission={hasEditPermission}
          onCurrencyChange={onCurrencyChange}
          onProjectKpiChange={onProjectKpiChange}
          onImpactChange={onImpactChange}
          onExpectedRoiChange={onExpectedRoiChange}
        />
      ) : null}

      {!createNew && <ProjectListings isProjectLoading={!!isProjectLoading} />}
    </>
  );
};

const SectionHeaderWrapper = styled(Inline)<{ withBottomBorder: boolean }>`
  padding-bottom: 30px;
  border-bottom: ${({ withBottomBorder, theme }) =>
    withBottomBorder ? `1px solid ${theme.colors.gray.c7}` : 'none'};
`;

const ClickableSectionHeader = styled(MinimizedHeader)`
  &:hover {
    cursor: pointer;
  }
`;

const ToggleIcon = styled(IconButton)<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.mixins.transition('transform')}
  transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
`;
