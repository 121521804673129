import type { UsersListSort } from '@/api/v4/user.api';
import { colors } from '@/theme/colors';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const userManagementTableHeaders: HeaderCellConfig<UsersListSort>[] = [
  {
    columnKey: 'name',
    sortKey: 'firstName',
    width: '35%',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'email',
    sortKey: 'email',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'role',
    sortKey: 'role',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'active',
    sortKey: 'status',
    align: 'left',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'action',
    hideLabel: true,
    bgColor: colors.basics.white,
  },
];
