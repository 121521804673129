import type { InvestorOption } from '@/types';
import type { Investor } from '../investor.api';

export const mapInvestorsToOptions = (
  investors: Investor[],
): InvestorOption[] => {
  return investors.map(i => ({
    value: i.uniqueId,
    label: i.name,
    investorId: i.investorId,
    crunchbaseId: i.crunchbaseId,
    website: i.website,
    logoUrl: i.logoUrl,
    description: i.description,
    source: i.source,
  }));
};
