import { useRef } from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { formatLocation } from '@/helpers/format-location';
import { isCompanyLocationOption, isEllipsis } from '@/helpers/other';
import { SingleValueContainer } from '@/ui/select/async/async-select.styles';
import { Tooltip } from '@/ui/tooltip/tooltip';

export function CompanyHqLocationSingleValue({
  ...props
}: Readonly<SingleValueProps<unknown, boolean>>) {
  const ref = useRef<HTMLDivElement>(null);

  if (!isCompanyLocationOption(props.data)) {
    return <></>;
  }

  return (
    <components.SingleValue {...props}>
      <Tooltip
        placement="bottom"
        content={formatLocation(props.data.label)}
        visible={isEllipsis(ref.current)}
      >
        <SingleValueContainer ref={ref}>
          {formatLocation(props.data.label)}
        </SingleValueContainer>
      </Tooltip>
    </components.SingleValue>
  );
}
