import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { filterSearchTags } from '@/helpers/filter-search-tags';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';
import { DROPDOWN_ITEMS_PER_PAGE } from '@/constants';
import { useTags } from '@/features/tags/use-tags.query';

export const TagsFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const { tags } = useTags();

  const setCompaniesFilters = useSetRecoilState(companiesFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const sliceTags = (page: number) => {
    const startIndex = (page - 1) * DROPDOWN_ITEMS_PER_PAGE;
    const endIndex = startIndex + DROPDOWN_ITEMS_PER_PAGE;

    return tags.slice(startIndex, endIndex);
  };
  const setOptions = useCallback(
    async (search: string, page = 1) => {
      const { doesTagMatchSearchPhrase } = filterSearchTags({
        searchPhrase: search,
      });

      const tagList =
        search.length === 0
          ? sliceTags(page)
          : tags.filter(doesTagMatchSearchPhrase);

      const tagOptions = tagList.map(tag => ({
        value: tag.id,
        label: tag.name,
      }));

      setCompaniesFilters(currentFilters => ({
        ...currentFilters,
        tags: tagOptions,
      }));
      return tagOptions;
    },
    [setCompaniesFilters, tags],
  );

  return (
    <AsyncSelect
      id="filter-tags"
      label={t`companies.filters.tags`}
      defaultValue={selectedFilters.tags}
      isMulti
      isSearchable
      maxMenuHeight={200}
      className={className}
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;
        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          tags: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
