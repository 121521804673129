import { styled } from 'goober';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { LastActivitiesCounts } from '@/api/v4/last-activities.api';
import { FilterFeatureOption } from '@/components/filter-feature-select/filter-feature-option';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { useLastActivitiesCounts } from './last-activities/use-last-activities-counts.query';
import { useLastActivities } from './last-activities/use-last-activities.query';
import { RecentItem } from './recent-item';
import { RecentItemsTypeSelect } from './recent-items-type-select';
import { recentItemsTypes, recentItemsTypeState } from './recent-items.state';

const MIN_AMOUNT_OF_ACTIVITIES = 1;
const MIN_ASSETS_ENABLED = 1;

export const RecentItemsWidget = () => {
  const { t } = useTranslation('default', {
    keyPrefix: 'recentItems',
  });
  const [selectedRecentItemsType, setSelectedRecentItemsType] =
    useRecoilState(recentItemsTypeState);

  const { data: counts } = useLastActivitiesCounts();
  const { data: recentItems } = useLastActivities();

  if (!counts) return null;

  if (!recentItems) return null;

  const menu: DropdownOption[] = recentItemsTypes.map(type => ({
    label: (
      <MenuOption
        label={t('recentItemType.' + type)}
        icon={selectedRecentItemsType === type ? 'CheckMark' : undefined}
        disabled={counts[type] < MIN_AMOUNT_OF_ACTIVITIES}
      />
    ),
    onClick: () => setSelectedRecentItemsType(type),
    isDisabled: counts[type] < MIN_AMOUNT_OF_ACTIVITIES,
  }));

  return (
    <Flexbox gap="6px" direction="column" name="recent-items-widget">
      {isSelectAssetsMenuVisible(counts) ? (
        <Select
          menu={menu}
          selectedOptionLabel={t('recentItemType.' + selectedRecentItemsType)}
        />
      ) : (
        <SelectPlaceholder />
      )}
      <ItemsGridContainer data-testid="recent-items-list">
        {recentItems.map(recentItem => {
          return (
            <RecentItem
              key={`${recentItem.id}-${
                recentItem.isPinned ? 'pinned' : 'last-activity'
              }`}
              id={recentItem.id}
              type={recentItem.type}
              name={recentItem.name}
              logoUrl={recentItem.logoUrl ?? undefined}
              listingCount={recentItem.listingCount ?? undefined}
              teamMembersCount={recentItem.membersCount ?? undefined}
              isPinned={recentItem.isPinned}
            />
          );
        })}
      </ItemsGridContainer>
    </Flexbox>
  );
};

const MenuOption = styled(FilterFeatureOption)`
  width: 100px;
`;

const ItemsGridContainer = styled('div')`
  max-width: 1270px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
`;

const Select = styled(RecentItemsTypeSelect)`
  align-self: end;
  width: max-content;
`;

const SelectPlaceholder = styled('div')`
  height: 23.5px;
  width: 94px;
`;

const isSelectAssetsMenuVisible = (counts: LastActivitiesCounts) =>
  Object.values(counts)
    .map(c => c >= MIN_AMOUNT_OF_ACTIVITIES)
    .filter(Boolean).length >= MIN_ASSETS_ENABLED;
