import { styled } from 'goober';
import type { SetterOrUpdater } from 'recoil';

import { colors } from '@/theme/colors';
import { Icon } from '@/ui/icons/icon';
import { Inline } from '@/ui/line/line';
import { SquareLogo } from '@/ui/logo/logo';
import { SmallText } from '@/ui/typography/widgets';

import type { ExampleCompany } from '../explore.state';

export const ExampleCompanyItem = ({
  company,
  setExampleCompanies,
}: {
  company: ExampleCompany;
  setExampleCompanies: SetterOrUpdater<ExampleCompany[]>;
}) => {
  return (
    <BadgeContainer alignItems="center" justifyContent="flex-start" gap="4px">
      <SquareLogo name={company.name} logoUrl={company.logoUrl} size="28px" />
      <Text>{company.name}</Text>
      <Icon
        icon="Close"
        onClick={event => {
          event?.stopPropagation();
          setExampleCompanies(addedCompanies =>
            addedCompanies.filter(
              addedCompany => addedCompany.id !== company.id,
            ),
          );
        }}
        color={colors.gray.c13}
        width="10px"
        height="10px"
      />
    </BadgeContainer>
  );
};

const BadgeContainer = styled(Inline)`
  padding: 3px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray.c2};
  }
`;

const Text = styled(SmallText)`
  ${({ theme }) => `color: ${theme.colors.gray.c12}`};
`;
