import { useTranslation } from 'react-i18next';
import { Descope } from '@descope/react-sdk';
import { captureException } from '@sentry/react';

import { Loader } from '@/components/loader/loader';
import { useDescopeFlow } from '@/hooks/use-descope-flow';
import { notify } from '@/ui/snackbar/notify';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { descopeErrorMessageTransformer } from '@/auth/helpers/descope-error-message-transformer';
import { getDescopeError } from '@/auth/helpers/get-descope-error';
import { useSignIn } from '@/auth/hooks/use-sign-in.mutation';
import type {
  SignInFlowSuccessEvent,
  DescopeFlowErrorEvent,
} from '@/auth/types';

export const SignInPage = () => {
  const { t } = useTranslation('auth');
  const { isFlowLoading, onReady } = useDescopeFlow();

  const { handleSignIn } = useSignIn();

  const handleSuccessfulSignIn = async (event: SignInFlowSuccessEvent) => {
    await handleSignIn(event.detail.sessionJwt);
  };

  const handleError = (event: string | Event | DescopeFlowErrorEvent) => {
    const error = getDescopeError(event);
    captureException(error ?? event);

    notify({ message: t`loginError` });
  };

  return (
    <WithHtmlSavvyTitle title={t`signUpPageTitle`}>
      {isFlowLoading && <Loader />}
      <Descope
        flowId={window.runTimeEnvs.VITE_DESCOPE_SIGN_IN_FLOW}
        onSuccess={handleSuccessfulSignIn}
        onError={handleError}
        errorTransformer={descopeErrorMessageTransformer}
        onReady={onReady}
      />
    </WithHtmlSavvyTitle>
  );
};
