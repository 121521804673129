import { useRef } from 'react';
import { styled } from 'goober';

import LogoPlaceholder from '@/assets/svg/logo-upload-placeholder.svg';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';

import { useLogoUploader } from './use-logo-uploader';

interface LogoUploaderProps {
  logoUrl: string | null;
  name: string;
  isVerified?: boolean;
  verificationIconColor?: string;
  onFileChange: (logo: File) => void;
}

export function LogoUploader({
  logoUrl,
  name,
  isVerified = false,
  verificationIconColor,
  onFileChange,
}: LogoUploaderProps) {
  const { handleChangeLogo, temporaryFile } = useLogoUploader({
    onFileChange,
  });
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Container
      name="logo-uploader"
      justify="center"
      alignItems="center"
      onClick={() => ref.current?.click()}
      bgColor={logoUrl ? 'transparent' : colors.system.disable}
    >
      <>
        {logoUrl || temporaryFile ? (
          <CompanyLogo
            singleLetter
            name={name}
            logoUrl={temporaryFile ?? logoUrl ?? undefined}
            bgColor={logoUrl ? 'transparent' : colors.accent.green.c3}
            isVerified={isVerified}
            verificationIconColor={verificationIconColor}
          />
        ) : (
          <img src={LogoPlaceholder} width="41.22px" height="41.22px" />
        )}
        <AddIcon name="logo-uploader-add-icon" justify="center">
          <Icon icon="Plus" />
        </AddIcon>
      </>

      <input ref={ref} type="file" onChange={handleChangeLogo} hidden />
    </Container>
  );
}

const Container = styled(Flexbox)<{ bgColor: string }>`
  height: 80px;
  width: 80px;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  cursor: pointer;
`;

const CompanyLogo = styled(Logo)`
  ${({ theme }) => theme.typography.acrossPlatform.fullSizedHeader}
  color: ${({ theme }) => theme.colors.basics.white};
  width: 80px;
  height: 80px;
  border: 2px solid
    ${({ theme, logoUrl }) => (!logoUrl ? 'transparent' : theme.colors.gray.c3)};
`;

const AddIcon = styled(Flexbox)`
  position: absolute;
  right: -12px;
  bottom: 0;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border: 2px solid ${({ theme }) => theme.colors.blue.primaryA};
  border-radius: 50%;

  :active > & {
    transform: translate3d(1px, 1px, 0);
  }
`;
