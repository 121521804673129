import { styled } from 'goober';

import aiIntroVideo from '@/assets/videos/explore-startups.mp4';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { ExploreLoadingResultsSkeleton } from './explore-loading-results-skeleton';

export const ExploreLoadingView = () => {
  return (
    <Wrapper name="explore-loading" direction="column">
      <VideoBox>
        <video width="400" autoPlay loop muted>
          <source src={aiIntroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoBox>

      <ExploreLoadingResultsSkeleton rows={6} />
    </Wrapper>
  );
};

const Wrapper = styled(Flexbox)`
  margin: 0 25px;
`;

const VideoBox = styled('div')`
  margin: 0 auto;
`;
