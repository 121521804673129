import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { useCurrentUser } from '@/user/use-current-user.query';
import type { VerificationMethod } from '@/user/verification-method/verification-method.type';

export const useTwoFactorAuthentication = () => {
  const { data: user } = useCurrentUser();
  const setModal = useSetRecoilState(modalState);

  const isTwoFactorAuthMethodActive = useCallback(
    (method: VerificationMethod): boolean => {
      return Boolean(
        user && user.verificationMethod && user.verificationMethod === method,
      );
    },
    [user],
  );

  const startEnablingTwoFactorAuthenticationMethod = (
    method: VerificationMethod,
  ) => {
    setModal({ state: 'set2FAMethod', method });
  };

  return {
    startEnablingTwoFactorAuthenticationMethod,
    isTwoFactorAuthMethodActive,
  };
};
