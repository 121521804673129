import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import { FilterFeatureInput } from '@/components/filter-feature-select/filter-feature-select';
import { formatDate } from '@/helpers/format-date';
import { DatePicker } from '@/ui/date-picker/date-picker';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';

export type SelectedDateRange = {
  from: Date;
  to: Date;
};

export const CompaniesInProjectDateRangeSelector = ({
  menu,
  isCalendarOpened,
  dateRangeSelectorLabel,
  setIsCalendarOpened,
  onCalendarClose,
}: {
  menu: DropdownOption[];
  isCalendarOpened: boolean;
  dateRangeSelectorLabel: string;
  setIsCalendarOpened: (isCalendarOpened: boolean) => void;
  onCalendarClose: (selectedDateRange: SelectedDateRange) => void;
}) => {
  const { t } = useTranslation('companies', { keyPrefix: 'charts' });

  const [selectedDateRange, setSelectedDateRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({ from: null, to: null });

  useEffect(() => {
    setSelectedDateRange({
      from: null,
      to: null,
    });
  }, [isCalendarOpened]);

  const closeCalendar = () => {
    setIsCalendarOpened(false);
  };

  const handleDateChange = (dateRange: [Date, Date | null]) => {
    const [from, to] = dateRange;

    setSelectedDateRange({
      from,
      to,
    });

    if (from && to) {
      onCalendarClose({ from, to });
    }
  };

  const getCalendarInputLabel = () => {
    const { from, to } = selectedDateRange;

    if (!from) return t`dateRangeDropdown.custom`;

    return formatDate(from) + (to ? ' - ' + formatDate(to) : '');
  };

  return (
    <>
      <WithDropdown
        items={menu}
        options={{
          placement: 'bottom-end',
        }}
      >
        {!isCalendarOpened && (
          <FilterFeatureInput label={dateRangeSelectorLabel} />
        )}
      </WithDropdown>
      {isCalendarOpened && (
        <OutsideClickHandler onOutsideClick={closeCalendar}>
          <DatePicker
            open={isCalendarOpened}
            id="funnel-custom-date-picker"
            selectsRange
            startDate={selectedDateRange.from}
            endDate={selectedDateRange.to}
            onInputClick={() => setIsCalendarOpened(!isCalendarOpened)}
            onChange={handleDateChange}
            customInput={
              <FilterFeatureInput
                label={getCalendarInputLabel()}
                onClick={closeCalendar}
              />
            }
          />
        </OutsideClickHandler>
      )}
    </>
  );
};
