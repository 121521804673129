import { useRecoilState, useRecoilValue } from 'recoil';

import { useCurrentUser } from '@/user/use-current-user.query';

import { projectIdState } from '../project.state';
import { projectListingsDisplayModeState } from '../project-listing.state';

import type { ProjectListingsDisplayMode } from './project-listings';

export const useChangeProjectListingsDisplayMode = () => {
  const { data: user } = useCurrentUser();
  const projectId = useRecoilValue(projectIdState);

  const [displayMode, setDisplayMode] = useRecoilState(
    projectListingsDisplayModeState([
      projectId ?? '',
      user ? user.id.toString() : '',
    ]),
  );

  const toggleDisplayMode = (activeDisplayMode: ProjectListingsDisplayMode) =>
    activeDisplayMode === 'table' ? 'byStage' : 'table';

  const onDisplayModeChange = () => setDisplayMode(toggleDisplayMode);

  return {
    displayMode,
    onDisplayModeChange,
  };
};
