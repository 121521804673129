import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { editUserProfileValidationSchema } from './edit-user-profile-validation-schema';
import { useEditAccount } from './use-edit-account.mutation';
import { useEditProfile } from './use-edit-profile.mutation';

export interface EditProfileForm {
  name: string;
  lastName: string;
  email: string;
  position?: string;
  accountName?: string;
}

export const useEditProfileForm = (defaultValues: EditProfileForm) => {
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(editUserProfileValidationSchema),
    mode: 'onBlur',
  });
  const { editProfile, isLoading: isEditProfileInProgress } = useEditProfile();
  const { editAccount, isLoading: isEditAccountInProgress } = useEditAccount();
  const { getFieldState } = methods;

  const positionFieldState = getFieldState('position');
  const accountNameFieldState = getFieldState('accountName');

  const onSubmit = async (data: EditProfileForm) => {
    if (accountNameFieldState.isDirty && data.accountName) {
      await editAccount({ name: data.accountName });
    }

    await editProfile({
      firstName: data.name,
      lastName: data.lastName,
      ...(positionFieldState.isDirty && { title: data.position }),
    });
  };

  return {
    methods,
    handleEditProfileSubmit: methods.handleSubmit(onSubmit),
    isSubmitting: isEditProfileInProgress || isEditAccountInProgress,
  };
};
