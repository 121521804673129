import { type ReactNode, useCallback } from 'react';
import { InView } from 'react-intersection-observer';
import { styled } from 'goober';

import { Stack } from '@/ui/line/line';

interface LoadableListProps {
  hasMore: boolean;
  onLoadMore: () => void;
  className?: string;
  children: ReactNode;
}

export const LoadableList = ({
  hasMore,
  onLoadMore,
  children,
  className,
}: LoadableListProps) => {
  const inViewChangeHandler = useCallback(
    (inView: boolean) => {
      if (inView && hasMore) {
        onLoadMore();
      }
    },
    [hasMore, onLoadMore],
  );

  return (
    <List className={className}>
      {children}
      <InView as="div" threshold={0.5} onChange={inViewChangeHandler} />
    </List>
  );
};

const List = styled(Stack)`
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;
`;
