import { useEffect } from 'react';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { companyIdState } from '@/features/companies/overview/company.state';
import { CompanyOverviewWidgetsGrid } from '@/features/companies/overview/company-overview-widgets-grid';
import { CompanyFooter } from '@/features/companies/overview/footer';
import { CompanyHeader } from '@/features/companies/overview/header/header';
import { scrollMainLayoutTo } from '@/page-layout';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';

import { useParams } from 'react-router-dom';
import { useActionPanel } from '@/features/action-panel/use-action-panel';
import { SectionsPane } from '@/features/companies/overview/sections-pane/sections-pane';
import { useCompany } from '@/features/companies/overview/use-company.query';
import { useActionPanelSearchParams } from '../action-panel/use-action-panel-search-params';

const COMPANY_MAIN_CONTENT_WIDTH = 1235;
const COMPANY_STACK_GAP = 40;

export const CompanyPage = () => {
  const { companyId } = useParams();
  const setCompanyId = useSetRecoilState(companyIdState);
  const {
    closeActionPanel,
    isAlreadyOpenedOnLoad,
    setIsOpenOnLoad,
    openActionPanelOnSearchParamTab,
  } = useActionPanel();
  const { actionPanelSearchParam } = useActionPanelSearchParams();
  const { data: company, isFetched } = useCompany();

  useEffect(() => {
    setCompanyId(companyId ?? null);

    return () => {
      setCompanyId(null);
      closeActionPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    scrollMainLayoutTo({ top: 0, behavior: 'instant' });
  }, [companyId]);

  useEffect(() => {
    if (
      !actionPanelSearchParam ||
      (actionPanelSearchParam && !isFetched) ||
      isAlreadyOpenedOnLoad
    )
      return;

    openActionPanelOnSearchParamTab(actionPanelSearchParam, {
      context: 'company',
      title: company?.name.value ?? '',
    });
    setIsOpenOnLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPanelSearchParam, isAlreadyOpenedOnLoad, isFetched]);

  return (
    <WithHtmlSavvyTitle title={company ? company.name.value ?? '' : 'Company'}>
      <Flexbox name="x" justify="space-between">
        <CompanyPageContainer
          name="company-page-container"
          direction="column"
          fullWidth
        >
          <CompanyHeader />
          <Stack
            gap={`${COMPANY_STACK_GAP}px`}
            fullWidth
            maxWidth={`${COMPANY_MAIN_CONTENT_WIDTH}px`}
            margin="0 auto"
          >
            <CompanyOverviewWidgetsGrid />
            <CompanyFooter company={company} />
          </Stack>
        </CompanyPageContainer>
        <SectionsPane />
      </Flexbox>
    </WithHtmlSavvyTitle>
  );
};

const CompanyPageContainer = styled(Flexbox)`
  position: relative;
  overflow-x: hidden;
`;
