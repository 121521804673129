import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import type { SingleValue } from 'react-select';
import { zodResolver } from '@hookform/resolvers/zod';

import { editCompanyHeaderValidationSchema } from '@/features/companies/helpers/edit-company-header-validation-schema';
import { OwnershipStatus } from '@/features/companies/overview/company.state';
import type { CompanyLocationOption, IOption } from '@/types';
import type { SelectOptionValue } from '@/ui/select/select';

import { useCompany } from '../overview/use-company.query';

import { useDropdownFormFields } from './use-dropdown-form-fields';
import type { EditCompanyPayload } from '@/api/v4/organization.api';

interface EditCompanyFormFields {
  name?: string;
  activityStatusId?: string;
  ownershipId?: string;
  stockExchangeSymbol?: string;
  ticker?: string;
  ownedBy?: string;
  yearFounded?: number;
  employeesRangeId?: string;
  hqLocation?: CompanyLocationOption['value'] | null;
  websiteUrl?: string;
  linkedinUrl?: string;
  twitterUrl?: string;
  keyOffering?: string;
  formerName?: string;
  impacts?: string[];
  logo: File | null;
}

export const useEditCompanyForm = () => {
  const { data: company } = useCompany();

  const {
    ownershipOptions,
    activityStatusOptions,
    employeesRangeOptions,
    stockExchangeSymbolOptions,
    yearsOptions,
    hqLocationOptionDefaultValue,
  } = useDropdownFormFields() || {};

  const defaultValues: EditCompanyFormFields = {
    name: company?.name?.value ?? '',
    activityStatusId: company?.activityStatus?.value?.id ?? '',
    ownershipId: company?.ownershipStatus?.value?.id ?? '',
    stockExchangeSymbol: company?.stockExchangeSymbol?.value ?? '',
    ticker: company?.ticker?.value ?? '',
    ownedBy: company?.ownedByIrm.value ?? '',
    yearFounded: Number(company?.yearEstablished.value) ?? 0, //  TODO: Number conversion to removed after BE fix (in some cases BE returns yearEstablished.value as string)
    hqLocation: hqLocationOptionDefaultValue?.value ?? null,
    employeesRangeId: company?.employeesRange?.value?.id ?? '',
    twitterUrl: company?.twitterUrl.value ?? '',
    linkedinUrl: company?.linkedInUrl.value ?? '',
    websiteUrl: company?.website.value ?? '',
    keyOffering: company?.keyOffering.value ?? '',
    formerName: company?.formallyKnownAs?.join(', ') ?? '',
    impacts: company?.impacts.map(impact => impact.id) ?? [],
    logo: null,
  };

  const methods = useForm<EditCompanyFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(editCompanyHeaderValidationSchema),
    defaultValues,
  });
  const { watch, formState, setValue, getValues } = methods;

  const nameValue = watch('name');
  const ownershipFormValue = watch('ownershipId');

  const isNameDirty = formState.dirtyFields.name;
  const isOwnershipIdDirty = formState.dirtyFields.ownershipId;
  const isStockExchangeSymbolDirty = formState.dirtyFields.stockExchangeSymbol;
  const isTickerDirty = formState.dirtyFields.ticker;
  const isOwnedByDirty = formState.dirtyFields.ownedBy;
  const isYearFoundedDirty = formState.dirtyFields.yearFounded;
  const isEmployeesRangeIdDirty = formState.dirtyFields.employeesRangeId;
  const isHqLocationDirty = formState.dirtyFields.hqLocation;
  const isWebsiteUrlDirty = formState.dirtyFields.websiteUrl;
  const isLinkedinUrlDirty = formState.dirtyFields.linkedinUrl;
  const isTwitterUrlDirty = formState.dirtyFields.twitterUrl;
  const isFormerNameDirty = formState.dirtyFields.formerName;
  const isKeyOfferingDirty = formState.dirtyFields.keyOffering;
  const isImpactsDirty = formState.dirtyFields.impacts;
  const isWebsiteUrlValid = !formState.errors['websiteUrl'];
  const isTwitterUrlValid = !formState.errors['twitterUrl'];
  const isLinkedinUrlValid = !formState.errors['linkedinUrl'];

  const privateOwnershipId = ownershipOptions?.find(
    ({ label }) => label === OwnershipStatus.PRIVATE,
  )?.value;

  const defaultOwnershipOption: SelectOptionValue<string> | null =
    useMemo(() => {
      const selectedOwnership = ownershipOptions?.find(
        v => v.value === formState.defaultValues?.ownershipId,
      );

      return selectedOwnership ?? null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownershipFormValue, ownershipOptions]);

  const selectedOwnershipOption: SelectOptionValue<string> | null =
    useMemo(() => {
      const selectedOwnership = ownershipOptions?.find(
        v => v.value === ownershipFormValue,
      );

      return selectedOwnership ?? null;
    }, [ownershipFormValue, ownershipOptions]);

  const isPrivateOwnershipValueSelected =
    selectedOwnershipOption?.value === privateOwnershipId;

  const defaultActivityStatusOption: SelectOptionValue<string> | null =
    useMemo(() => {
      const selectedActivityStatus = activityStatusOptions?.find(
        v => v.value === formState.defaultValues?.activityStatusId,
      );

      return selectedActivityStatus ?? null;
    }, [activityStatusOptions, formState.defaultValues?.activityStatusId]);

  const defaultStockExchangeSymbolOption: SelectOptionValue<string> | null =
    useMemo(() => {
      const selectedStockExchangeSymbol = stockExchangeSymbolOptions?.find(
        v => v.value === formState.defaultValues?.stockExchangeSymbol,
      );

      return selectedStockExchangeSymbol ?? null;
    }, [
      formState.defaultValues?.stockExchangeSymbol,
      stockExchangeSymbolOptions,
    ]);

  const defaultYearOption: SelectOptionValue<string> | null = useMemo(() => {
    const selectedYear = yearsOptions?.find(
      v => Number(v.value) === formState.defaultValues?.yearFounded,
    );

    return selectedYear ?? null;
  }, [formState.defaultValues?.yearFounded, yearsOptions]);

  const defaultEmployeesRangeOption: SelectOptionValue<string> | null =
    useMemo(() => {
      const selectedEmployeesRange = employeesRangeOptions?.find(
        v => v.value === formState.defaultValues?.employeesRangeId,
      );

      return selectedEmployeesRange ?? null;
    }, [employeesRangeOptions, formState.defaultValues?.employeesRangeId]);

  const handleOwnershipValueChange = (
    selectedOption: SingleValue<SelectOptionValue<string>>,
  ) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.value === privateOwnershipId) {
      setValue('stockExchangeSymbol', '');
      setValue('ticker', '');
    }
    setValue('ownershipId', selectedOption.value, {
      shouldDirty: true,
    });
  };

  const setSelectedOptionFormValue = ({
    selectedOption,
    fieldName,
    isNumber = false,
  }: {
    selectedOption: IOption | CompanyLocationOption | null;
    fieldName: keyof EditCompanyFormFields;
    isNumber?: boolean;
  }) => {
    let valueToSet = null;

    if (selectedOption?.value) {
      valueToSet = isNumber
        ? Number(selectedOption?.value)
        : selectedOption?.value;
    }

    setValue(fieldName, valueToSet, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const getPreparedFormData = () => {
    const {
      activityStatusId,
      ownershipId,
      stockExchangeSymbol,
      ticker,
      ownedBy,
      twitterUrl,
      websiteUrl,
      linkedinUrl,
      hqLocation,
      yearFounded,
      formerName,
      keyOffering,
      employeesRangeId,
      impacts,
    } = getValues();

    const payload: EditCompanyPayload = {
      ...(isNameDirty &&
        nameValue && {
          name: nameValue,
        }),
      ...(activityStatusId && {
        activityStatusId,
      }),
      ...(ownershipId &&
        isOwnershipIdDirty && {
          ownershipStatusId: ownershipId,
        }),
      ...(stockExchangeSymbol &&
        isStockExchangeSymbolDirty && {
          stockExchangeSymbol: stockExchangeSymbol,
        }),
      ...(employeesRangeId &&
        isEmployeesRangeIdDirty && {
          employeesRangeId: employeesRangeId,
        }),
      ...(ownedBy &&
        isOwnedByDirty && {
          ownedByIrm: ownedBy,
        }),
      ...(ticker &&
        isTickerDirty && {
          ticker,
        }),
      ...(twitterUrl &&
        isTwitterUrlDirty &&
        isTwitterUrlValid && {
          twitterUrl: twitterUrl,
        }),
      ...(linkedinUrl &&
        isLinkedinUrlDirty &&
        isLinkedinUrlValid && {
          linkedInUrl: linkedinUrl,
        }),
      ...(websiteUrl &&
        isWebsiteUrlDirty &&
        isWebsiteUrlValid && {
          website: websiteUrl,
        }),
      ...((hqLocation &&
        isHqLocationDirty &&
        hqLocation.cityId && {
          hqLocation: {
            cityId: hqLocation.cityId,
          },
        }) ??
        undefined),
      ...(yearFounded &&
        isYearFoundedDirty &&
        formState.dirtyFields.yearFounded && {
          yearEstablished: yearFounded,
        }),
      ...(isKeyOfferingDirty && {
        keyOffering,
      }),
      ...(formerName &&
        isFormerNameDirty && {
          formerName,
        }),
      ...(impacts &&
        isImpactsDirty && {
          impactIds: impacts,
        }),
    };

    return payload;
  };

  return {
    methods,
    isPrivateOwnershipValueSelected,
    defaultOwnershipOption,
    selectedOwnershipOption,
    handleOwnershipValueChange,
    defaultActivityStatusOption,
    defaultStockExchangeSymbolOption,
    defaultYearOption,
    defaultEmployeesRangeOption,
    getPreparedFormData,
    setSelectedOptionFormValue,
  };
};
