import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import type { InvitedUserDetails } from '@/api/v4/auth.api';
import { fetchInvitedUserDetails } from '@/api/v4/auth.api';
import { BaseExceptions, isBaseError } from '@/api/v4/base-fetch';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';

import { useSignUpParams } from './use-sign-up-params';

export const useInvitedUserDetails = ({
  onSuccessHandler,
}: {
  onSuccessHandler: (data?: InvitedUserDetails) => void;
}) => {
  const { t } = useTranslation('auth');
  const navigateTo = useNavigateTo();
  const { invitedUserParams, areParamsFetched, setFetchedParams } =
    useSignUpParams();

  return useQuery(
    [QueryKey.InvitedUserData, invitedUserParams],
    async () =>
      invitedUserParams
        ? await fetchInvitedUserDetails(invitedUserParams)
        : undefined,
    {
      enabled: !areParamsFetched && !!invitedUserParams,
      retry: false,
      onSuccess: invitedUserData => {
        onSuccessHandler(invitedUserData);
        setFetchedParams(true);
      },
      onError: error => {
        if (isBaseError(error)) {
          if (error.response.status === BaseExceptions.NOT_FOUND) {
            return navigateTo.signUpInvitationExpired();
          }
          return notify({ message: t`unknownError` });
        }

        notify({ message: t`unknownError` });
      },
    },
  );
};
