import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { useSidebars } from '@/hooks/use-sidebars';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import type { IOption } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';

import {
  CLOSED_NAVBAR_WIDTH,
  OPEN_NAVBAR_WIDTH,
} from '../../navbar/navbar.styles';
import type { ExampleCompany } from '../explore.state';
import { ExploreFormFields } from '../form/explore-form-fields';

import { useRegenerateExploreForm } from './use-regenerate-explore-form';
import { FixedPanelWithShadowLayer } from '@/components/fixed-panel-with-shadow-layer/fixed-panel-with-shadow-layer';
import { queryClient, QueryKey } from '@/config/query-client';

export const RegeneratePanel = ({
  onClose,
  isLoading,
  defaultValues,
}: {
  onClose: () => void;
  isLoading: boolean;
  defaultValues: {
    defaultLocations: IOption[];
    defaultDescription: string;
    defaultExampleCompanies: ExampleCompany[];
  };
}) => {
  const { t } = useTranslation('explore');
  const { isNavbarOpen } = useSidebars();

  const {
    updatedLocations,
    updatedExampleCompanies,
    updatedDescription,
    setUpdatedDescription,
    setUpdatedExampleCompanies,
    setUpdatedLocations,
    setDefaultValues,
    saveUpdatedValues,
    resetForm,
    checkIsDirty,
    isValid,
  } = useRegenerateExploreForm();
  const [modal, setModalState] = useRecoilState(modalState);
  const leftOffset = isNavbarOpen ? OPEN_NAVBAR_WIDTH : CLOSED_NAVBAR_WIDTH;
  const isSelectingExampleCompanies =
    modal?.state === 'addExampleCompaniesToExploreForm';
  const isDirty = checkIsDirty(defaultValues);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setDefaultValues(defaultValues);

    return () => {
      resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, isLoading]);

  const handleClose = () => {
    if (
      isSelectingExampleCompanies ||
      modal?.state === 'confirmDiscardChanges'
    ) {
      return;
    }

    if (isDirty) {
      return setModalState({
        state: 'confirmDiscardChanges',
        mainAction: () => {
          resetForm();
          onClose();
        },
      });
    }
    onClose();
  };

  const handleSubmit = async () => {
    saveUpdatedValues();
    await queryClient.invalidateQueries([QueryKey.ExploreSuggestions]);
    onClose();
  };

  return (
    <ContentWrapper leftOffset={leftOffset} onOutsideClick={handleClose}>
      <Container name="regenerate-explore-results-panel" direction="column">
        <Flexbox
          name="regenerate-explore-results-header"
          justify="space-between"
          fullWidth
        >
          <HeaderTitle>{t`editExploreInfo`}</HeaderTitle>
          <Icon
            icon="ArrowLeftBack"
            width="24px"
            height="24px"
            color={colors.basics.black}
            onClick={handleClose}
          />
        </Flexbox>
        <Form gap="20px">
          <ExploreFormFields
            locations={updatedLocations}
            setLocations={setUpdatedLocations}
            exampleCompanies={updatedExampleCompanies}
            description={updatedDescription}
            setExampleCompanies={setUpdatedExampleCompanies}
            setDescription={setUpdatedDescription}
            isLoadingDefaultValues={isLoading}
          />
          <SubmitButton
            startIcon="Sprinkles"
            onClick={handleSubmit}
            disabled={!isDirty || !isValid}
          >{t`regenerate`}</SubmitButton>
        </Form>
      </Container>
    </ContentWrapper>
  );
};

const ContentWrapper = styled(FixedPanelWithShadowLayer)`
  @media (min-width: 1600px) {
    min-width: unset;
    max-width: 680px;
  }
`;

const Container = styled(Flexbox)`
  padding: 33px 20px 33px 33px;
`;

const HeaderTitle = styled('h1')`
  ${({ theme }) => theme.typography.widget.emptyState};
  margin-left: 12px;
`;

const Form = styled(Stack)`
  margin-top: 10px;
  padding-right: 10px;
  max-height: calc(100vh - 100px);
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
`;
