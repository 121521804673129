import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export type SettingsTabs =
  | 'profileSettings'
  | 'userManagement'
  | 'invitedUsers';

export const useSettingsTabsConfig = (): {
  id: SettingsTabs;
}[] => {
  const { hasRequiredPermission } = useUserPermissions();

  return [
    {
      id: 'profileSettings',
    },
    ...(hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT)
      ? ([
          {
            id: 'userManagement',
          },
          {
            id: 'invitedUsers',
          },
        ] as { id: SettingsTabs }[])
      : []),
  ];
};
