import { type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { useVerifyCompany } from '@/features/companies/hooks/use-verify-company';
import { formatRanking } from '@/helpers/format-number';
import { scrollTo } from '@/helpers/scroll-to';
import { colors } from '@/theme/colors';
import { AdjustableHeader } from '@/ui/adjustable-header/adjustable-header';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { StatusTag as Badge } from '@/ui/tag/status-tag';
import { EditIcon } from '@/ui/text-editable/text-editable.styles';
import { ParagraphLarge, SubHeader } from '@/ui/typography/widgets';

import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import { RankingStarIcon } from '../rating/ranking-star-icon';
import { COMPANY_MAIN_CONTENT_WIDTH } from '../utils/constants';

import { EditModeForm } from './edit-form/edit-mode-form';
import { Impacts } from './impacts/impacts';
import { GeneralInformation } from './general-information';
import { Metadata } from './metadata';
import { useCompanyRankings } from '../rating/use-company-rating.query';
import type { CompanyData } from '@/api/v4/organization.api';
import { useCompanyProjectsListings } from '@/hooks/queries/use-company-projects-listings.query';

export const MainContent = ({
  isEditMode,
  setIsEditMode,
  company,
}: {
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  company: CompanyData;
}) => {
  const { t } = useTranslation(['companies', 'default']);
  const { data, isLoading } = useCompanyRankings();
  const { verificationIconColor } = useVerifyCompany();
  const canEditCompany = useCanEditCompany();

  const { data: companyListings } = useCompanyProjectsListings(company.id);

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!company) return null;

  if (isEditMode) {
    return (
      <Container>
        <EditModeForm
          onCancelEditMode={() => setIsEditMode(false)}
          company={company}
        />
      </Container>
    );
  }

  const overallAverage = data?.overallRating;

  return (
    <>
      <ColumnsContainer gap="24px" templateColumns="2fr 1fr">
        <Flexbox
          name="main-content-company-header"
          direction="column"
          justify="space-between"
          height="100%"
        >
          <Stack gap="0px">
            <Stack gap="12px">
              <CompanyLogo
                name={company.name.value ?? ''}
                singleLetter={true}
                logoUrl={company.logoUrl.value ?? undefined}
                bgColor={
                  company.logoUrl.value ? 'transparent' : colors.accent.green.c3
                }
                isVerified={Boolean(company.lastVerifiedAt)}
                verificationIconColor={verificationIconColor}
              />

              <AdjustableHeader
                smallerFontSize={60}
                initialFontSize={75}
                containerMaxWidth={800}
                text={company.name.value ?? ''}
              />
            </Stack>
            <Metadata />
          </Stack>
          <GeneralInformation />
        </Flexbox>

        <RightColumn gap="28px">
          <BadgeContainer
            name="company-ranking-rating-overview-overall"
            height="76px"
          >
            <Badge variant="green">
              {overallAverage ? (
                <RankLink
                  onClick={() =>
                    scrollTo('[data-name="company-ranking-widget"]')
                  }
                >
                  {t`company.header.ourRanking`}
                  <Flexbox name="rank" gap="4px">
                    {formatRanking(overallAverage.toFixed(1)) ??
                      t`ranking.emptyRankingMessage`}
                    <RankingStarIcon filled />
                  </Flexbox>
                </RankLink>
              ) : (
                <>
                  <RatingEmptyStateContainer>
                    {t`company.header.noRatings1`}
                    <LinkToRanking
                      onClick={() =>
                        scrollTo('[data-name="company-ranking-widget"]')
                      }
                    >
                      {t`company.header.noRatings2`}
                    </LinkToRanking>
                  </RatingEmptyStateContainer>
                  <RankingStarIcon filled />
                </>
              )}
            </Badge>
          </BadgeContainer>
          <Flexbox name="company-header-desc" direction="column" gap="15px">
            <DescriptionWrapper>
              {company.keyOffering.value ? (
                <Descripton>{company.keyOffering.value}</Descripton>
              ) : (
                <SubHeader color={colors.gray.c9}>
                  {canEditCompany
                    ? t`company.header.keyOfferingPlaceholderDefault`
                    : t`company.header.notAdded`}
                </SubHeader>
              )}
            </DescriptionWrapper>

            {company.formallyKnownAs?.length ? (
              <FormerName>
                {t`company.header.formerNamePlaceholder`}:{' '}
                {company.formallyKnownAs.join(', ')}
              </FormerName>
            ) : null}
          </Flexbox>
          <Stack gap="8px">
            <Impacts
              data={company.impacts} // add impacts
              emptyStateClickHandler={() => setIsEditMode(true)}
            />
            {companyListings?.listings.length ? (
              <BadgeContainer name="blue-badges-container" cursor="pointer">
                <Badge
                  variant="darkBlue"
                  onClick={() => scrollTo('#related-projects-widget')}
                >
                  {t('company.header.listedIn', {
                    count: companyListings.listings.length,
                  })}
                </Badge>
              </BadgeContainer>
            ) : null}
          </Stack>
        </RightColumn>
      </ColumnsContainer>

      {canEditCompany && (
        <StyledEditIcon
          icon="Edit"
          className="edit-icon"
          onClick={() => setIsEditMode(true)}
          role="button"
        />
      )}
    </>
  );
};

const CompanyLogo = styled(Logo)`
  ${({ theme }) => theme.typography.acrossPlatform.fullSizedHeader}
  color: ${({ theme }) => theme.colors.basics.white};
  width: 80px;
  height: 80px;
  letter-spacing: normal;
  border: 2px solid
    ${({ theme, logoUrl }) => (!logoUrl ? 'transparent' : theme.colors.gray.c3)};
`;

const StyledEditIcon = styled(EditIcon)`
  right: 82px;
  top: 30px;
`;

const FormerName = styled('div')`
  font-family: ${({ theme }) => theme.fonts.neue};
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.basics.black};
`;

const Descripton = styled(ParagraphLarge)`
  ${({ theme }) => theme.mixins.lineClamp(4)}
`;

const DescriptionWrapper = styled('div')`
  height: 128px;
`;

const Container = styled('div')`
  max-width: ${COMPANY_MAIN_CONTENT_WIDTH}px;
  margin: 0 auto;
`;

const ColumnsContainer = styled(Inline)`
  min-height: 342px;
`;

const RightColumn = styled(Stack)`
  height: 100%;
`;

const BadgeContainer = styled(Flexbox)<{ height?: string; cursor?: string }>`
  white-space: nowrap;
  ${({ height }) => `height: ${height}`};
  align-items: flex-end;
  ${({ cursor }) => `cursor: ${cursor}`};
`;

const RankLink = styled(Inline)`
  cursor: pointer;
`;

const RatingEmptyStateContainer = styled('div')`
  ${({ theme }) => theme.typography.atoms.tags.status}
  color: inherit;
`;

const LinkToRanking = styled('span')`
  text-decoration: underline;
  cursor: pointer;
`;
