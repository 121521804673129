import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Stack } from '@/ui/line/line';
import { FullSizedHeader } from '@/ui/typography/across-platform';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { SettingsContent } from '@/features/settings/settings-content';

export const SettingsPage = () => {
  const { t } = useTranslation('settings');
  return (
    <WithHtmlSavvyTitle title={t`page.title`}>
      <PageStack gap="32px">
        <FullSizedHeader>{t`page.title`}</FullSizedHeader>
        <SettingsContent />
      </PageStack>
    </WithHtmlSavvyTitle>
  );
};

const PageStack = styled(Stack)`
  padding: 16px 0;
  max-width: 1235px;
  margin: 0 auto;
`;
