import { useMemo } from 'react';

import type { ListingStage } from '@/api/v4/project-listings.api';
import type { IconVariant } from '@/ui/icons/icon';

import { ListingStageName } from '../../../listing-stage/types';
import { useListingStages } from '../../../listing-stage/use-listing-stages.query';

export interface TabStage extends ListingStage {
  icon?: IconVariant;
}

export const useStageTabsConfig = () => {
  const { listingStages } = useListingStages();

  const putNotRelevantAsLast = (stages: ListingStage[]) => {
    const notRelevantStage = stages.find(
      stage => stage.name === ListingStageName.notRelevant,
    );
    const others = stages.filter(
      stage => stage.name !== ListingStageName.notRelevant,
    );

    return notRelevantStage ? [...others, notRelevantStage] : others;
  };

  const tabStages: TabStage[] = useMemo(() => {
    const stagesForTabs = listingStages.filter(stage =>
      Object.values(ListingStageName).some(
        stageName => stageName === stage?.name,
      ),
    );

    const dealTab = {
      id: ListingStageName.deal,
      name: ListingStageName.deal,
      displayName: null,
      sortOrder: 6,
    };

    return putNotRelevantAsLast([...stagesForTabs, dealTab]).map(stage => ({
      ...stage,
      ...(stage.name === ListingStageName.notRelevant && {
        icon: 'CloseCircle',
      }),
    }));
  }, [listingStages]);

  return tabStages;
};
