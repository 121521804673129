import type { ListingStage } from '@/api/v4/project-listings.api';
import { fetchListingStages } from '@/api/v4/project-listings.api';
import { QueryKey } from '@/config/query-client';
import { useQueries } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { projectsFilterOptionsState } from '../project.state';
import {
  mapListingStatusFilter,
  mapProjectCreatedByFilter,
  mapProjectCreatedForFilter,
} from './project-filters-helpers';
import type {
  ProjectCreator,
  ProjectFiltersTeamOptionAPI,
} from '@/api/v4/project-filters.api';
import {
  fetchProjectCreators,
  fetchProjectFiltersTeamOptions,
} from '@/api/v4/project-filters.api';

export const useFetchProjectFiltersOptions = () => {
  const setProjectFiltersOptions = useSetRecoilState(
    projectsFilterOptionsState,
  );
  const staleTime = 5 * 60 * 1000;
  const cacheTime = 5 * 60 * 1000;

  const results = useQueries({
    queries: [
      {
        queryKey: [QueryKey.ListingStages],
        queryFn: () => fetchListingStages(),
        onSuccess: (data: ListingStage[]) => {
          const stageOptions = data.map(mapListingStatusFilter);

          setProjectFiltersOptions(currentFilters => ({
            ...currentFilters,
            listingStatuses: stageOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.ProjectCreators],
        queryFn: () => fetchProjectCreators(),
        onSuccess: (data: ProjectCreator[]) => {
          const creatorOptions = data.map(mapProjectCreatedByFilter);

          setProjectFiltersOptions(currentFilters => ({
            ...currentFilters,
            createdBy: creatorOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
      {
        queryKey: [QueryKey.ProjectFiltersTeamOptions],
        queryFn: () => fetchProjectFiltersTeamOptions(),
        onSuccess: (data: ProjectFiltersTeamOptionAPI[]) => {
          const teamOptions = data.map(mapProjectCreatedForFilter);

          setProjectFiltersOptions(currentFilters => ({
            ...currentFilters,
            createdFor: teamOptions,
          }));
        },
        staleTime,
        cacheTime,
      },
    ],
  });
  const isLoading = results.some(result => result.isLoading);

  return {
    isFetchingProjectFilters: isLoading,
  };
};
