import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { cookies } from '@/config/cookies';
import { descope } from '@/config/descope';
import { COOKIES } from '@/constants';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';

import { jwtTokenState } from '../auth.state';

export const useLogout = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();
  const navigateTo = useNavigateTo();
  const setToken = useSetRecoilState(jwtTokenState);

  const logoutMutation = useMutation(async () => await descope.logout(), {
    onSuccess: async () => {
      queryClient.removeQueries();
      setToken(null);
      navigateTo.signIn();
    },
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
  });

  return async () => {
    cookies.remove(COOKIES.INTERCOM_HASH);

    await logoutMutation.mutateAsync();
  };
};
