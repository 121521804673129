import type { CSSAttribute } from 'goober';
import { styled } from 'goober';

import type { FlexBoxProps } from '@/ui/flexbox/flexbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';

import { colorIcon } from '../icons/functions';

import type { WidgetProps } from './widget';
import { Widget } from './widget';

const DEFAULT_GAP = 58;
const DEFAULT_MIN_WIDTH = '274px';

export interface EmptyStateWidgetProps extends WidgetProps {
  image?: string;
  imageWidth?: number;
  label: string;
  linkText?: string;
  onEditClick?: () => void;
  direction?: FlexBoxProps['direction'];
  gap?: number;
  labelAlign?: CSSAttribute['textAlign'];
  labelJustify?: string;
  labelGrow?: number;
  labelGap?: string;
  labelWidth?: number;
  minWidth?: string;
  maxHeight?: number;
  footer?: React.ReactNode;
  padding?: string;
}

export function EmptyStateWidget({
  image,
  imageWidth,
  label,
  linkText,
  onEditClick,
  direction,
  gap = DEFAULT_GAP,
  labelAlign,
  labelJustify,
  labelGap,
  labelGrow,
  labelWidth,
  minWidth = DEFAULT_MIN_WIDTH,
  maxHeight,
  footer,
  padding,
  ...widgetProps
}: EmptyStateWidgetProps) {
  return (
    <WidgetContainer
      onClick={onEditClick}
      showEditButton={false}
      minWidth={minWidth}
      maxHeight={maxHeight}
      {...widgetProps}
    >
      <Container
        name="empty-state-container"
        justify="center"
        alignItems="center"
        grow={1}
        gap={`${gap}px`}
        direction={direction ?? 'unset'}
        padding={padding}
      >
        {!!image && <img src={image} alt="" width={imageWidth} />}
        <LabelAreaContainer
          name="empty-state-content"
          direction="column"
          grow={labelGrow}
          gap={labelGap ?? '12px'}
          alignItems={labelAlign ?? 'unset'}
          justify={labelJustify}
          width={labelWidth}
        >
          <Label labelAlign={labelAlign}>{label}</Label>
          {!!linkText && (
            <LinkText>
              <Icon icon="ArrowLong" />
              <span>{linkText}</span>
            </LinkText>
          )}
        </LabelAreaContainer>
      </Container>
      {footer}
    </WidgetContainer>
  );
}

const Container = styled(Flexbox)<{ padding: string | undefined }>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  cursor: pointer;
`;

const LabelAreaContainer = styled(Flexbox)<{ width?: number }>`
  ${({ width }) => width && `width: ${width}px`};
`;

const WidgetContainer = styled(Widget)<
  Pick<EmptyStateWidgetProps, 'minWidth' | 'maxHeight'>
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  padding: 0 36px;
  cursor: pointer;
  white-space: pre-line;
`;

const Label = styled('h1')<Pick<EmptyStateWidgetProps, 'labelAlign'>>`
  ${({ theme }) => theme.typography.widget.emptyState};
  ${({ labelAlign }) => labelAlign && `text-align: ${labelAlign};`}
`;

const LinkText = styled('div')`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
  color: ${({ theme }) => theme.colors.gray.c12};
  display: inline-flex;
  gap: 12px;
  ${({ theme }) =>
    `
      :hover > [data-name='empty-state-container'] & {
        color: ${theme.colors.blue.primaryA};
        ${colorIcon(theme.colors.blue.primaryA)}
      }
  `}
`;
