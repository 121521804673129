import { colors, componentsPalette } from '@/theme/colors';

export const fonts = {
  wallop: 'Wallop',
  neue: 'Neue Haas Unica Pro',
  inter: 'Inter',
};

const defaults = {
  widget: {
    header: `
      font-family: ${fonts.wallop};
      color: ${colors.basics.black};
    `,
    paragraph: `
      font-family: ${fonts.neue};
      color: ${colors.basics.black};
    `,
  },
  companyInfo: `
    font-family: ${fonts.neue};
    color: ${colors.basics.black};
  `,
  navigationPanel: `
    font-family: ${fonts.wallop};
    font-size: 14px;
    line-height: 145%;
    letter-spacing: 0.03em;
    color: ${colors.basics.black};
  `,
  acrossPlatform: `
    font-family: ${fonts.wallop};
    font-weight: 500;
  `,
};

export const typography = {
  widget: {
    pilotHeader: `
      ${defaults.widget.header};
      font-weight: 500;
      font-size: 75px;
      line-height: 86px;
      letter-spacing: -0.01em;
      text-transform: capitalize;
    `,
    projectNameHeader: `
      ${defaults.widget.header};
      font-weight: 500;
      font-size: 53px;
      line-height: 130%;
    `,
    header: `
      ${defaults.widget.header};
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      letter-spacing: 0.01em;
    `,
    subHeader: `
      ${defaults.widget.header};
      font-weight: 500;
      font-size: 19px;
      line-height: 145%;
      letter-spacing: 0.01em;
    `,
    subHeaderBold: `
      ${defaults.widget.header};
      font-weight: 600;
      font-size: 18px;
      line-height: 145%;
      letter-spacing: 0.03em;
    `,
    capsuleHeader: `
      ${defaults.widget.header};
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    emptyState: `
      font-family: ${fonts.wallop};
      font-weight: 500;
      font-size: 27px;
      line-height: 125%;
      letter-spacing: 0.01em;
    `,
    paragraphLarge: `
      ${defaults.widget.paragraph};
      font-size: 22px;
      line-height: 145%;
      letter-spacing: 0.01em;
    `,
    paragraphMedium: `
      ${defaults.widget.paragraph};
      font-size: 20px;
      line-height: 140%;
      letter-spacing: 0.02em;
    `,
    paragraphSmall: `
      ${defaults.widget.paragraph};
      font-size: 16px;
      line-height: 146%;
      letter-spacing: 0.01em;
    `,
    smallText: `
      ${defaults.widget.header};
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: ${colors.gray.c11};
    `,
  },
  companyInfo: {
    info: `
      ${defaults.companyInfo};
      font-size: 18px;
      line-height: 145%;
      letter-spacing: 0.01em;
    `,
    rank: `
      ${defaults.companyInfo};
      font-size: 18px;
      line-height: 122.5%;
      letter-spacing: 0.01em;
    `,
    subHeader: `
      ${defaults.companyInfo};
      font-size: 14px;
      line-height: 145%;
      letter-spacing: 0.03em;
    `,

    filter: `
      font-family: ${fonts.wallop};
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: ${colors.basics.black};
    `,

    tableHeader: `
      ${defaults.companyInfo};
      font-size: 12px;
      line-height: 145%;
      letter-spacing: 0.03em;
      opacity: 0.5;
    `,

    amountIndicator: `
      font-family: ${fonts.wallop};
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: ${colors.basics.black};
    `,
  },
  companyRanking: {
    generalImpressionRating: `
      font-family: ${fonts.wallop};
      font-weight: 500;
      font-size: 14px;
      line-height: 30.3px;
      color: ${colors.accent.green.c3};
    `,
    generalImpressionText: `
      font-family: ${fonts.wallop};
      font-weight: 400;
      font-size: 14px;
      line-height: 16.07px;
      color: ${colors.gray.c12};
    `,
    card: {
      title: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 19px;
        line-height: 27.55px;
        color: ${colors.basics.black};
      `,
      averageRating: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 36px;
        line-height: 39.6px;
        color: ${colors.accent.green.c3};
      `,
      yourRanking: `
        font-family: ${fonts.wallop};
        font-weight: 400;
        font-size: 14px;
        line-height: 16.07px;
        color: ${colors.basics.black};
      `,
    },
  },
  actionsPanel: {
    acrossPanel: {
      inPlatformLocation: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      tab: `
        font-family: ${fonts.neue};
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
      `,
      smallText: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
        opacity: 0.7;
      `,
      searchOrCommentText: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
    },
    updates: {
      personOrCompanyName: `
        font-family: ${fonts.neue};
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      personOrCompanyAction: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      quote: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
        opacity: 0.7;
      `,
    },
    comments: {
      personName: `
        font-family: ${fonts.neue};
        font-weight: 500;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      professionTitle: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      commentContent: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
    },
    tasks: {
      taskContent: `
        font-family: ${fonts.neue};
        font-weight: 500;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      taskContentDone: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${componentsPalette.actionPanel.tasks.done}
      `,
    },
    meetings: {
      month: `
        font-family: ${fonts.neue};
        font-weight: 500;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      header: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      subHeader: `
        font-family: ${fonts.wallop};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      subTitle: `
        font-family: ${fonts.wallop};
        font-weight: 400;
        font-size: 14px;
        line-height: 20.3px;
        color: ${colors.gray.c13};
      `,
      small: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 12px;
        line-height: 17.4px;
        color: ${colors.basics.black};
        opacity: 50%;
      `,
      date: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 12px;
        line-height: 17.4px;
        color: ${colors.gray.c11};
      `,
      paragraph: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      email: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
    },
    files: {
      fileName: `
        font-family: ${fonts.wallop};
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
      `,
      creatorName: `
        font-family: ${fonts.neue};
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        color: ${colors.basics.black};
      `,
    },
  },
  navigationPanel: {
    nonSelected: `
      ${defaults.navigationPanel};
      font-weight: 400;
    `,

    selected: `
      ${defaults.navigationPanel};
      font-weight: 500;
    `,
  },
  acrossPlatform: {
    fullSizedHeader: `
      ${defaults.acrossPlatform};
      font-size: 53px;
      line-height: 110%;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,

    minimizedHeader: `
      ${defaults.acrossPlatform};
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,

    buttonText: `
      ${defaults.acrossPlatform};
      font-size: 14px;
      letter-spacing: 0.03em;
    `,
    small: `
      font-family: ${fonts.neue};
      font-size: 14px;
      line-height: 146%;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,
    tiny: `
      font-family: ${fonts.neue};
      font-size: 12px;
      line-height: 17.4px;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,
    readMore: `
      ${defaults.acrossPlatform};
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      text-decoration-line: underline;
      color: ${colors.gray.c12};
    `,
    userName: `
      font-family: ${fonts.wallop};
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: ${colors.basics.black};
    `,
    editStamp: `
      font-family: ${fonts.wallop};
      font-size: 14px;
      color: ${colors.gray.c6};
      font-weight: 400;
    `,
  },
  atoms: {
    tags: {
      status: `
        font-family: ${fonts.wallop};
        font-size: 14px;
        line-height: 145%;
        letter-spacing: 0.03em;
      `,
      general: {
        xs: `
          font-family: ${fonts.neue};
          font-size: 14px;
          line-height: 145%;
          letter-spacing: 0.03em;
        `,
        s: `
          font-family: ${fonts.neue};
          font-size: 14px;
          line-height: 146%;
          letter-spacing: 0.01em;
        `,
        m: `
          font-family: ${fonts.neue};
          font-size: 16px;
          line-height: 146%;
          letter-spacing: 0.01em;
        `,
        l: `
          font-family: ${fonts.neue};
          font-size: 16px;
          line-height: 146%;
          letter-spacing: 0.01em;
        `,
      },
    },
    dragDrop: `
      font-family: ${fonts.neue};
      font-size: 16px;
      line-height: 146%;
      letter-spacing: 0.01em;
    `,
    snackbar: `
      font-family: ${fonts.neue};
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.01em;
    `,
    tooltip: `
      font-family: ${fonts.neue};
      font-size: 14px;
      line-height: 141%;
    `,
  },
  inputs: {
    select: {
      label: `
        font-family: ${fonts.wallop};
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: ${colors.gray.c13};
      `,
      singleValue: `
        font-family: ${fonts.neue};
        font-size: 14px;
        line-height: 146%;
        letter-spacing: 0.01em;
        color: ${colors.basics.black};
      `,
      option: `
        font-family: ${fonts.wallop};
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: ${colors.basics.black};
    `,
    },
    textarea: `
      font-family: ${fonts.neue};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; 
      letter-spacing: 0.36px;
    `,
  },
  authPage: {
    location: {
      name: `
        font-family: ${fonts.neue};
        font-size: 24px;
        line-height: 145%;
        letter-spacing: 0.01em;
      `,
      address: `
        font-family: ${fonts.neue};
        font-size: 16px;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: ${componentsPalette.actionPanel.tasks.done};
      `,
    },
  },
  contacts: {
    addContact: `
      font-family: ${fonts.wallop};
      font-weight: 500;
      font-size: 27px;
      line-height: 34px;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,
    name: `
      font-family: ${fonts.wallop};
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.03em;
      line-height: 155%;
      color: ${colors.basics.black};
    `,
    title: `
      font-family: ${fonts.neue};
      font-size: 16px;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,
    info: `
      font-family: ${fonts.wallop};
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: ${colors.basics.black};
    `,
    forms: {
      label: `
        font-family: ${fonts.wallop};
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.03em;
        color: ${colors.gray.c13};
      `,
      inputPlaceholder: `
        font-family: ${fonts.neue};
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;
        fontweight: 600;
        color: ${colors.gray.c7};
      `,
    },
  },
  kpi: {
    kpiLabel: `
    font-family: ${fonts.wallop};
    font-weight: 400;
    font-size: 14px;
    line-height: 16.07px;
    color: ${colors.gray.c12};
  `,
  },
  enhance: {
    header: `
      color: ${colors.basics.black};
      font-family: ${fonts.wallop};
      font-size: 55px;
      font-style: normal;
      font-weight: 500;
      line-height: 146%; 
      letter-spacing: 0.55px;
    `,
    subheader: `
      color: ${colors.gray.c13};
      font-family: ${fonts.neue};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.3px;
      letter-spacing: 0.01em;
    `,
    text: `
      color: ${colors.basics.black};
      font-family: ${fonts.wallop};
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 29.9px */
      letter-spacing: 0.23px;
    `,
    tableCompanyName: `
      font-family: ${fonts.wallop};
      font-size: 16px;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: 0.01em;
      color: ${colors.basics.black};
    `,
    tableSmallText: `
      font-family: ${fonts.neue};
      font-size: 12px;
      font-weight: 400;
      line-height: 17.4px;
      letter-spacing: 0.01em;
      color: ${colors.gray.c13};
    `,
  },
  exploreHistory: {
    periodTitle: `
      font-family: ${fonts.wallop};
      font-size: 12px;
      letter-spacing: 0.36px;
      line-height: 17.4px;
      font-weight: 500;
      color: ${colors.gray.c10};
    `,
  },
  copyProjectToAccount: {
    listTitle: `
      font-family: ${fonts.wallop};
      font-size: 12px;
      letter-spacing: 0.36px;
      line-height: 17.4px;
      font-weight: 500;
      color: ${colors.gray.c8};
    `,
  },
};
