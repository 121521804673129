import { useCurrentUser } from '@/user/use-current-user.query';

import { EditProfileForm } from './edit-profile-form';

export const ProfileSettings = () => {
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return <EditProfileForm user={user} />;
};
