import type { FieldError } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { styled } from 'goober';

import { theme } from '@/theme/setupTheme';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { Input } from '@/ui/input/input';
import { inputContainerMixin } from '@/ui/input/input-mixin';
import { Stack } from '@/ui/line/line';
import { Switch } from '@/ui/switch/switch';

import type { ContactsForm } from './contacts';

import 'react-phone-input-2/lib/style.css';

export const ContactForm = ({
  index,
  onDelete,
  onFocus,
}: {
  index: number;
  onDelete?: () => void;
  onFocus?: () => void;
}) => {
  const { t } = useTranslation('contacts');

  const {
    control,
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<ContactsForm>();

  const itemError = errors.contacts?.[index];

  return (
    <Stack gap="22px">
      <Flexbox
        name="contact-form-heading"
        justify="space-between"
        alignItems="top"
      >
        <Icon icon="Contact" />
        <Stack>
          <IconDanger
            icon="Bin"
            variant="ghost"
            size="16px"
            onClick={onDelete}
          />
        </Stack>
      </Flexbox>
      <Stack gap="18px">
        <Flexbox
          name="contact-primary"
          justify="space-between"
          alignContent="center"
        >
          <Label>{t('forms.primaryContact')}</Label>

          <Controller
            control={control}
            name={`contacts.${index}.isPrimary`}
            render={({ field: { value, ...fieldProps } }) => (
              <Switch
                {...fieldProps}
                checked={value}
                disabled={isSubmitting}
                onFocus={onFocus}
              />
            )}
          />
        </Flexbox>
      </Stack>
      <Stack>
        <Input
          type="text"
          {...register(`contacts.${index}.firstName`)}
          placeholder={t('forms.firstName')}
          disabled={isSubmitting}
          error={itemError?.firstName?.message}
          onFocus={onFocus}
          hideErrorMessage
        />
      </Stack>
      <Stack>
        <Input
          type="text"
          {...register(`contacts.${index}.lastName`)}
          placeholder={t('forms.lastName')}
          disabled={isSubmitting}
          error={itemError?.lastName?.message}
          onFocus={onFocus}
          hideErrorMessage
        />
      </Stack>
      <Stack>
        <Input
          type="text"
          {...register(`contacts.${index}.title`)}
          placeholder={t('forms.title')}
        />
      </Stack>
      <Stack>
        <Input
          type="text"
          {...register(`contacts.${index}.email`)}
          placeholder={t('forms.email')}
          onFocus={onFocus}
          error={itemError?.email?.message}
          hideErrorMessage
        />
      </Stack>
      <Stack>
        <Controller
          control={control}
          name={`contacts.${index}.mobilePhone`}
          render={({ field: { onChange, value } }) => (
            <Container>
              <PhoneInput
                enableLongNumbers
                value={value}
                placeholder={t('forms.phone')}
                onChange={onChange}
                onFocus={onFocus}
                dropdownStyle={{
                  display: 'none',
                }}
                buttonStyle={{
                  display: 'none',
                }}
                inputStyle={{
                  border: 'none',
                  paddingLeft: '2px',
                  width: '100%',
                  fontFamily: theme.fonts.neue,
                }}
              />
            </Container>
          )}
        />
      </Stack>
      <Stack>
        <Input
          type="text"
          {...register(`contacts.${index}.linkedinUrl`)}
          onFocus={onFocus}
          placeholder={t('forms.linkedInUrl')}
        />
      </Stack>

      {itemError && Object.keys(itemError).length > 0 && (
        <Flexbox name="contact-form-error" justify="center">
          <FormErrorMessage>
            {
              (
                itemError[
                  Object.keys(itemError)[0] as keyof typeof itemError
                ] as FieldError
              )?.message
            }
          </FormErrorMessage>
        </Flexbox>
      )}
    </Stack>
  );
};

const FormErrorMessage = styled('p')`
  color: ${({ theme }) => theme.colors.system.lowFit};
`;

const Label = styled('p')`
  ${({ theme }) => theme.typography.contacts.forms.label};
`;

const IconDanger = styled(IconButton)`
  padding: 0;
  ${({ theme }) =>
    `
    ${colorIcon(theme.colors.system.lowFit)}
  `};
`;

const Container = styled('div')<{
  error?: string;
  width?: string;
  disabled?: boolean;
}>`
  ${({ theme, disabled }) => inputContainerMixin(theme, disabled)}
  ${({ error, theme }) =>
    error && `border-color: ${theme.colors.system.lowFit};`}

    ${({ width }) => Boolean(width) && `width: ${width};`}

  input::placeholder {
    ${theme.typography.contacts.forms.inputPlaceholder}
  }
  padding: 4px 12px;
`;
