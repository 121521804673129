import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Descope } from '@descope/react-sdk';
import { captureException } from '@sentry/react';

import { Loader } from '@/components/loader/loader';
import { useDescopeFlow } from '@/hooks/use-descope-flow';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';
import { getDescopeError } from '@/auth/helpers/get-descope-error';
import { isInvalidInvitationTokenError } from '@/auth/helpers/is-invalid-invitation-token-error';
import { useInvitedUserDetails } from '@/auth/hooks/use-invited-user-details.query';
import { useSignUpParams } from '@/auth/hooks/use-sign-up-params';
import { useSignUp } from '@/auth/hooks/use-sign-up.mutation';
import type {
  SignUpFlowSuccessEvent,
  DescopeFlowErrorEvent,
  DescopeError,
} from '@/auth/types';

interface NewUserFormData {
  email: string;
  accountName: string;
}

export const SignUpPage = () => {
  const { t } = useTranslation('auth');
  const { isFlowLoading, onReady } = useDescopeFlow();
  const navigateTo = useNavigateTo();
  const { invitedUserParams } = useSignUpParams();
  const { handleSignUp } = useSignUp();
  const [newUserFormData, setNewUserFormData] = useState<NewUserFormData>({
    email: '',
    accountName: '',
  });
  const { isLoading: isUserDetailsLoading } = useInvitedUserDetails({
    onSuccessHandler: invitedUser => {
      if (invitedUser && invitedUserParams) {
        setNewUserFormData({
          email: invitedUserParams.email,
          accountName: invitedUser.account.name,
        });
      }
    },
  });

  const handleSuccessfulSignUp = async (event: SignUpFlowSuccessEvent) => {
    await handleSignUp({
      descopeToken: event.detail.sessionJwt,
      firstName: event.detail.user.givenName,
      lastName: event.detail.user.familyName,
      title: event.detail.user.middleName,
    });
  };

  if (isUserDetailsLoading) {
    return <Loader />;
  }

  const handleError = (
    event: string | Event | DescopeFlowErrorEvent | DescopeError,
  ) => {
    if (isInvalidInvitationTokenError(event)) {
      return navigateTo.signUpInvitationExpired();
    }
    const error = getDescopeError(event);
    captureException(error ?? event);
    notify({ message: t`loginError` });
  };

  return (
    <WithHtmlSavvyTitle title={t`signUpPageTitle`}>
      {isFlowLoading && <Loader />}
      <Descope
        flowId={window.runTimeEnvs.VITE_DESCOPE_SIGN_UP_FLOW}
        onSuccess={handleSuccessfulSignUp}
        onError={handleError}
        onReady={onReady}
        form={{
          accountName: newUserFormData.accountName,
          email: newUserFormData.email,
          invitationEmail: newUserFormData.email,
        }}
      />
    </WithHtmlSavvyTitle>
  );
};
