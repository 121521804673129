import type { RemoveProjectExternalLinkPayload } from '@/api/v4/project-external-links.api';
import { removeProjectExternalLink } from '@/api/v4/project-external-links.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useRemoveProjectExternalLink = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  return useMutation(
    async ({ projectId, linkId }: RemoveProjectExternalLinkPayload) => {
      return removeProjectExternalLink({ projectId, linkId });
    },
    {
      onSuccess: async (_data, variables) => {
        await queryClient.invalidateQueries([
          QueryKey.ProjectExternalLinks,
          variables.projectId,
        ]);
      },
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
