import { getYear } from 'date-fns';

export const getYearsOptions = (range?: number) => {
  const years = Array.from(
    Array(range || 151),
    (_, index) => `${getYear(new Date()) - index}`,
  );
  return years.map(year => ({
    label: year,
    value: year,
  }));
};
