import { Carousel } from '@/components/carousel/carousel';
import { NewsSlide } from './news-slide';
import { Header } from '@/ui/typography/widgets';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useCompanyNews } from './use-company-news.query';
import { Loader } from '@/components/loader/loader';
import { useCallback, useMemo } from 'react';
import { useSwiperWithPagination } from '@/hooks/use-swiper-with-pagination';
import { EditStamp } from '../edit-stamp';
import { useCompany } from '../use-company.query';

export const NewsFeedWidget = ({ className }: { className: string }) => {
  const { t } = useTranslation('companies');
  const {
    news,
    pageSize,
    pages,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useCompanyNews();
  const { swiperRef } = useSwiperWithPagination(pages.length, pageSize);
  const {
    data: company,
    isCrunchbaseCompany,
    isFetched: isCompanyFetched,
  } = useCompany();

  const slides = useMemo(() => {
    if (!news) return [];
    return news.map((item, index) => (
      <NewsSlide
        key={`${item.title}-${index}`}
        articleUrl={item.url}
        imageUrl={item.imageUrl}
        title={item.title}
        publisher={item.publisher}
        postedOn={item.postedOn}
      />
    ));
  }, [news]);

  const loadMore = useCallback(async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const noResults = useMemo(
    () => !isLoading && !isFetching && (!news || news?.length === 0),
    [isLoading, isFetching, news],
  );
  const withBackground = useMemo(
    () => isLoading || isFetching || noResults,
    [isLoading, isFetching, noResults],
  );

  if (!isCrunchbaseCompany && isCompanyFetched) return null;

  return (
    <Widget
      className={className}
      withBackground={withBackground}
      data-testid="news-feed-widget"
    >
      {isLoading || isFetching ? (
        <LoaderBox>
          <Loader />
        </LoaderBox>
      ) : (
        <>
          <WidgetHeader>{t`newsFeed.header`}</WidgetHeader>
          {noResults ? (
            <EmptyStateHeader>{t`newsFeed.emptyState`}</EmptyStateHeader>
          ) : (
            <ContentWrapper>
              <Carousel
                onSwiper={swiper => {
                  swiperRef.current = swiper;
                }}
                slides={slides}
                slidesPerView={3.5}
                onReachEnd={loadMore}
              />
              <EditStamp
                crunchbasePermalink={company?.crunchbasePermalink ?? null}
                align="left"
                margin="0 0 0 20px"
              />
            </ContentWrapper>
          )}
        </>
      )}
    </Widget>
  );
};

const Widget = styled('div')<{ withBackground?: boolean }>`
  padding: 36px 0;

  ${({ theme, withBackground }) =>
    withBackground &&
    `
    background-color: ${theme.colors.basics.white};
    border-radius: 20px;
    box-shadow:  ${theme.shadow.widgets};
  `}
`;

const LoaderBox = styled('div')`
  height: 360px;
`;

const WidgetHeader = styled(Header)`
  margin-bottom: 36px;
  padding-left: 40px;
`;

const EmptyStateHeader = styled('h1')`
  ${({ theme }) => theme.typography.widget.emptyState};
  text-align: center;
  padding: 30px;
`;

const ContentWrapper = styled('div')`
  padding-left: 20px;
`;
