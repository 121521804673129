import { styled } from 'goober';

import { Z_INDEX_STICKY_HEADER } from '@/theme/z-index';

import { Flexbox } from '../../flexbox/flexbox';
import { colorIcon } from '../../icons/functions';
import { Icon } from '../../icons/icon';
import type {
  IAlign,
  IHeaderCell,
  ITableSort,
  IVerticalAlign,
} from '../table.types';

interface ContentProps<T> {
  cell: IHeaderCell<T>;
  sort?: ITableSort<T>;
}

export const DraggableTableHeaderCell = <T,>({
  cell,
  sort,
}: ContentProps<T>) => {
  const onHeaderClick = (cell: IHeaderCell<T>) => {
    if (!sort || !cell.sortKey) return;

    sort.onSort({
      direction: sort.direction === 'desc' ? 'asc' : 'desc',
      sort: cell.sortKey,
    });
  };

  return (
    <Th
      key={cell.columnKey}
      align={cell.align}
      width={cell.width}
      padding={cell.padding}
    >
      <Cell
        name="draggable-table-header-cell"
        gap="8px"
        isClickable={Boolean(sort) && Boolean(cell.sortKey)}
        onClick={() => onHeaderClick(cell)}
        isSortActive={sort?.sort === cell.sortKey}
      >
        {!cell.hideLabel && (
          <>
            {cell.value}
            {!!sort && !!cell.sortKey && (
              <Icon
                icon={
                  sort.direction === 'asc' && sort.sort === cell.sortKey
                    ? 'SortArrowUp'
                    : 'SortArrowDown'
                }
              />
            )}
          </>
        )}
      </Cell>
    </Th>
  );
};

const Th = styled('th')<{
  align?: IAlign;
  verticalAlign?: IVerticalAlign;
  width?: string;
  breakWord?: boolean;
  padding?: string;
}>`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.basics.white};
  z-index: ${Z_INDEX_STICKY_HEADER};
  padding: ${({ padding = '0 20px' }) => padding};
  ${({ align = 'left' }) => `text-align: ${align}`};

  ${({ width }) => !!width && `width: ${width};`}
  ${({ breakWord }) => !!breakWord && 'word-break: break-word;'}
`;

const Cell = styled(Flexbox)<{
  isClickable?: boolean;
  isSortActive?: boolean;
}>`
  ${({ isClickable }) => Boolean(isClickable) && `cursor: pointer`};
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
  
  padding-bottom: 12px;

  color: ${({ theme }) => theme.colors.basics.black};

  opacity: ${({ isSortActive }) => (isSortActive ? 1 : 0.5)};
`;
