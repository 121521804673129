import { isEqual } from 'lodash-es';
import { useRecoilState } from 'recoil';

import type { IOption } from '@/types';

import type { ExampleCompany } from '../explore.state';
import { useExploreForm } from '../form/use-explore-form';

import {
  regenerateExploreFormDescriptionState,
  regenerateExploreFormExampleCompaniesState,
  regenerateExploreFormLocationsState,
} from './regenerate-explore.state';

export const useRegenerateExploreForm = () => {
  const [updatedLocations, setUpdatedLocations] = useRecoilState(
    regenerateExploreFormLocationsState,
  );
  const [updatedExampleCompanies, setUpdatedExampleCompanies] = useRecoilState(
    regenerateExploreFormExampleCompaniesState,
  );
  const [updatedDescription, setUpdatedDescription] = useRecoilState(
    regenerateExploreFormDescriptionState,
  );
  const { setLocations, setExampleCompanies, setDescription } =
    useExploreForm();
  const isValid = !!updatedDescription.length;

  const resetForm = () => {
    setUpdatedLocations([]);
    setUpdatedDescription('');
    setUpdatedExampleCompanies([]);
  };

  const setDefaultValues = ({
    defaultLocations,
    defaultDescription,
    defaultExampleCompanies,
  }: {
    defaultLocations: IOption[];
    defaultDescription: string;
    defaultExampleCompanies: ExampleCompany[];
  }) => {
    setUpdatedLocations(defaultLocations);
    setUpdatedExampleCompanies(defaultExampleCompanies);
    setUpdatedDescription(defaultDescription);
  };

  const saveUpdatedValues = () => {
    setLocations(updatedLocations);
    setExampleCompanies(updatedExampleCompanies);
    setDescription(updatedDescription);
  };

  const checkIsDirty = ({
    defaultLocations,
    defaultDescription,
    defaultExampleCompanies,
  }: {
    defaultLocations: IOption[];
    defaultDescription: string;
    defaultExampleCompanies: ExampleCompany[];
  }) => {
    return (
      updatedDescription !== defaultDescription ||
      !isEqual(defaultLocations, updatedLocations) ||
      !isEqual(defaultExampleCompanies, updatedExampleCompanies)
    );
  };

  return {
    updatedLocations,
    updatedExampleCompanies,
    updatedDescription,
    setUpdatedDescription,
    setUpdatedExampleCompanies,
    setUpdatedLocations,
    setDefaultValues,
    saveUpdatedValues,
    isValid,
    checkIsDirty,
    resetForm,
  };
};
