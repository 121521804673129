import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import SvgNoProjects from '@/assets/widgets/empty-state-no-project.svg';
import { Loader } from '@/components/loader/loader';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { Header } from '@/ui/typography/widgets';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useActiveProjectTableConfig } from './active-project-table-config';
import { useActiveProjects } from './use-active-projects';
import { RouteLink } from '@/ui/link/route-link';
import { paths } from '@/routes/helpers/paths';
const WIDGET_WIDTH = 747;

export const ActiveProjectWidget = () => {
  const { t } = useTranslation('projects');
  const { hasRequiredPermission } = useUserPermissions();
  const canAddProject = hasRequiredPermission(PERMISSION.ADD_PROJECT);
  const navigateTo = useNavigateTo();
  const { data } = useActiveProjects();

  const { rows } = useActiveProjectTableConfig(data);

  if (!data)
    return (
      <Widget showEditButton={false} width={WIDGET_WIDTH}>
        <Flexbox name="active-proejcts-loading" justify="center" height="100%">
          <Loader />
        </Flexbox>
      </Widget>
    );

  if (data.length === 0 && !canAddProject)
    return (
      <EmptyStateWidget
        minWidth={`${WIDGET_WIDTH}px`}
        label={t`projects.noActiveProjects`}
        labelWidth={220}
        image={SvgNoProjects}
        imageWidth={144}
      />
    );

  const handleEmptyStateOnClick = () => {
    if (canAddProject) navigateTo.createProject();
  };

  if (data.length === 0 && canAddProject)
    return (
      <EmptyStateWidget
        minWidth={`${WIDGET_WIDTH}px`}
        label={t`projects.createFirstProject`}
        labelWidth={220}
        linkText={t`projects.createProjects`}
        image={SvgNoProjects}
        imageWidth={144}
        onClick={handleEmptyStateOnClick}
      />
    );

  return (
    <Widget showEditButton={false} width={WIDGET_WIDTH}>
      <Flexbox
        name="active-projects-widget-flexcontainer"
        direction="column"
        gap="36px"
      >
        <Header>{t`projects.activeProjects`}</Header>
        <Table>
          <TableBody
            rows={rows}
            isLoading={false}
            skeletonConfig={{
              columns: 1,
            }}
          />
        </Table>
        <AllProjectsLink
          to={{
            pathname: paths.projects(),
          }}
        >
          {t`projects.seeAllProjectsLink`}
        </AllProjectsLink>
      </Flexbox>
    </Widget>
  );
};

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  position: relative;
`;

const AllProjectsLink = styled(RouteLink)`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
  color: ${({ theme }) => theme.colors.gray.c12};
  display: inline-flex;
  gap: 12px;
`;
