import { forwardRef, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd-cjs';
import { getEmptyImage } from 'react-dnd-html5-backend-cjs';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import type { IAlign, ICell, Row, IVerticalAlign } from '../table.types';

import { isTableItemDraggingState } from './draggable-table.state';

export const DND_DRAGGABLE_ITEM = 'row';

export type DraggableItem = {
  type: typeof DND_DRAGGABLE_ITEM;
  id: string;
  content: ICell['value'];
};
export const DraggableRow = ({ row }: { row: Row }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isForbidDrag, setForbidDrag] = useState(false);
  const setIsDragging = useSetRecoilState(isTableItemDraggingState);

  const { hasRequiredPermission } = useUserPermissions();

  const canDrag = hasRequiredPermission(
    PERMISSION.UPDATE_COMPANY_LISTING_STATUS,
  );

  const [{ isDragging, item }, dragRef, dragPreview] = useDrag({
    canDrag: () => canDrag && !isForbidDrag,
    item: {
      type: DND_DRAGGABLE_ITEM,
      id: row.id,
      content: row.cells[0].value,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
    begin: monitor => {
      if (isForbidDrag) {
        const handlerId = monitor.getHandlerId();
        if (handlerId) {
          monitor.getDropResult()?.endDrag(handlerId);
        }
        return;
      }
      setIsDragging(true);
      document.body.classList.add('dragging');
    },
    end: () => {
      setIsDragging(false);
      setForbidDrag(false);
      document.body.classList.remove('dragging');
    },
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  const isOtherDragged = item && row.id !== item.id;

  return (
    <Tr
      ref={dragRef}
      onMouseEnter={() => !isOtherDragged && setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setForbidDrag(false);
      }}
      isOtherDragged={isOtherDragged}
      isCurrentDragged={isDragging}
      disabled={!canDrag}
      onClick={row.onClick}
      onMouseDown={event => {
        const target = event.target as HTMLElement;
        const isExcluded =
          target.closest('[data-not-draggable="true"]') !== null;

        if (isExcluded) {
          setForbidDrag(true);
          setTimeout(() => {
            setIsDragging(false);
            document.body.classList.remove('dragging');
          }, 0);
        }
      }}
      onMouseUp={() => setForbidDrag(false)}
    >
      {row.cells.map((cell, cellIndex) => (
        <Td
          key={cellIndex}
          isHeader={cell.isHeader}
          align={cell.align}
          verticalAlign={cell.verticalAlign}
          breakWord={cell.breakWord}
          isDragging={isDragging}
          showOnHover={Boolean(cell.hoverOnly)}
          isHovered={isHovered}
          padding={cell.padding}
        >
          {cell.value}
        </Td>
      ))}
    </Tr>
  );
};

const Td = styled('td')<{
  isHeader?: boolean;
  align?: IAlign;
  verticalAlign?: IVerticalAlign;
  width?: string;
  breakWord?: boolean;
  isDragging: boolean;
  isHovered: boolean;
  showOnHover?: boolean;
  padding?: string;
}>`
  ${({ theme }) => theme.typography.companyInfo.info};

  ${({ verticalAlign = 'top' }) => `vertical-align: ${verticalAlign}`};
  padding: ${({ padding = '30px 20px' }) => padding};
  background: ${({ theme }) => theme.colors.basics.canvas};
  border: 2px solid transparent;

  ${({ align = 'left' }) => `text-align: ${align};`}
  ${({ width }) => Boolean(width) && `width: ${width};`}
  ${({ breakWord }) => Boolean(breakWord) && 'word-break: break-word;'}

  ${({ isDragging }) =>
    isDragging &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
  user-select: none;

  & * {
    ${({ showOnHover }) =>
      Boolean(showOnHover) && 'visibility: hidden; pointer-events: none;'}

    ${({ showOnHover, isHovered }) =>
      Boolean(showOnHover) &&
      isHovered &&
      'visibility: visible; pointer-events: auto;'}
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Tr = styled('tr', forwardRef)<{
  isCurrentDragged: boolean;
  isOtherDragged: boolean;
  disabled: boolean;
}>`
  border-radius: 20px;

  ${({ theme, isCurrentDragged }) =>
    isCurrentDragged &&
    `
    ${theme.mixins.pseudoBorder({
      color: theme.colors.gray.c6,
      type: 'solid',
      length: 2,
    })}
  `};

  &:hover {
    cursor: ${({ isCurrentDragged, isOtherDragged }) =>
      isCurrentDragged || isOtherDragged ? 'grabbing' : 'pointer'};

    ${({ disabled }) => disabled && `cursor: default;`}

    ${({ isOtherDragged, theme, disabled }) =>
      !isOtherDragged &&
      !disabled &&
      `
      ${theme.mixins.pseudoBorder({
        color: theme.colors.gray.c6,
        type: 'solid',
        length: 2,
      })}
    `};
  }
`;
