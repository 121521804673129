import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isDefaultOption } from '@/helpers/other';
import { Inline } from '@/ui/line/line';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const YearFoundedFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  const filterOptions = useRecoilValue(companiesFilterOptionsState);

  return (
    <Inline gap="8px" templateColumns="repeat(2, 1fr)" className={className}>
      <AsyncSelect
        id="filter-year-founded-from"
        label={t`companies.filters.yearFrom`}
        defaultValue={selectedFilters.yearFounded?.start}
        setOptions={filterOptions.yearFounded}
        isSearchable={false}
        onChange={option => {
          const newOption = isDefaultOption(option) ? option : null;
          setSelectedFilters(currentFilters => {
            const isYearToValid =
              Number(currentFilters.yearFounded?.end?.value) >=
              Number(newOption?.value);
            return {
              ...currentFilters,
              yearFounded: {
                start: newOption,
                end: isYearToValid
                  ? currentFilters.yearFounded?.end
                  : newOption,
              },
            };
          });
        }}
        {...sharedProps}
      />
      <AsyncSelect
        id="filter-year-founded-to"
        defaultValue={selectedFilters.yearFounded?.end}
        label={t`companies.filters.yearTo`}
        setOptions={filterOptions.yearFounded}
        optionProps={{ hideDisabled: true }}
        isSearchable={false}
        isOptionSelected={option => {
          return (
            (!selectedFilters.yearFounded?.end &&
              option === selectedFilters.yearFounded?.start) ||
            Number(selectedFilters.yearFounded?.end?.value) <
              Number(selectedFilters.yearFounded?.start?.value)
          );
        }}
        isOptionDisabled={option => {
          if (!isDefaultOption(option) || !selectedFilters.yearFounded.start) {
            return false;
          }
          return (
            Number(option.value) <
            Number(selectedFilters.yearFounded.start.value)
          );
        }}
        onChange={option => {
          setSelectedFilters(currentFilters => ({
            ...currentFilters,
            yearFounded: {
              start: currentFilters.yearFounded?.start || null,
              end: isDefaultOption(option) ? option : null,
            },
          }));
        }}
        {...sharedProps}
      />
    </Inline>
  );
};
