import { filterOutEmptyFilterValues } from '@/helpers/filters-helpers';
import type { Entries } from '@/helpers/other';
import {
  isCapitalRaisedOption,
  isDefaultOption,
  isNumberOption,
} from '@/helpers/other';
import type {
  CapitalRaisedOption,
  INumberOption,
  IOption,
  MinMaxRange,
} from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { GeneralTag } from '@/ui/tag/general-tag';
import { useTranslation } from 'react-i18next';

interface Props<T> {
  tags: Entries<T>;
  handleRemove: (key: keyof T, value?: string | number | MinMaxRange) => void;
  handleClear: () => void;
  getTagLabel: (option: unknown) => string;
}

export const AppliedFiltersTags = <T extends object>({
  tags,
  handleRemove,
  handleClear,
  getTagLabel,
}: Props<T>) => {
  const { t } = useTranslation('projects');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (!tags.filter(([_, value]) => filterOutEmptyFilterValues(value)).length) {
    return null;
  }

  return (
    <Flexbox name="applied-filters-container" gap="10px">
      <Flexbox name="applied-filters-row" gap="10px" fullWidth wrap="wrap">
        {tags.map(([key, value]) => {
          if (Array.isArray(value)) {
            const filters = value as
              | IOption[]
              | INumberOption[]
              | CapitalRaisedOption[];

            return filters.map(v => {
              if (
                !isDefaultOption(v) &&
                !isNumberOption(v) &&
                !isCapitalRaisedOption(v)
              )
                return null;

              return (
                <GeneralTag
                  key={v.label}
                  variant="blue"
                  size="s"
                  onRemove={() => handleRemove(key, v.value)}
                >
                  {v.label}
                </GeneralTag>
              );
            });
          }
          if (value && typeof value === 'object') {
            const label = getTagLabel(value);
            if (!label) return null;
            return (
              <GeneralTag
                key={label}
                variant="blue"
                size="s"
                onRemove={() => handleRemove(key)}
              >
                {label}
              </GeneralTag>
            );
          }
          if (typeof value === 'boolean') {
            const str = key.toString();
            const label = `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
            return (
              <GeneralTag
                key={label}
                variant="blue"
                size="s"
                onRemove={() => handleRemove(key)}
              >
                {label}
              </GeneralTag>
            );
          }
          return null;
        })}
      </Flexbox>
      <div>
        <Button variant="underlined" onClick={handleClear}>
          {t`projects.clearAll`}
        </Button>
      </div>
    </Flexbox>
  );
};
