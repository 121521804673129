import type { SwiperProps } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import './custom-carousel-styles.css';
import type { ReactNode } from 'react';

interface CarouselProps extends SwiperProps {
  slides: ReactNode[];
}

export const Carousel = ({ slides, ...options }: CarouselProps) => {
  return (
    <Swiper modules={[Navigation]} navigation {...options}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};
