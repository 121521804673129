import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';
import { appliedProjectsFiltersState } from '../project.state';
import { useRecoilValue } from 'recoil';
import { fetchProjectKPIs } from '@/api/v4/projects.api';
import { prepareProjectApiFilters } from '../filters/project-filters-helpers';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const useProjectKpi = () => {
  const appliedFilters = useRecoilValue(appliedProjectsFiltersState);

  const getProjectsKPIs = async () =>
    fetchProjectKPIs({
      ...prepareProjectApiFilters(appliedFilters),
    });

  return useQuery([QueryKey.ProjectsKpi, appliedFilters], getProjectsKPIs, {
    staleTime: getTimeInMs(5, 'minute'),
    cacheTime: getTimeInMs(15, 'minute'),
  });
};
