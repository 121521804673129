import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { companyIdState } from '../company.state';
import type { CreateCompanyContactBodyPayload } from '@/api/v4/organization-contacts.api';
import { createCompanyContacts } from '@/api/v4/organization-contacts.api';

export const useCreateCompanyContacts = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  const mutation = useMutation(createCompanyContacts, {
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const createContacts = (data: CreateCompanyContactBodyPayload) =>
    companyId
      ? mutation.mutateAsync({
          companyId,
          data,
        })
      : undefined;

  return {
    createContacts,
    isError: mutation.isError,
  };
};
