import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDebouncedResizeObserver } from '@/hooks/use-debounced-resize-observer';
import { usePrevious } from '@/hooks/use-previous';

import { handleFontSize } from './text-editable.helpers';
import {
  EditIcon,
  TextArea,
  TextEditableWrapper,
} from './text-editable.styles';

interface TextEditableProps {
  value: string;
  placeholder?: string;
  adjustFontSize?: boolean;
  allowLineBreaks?: boolean;
  className?: string;
  maxLength?: number;
  width?: number;
  shouldFocus?: boolean;
  disableEditButton?: boolean;
  focusAtEnd?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (textArea: HTMLTextAreaElement | null) => void;
  onInput?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onConfirm?: (value: string) => void;
  onError?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  border?: boolean;
  borderedWrapper?: boolean;
  hideOnEmpty?: boolean;
  onScrollHeightChange?: (height: number) => void;
  isExpanded?: boolean;
  allowEmptyValue?: boolean;
  dataTestId?: string;
}

export function TextEditable({
  className,
  value,
  placeholder,
  adjustFontSize = false,
  allowLineBreaks = false,
  maxLength,
  width,
  shouldFocus = false,
  disableEditButton = false,
  focusAtEnd = false,
  disabled = false,
  readOnly = true,
  border = false,
  allowEmptyValue = true,
  hideOnEmpty,
  isExpanded,
  onChange,
  onConfirm,
  onFocus,
  onInput,
  onScrollHeightChange,
  onError,
  dataTestId,
}: TextEditableProps) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [input, setInput] = useState(value);
  const [baseFontSize, setBaseFontSize] = useState(-1);
  const [baseWidth, setBaseWidth] = useState(-1);
  const [resizeWidth, setResizeWidth] = useState<number | null>(null);

  const oldInput = usePrevious(input) ?? value ?? '';

  useEffect(() => {
    if (shouldFocus) {
      handleEdit(ref);
    }
  }, [shouldFocus]);

  useEffect(() => {
    setInput(value);
    if (ref.current?.parentNode) {
      (ref.current.parentNode as HTMLDivElement).dataset.value = value;
    }
  }, [value]);

  useEffect(() => {
    if (!ref.current || !adjustFontSize) return;

    handleFontSize({
      el: ref.current,
      baseFontSize,
      value: input,
      previousValue: oldInput,
    });
  }, [input, oldInput]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const refStyles = getComputedStyle(ref.current);
    const defaultFontSize = parseInt(refStyles.fontSize, 10);
    setBaseFontSize(defaultFontSize);
    setBaseWidth(ref.current.scrollWidth);
  }, [ref]);

  useEffect(() => {
    if (!ref?.current?.scrollHeight) return;

    onScrollHeightChange?.(ref?.current?.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.scrollHeight]);

  useDebouncedResizeObserver({
    ref,
    wait: 100,
    onResize(_, scroll) {
      if (!ref.current || !scroll || !adjustFontSize) return;

      const refStyles = getComputedStyle(ref.current);
      const vPadding =
        parseFloat(refStyles.paddingTop) + parseFloat(refStyles.paddingBottom);
      let defaultFontSize = parseInt(refStyles.fontSize, 10);

      if (
        (ref.current.dataset.resize !== 'asc' && scroll.width < baseWidth) ||
        (resizeWidth ? scroll.width <= resizeWidth : true)
      ) {
        ref.current.dataset.resize = 'desc';
        while (ref.current.scrollHeight > ref.current.clientHeight + vPadding) {
          defaultFontSize--;
          ref.current.style.fontSize = `${defaultFontSize}px`;
        }
        if (ref.current.scrollHeight <= ref.current.clientHeight + vPadding) {
          setResizeWidth(scroll.width);
        }
      }

      if (resizeWidth && scroll.width > resizeWidth) {
        while (
          ref.current.scrollHeight <= ref.current.clientHeight + vPadding &&
          defaultFontSize < baseFontSize
        ) {
          defaultFontSize++;
          ref.current.style.fontSize = `${defaultFontSize}px`;
        }
      }

      while (ref.current.scrollHeight > ref.current.clientHeight + vPadding) {
        defaultFontSize--;
        ref.current.style.fontSize = `${defaultFontSize}px`;
      }
    },
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
    onChange?.(event);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && allowLineBreaks === false) {
      event.preventDefault();
    }
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (event.keyCode === 13 || event.keyCode === 27) &&
      allowLineBreaks === false
    ) {
      event.preventDefault();
      (event.target as HTMLTextAreaElement).blur();
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!allowEmptyValue && event.target.value.length === 0) {
      onError?.();
      setInput(value);
      return;
    }

    const { relatedTarget, target } = event;

    if (relatedTarget?.tagName.toUpperCase() === 'A') {
      event.preventDefault();
      return;
    }
    if (relatedTarget?.contains(target)) {
      event.preventDefault();
      target.focus();
      return;
    }
    if (maxLength && target.value.length > maxLength) {
      event.preventDefault();
      return;
    }
    target.scrollTop = 0;

    onConfirm?.(event.target.value);
  };

  const handleEdit = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (!ref.current) return;
    ref.current.readOnly = false;
    ref.current.selectionStart = ref.current.selectionEnd = focusAtEnd
      ? ref.current.value.length
      : 0;
    ref.current.focus();
    onFocus?.(ref.current);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [ref, isExpanded]);

  if (hideOnEmpty && input.length === 0) return null;
  return (
    <TextEditableWrapper
      className="text-editable-wrapper"
      width={width}
      data-testid={dataTestId}
    >
      <TextArea
        className={className} // needed to apply css via goober className when creating styles like styled(TextEditable)
        value={input}
        maxLength={maxLength}
        placeholder={placeholder}
        spellCheck={false}
        ref={ref}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDown}
        onInput={onInput}
        disabled={disabled}
        readOnly={readOnly}
        border={border}
      />
      {!disableEditButton && (
        <EditIcon
          icon="Edit"
          className="edit-icon"
          role="button"
          onClick={() => handleEdit(ref)}
        />
      )}
    </TextEditableWrapper>
  );
}
