import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import type { CompanySortOptions } from '@/api/v4/organization.api';
import type { ISort } from '@/ui/table/table.types';

import type { CompanyFilters } from '../companies.state';
import { companiesSortState } from '../companies.state';

import { stringifyCompanyFilters } from './company-filters-helpers';

export const useCompaniesFiltersSearchLocationSearch = () => {
  const navigate = useNavigate();
  const sort = useRecoilValue(companiesSortState);

  const updateLocationSearch = (
    filters: CompanyFilters,
    newSort?: ISort<CompanySortOptions | undefined>,
  ) => {
    const locationSearch = stringifyCompanyFilters({
      filters,
      sort: newSort || (sort?.sort ? sort : undefined),
    });
    navigate({ ...location, search: locationSearch }, { replace: true });
  };

  const resetLocationSearch = () => {
    const search = sort?.sort ? stringifyCompanyFilters({ sort }) : '';
    navigate({ ...location, search }, { replace: true });
  };

  return {
    updateLocationSearch,
    resetLocationSearch,
  };
};
