import { useSetRecoilState } from 'recoil';

import type { Team } from '@/api/v4/team.api';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { projectFormState } from './project.state';

export const useNavigateToProjectFormWithCompanyData = () => {
  const setProjectForm = useSetRecoilState(projectFormState);
  const navigateTo = useNavigateTo();

  const navigateToProjectFormWithCompanyData = (
    organization: Team['organization'] | null,
  ) => {
    setProjectForm(currentForm => ({
      ...currentForm,
      organization,
    }));

    navigateTo.createProject();
  };

  return {
    navigateToProjectFormWithCompanyData,
  };
};
