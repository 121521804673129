import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchProjectFiltersTeamOptions } from '@/api/v4/project-filters.api';
import {
  projectsFilterOptionsState,
  selectedProjectsFiltersState,
} from '@/features/projects/project.state';
import { isDefaultOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

import { mapProjectCreatedForFilter } from './project-filters-helpers';

export const CreatedForFilter = () => {
  const { t } = useTranslation('projects');

  const setProjectsFilters = useSetRecoilState(projectsFilterOptionsState);
  const projectFiltersOptions = useRecoilValue(projectsFilterOptionsState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  const setOptions = async (search: string) => {
    if (search.length) {
      const teams = await fetchProjectFiltersTeamOptions({
        search,
      });
      const createdForOptions = teams.map(mapProjectCreatedForFilter);
      setProjectsFilters(currentFilters => ({
        ...currentFilters,
        createdFor: createdForOptions,
      }));
      return createdForOptions;
    }
    return projectFiltersOptions.createdFor;
  };

  return (
    <AsyncSelect
      id="filter-created-for"
      label={t`projects.createdFor`}
      defaultValue={selectedFilters.createdFor}
      isSearchable
      isMulti
      setOptions={setOptions}
      onChange={option => {
        if (!isDefaultOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          createdFor: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
