import { useState } from 'react';
import type { DragLayerMonitor } from 'react-dnd-cjs';
import { useDragLayer } from 'react-dnd-cjs';
import { isEqual } from 'lodash-es';

export function useEfficientDragLayer<CollectedProps>(
  collect: (monitor: DragLayerMonitor) => CollectedProps,
): CollectedProps {
  const collected = useDragLayer(collect);
  const [previousCollected, setPreviousCollected] =
    useState<CollectedProps>(collected);
  const [requestID, setRequestID] = useState<number>();
  if (requestID === undefined && !isEqual(collected, previousCollected)) {
    setPreviousCollected(collected);
    setRequestID(requestAnimationFrame(() => setRequestID(undefined)));
  }
  return previousCollected;
}
