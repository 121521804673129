import type { UsersListSort } from '@/api/v4/user.api';
import { colors } from '@/theme/colors';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const invitedUsersTableHeaders: HeaderCellConfig<UsersListSort>[] = [
  {
    columnKey: 'email',
    sortKey: 'email',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'role',
    sortKey: 'role',
    align: 'left',
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'createdAt',
    sortKey: 'createdDate',
    isVisible: true,
    bgColor: colors.basics.white,
  },
  {
    columnKey: 'action',
    hideLabel: true,
    width: '32px',
    bgColor: colors.basics.white,
  },
];
