import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  companiesFilterOptionsState,
  selectedCompaniesFiltersState,
} from '@/features/companies/companies.state';
import { isCapitalRaisedOptionArray } from '@/helpers/other';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const CapitalRaisedFilter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const defaultOptions = useRecoilValue(companiesFilterOptionsState);

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedCompaniesFiltersState,
  );

  return (
    <AsyncSelect
      id="filter-capital-raised"
      label={t`companies.filters.capitalRaised`}
      defaultValue={selectedFilters.capitalRaised}
      setOptions={defaultOptions.capitalRaised}
      isSearchable={false}
      isMulti
      className={className}
      onChange={option => {
        if (!isCapitalRaisedOptionArray(option)) return;

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          capitalRaised: option.length ? option : null,
        }));
      }}
      {...sharedProps}
    />
  );
};
