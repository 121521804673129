import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';

import type { EditProfileForm } from './use-edit-profile-form';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PERMISSION } from '@/user/permissions/permission.type';

export const EditProfileFormFields = () => {
  const { t } = useTranslation('settings');
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<EditProfileForm>();
  const { hasRequiredPermission } = useUserPermissions();

  const email = watch('email');

  return (
    <Grid>
      <Input
        placeholder={t`name`}
        {...register('name')}
        error={errors?.name?.message}
      />
      <Input
        placeholder={t`lastName`}
        {...register('lastName')}
        error={errors?.lastName?.message}
      />
      <Input
        placeholder={t`position`}
        {...register('position')}
        error={errors?.position?.message}
      />
      <EmailInput name="email" readOnly type="email" value={email} disabled />

      <AccountNameBox gap="6px" fullWidth>
        <Label>{t`accountName`}</Label>
        <Input
          placeholder={t`accountName`}
          {...register('accountName')}
          disabled={!hasRequiredPermission(PERMISSION.ENABLE_ACCOUNT_DETAILS)}
        />
      </AccountNameBox>
    </Grid>
  );
};

const Grid = styled('div')`
  margin-top: 12px;
  display: grid;
  gap: 40px 80px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr auto;
`;

const AccountNameBox = styled(Stack)`
  grid-column: auto;
`;

const EmailInput = styled(Input)`
  color: ${({ theme }) => theme.colors.gray.c10};
`;

const Label = styled('h2')`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.tab};
  color: ${({ theme }) => theme.colors.gray.c11};
`;
