import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, post, remove } from './base-fetch';

export type CompanyExternalLink =
  APIResponse<'/api/v4/organizations/{organizationId}/external-links'>[number];

export const fetchCompanyExternalLinks = async (organizationId: string) =>
  get<CompanyExternalLink[]>(
    `/v4/organizations/${organizationId}/external-links`,
  );

export type AddCompanyExternalLinkRequestBody =
  APIRequestBody<'/api/v4/organizations/{organizationId}/external-links/add'>;

export type RemoveCompanyExternalLinkPayload = {
  companyId: string;
  linkId: string;
};

export interface AddCompanyExternalLinkPayload {
  companyId: string;
  data: AddCompanyExternalLinkRequestBody;
}

export const addCompanyExternalLinkAPI = async ({
  companyId,
  data,
}: AddCompanyExternalLinkPayload) =>
  post<CompanyExternalLink>(
    `/v4/organizations/${companyId}/external-links/add`,
    data,
  );

export const removeCompanyExternalLink = async ({
  linkId,
  companyId,
}: RemoveCompanyExternalLinkPayload) =>
  remove<CompanyExternalLink>(
    `/v4/organizations/${companyId}/external-links/remove/${linkId}`,
  );
