import { forwardRef } from 'react';
import type { XYCoord } from 'react-dnd-cjs';
import { styled } from 'goober';

import { useEfficientDragLayer } from '@/hooks/use-efficient-drag-layer';
import { Z_INDEX_DRAGGABLE_ROW } from '@/theme/z-index';

import type { DraggableItem } from './draggable-row';

export const DraggableRowLayer = () => {
  const { item, currentOffset } = useEfficientDragLayer(monitor => ({
    item: monitor.getItem() as DraggableItem,
    currentOffset: monitor.getClientOffset(),
  }));

  if (!item) {
    return null;
  }

  return <DraggedRow currentOffset={currentOffset}>{item.content}</DraggedRow>;
};

const DRAG_PREVIEW_DIMENSIONS = {
  width: 302,
  height: 96,
};

const DraggedRow = styled('div', forwardRef)<{
  currentOffset: XYCoord | null;
}>`
  height: ${DRAG_PREVIEW_DIMENSIONS.height}px;
  width: ${DRAG_PREVIEW_DIMENSIONS.width}px;

  display: flex;
  align-items: center;

  position: fixed;
  pointer-events: none;

  z-index: ${Z_INDEX_DRAGGABLE_ROW};
  left: -${DRAG_PREVIEW_DIMENSIONS.width / 2}px;
  top: -${DRAG_PREVIEW_DIMENSIONS.height / 2}px;
  padding: 0 20px;

  background: ${({ theme }) => theme.colors.basics.white};
  border: 2px solid ${({ theme }) => theme.colors.blue.primaryA};
  box-shadow: ${({ theme }) => theme.shadow.actionsPanel};
  border-radius: 20px;

  ${({ currentOffset }) =>
    !!currentOffset &&
    `
      transform: translate3d(${currentOffset.x}px, ${currentOffset.y}px, 0);
    `};

  button {
    pointer-events: none;
  }
`;
