import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import SvgClients from '@/assets/widgets/empty-state-clients.svg';

import { getSectionClassProp } from '../helpers/get-section-class-prop';

import { CompanyContactsWidget } from './contacts/company-contacts-widget';
import { FreeTextFieldsWidget } from './free-text-fields/free-text-fields-widget';
import { CompanyFundingWidget } from './funding/company-funding-widget';
import { ListedInProjectsWidget } from './listed-in-projects/listed-in-projects-widget';
import { OfficeLocationsWidget } from './office-locations/office-locations-widget';
import { CompanyProductsWidget } from './products/company-products-widget';
import { CompanyRatingWidget } from './rating/company-rating-widget';
import { CompanySection } from './sections-pane/sections-pane.state';
import { NewsFeedWidget } from './news-feed/news-feed-widget';
import { CompanyTagsWidget } from './tags/company-tags-widget';
import {
  COLUMN_1_WIDTH,
  COLUMN_WIDTH,
  WIDGET_CLIENTS_HEIGHT,
  WIDGET_CLIENTS_ITEMS_LIMIT,
  WIDGET_INVESTORS_HEIGHT,
  WIDGET_INVESTORS_ITEMS_LIMIT,
} from './utils/constants';
import { AboutWidget } from './about-widget';
import { ClientsWidget } from './clients/clients-widget';
import { EditorNoteWidget } from './editor-note-widget';
import { InvestorsWidget } from './investors/investors-widget';
import { CompanyExternalLinksWidget } from './external-links/company-external-links-widget';

export const CompanyOverviewWidgetsGrid = () => {
  const { t } = useTranslation('companies');

  return (
    <Grid>
      <About {...getSectionClassProp(CompanySection.ABOUT)} />
      <ExternalLinks {...getSectionClassProp(CompanySection.EXTERNAL_LINKS)} />

      <Funding {...getSectionClassProp(CompanySection.FUNDING)} />
      <ListedInProjects {...getSectionClassProp(CompanySection.LISTED_IN)} />
      <Rating {...getSectionClassProp(CompanySection.RANKING)} />
      <Product {...getSectionClassProp(CompanySection.PRODUCT)} />
      <NewsFeed {...getSectionClassProp(CompanySection.NEWS_FEED)} />
      <Contacts {...getSectionClassProp(CompanySection.CONTACTS)} />

      <Investors
        {...getSectionClassProp(CompanySection.INVESTORS)}
        height={WIDGET_INVESTORS_HEIGHT}
        optionsListLimit={WIDGET_INVESTORS_ITEMS_LIMIT}
        emptyStateOptions={{
          label: t`company.emptyState.investorsStatement`,
          linkText: t`company.emptyState.clickToAdd`,
        }}
      />

      <Clients
        {...getSectionClassProp(CompanySection.CLIENTS)}
        height={WIDGET_CLIENTS_HEIGHT}
        optionsListLimit={WIDGET_CLIENTS_ITEMS_LIMIT}
        emptyStateOptions={{
          padding: '36px 60px !important',
          gap: 32,
          label: t`company.emptyState.clientsStatement`,
          linkText: t`company.emptyState.clickToAdd`,
          image: SvgClients,
          direction: 'column',
          labelGrow: 1,
          labelJustify: 'space-around',
          labelAlign: 'center',
        }}
      />

      <ActiveRound />
      <EditorNote {...getSectionClassProp(CompanySection.EDITOR_NOTE)} />

      <Tags {...getSectionClassProp(CompanySection.TAGS)} />

      <OfficeLocations
        {...getSectionClassProp(CompanySection.OFFICE_LOCATIONS)}
      />
      <FreeTextFields {...getSectionClassProp(CompanySection.FREE_TEXT)} />
    </Grid>
  );
};

const Grid = styled('div')`
  display: grid;
  gap: 30px 30px;
  margin: 0 30px;
  grid-template-columns:
    minmax(0, ${COLUMN_1_WIDTH}px) minmax(0, ${COLUMN_WIDTH}px)
    minmax(0, ${COLUMN_WIDTH}px);
  grid-template-areas:
    'about about external-links'
    'funding investors clients'
    'active-round active-round editor-note'
    'listed-in listed-in tags'
    'ranking ranking ranking'
    'products products products'
    'news-feed news-feed news-feed'
    'contacts contacts contacts'
    'office-locations office-locations office-locations'
    'free-text-fields free-text-fields free-text-fields';
`;

const About = styled(AboutWidget)`
  grid-area: about;
`;

const EditorNote = styled(EditorNoteWidget)`
  grid-area: editor-note;
`;

const Funding = styled(CompanyFundingWidget)`
  grid-area: funding;
`;

const Rating = styled(CompanyRatingWidget)`
  grid-area: ranking;
`;

const Product = styled(CompanyProductsWidget)`
  grid-area: products;
`;

const ListedInProjects = styled(ListedInProjectsWidget)`
  grid-area: listed-in;
`;

const NewsFeed = styled(NewsFeedWidget)`
  grid-area: news-feed;
`;

const Contacts = styled(CompanyContactsWidget)`
  grid-area: contacts;
`;

const Investors = styled(InvestorsWidget)`
  grid-area: investors;
`;

const Clients = styled(ClientsWidget)`
  grid-area: clients;
`;

const Tags = styled(CompanyTagsWidget)`
  grid-area: tags;
`;

const ExternalLinks = styled(CompanyExternalLinksWidget)`
  grid-area: external-links;
`;

const FreeTextFields = styled(FreeTextFieldsWidget)`
  grid-area: free-text-fields;
`;

const OfficeLocations = styled(OfficeLocationsWidget)`
  grid-area: office-locations;
`;

const ActiveRound = styled('div')`
  grid-area: active-round;
`;
