import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { ActivityStatus } from '@/api/v4/activity-status.api';
import { fetchActivityStatus } from '@/api/v4/activity-status.api';

export const useActivityStatuses = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.ActivityStatuses], fetchActivityStatus, {
    select: mapActivityStatusesToOptions,
    enabled: hasAtLeastOnePermission([
      PERMISSION.ADD_ORGANIZATION,
      PERMISSION.EDIT_ORGANIZATION,
    ]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const mapActivityStatusesToOptions = (activityStatuses: ActivityStatus[]) => {
  return activityStatuses.map(activityStatus => ({
    label: activityStatus.name,
    value: activityStatus.id,
  }));
};
