import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';

export const CompanyFormSelect = ({
  isSearchable = true,
  components: customComponents,
  ...props
}: AsyncSelectProps) => {
  const { components, ...restSharedProps } = sharedProps;
  return (
    <AsyncSelect
      isSearchable={isSearchable}
      components={{ ...components, ...customComponents }}
      isClearable
      {...restSharedProps}
      {...props}
    />
  );
};
