import { useRef } from 'react';
import { styled } from 'goober';

import { formatLocation } from '@/helpers/format-location';
import { isCompanyLocationOption, isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import { OptionLabel } from '@/ui/select/async/async-select.styles';
import { CustomOptionWrapper } from '@/ui/select/async/components/option/custom-option-wrapper';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

export const CompanyHqLocationOption = ({
  ...props
}: AsyncSelectOptionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isCompanyLocationOption(props.data)) {
    return <></>;
  }

  const { cityName, countryName, regionName } = props.data.label;

  return (
    <CustomOptionWrapper
      tooltipContent={formatLocation(props.data.label)}
      isTooltipVisible={isEllipsis(ref.current)}
      {...props}
    >
      <OptionLabel ref={ref}>
        <Element color={colors.basics.black}>{cityName + ', '}</Element>
        {regionName && (
          <Element color={colors.gray.c13}>{regionName + ', '}</Element>
        )}
        {countryName && (
          <Element color={colors.gray.c13}>{countryName}</Element>
        )}
      </OptionLabel>
    </CustomOptionWrapper>
  );
};

const Element = styled('span')<{ color: string }>`
  color: ${({ color }) => color};
`;
