import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Small } from '@/ui/typography/across-platform';
import type { WidgetProps } from '@/ui/widget/widget';
import { Widget } from '@/ui/widget/widget';

interface ReadOnlyEmptyWidgetProps extends WidgetProps {
  className?: string;
  maxHeight?: number;
  fullHeight?: boolean;
}

export function ReadOnlyEmptyWidget({
  className,
  maxHeight,
  fullHeight,
  header,
  ...widgetProps
}: ReadOnlyEmptyWidgetProps) {
  const { t } = useTranslation('projects');
  return (
    <WidgetContainer
      showEditButton={false}
      height={widgetProps.height}
      className={className}
      backgroundColor={widgetProps.backgroundColor}
      maxHeight={maxHeight}
      fullHeight={fullHeight}
    >
      <Flexbox
        name="no-editable-empty-container"
        direction="column"
        justify="space-between"
        fullHeight
      >
        {header}
        <Small
          color={colors.basics.black}
        >{t`projectOverview.emptyState.noInformationYet`}</Small>
      </Flexbox>
    </WidgetContainer>
  );
}

const WidgetContainer = styled(Widget)<ReadOnlyEmptyWidgetProps>`
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
  opacity: 50%;
`;
