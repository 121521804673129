import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { TimeframeRange } from '@/features/projects/overview/dropdowns/dropdown-timeframe';
import { DropdownTimeframe } from '@/features/projects/overview/dropdowns/dropdown-timeframe';
import { selectedProjectsFiltersState } from '@/features/projects/project.state';
import { formatCalendarDate } from '@/helpers/format-date';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { sharedComponents, sharedStyles } from '@/ui/select/select-shared';

const FILTER_DATE_FORMAT = 'dd MMM yyyy';

export const LastEditedDateFilter = () => {
  const { t } = useTranslation('projects');

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    selectedProjectsFiltersState,
  );

  return (
    <DropdownTimeframe
      id="filter-last-edited-date"
      label={t`projects.lastEditedDate`}
      components={{
        Control: sharedComponents?.Control,
        SelectContainer: sharedComponents?.SelectContainer,
      }}
      styles={{
        ...sharedStyles,
        menu: base => ({
          ...base,
          zIndex: Z_INDEX_FILTERS_DROPDOWN_MENU,
          width: 'fit-content',
        }),
      }}
      defaultValue={{
        timeframeStart: selectedFilters.lastEditedDate?.start?.value ?? null,
        timeframeEnd: selectedFilters.lastEditedDate?.end?.value ?? null,
      }}
      labelPlacement="bottom"
      displayFormat={FILTER_DATE_FORMAT}
      onChange={(range: TimeframeRange) => {
        if (!range.timeframeStart) return;

        const lastEditedDate = {
          start: {
            value: range.timeframeStart,
            label:
              formatCalendarDate(range.timeframeStart, FILTER_DATE_FORMAT) ??
              '',
          },
          end: range.timeframeEnd
            ? {
                value: range.timeframeEnd,
                label:
                  formatCalendarDate(range.timeframeEnd, FILTER_DATE_FORMAT) ??
                  '',
              }
            : null,
        };

        setSelectedFilters(currentFilters => ({
          ...currentFilters,
          lastEditedDate,
        }));
      }}
    />
  );
};
