import type { ReactElement } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import { useMeasuringRender } from '@/hooks/use-measuring-render';

export const useOverflowTags = ({
  children,
  additionalSpacePerElement = 0,
  containerHeight,
}: {
  children: ReactElement[];
  additionalSpacePerElement?: number;
  containerHeight: number;
}) => {
  const { measuringRender, isMounted, allElements, setMeasuringRender } =
    useMeasuringRender({
      children,
    });

  const [visibleElementIndexes, setVisibleElementsIndexes] = useState<number[]>(
    [],
  );

  const [difference, setDifference] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const childrenContainerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!childrenContainerRef.current || !buttonRef.current) {
      return;
    }

    const elements = Array.from(childrenContainerRef.current.children);

    let stopHeight = 0;
    const visibleElementsIndexes: number[] = [];
    const moreButtonHeight = buttonRef.current.getBoundingClientRect().height;
    const headerHeight = headerRef.current
      ? headerRef.current.getBoundingClientRect().height
      : 0;

    const availableSpace = containerHeight - moreButtonHeight - headerHeight;

    const childrenHeight =
      childrenContainerRef.current.getBoundingClientRect().height;
    const diff = childrenHeight - availableSpace;

    if (diff > 0) {
      setDifference(diff);
    }

    elements.forEach((element, index) => {
      const rect = element.getBoundingClientRect();
      if (
        index > 0 &&
        elements[index - 1].getBoundingClientRect().bottom === rect.bottom
      ) {
        if (availableSpace >= stopHeight) {
          visibleElementsIndexes.push(index);
        }
        return;
      }

      stopHeight +=
        rect.height +
        (stopHeight + rect.height + additionalSpacePerElement > availableSpace
          ? 0
          : additionalSpacePerElement);

      if (availableSpace >= stopHeight) {
        visibleElementsIndexes.push(index);
      }
    });

    setVisibleElementsIndexes(visibleElementsIndexes);
    setMeasuringRender(false);
  }, [measuringRender, additionalSpacePerElement, containerHeight]);

  let visibleElements: ReactElement[] = [];
  const overflowElements: ReactElement[] = [];
  if (!isMounted || measuringRender) {
    visibleElements = allElements;
  } else {
    allElements.forEach((tab, index) => {
      if (visibleElementIndexes.includes(index)) {
        visibleElements.push(tab);
      } else {
        overflowElements.push(tab);
      }
    });
  }

  return {
    visibleElements,
    overflowElements,
    containerRef,
    buttonRef,
    headerRef,
    measuringRender,
    difference,
    childrenContainerRef,
  };
};
