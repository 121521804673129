import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { Header } from '@/ui/typography/widgets';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';

import { useCanEditCompany } from '../../hooks/use-can-edit-company';
import { EditStamp } from '../edit-stamp';
import { COLUMN_1_WIDTH, WIDGET_ROW_2_HEIGHT } from '../utils/constants';
import { WidgetLoaderContainer } from '../widget-loader-container';

import { CompanyFundingEditMode } from './company-funding-edit-mode';
import { CompanyFundingViewMode } from './company-funding-view-mode';
import { useCompanyFundings } from './use-company-fundings.query';
import { useCrunchbaseEnrichmentCheck } from '../../hooks/use-crunchbase-enrichment-check';
import { useCompany } from '../use-company.query';
import type { CompanyFundingStateType } from './company-funding.types';
import { useEditCompanyFundings } from './use-edit-company-fundings.mutation';

export const CompanyFundingWidget = ({ className }: { className?: string }) => {
  const { t } = useTranslation('companies');
  const canEditCompany = useCanEditCompany();
  const [isEditMode, setIsEditMode] = useState(false);
  const { mutateAsync, isLoading: isUpdating } = useEditCompanyFundings();
  const [currentFundings, setCurrentFundings] =
    useState<CompanyFundingStateType>({
      capitalRaised: null,
      fundingStageId: null,
      fundingStageName: null,
      lastFundingDate: null,
    });

  const { data: company } = useCompany();

  const { data: fundings, isLoading, refetch } = useCompanyFundings();
  const { isEnrichedFromCrunchbase } = useCrunchbaseEnrichmentCheck('funding');

  useEffect(() => {
    if (fundings) {
      setCurrentFundings({
        capitalRaised: fundings.capitalRaised?.value,
        fundingStageId: fundings.fundingStage?.value?.id ?? null,
        fundingStageName: fundings.fundingStage?.value?.name ?? null,
        lastFundingDate: fundings.lastFundingDate?.value,
      });
    }
  }, [fundings]);

  const handleSaveData = useCallback(async () => {
    const startingData = fundings;
    const payload = {
      ...(startingData?.capitalRaised.value !==
        currentFundings?.capitalRaised && {
        capitalRaised: currentFundings?.capitalRaised,
      }),
      ...(startingData?.fundingStage.value?.id !==
        currentFundings?.fundingStageId && {
        fundingStageId: currentFundings?.fundingStageId,
      }),
      ...(startingData?.lastFundingDate.value !==
        currentFundings?.lastFundingDate && {
        lastFundingDate: currentFundings?.lastFundingDate,
      }),
    };

    if (Object.keys(payload).length > 0) {
      await mutateAsync(payload);
      await refetch();
    }
    setIsEditMode(false);
  }, [currentFundings, fundings]);

  if (isUpdating || isLoading) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_2_HEIGHT} className={className}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  const handleEditMode = () => {
    if (canEditCompany) setIsEditMode(true);
  };

  if (
    !isEditMode &&
    !fundings?.capitalRaised.value &&
    !fundings?.fundingStage.value &&
    !fundings?.lastFundingDate.value
  ) {
    return (
      <EmptyStateWidget
        key="empty"
        className={className}
        height={WIDGET_ROW_2_HEIGHT}
        labelAlign="center"
        label={t`funding.addInfoHeader`}
        linkText={t`funding.addInfoButton`}
        minWidth={`${COLUMN_1_WIDTH}px`}
        onEditClick={handleEditMode}
      />
    );
  }

  return (
    <OutsideClickHandler onOutsideClick={handleSaveData} disabled={!isEditMode}>
      <Container
        className={className}
        header={<Header>{t`funding.header`}</Header>}
        height={WIDGET_ROW_2_HEIGHT}
        onEditClick={handleEditMode}
        disabled={!canEditCompany || isUpdating}
        isEditMode={isEditMode}
      >
        {!isEditMode && fundings ? (
          <CompanyFundingViewMode data={fundings} />
        ) : (
          fundings && (
            <CompanyFundingEditMode
              fundingData={currentFundings}
              setFundingData={setCurrentFundings}
              disabled={!canEditCompany || isUpdating}
            />
          )
        )}
        {!isEditMode && isEnrichedFromCrunchbase && (
          <CBBadge
            crunchbasePermalink={company?.crunchbasePermalink ?? null}
            align="left"
          />
        )}
      </Container>
    </OutsideClickHandler>
  );
};

const Container = styled(Widget)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CBBadge = styled(EditStamp)`
  flex: 1;
  align-items: end;
`;
