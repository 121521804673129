import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import {
  createTeamContact,
  type TeamContactPayload,
} from '@/api/v4/team-contacts.api';
import { notify } from '@/ui/snackbar/notify';

type CreateTeamContactMutationParams = {
  teamId: string;
  payload: TeamContactPayload;
};

export const useCreateTeamContact = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, payload }: CreateTeamContactMutationParams) => {
      return createTeamContact(teamId, payload);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
