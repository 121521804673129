import type { RefObject, BaseSyntheticEvent } from 'react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from '@draft-js-plugins/editor';
import type { RawDraftContentState, EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import { styled } from 'goober';

import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { useEditor } from './use-editor';
import { useRichTextToolbar } from './use-rich-text-toolbar';
import {
  getParagraphVariant,
  type TextWidgetParagraphVariant,
} from '../widgets/text-widget/text-variant.type';
import type PluginEditor from '@draft-js-plugins/editor/lib/Editor';
import { hasProperty } from '@/helpers/has-property';

export type RichTextFieldProps = {
  className?: string;
  onChange?: (value: string | null) => void;
  paragraphVariant?: TextWidgetParagraphVariant;
  defaultValue: string | null;
  readOnly?: boolean;
  placeholder?: string;
  width?: number;
  editorRef: RefObject<PluginEditor>;
  onSave: () => void;
};

export const RichTextField = ({
  onChange,
  defaultValue,
  readOnly,
  placeholder,
  editorRef,
  width,
  className,
  onSave,
  paragraphVariant = 'small',
}: RichTextFieldProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLinkInputActive, setIsLinkInputActive] = useState(false);

  const { t } = useTranslation('default');

  const {
    editor,
    setEditor,
    resetEditor,
    handleKeyCommand,
    myKeyBindingFn,
    handleOnTab,
  } = useEditor({
    readOnly,
    defaultValue,
  });

  useEffect(() => {
    if (readOnly) return;

    editorRef.current?.focus();
  }, [editor, readOnly]);

  const { plugins, Toolbar } = useRichTextToolbar();

  const handleSaveChanges = (e: BaseSyntheticEvent) => {
    if (isLinkInputActive) {
      setIsLinkInputActive(false);
      return;
    }

    if (isHyperlinkInputActivated(e)) {
      setIsLinkInputActive(true);
      return;
    }

    onSave();
  };

  const onStateChange = useCallback(
    (newEditorContent: EditorState) => {
      setEditor(newEditorContent);

      if (!editor) return onChange?.(null);

      const currentContentState = editor.getCurrentContent();
      const newContentState = newEditorContent.getCurrentContent();

      if (!currentContentState.equals(newContentState)) {
        const raw: RawDraftContentState = convertToRaw(
          newEditorContent.getCurrentContent(),
        );
        const hasSingleEmptyBlock =
          raw.blocks.length === 1 && raw.blocks[0].text === '';

        if (hasSingleEmptyBlock) {
          onChange?.(null);
          return;
        }
        onChange?.(JSON.stringify(raw));
      }
    },
    [editor, onChange, resetEditor],
  );

  return (
    <RichTextWrapper
      ref={containerRef}
      data-testid="rich-text-field"
      paragraphVariant={paragraphVariant}
      onBlur={handleSaveChanges}
      width={width}
      className={className}
    >
      {editor ? (
        <>
          <Editor
            ref={editorRef}
            editorKey=""
            editorState={editor}
            onChange={onStateChange}
            onTab={handleOnTab}
            placeholder={
              placeholder ?? t`actionPanel.meetings.meetingSummaryPlaceholder`
            }
            readOnly={readOnly}
            keyBindingFn={myKeyBindingFn}
            handleKeyCommand={handleKeyCommand}
            plugins={plugins}
          />
          {Toolbar}
        </>
      ) : null}
    </RichTextWrapper>
  );
};

const isHyperlinkInputActivated = (e: BaseSyntheticEvent) =>
  hasProperty('relatedTarget', e.nativeEvent) &&
  e.nativeEvent.relatedTarget instanceof HTMLInputElement &&
  e.nativeEvent.relatedTarget.classList.contains('i119ugvj');

const RichTextWrapper = styled('div', forwardRef)<{
  paragraphVariant: TextWidgetParagraphVariant;
  width?: number;
}>`
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  white-space: normal;
  ${props => getParagraphVariant(props)}

  ${({ theme }) => theme.mixins.scrollbar}

  ${({ width }) => width && `width: ${width}px;`}

  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
  padding-right: 20px;

  &:disabled {
    cursor: default;
  }

  .DraftEditor-root {
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.mixins.scrollbar}
  }

  .DraftEditor-editorContainer {
    cursor: text;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .public-DraftEditor-content {
    ${props => getParagraphVariant(props)};
    height: 100%;
    color: ${({ theme }) => theme.colors.basics.black};
  }

  .public-DraftEditorPlaceholder-root {
    ${props => getParagraphVariant(props)};
    color: ${({ theme }) => theme.colors.gray.c6};
    z-index: 0;
  }
`;
