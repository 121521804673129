import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import type { Project } from '@/api/v4/projects.api';
import { CurrencyInput } from '@/ui/input/currency-input';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';
import { Widget } from '@/ui/widget/widget';
import { Flexbox } from '@/ui/flexbox/flexbox';

type ProjectBudget = Project['projectBudget'];
type ExternalExpectedCost = Project['externalExpectedCost'];
type InternalExpectedCost = Project['internalExpectedCost'];

export type SaveCurrencyCallback = (
  options: Pick<
    Project,
    'projectBudget' | 'externalExpectedCost' | 'internalExpectedCost'
  >,
) => void;

interface CurrencyWidgetProps {
  height?: number;
  projectBudget: ProjectBudget;
  externalExpectedCost: ExternalExpectedCost;
  internalExpectedCost: InternalExpectedCost;
  onSave: SaveCurrencyCallback;
  disabled?: boolean;
  testId?: string;
  className?: string;
}

export function CurrencyWidget({
  height,
  onSave,
  disabled = false,
  projectBudget,
  externalExpectedCost,
  internalExpectedCost,
  testId,
  className,
}: CurrencyWidgetProps) {
  const { t } = useTranslation('projects');
  const [editMode, setEditMode] = useState(false);
  const [isEmptyWidget, setIsEmptyWidget] = useState(true);
  const [projectBudgetState, setProjectBudgetState] =
    useState<ProjectBudget>(null);
  const [externalExpectedCostState, setExternalExpectedCostState] =
    useState<ExternalExpectedCost>(null);
  const [internalExpectedCostState, setInternalExpectedCostState] =
    useState<InternalExpectedCost>(null);

  useEffect(() => {
    // immediate refresh on page load
    setProjectBudgetState(projectBudget);
    setExternalExpectedCostState(externalExpectedCost);
    setInternalExpectedCostState(internalExpectedCost);

    setIsEmptyWidget(
      !projectBudget && !externalExpectedCost && !internalExpectedCost,
    );
  }, [projectBudget, externalExpectedCost, internalExpectedCost]);

  const handleOnConfirm = () => {
    if (!editMode) return;

    setEditMode(false);
    onSave({
      projectBudget: projectBudgetState,
      externalExpectedCost: externalExpectedCostState,
      internalExpectedCost: internalExpectedCostState,
    });
  };

  if (isEmptyWidget && disabled)
    return (
      <ReadOnlyEmptyWidget
        className={className}
        height={height}
        header={
          <SubHeaderBold>{t`projectOverview.projectNumbers`}</SubHeaderBold>
        }
      />
    );

  return (
    <div className={className}>
      <OutsideClickHandler onOutsideClick={handleOnConfirm}>
        <Widget
          dataTestId={testId}
          height={height}
          onEditClick={() => setEditMode(true)}
          disabled={disabled}
          isEditMode={editMode}
        >
          <Flexbox name="currency-items" wrap="wrap" justify="center">
            <ContainerItem data-label={t`projectOverview.projectBudget`}>
              <CurrencyInput
                value={projectBudgetState}
                isEditMode={editMode}
                isEmptyWidget={isEmptyWidget}
                onChange={setProjectBudgetState}
              />
              <Label>{t`projectOverview.projectBudget`}</Label>
            </ContainerItem>
            <ContainerItem data-label={t`projectOverview.externalExpectedCost`}>
              <CurrencyInput
                value={externalExpectedCostState}
                isEditMode={editMode}
                isEmptyWidget={isEmptyWidget}
                onChange={setExternalExpectedCostState}
              />
              <Label>{t`projectOverview.externalExpectedCost`}</Label>
            </ContainerItem>
            <ContainerItem data-label={t`projectOverview.internalExpectedCost`}>
              <CurrencyInput
                value={internalExpectedCostState}
                isEditMode={editMode}
                isEmptyWidget={isEmptyWidget}
                onChange={setInternalExpectedCostState}
              />
              <Label>{t`projectOverview.internalExpectedCost`}</Label>
            </ContainerItem>
          </Flexbox>
        </Widget>
      </OutsideClickHandler>
    </div>
  );
}

const ContainerItem = styled('div')`
  display: inline-block;
  padding: 0 20px;
`;

const Label = styled('label')`
  ${({ theme }) => theme.typography.widget.smallText};
`;
