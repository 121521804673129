import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';

import type { ActionPanelContext } from '../../action-panel/action-panel.state';

const dimensions = {
  md: {
    totalWidth: '40px',
    fontSize: '12px',
    projectIconHeight: '22px',
    projectIconWidth: '20px',
    teamLogoSize: '30px',
    teamBorderRadius: '4px',
  },
  sm: {
    totalWidth: '30px',
    fontSize: '9px',
    projectIconHeight: '17px',
    projectIconWidth: '15px',
    teamLogoSize: '23px',
    teamBorderRadius: '3px',
  },
};

interface SavvyIconProps {
  logoUrl?: string;
  name?: string;
  size: 'sm' | 'md'; // add items size clasifications as needed
  type: ActionPanelContext;
}

export const RecentItemLogo = ({
  logoUrl,
  name,
  size,
  type,
}: SavvyIconProps) => {
  const {
    totalWidth,
    fontSize,
    projectIconHeight,
    projectIconWidth,
    teamLogoSize,
    teamBorderRadius,
  } = dimensions[size];

  return (
    <LogoSpacer width={totalWidth}>
      {type === 'project' ? (
        // for project types, only show the project icon
        <Icon
          icon="ProjectLogoDefault"
          color={colors.gray.c5}
          height={projectIconHeight}
          width={projectIconWidth}
          centered
        />
      ) : (
        // for companies and teams, we use logos (Team uses a square rounded)
        <Logo
          size={type === 'team' ? teamLogoSize : totalWidth}
          name={name || ''}
          logoUrl={logoUrl}
          fontSize={fontSize}
          bgColor={
            type === 'company'
              ? colors.accent.green.c3
              : colors.accent.purple.c2
          }
          borderRadius={type === 'team' ? teamBorderRadius : undefined}
          singleLetter={type === 'company'}
        />
      )}
    </LogoSpacer>
  );
};

const LogoSpacer = styled('div')<{ width: string }>`
  display: flex;
  width: ${({ width }) => width};
  justify-content: center;
  align-items: center;
`;
