import type { IndexParams } from './api';

export enum FieldMode {
  Active = 'active',
  Disabled = 'disabled',
  Hidden = 'hidden',
  Readonly = 'readonly',
}

export interface Action<T> {
  type: string;
  payload: T;
}

export type IEntityType =
  | 'Organization'
  | 'organization'
  | 'project'
  | 'person'
  | 'app_users'
  | 'use_case'
  | 'projects'
  | 'project_file'
  | 'team'
  | 'team_file'
  | 'project_status'
  | 'organization_use_cases'
  | 'project_listings'
  | 'project_listing'
  | 'user'
  | 'location'
  | 'owner'
  | 'organizations'
  | 'billing_country'
  | 'industry'
  | 'category'
  | 'status'
  | 'type'
  | 'tags'
  | 'list_status'
  | 'sosa_crf_lead_investor'
  | 'sosa_crf_funding_stage'
  | 'sosa_person_pitching'
  | 'owner'
  | 'funding_stage'
  | 'product_stage'
  | 'employees_range'
  | 'tag'
  | 'file'
  | 'file_organization'
  | 'activity_status'
  | 'verifier'
  | 'last_edit_author'
  | 'app_user'
  | 'types'
  | 'created_by'
  | 'project_listing_note_client'
  | 'project_listing'
  | 'role'
  | 'account'
  | 'project_labels'
  | 'project_label'
  | 'record_source'
  | 'record_medium'
  | 'record_campaign'
  | 'listing_status'
  | 'creator'
  | 'client'
  | 'meeting'
  | 'people'
  | 'invoice'
  | 'invited_user'
  | 'organization_use_case'
  | 'business_types'
  | 'relevant_for'
  | 'city'
  | 'region'
  | 'country'
  | 'state'
  | 'continent'
  | 'city'
  | 'ownership_status'
  | 'organization_owner'
  | 'deployment_models'
  | 'languages'
  | 'compliances'
  | 'integrations'
  | 'ip_status'
  | 'clients'
  | 'investors'
  | 'competitors'
  | 'owned_by'
  | 'stream'
  | 'stream_listing'
  | 'locations'
  | 'crunchbase/people_snapshot';

export interface IEntity {
  id: string;
  entityType: IEntityType;
  attributes: Record<string, unknown>;
}

export interface IEntityIndexFetchActionArgs {
  params: IndexParams;
  saveType?: 'concat' | 'overwrite';
  successCallback?: () => void;
  noResultsCallback?: () => void;
}

export interface IEntityFetchActionArgs {
  id: string;
}

export enum UserStatus {
  Invited = 'invited',
  Active = 'active',
  Disabled = 'disabled',
}

export interface ValidationError {
  path: string;
  msg: string;
}

export interface SosaEvent<T> {
  name: T;
  fulfilled: { byView: string }[];
  timestamp: number;
  payload?: any;
}
