import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isAllowedExtension, isSizeCorrect } from '@/helpers/files';
import { notify } from '@/ui/snackbar/notify';

interface UseLogoUploaderProps {
  onFileChange: (logo: File) => void;
}

export const useLogoUploader = ({ onFileChange }: UseLogoUploaderProps) => {
  const [temporaryFile, setTemporaryFile] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      setTemporaryFile(null);
    };
  }, []);

  const { t } = useTranslation('default');

  const getUploadErrorMessage = (file: File) => {
    switch (true) {
      case !isAllowedExtension(file.type):
        return t`upload.invalidFormat`;

      case !isSizeCorrect(file.size):
        return t`upload.invalidSize`;

      default:
        return null;
    }
  };
  const handleChangeLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    const file = files?.[0];

    if (!file) return;

    const message = getUploadErrorMessage(file);

    if (message) {
      notify({ message });
      return;
    }
    createTemporaryFile(file);
    onFileChange(file);
  };

  const createTemporaryFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target && typeof e.target.result === 'string') {
        setTemporaryFile(e.target.result);
      }
    };

    reader.onerror = () => {
      notify({ message: 'upload.readFileError' });
    };

    reader.readAsDataURL(file);
  };

  return {
    handleChangeLogo,
    temporaryFile,
  };
};
