import { useEffect, useMemo } from 'react';
import { styled } from 'goober';

import type { ProjectListing } from '@/api/v4/projects.api';
import { ListingStageName } from '@/features/listing-stage/types';
import type { TabConfig } from '@/ui/tabs/tabs';
import { Tabs } from '@/ui/tabs/tabs';

import { getDealListingsCount } from '../../helpers/get-deal-listings.count';
import { getStageListingsCount } from '../../helpers/get-stage-listings-count';

import { ListingStageTabContent } from './listing-stage-tab-content';
import { useListingStagesToSwitchTabs } from './use-listing-stages-to-switch-tabs';
import type { TabStage } from './use-stage-tabs-config';
import { useStageTabsConfig } from './use-stage-tabs-config';
import { WorkmodeTabControl } from './workmode-tab-control';
import { useSearchParams } from 'react-router-dom';

export const StageManagementTable = ({
  listingsData,
  isLoading,
}: {
  listingsData: ProjectListing[];
  isLoading: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stageParam = searchParams.get('stage');
  const tabStages = useStageTabsConfig();
  const {
    getDropToDestination,
    setActiveFirstTabWithListings,
    setActiveTabByStageName,
  } = useListingStagesToSwitchTabs();

  useEffect(() => {
    if (isLoading || stageParam) {
      return;
    }

    if (listingsData.length > 0) {
      setActiveFirstTabWithListings();
    } else {
      setActiveTabByStageName(ListingStageName.discovery);
    }

    return () => searchParams.delete('stage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, stageParam]);

  const tabsConfig: TabConfig<TabStage['id']>[] = useMemo(
    () =>
      tabStages.map(tab => {
        const isDealTab = tab.name === ListingStageName.deal;
        return {
          id: tab.id,
          title: (
            <WorkmodeTabControl
              name={tab?.displayName ?? tab?.name}
              countIndicator={
                isDealTab
                  ? getDealListingsCount(listingsData)
                  : getStageListingsCount(tab, listingsData)
              }
            />
          ),
          content: (
            <ListingStageTabContent
              tab={tab}
              dragTo={getDropToDestination(tab)}
            />
          ),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabStages, listingsData],
  );

  const activeStageTabId = useMemo(() => {
    return stageParam ?? tabStages[0].id;
  }, [stageParam, tabStages]);

  return (
    <WidgetWrapper>
      <Tabs<TabStage['id']>
        tabsConfig={tabsConfig}
        activeTab={activeStageTabId}
        setActiveTab={tabId =>
          setSearchParams(prev => ({ ...prev, stage: tabId }))
        }
        moveLastAtEnd
        padding="0 30px"
        withDividers
      />
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled('div')`
  box-shadow: ${({ theme }) => theme.shadow.widgets};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.basics.white};
`;
