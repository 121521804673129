import { styled } from 'goober';

import type { IconVariant } from '@/ui/icons/icon';
import { Inline } from '@/ui/line/line';
import { LinkIcon } from '@/ui/link/link-icon';

interface SocialUrlValues {
  linkedinUrl?: string;
  websiteUrl?: string;
  twitterUrl?: string;
}

interface SocialIconButtonsProps {
  urlValues: SocialUrlValues;
}

interface ButtonConfig {
  url?: string;
  icon: IconVariant;
}

export const SocialIconButtons = ({ urlValues }: SocialIconButtonsProps) => {
  const buttonsConfig: Record<string, ButtonConfig> = {
    website: {
      url: urlValues.websiteUrl,
      icon: 'Website',
    },
    linkedIn: {
      url: urlValues.linkedinUrl,
      icon: 'LinkedIn',
    },
    twitter: {
      url: urlValues.twitterUrl,
      icon: 'Twitter',
    },
  };

  return (
    <Wrapper gap="12px">
      {Object.entries(buttonsConfig).map(([socialKey, value]) => {
        if (!value.url) {
          return null;
        }
        return (
          <LinkIcon
            dataTestId={`company-socials-${socialKey}`}
            key={socialKey}
            icon={value.icon}
            url={value.url}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(Inline)`
  padding: 0 0 0 10px;
`;
