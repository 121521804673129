import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import {
  type TeamContactPayload,
  updateTeamContact,
} from '@/api/v4/team-contacts.api';
import { notify } from '@/ui/snackbar/notify';

type UpdateTeamContactMutationParams = {
  teamId: string;
  teamContactId: string;
  payload: TeamContactPayload;
};

export const useUpdateTeamContact = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({
      teamId,
      teamContactId,
      payload,
    }: UpdateTeamContactMutationParams) => {
      return updateTeamContact(teamId, teamContactId, payload);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
