import { useMutation } from '@tanstack/react-query';

import { useRecoilValue } from 'recoil';
import { companyIdState } from '../overview/company.state';
import type {
  CompanyData,
  EditCompanyPayload,
} from '@/api/v4/organization.api';
import { editCompany } from '@/api/v4/organization.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/user/use-current-user.query';
import { useActivityStatuses } from '@/hooks/queries/use-activity-statuses.query';
import { useOwnershipStatuses } from '@/hooks/queries/use-ownership-statuses.query';
import { useEmployeesRanges } from '@/hooks/queries/use-employees-ranges.query';

export const useEditCompany = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);
  const { data: activityStatuses } = useActivityStatuses();
  const { data: ownershipStatuses } = useOwnershipStatuses();
  const { data: employeesRanges } = useEmployeesRanges();

  const { data: userData } = useCurrentUser();

  const companyQueryKey = [QueryKey.Company, companyId];

  return useMutation(
    async (payload: EditCompanyPayload) =>
      companyId ? editCompany(companyId, payload) : undefined,
    {
      onSettled: async () => {
        await Promise.all([
          queryClient.invalidateQueries(companyQueryKey),
          queryClient.invalidateQueries([QueryKey.LastActivities]),
          queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
        ]);
      },
      onMutate: async editedCompany => {
        await queryClient.cancelQueries({ queryKey: companyQueryKey });
        const previousCompany = queryClient.getQueryData(companyQueryKey);

        queryClient.setQueryData<CompanyData>(
          [QueryKey.Company, companyId],
          old => {
            if (
              !old ||
              !userData ||
              !activityStatuses ||
              !ownershipStatuses ||
              !employeesRanges
            )
              return;

            const userFullName = `${userData.firstName} ${userData.lastName}`;

            const poweredBy = {
              lastEditedAt: new Date().toISOString(),
              poweredBy: userFullName,
            };

            return {
              ...old,
              ...(editedCompany.name
                ? {
                    name: {
                      value: editedCompany.name,
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.activityStatusId
                ? {
                    activityStatus: {
                      value: {
                        id: editedCompany.activityStatusId,
                        name: activityStatuses.find(
                          activity =>
                            activity.value === editedCompany.activityStatusId,
                        )!.label,
                      },
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.ownershipStatusId
                ? {
                    ownershipStatus: {
                      value: {
                        id: editedCompany.ownershipStatusId,
                        name: ownershipStatuses.find(
                          ownershipStatus =>
                            ownershipStatus.value ===
                            editedCompany.ownershipStatusId,
                        )!.label,
                      },
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.ownedByIrm
                ? {
                    ownedByIrm: {
                      value: editedCompany.ownedByIrm,
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.detailedDescription
                ? {
                    detailedDescription: {
                      value: editedCompany.detailedDescription,
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.editorNote
                ? {
                    editorNote: editedCompany.editorNote,
                  }
                : undefined),
              ...(editedCompany.employeesRangeId
                ? {
                    employeesRangeId: {
                      value: {
                        id: editedCompany.employeesRangeId,
                        name: employeesRanges.find(
                          employeeRange =>
                            employeeRange.value ===
                            editedCompany.employeesRangeId,
                        )!.label,
                      },
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.yearEstablished
                ? {
                    yearEstablished: {
                      value: editedCompany.yearEstablished.toString(),
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.twitterUrl
                ? {
                    twitterUrl: {
                      value: editedCompany.twitterUrl,
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.linkedInUrl
                ? {
                    linkedInUrl: {
                      value: editedCompany.linkedInUrl,
                      ...poweredBy,
                    },
                  }
                : undefined),
              ...(editedCompany.website
                ? {
                    website: {
                      value: editedCompany.website,
                      ...poweredBy,
                    },
                  }
                : undefined),
            };
          },
        );

        return { previousCompany };
      },
      onError: (_err, _company, context) => {
        queryClient.setQueryData(
          [QueryKey.Company, companyId],
          context?.previousCompany,
        );

        notify({
          message: t`default:unknownError`,
        });
      },
    },
  );
};
