import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type ProjectCreator = APIResponse<'/api/v4/projects/creators'>[number];

type ProjectCreatorParams = APIRequestQueryParams<'/api/v4/projects/creators'>;

export const fetchProjectCreators = async (params?: ProjectCreatorParams) =>
  get<ProjectCreator[]>('/v4/projects/creators', { params });

export type ProjectFiltersTeamOptionAPI =
  APIResponse<'/api/v4/projects/team-options'>[number];
type ProjectFiltersTeamOptionParams =
  APIRequestQueryParams<'/api/v4/projects/team-options'>;

export const fetchProjectFiltersTeamOptions = async (
  params?: ProjectFiltersTeamOptionParams,
) =>
  get<ProjectFiltersTeamOptionAPI[]>('/v4/projects/team-options', { params });
