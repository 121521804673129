import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { RankingStarIcon } from '@/features/companies/overview/rating/ranking-star-icon';

const DEFAULT_ICON_SIZE = '15px';

export const Rating = ({
  precision = 1,
  total = 5,
  emptyIcon,
  filledIcon,
  gap,
  initialValue = 0,
  onChange,
}: {
  precision?: number;
  total?: number;
  emptyIcon?: React.ReactElement;
  filledIcon?: React.ReactElement;
  gap?: string;
  initialValue?: number;
  onChange?: (value: number) => void;
}) => {
  const [activeStar, setActiveStar] = useState(-1);
  const [hoverActiveStar, setHoverActiveStar] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => setActiveStar(initialValue), [initialValue]);

  const calculateRating = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { width, left } =
      ratingContainerRef?.current?.getBoundingClientRect() as DOMRect;
    const percent = (e.clientX - left) / width;
    const numberInStars = percent * total;
    const nearestNumber =
      Math.round((numberInStars + precision / 2) / precision) * precision;

    return Number(
      nearestNumber.toFixed(precision.toString().split('.')[1]?.length || 0),
    );
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    setIsHovered(false);
    const calculatedRating = calculateRating(e);
    onChange?.(calculatedRating);
    setActiveStar(calculatedRating);
  };

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = e => {
    setIsHovered(true);
    setHoverActiveStar(calculateRating(e));
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    setHoverActiveStar(-1); // Reset to default state
    setIsHovered(false);
  };

  return (
    <RatingContainer
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      gap={gap}
      ref={ratingContainerRef}
    >
      {[...new Array(total)].map((_, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar;

        const showEmptyIcon = activeState === -1 || activeState < index + 1;

        const isActiveRating = activeState !== 1;
        const isRatingWithPrecision = activeState % 1 !== 0;
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

        return (
          <RatingComponentWrapper
            key={index}
            data-fill={
              showEmptyIcon
                ? showRatingWithPrecision
                  ? 'half-filled'
                  : 'empty'
                : 'filled'
            }
          >
            <RatingHoverContainer>
              {showRatingWithPrecision &&
                (filledIcon ?? (
                  <RankingStarIcon
                    size={DEFAULT_ICON_SIZE}
                    percentage={
                      showRatingWithPrecision && (activeState % 1) * 100
                    }
                    filled
                  />
                ))}
            </RatingHoverContainer>
            {showEmptyIcon
              ? emptyIcon ?? <RankingStarIcon size={DEFAULT_ICON_SIZE} />
              : filledIcon ?? (
                  <RankingStarIcon size={DEFAULT_ICON_SIZE} filled />
                )}
          </RatingComponentWrapper>
        );
      })}
    </RatingContainer>
  );
};

const RatingHoverContainer = styled('div')`
  position: absolute;
`;

const RatingComponentWrapper = styled('div')`
  position: relative;
  cursor: pointer;
`;

const RatingContainer = styled('div', forwardRef)<{
  gap?: string;
}>`
  outline: none;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  text-align: left;
  gap: ${({ gap }) => gap ?? '12px'};
`;
