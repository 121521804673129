import { get, type APIResponse } from './base-fetch';

type CompanyClients =
  APIResponse<'/api/v4/organization/{organizationId}/clients'>;

export type CompanyClient = CompanyClients[number];

export const fetchCompanyClients = async (
  organizationId: string,
): Promise<CompanyClients> =>
  get<CompanyClients>(`/v4/organization/${organizationId}/clients`);
