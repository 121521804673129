import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { OtherProjects } from '@/components/other-projects/other-projects';
import { WIDGET_ROW_4_EMPTY_STATE_HEIGHT } from '@/features/companies/overview/utils/constants';
import { WidgetHeaderWithBackground } from '@/features/companies/overview/widget-header-with-background';
import { useProjects } from '@/features/projects/projects-list/use-projects.query';
import { modalState } from '@/state/modal.state';
import { componentsPalette } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { notify } from '@/ui/snackbar/notify';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';

import { selectedCompaniesState } from '../../companies.state';
import { useCompany } from '../use-company.query';

export const ListedInEmptyState = ({
  className,
  otherProjectsCount,
  isDisabled,
}: {
  className?: string;
  otherProjectsCount: number;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation('companies', { keyPrefix: 'company' });
  const { data: company } = useCompany();

  const setModalState = useSetRecoilState(modalState);
  const setSelectedCompanies = useSetRecoilState(selectedCompaniesState);
  const { data } = useProjects();

  const projects = useMemo(() => (data ? data.pages.flat() : []), [data]);

  const { headerBackgroundColor, headerTextColor } =
    componentsPalette.company.listedInProjects;

  const navigateToProjectForm = useCallback(async () => {
    if (!company) return;

    if (!projects.length) {
      notify({ message: t`noProjectsToAdd` });
      return;
    }
    setSelectedCompanies([{ organizationId: company.id }]);
    setModalState({ state: 'addCompanyToProjects' });
  }, [company, projects, setModalState, setSelectedCompanies, t]);

  return (
    <EmptyStateWidget
      className={className}
      header={
        <WidgetHeaderWithBackground
          margin="36px 0 0 0"
          backgroundColor={headerBackgroundColor}
          textColor={headerTextColor}
        >
          {t`listedIn`}
        </WidgetHeaderWithBackground>
      }
      height={WIDGET_ROW_4_EMPTY_STATE_HEIGHT}
      onEditClick={!isDisabled ? navigateToProjectForm : undefined}
      label={t(`emptyState.listedInStatement`, {
        companyName: company?.name.value,
      })}
      linkText={t`emptyState.clickToAdd`}
      labelAlign={'center'}
      footer={
        otherProjectsCount > 0 && (
          <Flexbox name="empty-state-other-projects" padding="32px 0">
            <OtherProjects count={otherProjectsCount} emptyList />
          </Flexbox>
        )
      }
    />
  );
};
