import type { FC, ReactNode } from 'react';

import type { WidgetProps } from '@/ui/widget/widget';
import { Widget } from '@/ui/widget/widget';

type Props = Pick<WidgetProps, 'header'> & {
  dataTestId?: string;
  children: ReactNode;
};

export const BlockContainer: FC<Props> = ({ children, header, dataTestId }) => {
  return (
    <Widget showEditButton={false} header={header} dataTestId={dataTestId}>
      {children}
    </Widget>
  );
};
