import type { FC, ReactNode } from 'react';

import { HtmlTitleConfig } from '@/html-config/html-title-config';

interface Props {
  title: string;
  children: ReactNode;
}

export const WithHtmlSavvyTitle: FC<Props> = ({ title, children }) => {
  return (
    <>
      <HtmlTitleConfig title={title + ' - Savvy'} />
      {children}
    </>
  );
};
