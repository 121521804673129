import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { generateUntitledEntityName } from '@/helpers/generate-untitled-entity-name';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { TeamLogo } from '@/ui/logo/logo';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import { EditableHeader } from '../../components/editable-header/editable-header';
import { OverviewPageContainer } from '../../components/layout/overview-page-container';
import { PageGrid } from '../../components/layout/page-grid';
import { RelatedProjectsWidget } from '../../components/widgets/related-projects-widget/related-projects-widget';
import { WithHtmlSavvyTitle } from '../../with-html-savvy-title';
import { useActionPanel } from '@/features/action-panel/use-action-panel';
import { TeamContactsSection } from '@/features/teams/overview/team-contacts/team-contacts-section';
import { TeamMembersSection } from '@/features/teams/overview/team-members/team-members-section';
import { TeamTagsWidget } from '@/features/teams/overview/team-tags/team-tags-widget';
import { useTeam } from '@/features/teams/overview/use-team.query';
import { teamIdState } from '@/features/teams/team.state';
import { useCreateTeam } from '@/features/teams/use-create-team.mutation';
import { useTeamRelatedProjects } from '@/features/teams/use-team-related-projects.query';
import { useUpdateTeam } from '@/features/teams/use-update-team';

interface TeamOverviewPageProps {
  createNew?: boolean;
}

const TEAM_PAGE_WIDGET_HEIGHT = 384;

export const TeamOverviewPage = ({
  createNew = false,
}: TeamOverviewPageProps) => {
  const { t } = useTranslation('teams');
  const { teamId } = useParams();
  const setTeamId = useSetRecoilState(teamIdState);
  const { closeActionPanel, isActionPanelOpen } = useActionPanel();
  const { hasRequiredPermission } = useUserPermissions();
  const { hasRequiredProduct } = useUserProducts();

  const createTeam = useCreateTeam();

  useEffect(() => {
    setTeamId(teamId ?? null);

    if (isActionPanelOpen) {
      closeActionPanel();
    }

    return () => {
      closeActionPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const { data: fetchedTeam } = useTeam();

  const team = !createNew ? fetchedTeam : null;

  const { updateTeamData } = useUpdateTeam();

  const { data: relatedProjects } = useTeamRelatedProjects(teamId);

  const createNewTeam = async (input: string) => {
    const name = input === '' ? generateUntitledEntityName('team') : input;
    await createTeam.mutateAsync({ name });
  };

  const hasManageTeamPermission = hasRequiredPermission(
    PERMISSION.MANAGE_TEAMS,
  );

  const handleTeamNameChange = (name: string) => {
    if (!team) return createNewTeam(name);

    if (name === team.name) return;

    return updateTeamData(team.id, { name });
  };

  return (
    <WithHtmlSavvyTitle title={fetchedTeam ? fetchedTeam.name : 'Team'}>
      <OverviewPageContainer
        name="team-overview-page-container"
        direction="column"
      >
        <Inline gap="24px" templateColumns="auto 445px">
          <Stack margin="0 0 46px 0">
            <Flexbox name="team-page-header" gap="15px" alignItems="center">
              <TeamLogo name={team?.name} size="40px" />
              <EditableHeader
                value={team?.name ?? ''}
                maxLength={90}
                width={611}
                height="65px"
                placeholder={t`nameOfTeam`}
                adjustFontSize={true}
                shouldFocus={createNew}
                focusAtEnd
                disableEditButton={!hasManageTeamPermission}
                allowEmptyValue={createNew}
                onError={() => notify({ message: t('noEmptyName') })}
                onConfirm={handleTeamNameChange}
              />
            </Flexbox>
          </Stack>
        </Inline>

        <PageGrid marginTop="30px" marginBottom="34px">
          <Stack gap="24px">
            <RelatedProjectsWidget
              relatedProjects={relatedProjects ?? []}
              organization={team?.organization ?? null}
              defaultHeight={TEAM_PAGE_WIDGET_HEIGHT}
              disabled={!hasManageTeamPermission}
            />
          </Stack>
          {teamId ? (
            <Stack gap="24px">
              <TeamTagsWidget
                disabled={!hasManageTeamPermission}
                defaultHeight={TEAM_PAGE_WIDGET_HEIGHT}
                teamId={teamId}
              />
            </Stack>
          ) : undefined}
          <Stack gap="24px" colSpan={2} margin="63px 0 34px 0">
            {team && <TeamMembersSection team={team} />}
          </Stack>
          {hasRequiredPermission(PERMISSION.VIEW_ACCOUNT_DETAILS) &&
            hasRequiredProduct(PRODUCT.TEAM_CONTACTS) && (
              <Stack gap="24px" colSpan={2} margin="23px 0">
                {team && <TeamContactsSection />}
              </Stack>
            )}
        </PageGrid>
      </OverviewPageContainer>
    </WithHtmlSavvyTitle>
  );
};
