import { useTranslation } from 'react-i18next';

import { formatDate } from '@/helpers/format-date';
import { getUserFullName } from '@/helpers/get-user-full-name';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { SmallText } from '@/ui/typography/widgets';
import type { CompanyData } from '@/api/v4/organization.api';

export function CompanyFooter({
  company,
}: {
  company: CompanyData | null | undefined;
}) {
  const { t } = useTranslation('companies');

  if (!company) {
    return null;
  }

  return (
    <Flexbox name="authors-container" gap="100px" padding="80px 30px">
      {company.creator && (
        <FooterItem
          title={t`company.footer.createdBy`}
          name={getUserFullName(company.creator)}
          date={company.createdDate}
        />
      )}
      {company.editor && company.lastEditedDate && (
        <FooterItem
          title={t`company.footer.updatedBy`}
          name={getUserFullName(company.editor)}
          date={company.lastEditedDate}
        />
      )}
      {company.verifier && company.lastVerifiedAt && (
        <FooterItem
          title={t`company.footer.verifiedBy`}
          name={getUserFullName(company.verifier)}
          date={company.lastVerifiedAt}
        />
      )}
    </Flexbox>
  );
}

const FooterItem = ({
  title,
  name,
  date,
}: {
  title: string;
  name: string;
  date: string | number;
}) => (
  <Stack gap="4px">
    <SmallText color={colors.gray.c13}>{title}</SmallText>
    <div>
      <SmallText color={colors.system.disable}>{name}</SmallText>
      <SmallText color={colors.system.disable}>
        {formatDate(date, {
          shouldBeConvertedToMilliseconds: true,
        })}
      </SmallText>
    </div>
  </Stack>
);
