import type { ListingStage } from '@/api/v4/project-listings.api';
import type { ProjectListing } from '@/api/v4/projects.api';
import { ListingStageName } from '@/features/listing-stage/types';

export const getStageListingsCount = (
  stage: ListingStage,
  listings: ProjectListing[],
): number => {
  return listings.reduce((count, listing) => {
    const isNotRelevantStage = stage.name === ListingStageName.notRelevant;
    const isMatchingStage =
      listing.status.id === stage.id && !listing.notRelevant;

    if (isNotRelevantStage) {
      return listing.notRelevant ? count + 1 : count;
    }

    return isMatchingStage ? count + 1 : count;
  }, 0);
};
