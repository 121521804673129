import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ReactComponent as ErrorIllustration } from '@/assets/svg/explore-error.svg';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header, ParagraphMedium } from '@/ui/typography/widgets';

export const EXPLORE_RESULTS_ERROR_STATE = 'explore_results_error_state';

export const ExploreErrorView = () => {
  const { t } = useTranslation('explore');
  const navigateTo = useNavigateTo();

  return (
    <Stack gap="35px">
      <ImageBox name="explore-error-image" justify="center">
        <Image />
      </ImageBox>
      <Stack centered gap="42px">
        <Stack gap="0">
          <Header>{t`errorHeader`}</Header>
          <ParagraphMedium>{t`errorText1`}</ParagraphMedium>
          <ParagraphMedium>{t`errorText2`}</ParagraphMedium>
        </Stack>
        <TryAgainButton
          onClick={() =>
            navigateTo.explore(undefined, {
              state: {
                from: EXPLORE_RESULTS_ERROR_STATE,
              },
            })
          }
        >{t`tryAgain`}</TryAgainButton>
      </Stack>
    </Stack>
  );
};

const ImageBox = styled(Flexbox)`
  margin-top: 100px;
  margin-bottom: -220px;
  z-index: 0;
`;

const Image = styled(ErrorIllustration)`
  transform: translateX(-55px);
`;

const TryAgainButton = styled(Button)`
  z-index: 1;
`;
