import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Switch } from '@/ui/switch/switch';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Header } from '@/ui/typography/widgets';

import type { CompanyProducts } from '@/api/v4/organization.api';

type ProductHeader = Pick<CompanyProducts, 'productTitle' | 'enterpriseReady'>;

interface CompanyProductHeaderProps {
  data: ProductHeader;
  setData: (data: Partial<ProductHeader>) => void;
  isEditMode: boolean;
  showErrors: boolean;
}

export const CompanyProductHeader = ({
  data,
  setData,
  isEditMode,
  showErrors,
}: CompanyProductHeaderProps) => {
  const { t } = useTranslation('companies');

  const handleEnterpriseReadySwitched = () => {
    if (!data) return;
    setData({
      ...data,
      enterpriseReady: !data.enterpriseReady,
    });
  };

  const handleTitleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ productTitle: e.target.value });
  };

  return (
    <Flexbox name="company-products-header" direction="column" gap="16px">
      {isEditMode ? (
        <>
          <TitleContainer
            name="company-products-enterprise-title"
            direction="column"
          >
            <Input
              defaultValue={data.productTitle || undefined}
              onChange={handleTitleChanged}
              placeholder={t`products.titlePlaceholder`}
              fontSize="36px"
              error={
                showErrors && !data.productTitle
                  ? t`products.titleMissingError`
                  : ''
              }
              errorFontSize={'16px'}
              errorJustifyContent="end"
            />
          </TitleContainer>
          <Flexbox
            name="company-products-enterprise-switch"
            alignItems="center"
            gap="10px"
          >
            <Switch
              id="enterprise-ready-switch"
              checked={data.enterpriseReady}
              onChange={handleEnterpriseReadySwitched}
            />
            <EnterpriseReadyLabel htmlFor="enterprise-ready-switch">{t`products.enterpriseReady`}</EnterpriseReadyLabel>
          </Flexbox>
        </>
      ) : (
        <>
          <Header>{data.productTitle ?? t`products.header`}</Header>
          {data.enterpriseReady && (
            <GeneralTag
              width="fit-content"
              variant="navy"
            >{t`products.enterpriseReady`}</GeneralTag>
          )}
        </>
      )}
    </Flexbox>
  );
};

const TitleContainer = styled(Flexbox)`
  width: 500px;
`;

const EnterpriseReadyLabel = styled('label')`
  ${({ theme }) => theme.typography.inputs.select.label}
`;
