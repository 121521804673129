import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { companyIdState } from '../company.state';
import type { CompanyFreeTextField } from '@/api/v4/organization.api';
import { updateCompanyFreeTextField } from '@/api/v4/organization.api';

export const useEditCompanyFreeTextField = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  const mutation = useMutation(updateCompanyFreeTextField, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Company, companyId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const editFreeTextField = (data: CompanyFreeTextField) =>
    companyId
      ? mutation.mutateAsync({
          organizationId: companyId,
          data,
        })
      : undefined;

  return {
    editFreeTextField,
    isError: mutation.isError,
  };
};
