import { styled } from 'goober';

import { ParagraphLarge } from '@/ui/typography/widgets';

export const ProductDescription = styled(ParagraphLarge)`
  ${({ theme }) => theme.mixins.lineClamp(4)}
  display: block;
  font-size: 16px;
  white-space: pre-line;
`;
