import type { ProjectCompaniesSort } from '@/api/v4/projects.api';
import { colors } from '@/theme/colors';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const ProjectListingTableHeader: HeaderCellConfig<ProjectCompaniesSort>[] =
  [
    {
      columnKey: 'company',
      sortKey: 'companyName',
      padding: '30px 20px 20px 60px',
      bgColor: colors.basics.white,
    },
    {
      columnKey: 'lastRound',
      width: '300px',
      sortKey: 'fundingStage',
      bgColor: colors.basics.white,
    },
    {
      columnKey: 'totalRaised',
      width: '200px',
      sortKey: 'capitalRaised',
      bgColor: colors.basics.white,
    },
    {
      columnKey: 'founded',
      width: '200px',
      sortKey: 'yearEstablished',
      bgColor: colors.basics.white,
    },
    {
      columnKey: 'ranking',
      width: '180px',
      sortKey: 'averageRating',
      bgColor: colors.basics.white,
    },
    {
      columnKey: 'stage',
      padding: '21px 30px',
      width: '242px',
      sortKey: 'status',
      bgColor: colors.basics.white,
    },
  ];
