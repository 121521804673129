import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import type { QueryKey } from '@/config/query-client';
import { colors } from '@/theme/colors';
import { theme } from '@/theme/setupTheme';
import { Z_INDEX_MODAL_OVERLAY } from '@/theme/z-index';
import type { IOption } from '@/types';
import { Button } from '@/ui/button/button';
import { Stack } from '@/ui/line/line';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';

import type { ExampleCompany } from '../explore.state';
import { isExploreRequestHistoryPaneOpenState } from '../explore.state';
import { ExploreHistoryPane } from '../history/explore-history-pane';
import { isRegeneratePanelOpenState } from '../regenerate/regenerate-explore.state';
import { RegeneratePanel } from '../regenerate/regenerate-panel';

import type { ExploreSuggestionData } from './use-explore-results-table-config';
import { useExploreResultsTableConfig } from './use-explore-results-table-config';
import { useSortExploreResults } from './use-sort-explore-results';
import { useRegenerateExploreForm } from '../regenerate/use-regenerate-explore-form';
import { useBlockNavigation } from '@/routes/hooks/use-block-navigation';
import type { CompanySortOptions } from '@/api/v4/organization.api';

export const ExploreResults = ({
  inputData,
  suggestionData,
  dataQueryKey,
  requestId,
}: {
  inputData: {
    description: string;
    exampleCompanies: ExampleCompany[];
    locations: IOption[];
  };
  suggestionData: ExploreSuggestionData[];
  dataQueryKey: QueryKey;
  requestId: string;
}) => {
  const { t } = useTranslation('explore');
  const [isRegeneratePanelOpen, setRegeneratePanelOpen] = useRecoilState(
    isRegeneratePanelOpenState,
  );
  const [isHistoryPaneOpen, setIsHistoryPaneOpen] = useRecoilState(
    isExploreRequestHistoryPaneOpenState,
  );
  const { rows, headers } = useExploreResultsTableConfig({
    suggestions: suggestionData,
    requestId,
    dataQueryKey,
  });
  const { handleSort, sort } = useSortExploreResults();
  const { resetForm, checkIsDirty } = useRegenerateExploreForm();

  const defaultValues = useMemo(() => {
    return {
      defaultDescription: inputData?.description,
      defaultExampleCompanies: inputData?.exampleCompanies,
      defaultLocations: inputData?.locations,
    };
  }, [inputData]);

  const isRegenerateFormDirty = checkIsDirty(defaultValues);

  const onChangeRoute = () => {
    resetForm();
    setRegeneratePanelOpen(false);
  };

  useBlockNavigation({
    when: isRegeneratePanelOpen && isRegenerateFormDirty,
    additionalActionOnConfirm: onChangeRoute,
  });

  return (
    <Relative>
      <Container gap="20px">
        <Button
          variant="secondary"
          startIcon="Sprinkles"
          onClick={() => setRegeneratePanelOpen(true)}
        >
          {t`regenerate`}
        </Button>
        <Wrapper>
          <Table>
            <thead>
              <tr>
                {headers.cells.map(cell => (
                  <TableHeaderCell<CompanySortOptions | null>
                    key={cell.columnKey}
                    cell={cell}
                    sort={{
                      ...sort,
                      onSort: update =>
                        handleSort({ update, dataQueryKey, requestId }),
                    }}
                    sticky={false}
                  />
                ))}
              </tr>
            </thead>
            <TableBody rows={rows} borderColor={colors.gray.c3} />
          </Table>
        </Wrapper>
      </Container>
      {isRegeneratePanelOpen && (
        <RegeneratePanel
          onClose={() => setRegeneratePanelOpen(false)}
          isLoading={!!requestId && !suggestionData}
          defaultValues={defaultValues}
        />
      )}
      {isHistoryPaneOpen && (
        <>
          <ShadowLayer />
          <FixedWrapper>
            <ExploreHistoryPane
              isClosable
              onClose={() => setIsHistoryPaneOpen(false)}
            />
          </FixedWrapper>
        </>
      )}
    </Relative>
  );
};

const Relative = styled('div')`
  position: relative;
`;

const Container = styled(Stack)`
  height: 100%;
  padding: 20px 30px 40px 30px;
`;

const Wrapper = styled('div')`
  overflow-y: auto;
  position: relative;
  ${theme.mixins.scrollbar};
`;

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 15px);

  td {
    background-color: transparent;
  }
`;

const ShadowLayer = styled('div')`
  background-color: rgba(2, 6, 44, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX_MODAL_OVERLAY} -1;
`;

const FixedWrapper = styled('div')`
  width: 258px;
  background-color: ${({ theme }) =>
    theme.palettes.component.explore.form.background};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: ${Z_INDEX_MODAL_OVERLAY};
`;
