import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';

import type { Project } from '@/api/v4/projects.api';
import { WIDGET_CONTAINER_GAP } from '@/components/widgets/text-widget/text-widget';
import { isDefaultOptionArray } from '@/helpers/other';
import { useLoadableData } from '@/hooks/use-loadable-results';
import { impactsOptionsState } from '@/state/impacts-options.state';
import type { IOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';
import { GeneralTag } from '@/ui/tag/general-tag';
import type { EmptyStateWidgetProps } from '@/ui/widget/empty-state-widget';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';
import type { WidgetProps } from '@/ui/widget/widget';
import { Widget } from '@/ui/widget/widget';

interface ImpactWidgetProps extends WidgetProps {
  defaultValue: Project['impacts'] | undefined;
  emptyStateOptions?: Omit<EmptyStateWidgetProps, 'onClick'>;
  onSave: (value: string[]) => void;
}

export function ImpactWidget({
  height,
  defaultValue,
  emptyStateOptions,
  onSave,
  disabled,
  className,
  ...widgetProps
}: ImpactWidgetProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: impactsOptions } = useLoadableData(impactsOptionsState);

  const [impacts, setImpacts] = useState<IOption[]>([]);

  useEffect(() => {
    // force refresh default value on page load
    defaultValue &&
      setImpacts(
        defaultValue.map(item => ({
          value: item.id,
          label: item.name,
        })),
      );
  }, [defaultValue]);

  if (!isEditMode && emptyStateOptions && !disabled && !impacts.length) {
    return (
      <EmptyStateWidget
        height={height}
        onEditClick={() => setIsEditMode(true)}
        dataTestId={widgetProps.dataTestId}
        className={className}
        {...emptyStateOptions}
      />
    );
  }

  if (!impacts.length && disabled) {
    return <ReadOnlyEmptyWidget {...widgetProps} height={height} />;
  }

  const handleOnConfirm = () => {
    if (!isEditMode) return;

    setIsEditMode(false);
    if (
      impacts.length > 0 &&
      impacts.every((item, index) => item.value === defaultValue?.[index]?.id)
    ) {
      return;
    }
    onSave(impacts.map(item => item.value));
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOnConfirm}>
      <Container
        height={height}
        isEditMode={isEditMode}
        onEditClick={() => setIsEditMode(true)}
        disabled={disabled}
        className={className}
        {...widgetProps}
      >
        {!isEditMode ? (
          <Flexbox name="impacts-container" gap="12px" wrap="wrap">
            {impacts.map(tag => (
              <GeneralTag key={tag.value} variant="pink" size="m">
                {tag.label}
              </GeneralTag>
            ))}
          </Flexbox>
        ) : (
          <AsyncSelect
            defaultValue={impacts}
            setOptions={impactsOptions}
            isMulti
            onChange={newValue => {
              isDefaultOptionArray(newValue) && setImpacts(newValue);
            }}
            tagVariant="pink"
            {...sharedProps}
          />
        )}
      </Container>
    </OutsideClickHandler>
  );
}

const Container = styled(Widget)`
  min-width: 439px;
  display: flex;
  flex-direction: column;
  gap: ${WIDGET_CONTAINER_GAP}px;
`;
