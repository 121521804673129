import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { RelatedProjectOverflowContainer } from '@/components/widgets/related-projects-widget/related-project-overflow-container';
import { RelatedProjectRow } from '@/components/widgets/related-projects-widget/related-project-row';
import { useRelatedProjects } from '@/features/companies/overview/listed-in-projects/use-related-projects';
import { WIDGET_ROW_4_HEIGHT } from '@/features/companies/overview/utils/constants';
import { getListingStageName } from '@/features/listing-stage/helpers/get-listing-stage-name';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { componentsPalette } from '@/theme/colors';
import { APP_USER_ROLES } from '@/types';
import { ReadOnlyEmptyWidget } from '@/ui/widget/read-only-empty-widget';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { useUserRole } from '@/user/use-user-role';

import { companyIdState } from '../company.state';
import { useCompany } from '../use-company.query';
import { WidgetHeaderWithBackground } from '../widget-header-with-background';
import { WidgetLoaderContainer } from '../widget-loader-container';

import { ListedInEmptyState } from './empty-state';
import { WidgetHeader } from './widget-header';

export const ListedInProjectsWidget = ({
  className,
}: {
  className?: string;
}) => {
  const companyId = useRecoilValue(companyIdState);
  const {
    relatedProjects,
    otherProjectsCount,
    isLoading: isLoadingCompanyProjects,
  } = useRelatedProjects(companyId);
  const { t } = useTranslation('companies', { keyPrefix: 'company' });

  const { isLoading: isCompanyInitialLoading } = useCompany();

  const { hasRequiredPermission } = useUserPermissions();
  const userRole = useUserRole();
  const isAppUserGuest = userRole?.includes(APP_USER_ROLES.GUEST);
  const { listingStages, isFetching: isFetchingListingStages } =
    useListingStages();

  const canViewCompanyHomeScreen = hasRequiredPermission(
    PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN,
  );
  const { headerBackgroundColor, headerTextColor } =
    componentsPalette.company.listedInProjects;

  if (
    isLoadingCompanyProjects ||
    isCompanyInitialLoading ||
    isFetchingListingStages
  ) {
    return (
      <WidgetLoaderContainer height={WIDGET_ROW_4_HEIGHT} className={className}>
        <Loader />
      </WidgetLoaderContainer>
    );
  }

  if (!relatedProjects.length && canViewCompanyHomeScreen) {
    return (
      <ListedInEmptyState
        className={className}
        otherProjectsCount={otherProjectsCount}
        isDisabled={isAppUserGuest}
      />
    );
  }

  if (!canViewCompanyHomeScreen) {
    return (
      <ReadOnlyEmptyWidget
        header={
          <WidgetHeaderWithBackground
            backgroundColor={headerBackgroundColor}
            textColor={headerTextColor}
          >
            {t`listedIn`}
          </WidgetHeaderWithBackground>
        }
        height={WIDGET_ROW_4_HEIGHT}
        className={className}
        maxHeight={WIDGET_ROW_4_HEIGHT}
      />
    );
  }

  return (
    <RelatedProjectOverflowContainer
      Header={WidgetHeader}
      disabled={!canViewCompanyHomeScreen}
      defaultHeight={WIDGET_ROW_4_HEIGHT}
      className={className}
      withStatusTag={false}
      otherProjectsCount={otherProjectsCount}
      disableAddCompany={isAppUserGuest}
    >
      {relatedProjects.map(listedInProject => {
        const { project, listingCount, listingStatus } = listedInProject;

        return (
          <RelatedProjectRow
            key={project.id}
            projectId={project.id}
            projectName={project.name}
            listingsCount={listingCount}
            stage={getListingStageName(listingStatus, listingStages)}
          />
        );
      })}
    </RelatedProjectOverflowContainer>
  );
};
