import { useCallback } from 'react';
import { isEqual, omit } from 'lodash-es';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ContactsInfiniteScroll } from '@/features/contacts/contact-infinite-scroll';
import type { ContactFormItem } from '@/features/contacts/contacts';
import { ContactsWidget } from '@/features/contacts/contacts-widget';
import { processApiRequests } from '@/helpers/api-batch-request';

import {
  addTeamContactCounterState,
  editTeamContactProgressState,
  teamContactDeletedIdState,
  teamContactsAtom,
  teamContactsFormSubmitState,
  teamContactsPageState,
  teamIdState,
} from '../../team.state';

import { useCreateTeamContact } from './use-create-team-contact.mutation';
import { useTeamContacts } from './use-team-contacts';
import { useUpdateTeamContact } from './use-update-team-contacts.mutation';

export const TeamContactsSection = () => {
  const [editTeamContacts, setEditTeamContacts] = useRecoilState(
    editTeamContactProgressState,
  );

  const teamId = useRecoilValue(teamIdState);

  const { mutateAsync: createTeamContact } = useCreateTeamContact();
  const { mutateAsync: updateTeamContact } = useUpdateTeamContact();

  const {
    isLoading,
    contacts,
    hasNextPage,
    updateContact,
    reset,
    addContact,
    deleteContact,
  } = useTeamContacts();

  const onFormSubmit = useCallback(
    async (formContacts: ContactFormItem[]) => {
      if (!teamId) return;
      const contactsToSave = formContacts.reduce((accu, formContact) => {
        const oldCopy = contacts.find(({ id }) => id === formContact.id);
        if (!formContact.synced) return [...accu, formContact];

        if (
          (isEqual(omit(oldCopy, 'lastEditedDate'), formContact) &&
            formContact.synced) ||
          !oldCopy
        ) {
          return accu;
        }

        return [...accu, formContact];
      }, [] as ContactFormItem[]);

      const promises: Promise<void>[] = [];
      contactsToSave.forEach((contact: ContactFormItem) => {
        const promise = new Promise<void>(async resolve => {
          if (contact.synced) {
            const updatedTeamContact = await updateTeamContact({
              teamId,
              teamContactId: contact.id,
              payload: contact,
            });

            updateContact({
              ...updatedTeamContact,
              synced: true,
            });
          } else {
            const createdTeamContact = await createTeamContact({
              teamId,
              payload: contact,
            });

            addContact({
              ...contact,
              id: createdTeamContact.id,
              synced: true,
            });
          }

          resolve();
        });

        promises.push(promise);
      });

      await processApiRequests(promises);

      reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, contacts],
  );

  return (
    <ContactsWidget
      onEditClick={() => setEditTeamContacts(true)}
      showEditButton={!editTeamContacts && contacts.length > 0}
      isEditMode={editTeamContacts}
      contactFormSubmitState={teamContactsFormSubmitState}
      addContactCounterState={addTeamContactCounterState}
    >
      <ContactsInfiniteScroll
        formContactsState={teamContactsAtom}
        addContactCounterState={addTeamContactCounterState}
        contactsSubmitFormState={teamContactsFormSubmitState}
        contactDeletedIdState={teamContactDeletedIdState}
        contactsEditModeState={editTeamContactProgressState}
        contactsPageState={teamContactsPageState}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        onFormSubmit={onFormSubmit}
        deleteAction={deleteContact}
      />
    </ContactsWidget>
  );
};
