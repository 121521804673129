import { chunk } from 'lodash-es';

export const processApiRequests = async (requests: Promise<void>[]) => {
  const MAX_BATCH_SIZE = 10;
  const batches = chunk(requests, MAX_BATCH_SIZE);

  // Function to process a batch of requests
  const processBatch = async (batch: Promise<void>[]) => {
    try {
      await Promise.all(batch);
    } catch (error) {
      // Handle error for the batch
      if (error instanceof Error) console.error(error.message);
    }
  };

  // Process each batch sequentially
  const processBatches = async () => {
    for (const batch of batches) {
      await processBatch(batch);
    }
  };

  // Start processing the batches
  await processBatches();
};
