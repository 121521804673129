import { useQueryClient } from '@tanstack/react-query';
import { orderBy } from 'lodash-es';
import { useRecoilState } from 'recoil';

import type { GenAiRequestHistory } from '@/api/v4/genai-request-history.api';
import type { ExploreOrganizationSuggestion } from '@/api/v4/organization-suggestions.api';
import { QueryKey } from '@/config/query-client';
import type { ISort } from '@/ui/table/table.types';

import { exploreResultsSortState } from '../explore.state';
import type { CompanySortOptions } from '@/api/v4/organization.api';

export const useSortExploreResults = () => {
  const [sort, setSort] = useRecoilState(exploreResultsSortState);
  const queryClient = useQueryClient();

  const handleSort = ({
    update,
    dataQueryKey,
    requestId,
  }: {
    update: ISort<CompanySortOptions | null>;
    dataQueryKey: QueryKey;
    requestId?: string;
  }) => {
    setSort({ direction: update.direction, sort: update.sort });
    if (dataQueryKey === QueryKey.ExploreSuggestions) {
      queryClient.setQueryData(
        [QueryKey.ExploreSuggestions],
        (
          cachedResults: ExploreOrganizationSuggestion['results'] | undefined,
        ) => {
          if (!cachedResults) {
            return [];
          }
          return orderBy(
            cachedResults,
            cachedResults => cachedResults.name,
            update.direction,
          );
        },
      );
    }

    if (dataQueryKey === QueryKey.ExploreRequestHistorySuggestions) {
      queryClient.setQueryData(
        [QueryKey.ExploreRequestHistorySuggestions, requestId],
        (cachedResults: GenAiRequestHistory['result'] | undefined) => {
          if (!cachedResults) {
            return [];
          }
          return orderBy(
            cachedResults,
            cachedResults => cachedResults.name,
            update.direction,
          );
        },
      );
    }
  };

  return {
    handleSort,
    sort,
  };
};
