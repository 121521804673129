import { styled } from 'goober';

import { ContactCardBaseContainer } from './contact-card-base';

export const ContactCardSkeleton = () => {
  return <SkeletonCard as={ContactCardBaseContainer} />;
};

const SkeletonCard = styled('div')`
  height: 385px;
  ${({ theme }) => theme.animations.skeletonAnimation()};
`;
