import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import type { IconVariant } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { useSectionsPane } from '../use-sections-pane';

export interface AdditionalSectionButtonProps {
  disabled: boolean;
  icon: IconVariant;
  addSectionHandler: () => void;
  text: string;
  disabledHoverText?: string;
}

export const AdditionalSectionButton = ({
  disabled,
  icon,
  addSectionHandler,
  text,
  disabledHoverText,
}: AdditionalSectionButtonProps) => {
  const { closeSectionPane } = useSectionsPane();

  const onAdd = () => {
    addSectionHandler();
    closeSectionPane();
  };

  return (
    <Tooltip
      content={disabledHoverText}
      visible={Boolean(disabledHoverText) && disabled}
    >
      <Button
        variant="secondary"
        startIcon={icon}
        iconColor={!disabled ? colors.blue.primaryA : colors.basics.white}
        disabled={disabled}
        onClick={onAdd}
      >
        {text}
      </Button>
    </Tooltip>
  );
};
