import { forwardRef, useMemo } from 'react';

import type { TeamMember, TeamMemberSortParams } from '@/api/v4/team.api';
import { formatDate } from '@/helpers/format-date';
import { getUserInitials } from '@/helpers/get-user-initials';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { CircleIcon } from '@/ui/icons/circle';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import type { Row } from '@/ui/table/table.types';

import { styled } from 'goober';
import { UserName } from '../../../../ui/typography/across-platform';
import type { TeamMemberStatus } from './basic-tab-config';
import { TeamMemberMenu } from './team-member-menu';
import { teamMembersTableConfiguration } from './team-members-table-configuration';

export const useTeamMembersTableConfiguration = (
  teamMembers: TeamMember[],
  status: TeamMemberStatus,
) => {
  const header = useTranslateHeaders<TeamMemberSortParams>(
    teamMembersTableConfiguration(status),
    'teams',
  );

  const rows: Row[] = useMemo(
    () =>
      teamMembers.map(teamMember => {
        return {
          id: `${teamMember.id}`,
          cells: [
            {
              value: (
                <Flexbox name="" alignItems="center" gap="8px">
                  <CircleIcon
                    bgColor={colors.blue.primaryA}
                    label={getUserInitials(teamMember)}
                  />
                  <EllipsisTextTooltip
                    text={`${teamMember.firstName} ${teamMember.lastName}`}
                    Component={<CellText />}
                  />
                </Flexbox>
              ),
            },
            {
              value: (
                <EllipsisTextTooltip
                  text={teamMember.email}
                  Component={<CellText />}
                />
              ),
            },
            {
              value: (
                <EllipsisTextTooltip
                  text={teamMember.title}
                  Component={<CellText />}
                />
              ),
            },
            {
              value: <CellText>{teamMember.role.name}</CellText>,
              align: 'left',
              padding: '0 20px',
            },
            {
              value: (
                <CellText>
                  {formatDate(new Date(teamMember.createdDate))}
                </CellText>
              ),
            },
            {
              value: <TeamMemberMenu teamMember={teamMember} status={status} />,
              padding: '0',
            },
          ],
        };
      }),
    [status, teamMembers],
  );

  return {
    header,
    rows,
  };
};

const CellText = styled(UserName, forwardRef)`
  max-width: 400px;
  ${({ theme }) => theme.mixins.ellipsis}
`;
